import { apiUrl } from '../../services';
import { http, http_noauth } from '../../services/client';
import { showMessage } from '../message';
import { getAccessToken } from '../../services/authData';

//===================================COMPANY===============================================

export const CREATE_JOB_SUCCESS = 'JOB_MANAGE_SUCCESS';
export const CREATE_JOB_REQUEST = 'JOB_MANAGE_REQUEST';
export const CREATE_JOB_FAILURE = 'JOB_MANAGE_FAILURE';

const createJobRequest = () => ({
  type: CREATE_JOB_REQUEST,
});

const createJobFailure = (error) => ({
  type: CREATE_JOB_FAILURE,
  payload: { error },
});

const createJobSuccess = (data) => ({
  type: CREATE_JOB_SUCCESS,
  payload: { data },
});

export const createJob = (payload) => {
  return (dispatch) => {
    dispatch(createJobRequest());
    const token = getAccessToken();
    return http
      .post(apiUrl.CREATE_JOB, payload)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === true) {
          showMessage({
            dispatch,
            message: 'Job Successfully created',
            variant: 'success',
          });
          dispatch(createJobSuccess(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.data,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(createJobFailure(error));
        return error;
      });
  };
};
// Update Job Company

export const UPDATE_JOB_SUCCESS = 'UPDATE_JOB_SUCCESS';
export const UPDATE_JOB_REQUEST = 'UPDATE_JOB_REQUEST';
export const UPDATE_JOB_FAILURE = 'UPDATE_JOB_FAILURE';

const updateJobRequest = () => ({
  type: UPDATE_JOB_REQUEST,
});

const updateJobFailure = (error) => ({
  type: UPDATE_JOB_FAILURE,
  payload: { error: error },
});

const updateJobSuccess = (data) => ({
  type: UPDATE_JOB_SUCCESS,
  payload: { jobs: data },
});

export const updateJob = (payload) => {
  return (dispatch) => {
    dispatch(updateJobRequest());
    const token = getAccessToken();
    return http
      .post(apiUrl.UPDATE_JOB, payload)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === true) {
          showMessage({
            dispatch,
            message: 'Job Update',
            variant: 'success',
          });
          dispatch(updateJobSuccess(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.data,
            variant: 'error',
          });
        }

        return response;
      })
      .catch((error) => {
        dispatch(updateJobFailure(error));
        return error;
      });
  };
};

// Company Get OPEN jobs
export const GET_ALL_OPEN_JOBS_SUCCESS = 'GET_ALL_OPEN_JOBS_SUCCESS';
export const GET_ALL_OPEN_JOBS_REQUEST = 'GET_ALL_OPEN_JOBS_REQUEST';
export const GET_ALL_OPEN_JOBS_FAILURE = 'GET_ALL_OPEN_JOBS_FAILURE';

const getAllJobsRequest = () => ({
  type: GET_ALL_OPEN_JOBS_REQUEST,
});

const getAllJobsFailure = (error) => ({
  type: GET_ALL_OPEN_JOBS_FAILURE,
  payload: { error: error },
});

const getAllJobsSuccess = (data) => ({
  type: GET_ALL_OPEN_JOBS_SUCCESS,
  payload: { jobs: data },
});
export const getAllOpenJobs = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getAllJobsRequest());
    return http
      .post(apiUrl.COMPANY_GET_ALL_JOB, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getAllJobsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getAllJobsFailure(error));
        console.log('error', error);
      });
  };
};
export const GET_ALL_INACTIVE_JOBS_SUCCESS = 'GET_ALL_INACTIVE_JOBS_SUCCESS';
export const GET_ALL_INACTIVE_JOBS_REQUEST = 'GET_ALL_INACTIVE_JOBS_REQUEST';
export const GET_ALL_INACTIVE_JOBS_FAILURE = 'GET_ALL_INACTIVE_JOBS_FAILURE';

const getAllInactiveJobsRequest = () => ({
  type: GET_ALL_INACTIVE_JOBS_REQUEST,
});

const getAllInactiveJobsFailure = (error) => ({
  type: GET_ALL_INACTIVE_JOBS_FAILURE,
  payload: { error: error },
});

const getAllInactiveJobsSuccess = (data) => ({
  type: GET_ALL_INACTIVE_JOBS_SUCCESS,
  payload: { jobs: data },
});
export const getAllInactiveJobs = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getAllInactiveJobsRequest());
    return http
      .post(apiUrl.COMPANY_GET_ALL_JOB, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getAllInactiveJobsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getAllInactiveJobsFailure(error));
        console.log('error', error);
      });
  };
};
export const GET_ALL_CLOSED_JOBS_SUCCESS = 'GET_ALL_CLOSED_JOBS_SUCCESS';
export const GET_ALL_CLOSED_JOBS_REQUEST = 'GET_ALL_CLOSED_JOBS_REQUEST';
export const GET_ALL_CLOSED_JOBS_FAILURE = 'GET_ALL_CLOSED_JOBS_FAILURE';

const getAllClosedJobsRequest = () => ({
  type: GET_ALL_CLOSED_JOBS_REQUEST,
});

const getAllClosedJobsFailure = (error) => ({
  type: GET_ALL_CLOSED_JOBS_FAILURE,
  payload: { error: error },
});

const getAllClosedJobsSuccess = (data) => ({
  type: GET_ALL_CLOSED_JOBS_SUCCESS,
  payload: { jobs: data },
});
export const getAllClosedJobs = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getAllClosedJobsRequest());
    return http
      .post(apiUrl.COMPANY_GET_ALL_JOB, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getAllClosedJobsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getAllClosedJobsFailure(error));
        console.log('error', error);
      });
  };
};

// Company Get Job Description
export const GET_JOB_DESCRIPTION_SUCCESS = 'GET_JOB_DESCRIPTION_SUCCESS';
export const GET_JOB_DESCRIPTION_REQUEST = 'GET_JOB_DESCRIPTION_REQUEST';
export const GET_JOB_DESCRIPTION_FAILURE = 'GET_JOB_DESCRIPTION_FAILURE';

const getJobDescriptionRequest = () => ({
  type: GET_JOB_DESCRIPTION_REQUEST,
});

const getJobDescriptionFailure = (error) => ({
  type: GET_JOB_DESCRIPTION_FAILURE,
  payload: { error: error },
});

const getJobDescriptionSuccess = (data) => ({
  type: GET_JOB_DESCRIPTION_SUCCESS,
  payload: { data },
});
export const getJobDescription = (payload) => {
  return (dispatch) => {
    dispatch(getJobDescriptionRequest());
    const path = apiUrl.COMPANY_GET_JOB_DESCRIPTION + payload;
    return http
      .get(path)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getJobDescriptionSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getJobDescriptionFailure(error));
        console.log('error', error);
      });
  };
};

// Company Get Job Description
export const GET_JOB_BY_FILTER_SUCCESS = 'GET_JOB_BY_FILTER_SUCCESS';
export const GET_JOB_BY_FILTER_REQUEST = 'GET_JOB_BY_FILTER_REQUEST';
export const GET_JOB_BY_FILTER_FAILURE = 'GET_JOB_BY_FILTER_FAILURE';

const getJobByFilterRequest = () => ({
  type: GET_JOB_BY_FILTER_REQUEST,
});

const getJobByFilterFailure = (error) => ({
  type: GET_JOB_BY_FILTER_FAILURE,
  payload: { error: error },
});

const getJobByFilterSuccess = (data) => ({
  type: GET_JOB_BY_FILTER_SUCCESS,
  payload: { jobs: data },
});
export const getJobByFilter = (payload) => {
  return (dispatch) => {
    dispatch(getJobByFilterRequest());
    return http
      .post(apiUrl.COMPANY_GET_JOB_BY_FILTER, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getJobByFilterSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getJobByFilterFailure(error));
        console.log('error', error);
      });
  };
};

//=======================================CANDIDATE==============================================
export const CANDIDATE_APPLY_JOB_SUCCESS = 'CANDIDATE_APPLY_JOB_SUCCESS';
export const CANDIDATE_APPLY_JOB_REQUEST = 'CANDIDATE_APPLY_JOB_REQUEST';
export const CANDIDATE_APPLY_JOB_FAILURE = 'CANDIDATE_APPLY_JOB_FAILURE';

const candidateApplyJobRequest = () => ({
  type: CANDIDATE_APPLY_JOB_REQUEST,
});

const candidateApplyJobFailure = (error) => ({
  type: CANDIDATE_APPLY_JOB_FAILURE,
  payload: { error: error },
});

const candidateApplyJobSuccess = (data) => ({
  type: CANDIDATE_APPLY_JOB_SUCCESS,
  payload: { jobs: data },
});
export const candidateApplyJob = (payload) => {
  return (dispatch) => {
    dispatch(candidateApplyJobRequest());
    return http
      .post(apiUrl.CANDTDATE_APPLY_JOB, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          showMessage({
            dispatch,
            message: 'Job Apply Successfully',
            variant: 'success',
          });
          dispatch(candidateApplyJobSuccess(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.msg,
            variant: 'success',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateApplyJobFailure(error));
        console.log('error', error);
      });
  };
};
export const CANDIDATE_WITHDRAW_JOB_SUCCESS = 'CANDIDATE_WITHDRAW_JOB_SUCCESS';
export const CANDIDATE_WITHDRAW_JOB_REQUEST = 'CANDIDATE_WITHDRAW_JOB_REQUEST';
export const CANDIDATE_WITHDRAW_JOB_FAILURE = 'CANDIDATE_WITHDRAW_JOB_FAILURE';

const candidateWithdrawJobRequest = () => ({
  type: CANDIDATE_WITHDRAW_JOB_REQUEST,
});

const candidateWithdrawJobFailure = (error) => ({
  type: CANDIDATE_WITHDRAW_JOB_FAILURE,
  payload: { error: error },
});

const candidateWithdrawJobSuccess = (data) => ({
  type: CANDIDATE_WITHDRAW_JOB_SUCCESS,
  payload: { data },
});
export const candidateWithdrawJob = (payload) => {
  return (dispatch) => {
    dispatch(candidateWithdrawJobRequest());
    return http
      .post(apiUrl.CANDIDATE_WITHDRAW_JOB, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          showMessage({
            dispatch,
            message: 'Withdraw from job  Successfully',
            variant: 'success',
          });
          dispatch(candidateWithdrawJobSuccess(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.msg,
            variant: 'success',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateWithdrawJobFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_ALL_CANDIDATE_OPEN_JOBS_SUCCESS =
  'GET_ALL_CANDIDATE_OPEN_JOBS_SUCCESS';
export const GET_ALL_CANDIDATE_OPEN_JOBS_REQUEST =
  'GET_ALL_CANDIDATE_OPEN_JOBS_REQUEST';
export const GET_ALL_CANDIDATE_OPEN_JOBS_FAILURE =
  'GET_ALL_CANDIDATE_OPEN_JOBS_FAILURE';

const getAllCandidateOpenJobsRequest = () => ({
  type: GET_ALL_CANDIDATE_OPEN_JOBS_REQUEST,
});

const getAllCandidateOpenJobsFailure = (error) => ({
  type: GET_ALL_CANDIDATE_OPEN_JOBS_FAILURE,
  payload: { error: error },
});

const getAllCandidateOpenJobsSuccess = (data) => ({
  type: GET_ALL_CANDIDATE_OPEN_JOBS_SUCCESS,
  payload: { jobs: data },
});
export const getAllCandidateOpenJobs = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getAllJobsRequest());
    return http
      .post(apiUrl.COMPANY_GET_ALL_JOB, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getAllJobsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getAllJobsFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_ALL_SEARCH_JOBS_SUCCESS = 'GET_ALL_SEARCH_JOBS_SUCCESS';
export const GET_ALL_SEARCH_JOBS_REQUEST = 'GET_ALL_SEARCH_JOBS_REQUEST';
export const GET_ALL_SEARCH_JOBS_FAILURE = 'GET_ALL_SEARCH_JOBS_FAILURE';

const getSearchJobListRequest = () => ({
  type: GET_ALL_SEARCH_JOBS_REQUEST,
});

const getSearchJobListFailure = (error) => ({
  type: GET_ALL_SEARCH_JOBS_FAILURE,
  payload: { error: error },
});

const getSearchJobListSuccess = (data) => ({
  type: GET_ALL_SEARCH_JOBS_SUCCESS,
  payload: { data },
});
export const getSearchJobList = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getSearchJobListRequest());
    let paramsurl = '';
    for (let [key, value] of Object.entries(payload)) {
      console.log(key, value);
      if (key === 'page_no') {
        paramsurl = paramsurl + '?' + key + '=' + value;
      } else {
        paramsurl = paramsurl + '&' + key + '=' + value;
      }
    }

    const path = apiUrl.COMPANY_GET_JOB_BY_FILTER + paramsurl;
    return http
      .get(path)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getSearchJobListSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getSearchJobListFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_SUCCESS =
  'GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_SUCCESS';
export const GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_REQUEST =
  'GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_REQUEST';
export const GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_FAILURE =
  'GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_FAILURE';

const getAllCandidateWithdrawOpenJobsRequest = () => ({
  type: GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_REQUEST,
});

const getAllCandidateWithdrawOpenJobsFailure = (error) => ({
  type: GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_FAILURE,
  payload: { error: error },
});

const getAllCandidateWithdrawOpenJobsSuccess = (data) => ({
  type: GET_ALL_CANDIDATE_WITHDRAW_OPEN_JOBS_SUCCESS,
  payload: data,
});
export const getAllCandidateWithdrawOpenJobs = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getAllCandidateWithdrawOpenJobsRequest());
    return http
      .post(apiUrl.GET_ALL_APPLIED_JOB, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getAllCandidateWithdrawOpenJobsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getAllCandidateWithdrawOpenJobsFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_SUCCESS =
  'GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_SUCCESS';
export const GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_REQUEST =
  'GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_REQUEST';
export const GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_FAILURE =
  'GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_FAILURE';

const getAllCandidateAppliedOpenJobsRequest = () => ({
  type: GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_REQUEST,
});

const getAllCandidateAppliedOpenJobsFailure = (error) => ({
  type: GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_FAILURE,
  payload: { error: error },
});

const getAllCandidateAppliedOpenJobsSuccess = (data) => ({
  type: GET_ALL_CANDIDATE_APPLIED_OPEN_JOBS_SUCCESS,
  payload: data,
});
export const getAllCandidateAppliedOpenJobs = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getAllCandidateAppliedOpenJobsRequest());
    return http
      .post(apiUrl.GET_ALL_APPLIED_JOB, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getAllCandidateAppliedOpenJobsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getAllCandidateAppliedOpenJobsFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_SUCCESS =
  'GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_SUCCESS';
export const GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_REQUEST =
  'GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_REQUEST';
export const GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_FAILURE =
  'GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_FAILURE';

const getAllCandidateShortlistOpenJobsRequest = () => ({
  type: GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_REQUEST,
});

const getAllCandidateShortlistOpenJobsFailure = (error) => ({
  type: GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_FAILURE,
  payload: { error: error },
});

const getAllCandidateShortlistOpenJobsSuccess = (data) => ({
  type: GET_ALL_CANDIDATE_SHORTLIST_OPEN_JOBS_SUCCESS,
  payload: data,
});
export const getAllCandidateShortlistOpenJobs = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getAllCandidateShortlistOpenJobsRequest());
    return http
      .post(apiUrl.GET_ALL_APPLIED_JOB, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getAllCandidateShortlistOpenJobsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getAllCandidateShortlistOpenJobsFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_SUCCESS =
  'GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_SUCCESS';
export const GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_REQUEST =
  'GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_REQUEST';
export const GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_FAILURE =
  'GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_FAILURE';

const getAllCandidateHoldOpenJobsRequest = () => ({
  type: GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_REQUEST,
});

const getAllCandidateHoldOpenJobsFailure = (error) => ({
  type: GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_FAILURE,
  payload: { error: error },
});

const getAllCandidateHoldOpenJobsSuccess = (data) => ({
  type: GET_ALL_CANDIDATE_HOLD_OPEN_JOBS_SUCCESS,
  payload: data,
});
export const getAllCandidateHoldOpenJobs = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getAllCandidateHoldOpenJobsRequest());
    return http
      .post(apiUrl.GET_ALL_APPLIED_JOB, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getAllCandidateHoldOpenJobsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getAllCandidateHoldOpenJobsFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_SUCCESS =
  'GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_SUCCESS';
export const GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_REQUEST =
  'GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_REQUEST';
export const GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_FAILURE =
  'GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_FAILURE';

const getAllCandidateRejectedOpenJobsRequest = () => ({
  type: GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_REQUEST,
});

const getAllCandidateRejectedOpenJobsFailure = (error) => ({
  type: GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_FAILURE,
  payload: { error: error },
});

const getAllCandidateRejectedOpenJobsSuccess = (data) => ({
  type: GET_ALL_CANDIDATE_REJECTED_OPEN_JOBS_SUCCESS,
  payload: data,
});
export const getAllCandidateRejectedOpenJobs = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getAllCandidateRejectedOpenJobsRequest());
    return http
      .post(apiUrl.GET_ALL_APPLIED_JOB, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getAllCandidateRejectedOpenJobsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getAllCandidateRejectedOpenJobsFailure(error));
        console.log('error', error);
      });
  };
};

export const CANDIDATE_APPLY_JOBS_SUCCESS = 'CANDIDATE_APPLY_JOBS_SUCCESS';
export const CANDIDATE_APPLY_JOBS_REQUEST = 'CANDIDATE_APPLY_JOBS_REQUEST';
export const CANDIDATE_APPLY_JOBS_FAILURE = 'CANDIDATE_APPLY_JOBS_FAILURE';

const candidateJobApplyJobsRequest = () => ({
  type: CANDIDATE_APPLY_JOBS_REQUEST,
});

const candidateJobApplyJobsFailure = (error) => ({
  type: CANDIDATE_APPLY_JOBS_FAILURE,
  payload: { error: error },
});

const candidateJobApplyJobsSuccess = (data) => ({
  type: CANDIDATE_APPLY_JOBS_SUCCESS,
  payload: data,
});
export const candidateJobApply = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(candidateJobApplyJobsRequest());
    return http
      .post(apiUrl.APPLY_JOB_CANDIDATE, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          showMessage({
            dispatch,
            message: 'Successfully Job Applied',
            variant: 'success',
          });

          dispatch(candidateJobApplyJobsSuccess(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.msg,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateJobApplyJobsFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_SUCCESS =
  'GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_SUCCESS';
export const GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_REQUEST =
  'GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_REQUEST';
export const GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_FAILURE =
  'GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_FAILURE';

const getAllCompanyCandidateAppliedOpenJobsRequest = () => ({
  type: GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_REQUEST,
});

const getAllCompanyCandidateAppliedOpenJobsFailure = (error) => ({
  type: GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_FAILURE,
  payload: { error: error },
});

const getAllCompanyCandidateAppliedOpenJobsSuccess = (data) => ({
  type: GET_ALL_COMAPANY_CANDIDATE_APPLIED_OPEN_JOBS_SUCCESS,
  payload: data,
});
export const getAllCompanyCandidateAppliedOpenJobs = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getAllCompanyCandidateAppliedOpenJobsRequest());
    return http
      .post(apiUrl.GET_ALL_APPLIED_CANDIDATE, payload)
      .then((response) => {
        console.log('GET_ALL_APPLIED_CANDIDATE', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getAllCompanyCandidateAppliedOpenJobsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getAllCompanyCandidateAppliedOpenJobsFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_SUCCESS =
  'GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_SUCCESS';
export const GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_REQUEST =
  'GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_REQUEST';
export const GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_FAILURE =
  'GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_FAILURE';

const getAllCompanyCandidateShortlistOpenJobsRequest = () => ({
  type: GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_REQUEST,
});

const getAllCompanyCandidateShortlistOpenJobsFailure = (error) => ({
  type: GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_FAILURE,
  payload: { error: error },
});

const getAllCompanyCandidateShortlistOpenJobsSuccess = (data) => ({
  type: GET_ALL_COMAPANY_CANDIDATE_SHORTLIST_OPEN_JOBS_SUCCESS,
  payload: data,
});
export const getAllCompanyCandidateShortlistOpenJobs = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getAllCompanyCandidateShortlistOpenJobsRequest());
    return http
      .post(apiUrl.GET_ALL_APPLIED_CANDIDATE, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getAllCompanyCandidateShortlistOpenJobsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getAllCompanyCandidateShortlistOpenJobsFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_SUCCESS =
  'GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_SUCCESS';
export const GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_REQUEST =
  'GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_REQUEST';
export const GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_FAILURE =
  'GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_FAILURE';

const getAllCompanyCandidateHoldOpenJobsRequest = () => ({
  type: GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_REQUEST,
});

const getAllCompanyCandidateHoldOpenJobsFailure = (error) => ({
  type: GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_FAILURE,
  payload: { error: error },
});

const getAllCompanyCandidateHoldOpenJobsSuccess = (data) => ({
  type: GET_ALL_COMAPANY_CANDIDATE_HOLD_OPEN_JOBS_SUCCESS,
  payload: data,
});
export const getAllCompanyCandidateHoldOpenJobs = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getAllCompanyCandidateHoldOpenJobsRequest());
    return http
      .post(apiUrl.GET_ALL_APPLIED_CANDIDATE, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getAllCompanyCandidateHoldOpenJobsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getAllCompanyCandidateHoldOpenJobsFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_SUCCESS =
  'GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_SUCCESS';
export const GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_REQUEST =
  'GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_REQUEST';
export const GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_FAILURE =
  'GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_FAILURE';

const getAllCompanyCandidateRejectOpenJobsRequest = () => ({
  type: GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_REQUEST,
});

const getAllCompanyCandidateRejectOpenJobsFailure = (error) => ({
  type: GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_FAILURE,
  payload: { error: error },
});

const getAllCompanyCandidateRejectOpenJobsSuccess = (data) => ({
  type: GET_ALL_COMAPANY_CANDIDATE_REJECT_OPEN_JOBS_SUCCESS,
  payload: data,
});
export const getAllCompanyCandidateRejectOpenJobs = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getAllCompanyCandidateRejectOpenJobsRequest());
    return http
      .post(apiUrl.GET_ALL_APPLIED_CANDIDATE, payload)
      .then((response) => {
        console.log('response', response);
        // setTokenResponse(response?.config?.headers["x-auth"]);
        if (response?.data?.status === true) {
          dispatch(getAllCompanyCandidateRejectOpenJobsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getAllCompanyCandidateRejectOpenJobsFailure(error));
        console.log('error', error);
      });
  };
};

export const UPDATE_JOB_STATUS_SUCCESS = 'UPDATE_JOB_STATUS_SUCCESS';
export const UPDATE_JOB_STATUS_REQUEST = 'UPDATE_JOB_STATUS_REQUEST';
export const UPDATE_JOB_STATUS_FAILURE = 'UPDATE_JOB_STATUS_FAILURE';

const updateJobStatusRequest = () => ({
  type: UPDATE_JOB_STATUS_REQUEST,
});

const updateJobStatusFailure = (error) => ({
  type: UPDATE_JOB_STATUS_FAILURE,
  payload: { error: error },
});

const updateJobStatusSuccess = (data) => ({
  type: UPDATE_JOB_STATUS_SUCCESS,
  payload: { data },
});

export const updateJobStatus = (payload) => {
  return (dispatch) => {
    dispatch(updateJobStatusRequest());
    return http
      .post(apiUrl.UPDATE_JOB_STATUS, payload)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === true) {
          showMessage({
            dispatch,
            message: 'Successfully update Job status',
            variant: 'success',
          });

          dispatch(updateJobStatusSuccess(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.data,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(updateJobStatusFailure(error));
        return error;
      });
  };
};

export const COMPANY_CANDIDATE_JOB_UPDATE_STATUS_SUCCESS =
  'COMPANY_CANDIDATE_JOB_UPDATE_STATUS_SUCCESS';
export const COMPANY_CANDIDATE_JOB_UPDATE_STATUS_REQUEST =
  'COMPANY_CANDIDATE_JOB_UPDATE_STATUS_REQUEST';
export const COMPANY_CANDIDATE_JOB_UPDATE_STATUS_FAILURE =
  'COMPANY_CANDIDATE_JOB_UPDATE_STATUS_FAILURE';

const updateJobCandidateStatusRequest = () => ({
  type: COMPANY_CANDIDATE_JOB_UPDATE_STATUS_REQUEST,
});

const updateJobCandidateStatusFailure = (error) => ({
  type: COMPANY_CANDIDATE_JOB_UPDATE_STATUS_FAILURE,
  payload: { error: error },
});

const updateJobCandidateStatusSuccess = (data) => ({
  type: COMPANY_CANDIDATE_JOB_UPDATE_STATUS_SUCCESS,
  payload: { data },
});

export const updateJobCandidateStatus = (payload) => {
  return (dispatch) => {
    dispatch(updateJobCandidateStatusRequest());
    return http
      .post(apiUrl.COMPANY_CANDIDATE_JOB_UPDATE_STATUS, payload)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === true) {
          dispatch(updateJobCandidateStatusSuccess(response));
          showMessage({
            dispatch,
            message: 'Successfully update Candidate status',
            variant: 'success',
          });
        } else {
          showMessage({
            dispatch,
            message: response?.data?.msg,
            variant: 'error',
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(updateJobCandidateStatusFailure(error));
        return error;
      });
  };
};

export const GET_COMPANY_CANDIDATE_APPLIED_STATUS_SUCCESS =
  'GET_COMPANY_CANDIDATE_APPLIED_STATUS_SUCCESS';
export const GET_COMPANY_CANDIDATE_APPLIED_STATUS_REQUEST =
  'GET_COMPANY_CANDIDATE_APPLIED_STATUS_REQUEST';
export const GET_COMPANY_CANDIDATE_APPLIED_STATUS_FAILURE =
  'GET_COMPANY_CANDIDATE_APPLIED_STATUS_FAILURE';

const getCompanyCandidateAppliedStatusRequest = () => ({
  type: GET_COMPANY_CANDIDATE_APPLIED_STATUS_REQUEST,
});

const getCompanyCandidateAppliedStatusFailure = (error) => ({
  type: GET_COMPANY_CANDIDATE_APPLIED_STATUS_FAILURE,
  payload: { error: error },
});

const getCompanyCandidateAppliedStatusSuccess = (data) => ({
  type: GET_COMPANY_CANDIDATE_APPLIED_STATUS_SUCCESS,
  payload: data,
});
export const getCompanyCandidateAppliedStatus = (payload) => {
  return (dispatch) => {
    dispatch(getCompanyCandidateAppliedStatusRequest());

    return http
      .post(apiUrl.COMPANY_CANDIDATE_APPLIED_STATUS, payload)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === true) {
          dispatch(getCompanyCandidateAppliedStatusSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getCompanyCandidateAppliedStatusFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_CANDIDATE_JOB_APPLIED_STATUS_SUCCESS =
  'GET_CANDIDATE_JOB_APPLIED_STATUS_SUCCESS';
export const GET_CANDIDATE_JOB_APPLIED_STATUS_REQUEST =
  'GET_CANDIDATE_JOB_APPLIED_STATUS_REQUEST';
export const GET_CANDIDATE_JOB_APPLIED_STATUS_FAILURE =
  'GET_CANDIDATE_JOB_APPLIED_STATUS_FAILURE';

const getCandidateJobAppliedStatusRequest = () => ({
  type: GET_CANDIDATE_JOB_APPLIED_STATUS_REQUEST,
});

const getCandidateJobAppliedStatusFailure = (error) => ({
  type: GET_CANDIDATE_JOB_APPLIED_STATUS_FAILURE,
  payload: { error: error },
});

const getCandidateJobAppliedStatusSuccess = (data) => ({
  type: GET_CANDIDATE_JOB_APPLIED_STATUS_SUCCESS,
  payload: data,
});
export const getCandidateJobAppliedStatus = (payload) => {
  return (dispatch) => {
    dispatch(getCandidateJobAppliedStatusRequest());

    return http
      .post(apiUrl.CANDIDATE_JOB_APPLIED_STATUS, payload)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === true) {
          dispatch(getCandidateJobAppliedStatusSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getCandidateJobAppliedStatusFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_JOB_CANDIDATE_PROFILE_DETAILS_SUCCESS =
  'GET_JOB_CANDIDATE_PROFILE_DETAILS_SUCCESS';
export const GET_JOB_CANDIDATE_PROFILE_DETAILS_REQUEST =
  'GET_JOB_CANDIDATE_PROFILE_DETAILS_REQUEST';
export const GET_JOB_CANDIDATE_PROFILE_DETAILS_FAILURE =
  'GET_JOB_CANDIDATE_PROFILE_DETAILS_FAILURE';

const getJobCandidateRequest = () => ({
  type: GET_JOB_CANDIDATE_PROFILE_DETAILS_REQUEST,
});

const getJobCandidateFailure = (error) => ({
  type: GET_JOB_CANDIDATE_PROFILE_DETAILS_FAILURE,
  payload: { error: error },
});

const getJobCandidateSuccess = (data) => ({
  type: GET_JOB_CANDIDATE_PROFILE_DETAILS_SUCCESS,
  payload: data,
});
export const getJobCandidateProfile = (id) => {
  return (dispatch) => {
    dispatch(getJobCandidateRequest());
    const path = apiUrl.JOB_CANDIDATE_PROFILE + '?id=' + id;
    return http
      .get(path)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === true) {
          dispatch(getJobCandidateSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getJobCandidateFailure(error));
        console.log('error', error);
      });
  };
};

///

export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';

const getLocationRequest = () => ({
  type: GET_LOCATION_REQUEST,
});

const getLocationFailure = (error) => ({
  type: GET_LOCATION_FAILURE,
  payload: { error: error },
});

const getLocationSuccess = (data) => ({
  type: GET_LOCATION_SUCCESS,
  payload: data,
});
export const getLocation = (id) => {
  return (dispatch) => {
    dispatch(getLocationRequest());
    const path = apiUrl.GET_LOCATION;
    return http_noauth
      .get(path)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === true) {
          dispatch(getLocationSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getLocationFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS';
export const GET_SKILLS_REQUEST = 'GET_SKILLS_REQUEST';
export const GET_SKILLS_FAILURE = 'GET_SKILLS_FAILURE';

const getSkillsRequest = () => ({
  type: GET_SKILLS_REQUEST,
});

const getSkillsFailure = (error) => ({
  type: GET_SKILLS_FAILURE,
  payload: { error: error },
});

const getSkillsSuccess = (data) => ({
  type: GET_SKILLS_SUCCESS,
  payload: data,
});
export const getSkills = (id) => {
  return (dispatch) => {
    dispatch(getSkillsRequest());
    const path = apiUrl.GET_SKILLS + '?page_no=' + 0 + '&query_string=' + '';
    return http_noauth
      .get(path)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === true) {
          dispatch(getSkillsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getSkillsFailure(error));
        console.log('error', error);
      });
  };
};

export const GET_DESIGNATION_SUCCESS = 'GET_DESIGNATION_SUCCESS';
export const GET_DESIGNATION_REQUEST = 'GET_DESIGNATION_REQUEST';
export const GET_DESIGNATION_FAILURE = 'GET_DESIGNATION_FAILURE';

const getDesignationRequest = () => ({
  type: GET_DESIGNATION_REQUEST,
});

const getDesignationFailure = (error) => ({
  type: GET_DESIGNATION_FAILURE,
  payload: { error: error },
});

const getDesignationSuccess = (data) => ({
  type: GET_DESIGNATION_SUCCESS,
  payload: data,
});
export const getDesignation = (id) => {
  return (dispatch) => {
    dispatch(getDesignationRequest());
    const path = apiUrl.GET_DESIGNATION;
    return http_noauth
      .get(path)
      .then((response) => {
        console.log('response', response);
        if (response?.data?.status === true) {
          dispatch(getDesignationSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getDesignationFailure(error));
        console.log('error', error);
      });
  };
};

export const CLOSE_APPLY_JOB = 'CLOSE_APPLY_JOB';
export const OPEN_APPLY_JOB = 'OPEN_APPLY_JOB';

export const SAVE_JOB_CANDIDATE = 'SAVE_JOB_CANDIDATE';

export const closeApplyJob = () => ({
  type: 'CLOSE_APPLY_JOB',
});

export const saveJobCandidate = (candidate) => {
  console.log('saveJobCandidate', candidate);
  return {
    type: 'SAVE_JOB_CANDIDATE',
    payload: candidate,
  };
};

export const candidateJob = (job) => {
  return {
    type: 'CANDIDATE_JOB',
    payload: job,
  };
};
