import React, { useEffect } from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../../components/Header/Navbar";
import * as companyActions from "../../../../Store/company";
import { useSelector, useDispatch } from "react-redux";
import CustomizedSnackbars from "../../../../components/Snackbar/CustomizedSnackbars";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
const useStyles = makeStyles((theme) => ({
  topbar: {
    position: "relative",
    marginTop: "80px",
    padding: "20px 70px",
    backgroundSize: "cover",
    backgroundColor: "#F0F1FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  imgFrame: {
    background: "#fff",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    objectFit: "cover",
    margin: "0 auto",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    backgroundSize: "cover",
  },
  text: { marginLeft: "30px" },
  button: {
    position: "absolute",
    top: "55px",
    right: "100px",
  },
  body: {
    padding: "20px 80px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#F6F8FD",
    textAlign: "center",
  },
  jobbox: {
    padding: "20px",
    margin: "20px 4px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },
  "@media (min-width: 1640px)": {
    mainHome: {
      marginTop: "80px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
  },
  "@media (maxWidth: 468px)": {
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "26px",
    },
    jobbox: {
      width: "100%",
      margin: "0",
      padding: "0 5px",
    },
    container: {
      width: "100%",
      padding: "0 0px",
    },
    topbar: {
      position: "relative",
      padding: "20px 10px",
      height: "170px",
      width: "100%",
    },
    imgFrame: {
      width: "90px",
      height: "90px",
    },
    img: {
      width: "80px",
      height: "80px",
    },
    text: { marginLeft: "10px" },
    button: {
      position: "absolute",
      top: "120px",
      right: "20px",
    },
    body: {
      padding: "0",
      borderRadius: "10px",
    },
  },
}));

const ProfileSuperAdmin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const superAdminProfile = useSelector(
    (state) => state?.company?.superAdminProfile
  );
  const editProfile = () => {
    navigate("/superadmin/editprofile");
  };
  useEffect(() => {
    const getProfile = async () => {
      try {
        const profiless = await dispatch(companyActions.getSuperAdminProfile());
        console.log("profilesssss??????????????", profiless);
      } catch (error) {
        console.error("Error fetching candidate profile:", error);
      }
    };

    getProfile();
  }, []);

  return (
    <>
      {" "}
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.container}>
        {" "}
        <Box className={classes.topbar}>
          <Stack direction='row'>
            <Box
              className={classes.imgFrame}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              {" "}
              <Avatar
                variant='square'
                src={
                  superAdminProfile?.profile_image
                    ? superAdminProfile?.profile_image
                    : PersonIcon
                }
                sx={{ width: 120, height: 120 }}
              />
            </Box>
            <Box className={classes.text}>
              <Typography
                variant='h4'
                sx={{
                  marginTop: "10px",
                  fontSize: "28px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "600",
                }}>
                {superAdminProfile?.firstName} {superAdminProfile?.lastName}
              </Typography>
              <Typography
                variant='h5'
                sx={{
                  marginTop: "10px",
                  fontSize: "24px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "500",
                }}>
                {superAdminProfile?.designation}
              </Typography>
              <Stack
                direction='row'
                spacing={{ xs: 2, sm: 2, md: 2 }}
                sx={{ marginTop: "15px" }}>
                <Box>
                  <Typography
                    variant='h7'
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}>
                    {superAdminProfile?.role}
                    {/* {Number(superAdminProfile?.totalExperience?.years) ?? 0}.
                    {Number(superAdminProfile?.totalExperience?.months) ?? 0}{" "}
                    years */}
                  </Typography>
                </Box>
                {/* <Box>|</Box>
                <Box>
                  <Typography
                    variant='h7'
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}>
                    {" "}
                    {superAdminProfile?.designation}
                  </Typography>
                </Box>
                <Box>|</Box>
                <Box>
                  <Typography
                    variant='h7'
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}>
                    {superAdminProfile?.location}{" "}
                  </Typography>
                </Box>
                <Box>
                  {" "}
                  <Typography
                    variant='h7'
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                    }}></Typography>
                </Box> */}
              </Stack>
            </Box>
            <Box className={classes.button}>
              {" "}
              <Button
                onClick={editProfile}
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "4px 20px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "18px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                }}>
                Edit Profile
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default ProfileSuperAdmin;
