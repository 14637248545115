import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "#5071C4",
  backgroundColor: "#F3F3F9",
  borderRadius: "5px",
  padding: "2px auto",
  width: "100%",

  "& input::placeholder": {},
  "& .MuiInputBase-root": {
    height: 34,
    fontSize: "16px",
    fontWeight: 500,
  },
  "& .MuiOutlinedInput-input": {
    padding: 10,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));
