import {
  getAccessToken,
  setTokenResponse,
  setBearerToken,
  getBearerToken,
} from "./authData";
import { http } from "./client";
import { logoutSuccess } from "../Store/candidate";

let _dispatch;
export const setDispatch = (dispatch) => (_dispatch = dispatch);

export const handleResponse = (response) => {
  if (response.config.url === "/o/token/") {
    setTokenResponse(response.data);
  }
  return response.data;
};
export const handleAuthResponse = (response) => {
  if (response.config.url === "/o/token/") {
    setBearerToken(response.data);
  }
  return response.data;
};
let newToken = null;

export const setAuthorizationHeader = async (config) => {
  const headers = config.headers;
  headers["Authorization"] = `Bearer ${getAccessToken()}`;
  return { ...config, headers };
};

export const setAuthHeader = (config) => {
  const headers = config.headers || {};
  const bearerToken = getBearerToken();

  if (bearerToken) {
    headers["Authorization"] = `Bearer ${bearerToken}`;
  }
  return { ...config, headers };
};
