import React, { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { StyledTextField } from "../../../../../components/DateInput/dateInput";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  feedbackbox: {
    textAlign: "left",
    padding: "20px 30px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#DFE5F6",
  },
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  "@media (min-width: 1600px)": {},
  "@media (maxWidth: 468px)": {},
}));
const ViewScheduleCard = () => {
  const classes = useStyles();
  const [date, setDate] = useState("");
  return (
    <>
      <Box className={classes.feedbackbox}>
        <Box className={classes.heading}>
          {" "}
          <Typography
            variant='h5'
            gutterBottom
            sx={{ color: "#223870", fontWeight: 500 }}>
            Round 1:Scheduled time for Interview
          </Typography>
        </Box>

        <Box>
          <Grid justify='space-between' py={3} container>
            <Grid item xs={4} sm={2} mr={2}>
              {" "}
              <Typography
                variant='p'
                gutterBottom
                sx={{
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "500",
                  fontSize: "14px",
                }}>
                Date
              </Typography>{" "}
              <StyledTextField size='small' variant='outlined' />
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  inputFormat="D MMM, YYYY"
                  value={date}
                  onChange={(newValue) => {
                    setDate(moment(newValue.toDate()).format("D MMM, YYYY"));
                  }}
                  components={{ OpenPickerIcon: DateRangeOutlinedIcon }}
                  renderInput={(params) => {
                    return (
                      <StyledTextField
                        size="small"
                        variant="outlined"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Date",
                        }}
                      />
                    );
                  }}
                />
              </LocalizationProvider> */}
            </Grid>
            <Grid item xs={3} sm={1} mr={2}>
              {" "}
              <Typography
                variant='p'
                gutterBottom
                sx={{
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "500",
                  fontSize: "14px",
                }}>
                Start Time
              </Typography>{" "}
              <StyledTextField size='small' variant='outlined' />
              {/* <Select
                required
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    sx={{
                      color: "#5071C4",
                      position: "absolute",
                      right: 10,
                      pointerEvents: "none",
                    }}
                  />
                )}
                sx={{
                  width: "100%",
                  color: "#5071C4",
                  backgroundColor: "#F3F3F9",
                  fontWeight: "500",
                }}
                 value={result}
                 onChange={handleResult}
                className={classes.countrycode}
              >
                <MenuItem value="10">10:30</MenuItem>
                <MenuItem value="11">11:00</MenuItem>
              </Select> */}
            </Grid>
            <Grid item xs={3} sm={1} mr={2}>
              {" "}
              <Typography
                variant='p'
                gutterBottom
                sx={{
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "500",
                  fontSize: "14px",
                }}>
                End Time
              </Typography>{" "}
              <StyledTextField size='small' variant='outlined' />
              {/* <Select
                required
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    sx={{
                      color: "#5071C4",
                      position: "absolute",
                      right: 10,
                      pointerEvents: "none",
                    }}
                  />
                )}
                sx={{
                  width: "100%",
                  color: "#5071C4",
                  backgroundColor: "#F3F3F9",
                  fontWeight: "500",
                }}
                 value={result}
                 onChange={handleResult}
                className={classes.countrycode}
              >
                <MenuItem value="10">10:30</MenuItem>
                <MenuItem value="11">11:00</MenuItem>
              </Select> */}
            </Grid>
            <Grid item xs={3} sm={1.5} mt={2.7} ml={2}>
              {/* <Button
                //onClick={redirectCandidateProfile}
                type="submit"
                sx={{
                  //position: "relative",
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "0 30px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "20px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                }}
              >
                Reschedule
              </Button> */}
            </Grid>
            <Grid item xs={2} sm={6.5}></Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ViewScheduleCard;
