import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  TextField,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormInput } from "../../components/Input/FormInput";
import AntSwitch from "../../components/Switch/loginSwitch";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { countryCodeList } from "../../assets/Data/data";
import HomeBg from "../../assets/images/loginbg.png";
import { makeStyles } from "@mui/styles";
import Navbar from "../../components/Header/Navbar";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as authActions from "../../Store/authall";
const useStyles = makeStyles((theme) => ({
  containerbg: {
    marginTop: "-15px",
    backgroundImage: `url(${HomeBg})`,
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
    display: "block",
    backgroundPosition: "center center",
    position: "static",
    overflow: "auto",
  },
  main: {
    paddingTop: "80px ",
    position: "absolute",
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #223870",
    width: "50px",
    height: "40px",
    color: "#5071C4",
    backgroundColor: "#E0DFF4",
  },
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "9.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));
const CssTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInput-underline:after": {
    border: "1px solid #223870",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid #223870",
    },
    "&:hover fieldset": {
      border: "1px solid #223870",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #223870",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #223870",
  },
  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #223870",
    },
  },
}));
const CompanyRecruiterLogin = () => {
  const classes = useStyles();
  const [countryCode, setCountryCode] = React.useState("+91");
  const [setName] = React.useState();
  const handleChangeInput = (event) => {
    setName(event.target.value);
  };
  const handleSelect = (event) => {
    setCountryCode(event.target.value);
  };
  const [loginToggle, setChecked] = React.useState(false);
  const handleChange = (event) => {
    console.log("handleChange", event.target.value);
    setChecked(event.target.checked);
  };
  const handleChangeText = () => {};
  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: "IN",
    country_name: "India",
    iso3: "IND",
    phone_code: 91,
  });
  const [email, setEmail] = React.useState();
  const handleClickEmail = async () => {
    console.log(email);
    const data = {
      signup_type: 2,
      user_type: 3,
      email_id: email,
      is_secondary: false,
    };
    // const sendotp = await dispatch(authActions.sendEmailOtp(data));
    // setOpenEmail(true);
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => console.log(data);
  const navigate = useNavigate();
  const redirect = () => {
    window.scrollTo({
      top: 0,
    });
    navigate("/company/profile");
  };
  const [counter, setCounter] = React.useState(59);
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);
  const dispatch = useDispatch();

  return (
    <>
      <Navbar />
      <Box className={classes.containerbg}>
        <Box className={classes.main}>
          <Box sx={{ width: "50%", display: "flex" }}></Box>
          <Box
            sx={{
              width: "50%",
              maxWidth: "430px",
              display: "flex",
              flexDirection: "column",
              marginRight: "80px",
              textAlign: "center",
            }}>
            <Box sx={{ marginBottom: "30px" }}>
              {" "}
              <Typography
                variant='h4'
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                }}>
                Login as Company Recruiter
              </Typography>
            </Box>
            <Box py={1}>
              <Stack spacing={2} direction='row'>
                <Grid container sx={{ padding: "0 40px" }}>
                  <Grid
                    xs={4.5}
                    item
                    sx={{
                      color: "#223870",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChangeText("checked");
                    }}>
                    {loginToggle ? (
                      <Typography
                        sx={{
                          color: "#223870",
                          fontSize: "14px",
                        }}
                        variant='p'>
                        Mobile No.
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "#5071C4",
                          fontSize: "14px",
                        }}
                        variant='p'>
                        Mobile No.
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <AntSwitch
                      checked={loginToggle}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4.5}
                    sx={{
                      color: "#223870",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChangeText("unchecked");
                    }}>
                    {loginToggle ? (
                      <Typography
                        sx={{
                          color: "#5071C4",

                          fontSize: "14px",
                        }}
                        variant='p'
                        component='p'>
                        Email ID
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "#223870",
                          fontSize: "14px",
                        }}
                        variant='p'
                        component='p'>
                        Email ID
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Stack>
            </Box>

            {loginToggle ? (
              <Box sx={{ padding: "0 20px" }}>
                {" "}
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    Item
                    sx={{
                      textAlign: "left",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Email ID*
                    </Typography>{" "}
                  </Grid>
                  <Grid Item>
                    <Box
                      sm={12}
                      sx={{
                        width: "100%",
                      }}>
                      {" "}
                      <Stack direction='row' spacing={0}>
                        <FormInput
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                          name='email'
                          fullWidth
                          onChange={(e) => setEmail(e.target.value)}
                          {...register("email", {
                            required: "Email id is required",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              message: "Please enter a valid email address",
                            },
                          })}
                        />

                        <Box
                          sx={{
                            width: "170px",
                            height: "42px",
                            backgroundColor: "#E0DFF4",
                            padding: "3px 0px",
                            borderRadius: "0 5px 5px 0px",
                          }}>
                          {" "}
                          <Button
                            onClick={handleClickEmail}
                            variant='standard'
                            sx={{
                              border: "none",
                              //marginRight: "5px",
                              backgroundColor: "#223870",
                              borderRadius: "3px",
                              color: "#FDCF57",
                              marginTop: "2px",
                              padding: "3px 6px",
                              fontSize: "14px",
                              textTransform: "none",
                              "&:hover": {
                                color: "#FDCF57",
                                backgroundColor: "#223870",
                              },
                              "@media (maxWidth: 468px)": {
                                marginTop: "-29px",
                                textAlign: "center",
                                marginRight: "-10px",
                                padding: "0 10px",
                              },
                            }}>
                            Login via OTP
                          </Button>
                        </Box>
                      </Stack>
                      {errors.email && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.email.message}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box sx={{ padding: "0 20px" }}>
                {" "}
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    Item
                    sx={{
                      textAlign: "left",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        fontSize: "16px",
                        marginLeft: "2px",
                        marginBottom: "10px",
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Mobile No.*
                    </Typography>{" "}
                  </Grid>
                  <Grid container>
                    <Grid item sm={3} sx={{ paddingRight: "10px" }}>
                      <Select
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                          fontWeight: "500",
                          width: "100%",
                          height: 37,
                        }}
                        value={countryCode}
                        onChange={handleSelect}
                        className={classes.countrycode}>
                        {countryCodeList?.map((option) => {
                          return (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}>
                              {option.value ?? option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>{" "}
                    </Grid>
                    <Grid item sm={9}>
                      <Stack direction='row' spacing={0}>
                        {" "}
                        <FormInput
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          sx={{
                            width: "100%",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                          onChange={handleChangeInput}
                          name='mobile'
                          {...register("mobile", {
                            required: "Mobile number is required",
                            pattern: {
                              value: /^((\+91?)|\+)?[7-9][0-9]{9}$/,
                              message:
                                "Please enter a valid 10-digit mobile number",
                            },
                          })}
                        />{" "}
                        <Box
                          sx={{
                            width: "170px",
                            marginLeft: "-20px",
                            height: "42px",
                            backgroundColor: "#E0DFF4",
                            padding: "3px 0px",
                            borderRadius: "0 5px 5px 0px",
                          }}>
                          {" "}
                          <Button
                            variant='standard'
                            sx={{
                              border: "none",
                              //marginRight: "5px",
                              backgroundColor: "#223870",
                              borderRadius: "3px",
                              color: "#FDCF57",
                              marginTop: "2px",
                              padding: "3px 6px",
                              fontSize: "14px",
                              textTransform: "none",
                              "&:hover": {
                                color: "#FDCF57",
                                backgroundColor: "#223870",
                              },
                              "@media (maxWidth: 468px)": {
                                marginTop: "-29px",
                                textAlign: "center",
                                marginRight: "-10px",
                                padding: "0 10px",
                              },
                            }}>
                            Login via OTP
                          </Button>
                        </Box>
                      </Stack>

                      {errors.mobile && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.mobile.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box sx={{ marginTop: "40px", marginBottom: "10px" }}>
              {" "}
              <Typography
                variant='p'
                sx={{
                  fontSize: "16px",
                  color: "#223870",
                  fontWeight: "600",
                }}>
                Enter OTP
              </Typography>
            </Box>
            <Box>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: "0 30px",
                }}
                container
                direction='row'
                columns={{ xs: 3, sm: 8, md: 18, lg: 18 }}>
                <Grid item xs={2} md={2}>
                  {" "}
                  <CssTextField
                    size='small'
                    variant='outlined'
                    type='text'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name='inp1'
                    fullWidth
                    className={classes.input}
                  />
                </Grid>
                <Grid item xs={2} md={2}>
                  <CssTextField
                    size='small'
                    variant='outlined'
                    type='text'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name='inp2'
                    fullWidth
                    className={classes.input}
                  />
                </Grid>
                <Grid item xs={2} md={2}>
                  <CssTextField
                    size='small'
                    variant='outlined'
                    type='text'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name='inp3'
                    fullWidth
                    className={classes.input}
                  />
                </Grid>
                <Grid item xs={2} md={2}>
                  <CssTextField
                    size='small'
                    variant='outlined'
                    type='text'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name='inp4'
                    fullWidth
                    className={classes.input}
                  />
                </Grid>
                <Grid item xs={2} md={2}>
                  <CssTextField
                    size='small'
                    variant='outlined'
                    type='text'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name='inp5'
                    fullWidth
                    className={classes.input}
                  />
                </Grid>
                <Grid item xs={2} md={2}>
                  <CssTextField
                    size='small'
                    variant='outlined'
                    type='text'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name='inp6'
                    fullWidth
                    className={classes.input}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <Typography
                variant='p'
                sx={{
                  color: "#223870",
                  fontWeight: "500",
                  fontSize: "16px",
                }}>
                00:{counter}
              </Typography>
            </Box>
            <Box sx={{ marginTop: "30px" }}>
              {" "}
              <Button
                onClick={redirect}
                type='submit'
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "4px 20px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "20px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                }}>
                Login
              </Button>
            </Box>
            <Box sx={{ marginTop: "20px" }}>
              <Typography
                variant='p'
                sx={{
                  color: "#223870",
                  fontWeight: "500",
                  fontSize: "16px",
                }}>
                Don't have an account?{" "}
                <a
                  style={{
                    color: "#223870",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                  href='/company/recruiter/signup'>
                  Signup
                </a>
              </Typography>
            </Box>
          </Box>
        </Box>{" "}
      </Box>
    </>
  );
};

export default CompanyRecruiterLogin;
