import React from "react";
import { Grid, Box, Typography, IconButton, Tooltip } from "@mui/material";
import experience from "../../assets/Icons/Experience.png";
import job from "../../assets/Icons/JobType.png";
import ind from "../../assets/Icons/Industry.png";
import locationIcon from "../../assets/Icons/Location.png";
import date from "../../assets/Icons/Calendar.png";
import designation from "../../assets/Icons/Function.png";
import salary from "../../assets/Icons/Salary.png";
import jobdescription from "../../assets/Icons/JobDescription.png";
import Edu from "../../assets/Icons/Education.png";
import { useNavigate } from "react-router-dom";

import moment from "moment";

const JobSearchCard = (props) => {
  console.log("JobSearchCard", props);
  const navigate = useNavigate();

  const jobDetails = (e) => {
    e.stopPropagation();
    window.scrollTo({ top: 0 });
    navigate("/companyJd/" + props?.job?._id);
  };

  return (
    <>
      <div onClick={jobDetails}>
        {" "}
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}>
          <Grid item>
            <Typography
              variant='h5'
              sx={{
                marginTop: "15px",
                marginLeft: "40px",
                textAlign: "left",
                color: "#304672",
                fontWeight: "600",
                "@media (maxWidth: 468px)": {
                  marginTop: "10px",
                  fontSize: "16px",
                  marginLeft: "30px",
                },
              }}>
              {props?.job?.job_title}
            </Typography>

            {props?.job?.is_company_name_hide ? (
              <Typography
                variant='h7'
                sx={{
                  marginLeft: "40px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "600",
                  "@media (maxWidth: 468px)": {
                    marginTop: "10px",
                    fontSize: "16px",
                    marginLeft: "30px",
                  },
                }}>
                Confidential
              </Typography>
            ) : (
              <Typography
                variant='h7'
                sx={{
                  marginLeft: "40px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "600",
                  "@media (maxWidth: 468px)": {
                    marginTop: "10px",
                    fontSize: "16px",
                    marginLeft: "30px",
                  },
                }}>
                {props?.job?.company_name}
              </Typography>
            )}
          </Grid>
          <Grid item>
            {props?.job?.is_company_name_hide ? (
              <Box
                sx={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  width: "80px",
                  height: "80px",
                  backgroundColor: "#ffffff",
                  "@media (maxWidth: 468px)": {
                    top: "10px",
                    right: "10px",
                    width: "60px",
                    height: "60px",
                  },
                }}>
                <Typography
                  variant='h6'
                  sx={{
                    padding: "30px 0px",
                    fontSize: "12px",
                    textAlign: "center",
                    marginLeft: "-2px",
                    color: "#F00",
                    "@media (maxWidth: 468px)": {
                      fontSize: "8px",
                      lineHeight: "18px",
                      textAlign: "left",
                    },
                  }}>
                  Confidential
                </Typography>
              </Box>
            ) : (
              <Box
                component='img'
                src={props?.job?.company_logo}
                sx={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  width: "80px",
                  height: "80px",
                  "@media (maxWidth: 468px)": {
                    top: "10px",
                    right: "10px",
                    width: "60px",
                    height: "60px",
                  },
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Grid container sx={{ marginTop: "20px" }}>
              <Grid item sm={5}>
                {" "}
                <Grid container>
                  <Grid item xs={2} sm={1}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Location'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            width: "18px",
                            height: "18px",
                            "@media (maxWidth: 468px)": {
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={locationIcon}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: "3px",
                        marginLeft: "10px",
                        fontSize: "16px",
                        textAlign: "left",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                        },
                      }}>
                      {props?.job?.location?.city}
                    </Typography>{" "}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2} sm={1}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Job Type'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            width: "18px",
                            height: "18px",
                            "@media (maxWidth: 468px)": {
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={job}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: "3px",
                        marginLeft: "10px",
                        fontSize: "16px",
                        textAlign: "left",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                        },
                      }}>
                      {props?.job?.job_type?.job_type}
                    </Typography>{" "}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2} sm={1}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Industry'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            width: "18px",
                            height: "18px",
                            "@media (maxWidth: 468px)": {
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={ind}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: "3px",
                        marginLeft: "10px",
                        fontSize: "16px",
                        textAlign: "left",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                        },
                      }}>
                      {props?.job?.industry?.industry}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2} sm={1}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Function'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            width: "18px",
                            height: "18px",
                            "@media (maxWidth: 468px)": {
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={designation}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: "3px",
                        marginLeft: "10px",
                        fontSize: "16px",
                        textAlign: "left",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                        },
                      }}>
                      {props?.job?.function?.function}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={5}>
                {" "}
                <Grid container>
                  <Grid item xs={2} sm={1}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Total Experience'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            width: "18px",
                            height: "18px",
                            "@media (maxWidth: 468px)": {
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={experience}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: "3px",
                        marginLeft: "10px",
                        fontSize: "16px",
                        textAlign: "left",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                        },
                      }}>
                      {props?.job?.experience?.min
                        ? props?.job?.experience?.min
                        : props?.job?.experience?.min}
                      {" - "}
                      {props?.job?.experience?.max} yrs exp.
                    </Typography>{" "}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2} sm={1}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title=' Salary'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            width: "18px",
                            height: "18px",
                            "@media (maxWidth: 468px)": {
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={salary}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    {props?.job?.is_salary_hide ? (
                      <Typography
                        variant='h6'
                        sx={{
                          marginTop: "3px",
                          marginLeft: "10px",
                          fontSize: "16px",
                          textAlign: "left",
                          color: "#5677B9",
                          "@media (maxWidth: 468px)": {
                            fontSize: "14px",
                          },
                        }}>
                        Confidential
                      </Typography>
                    ) : (
                      <Typography
                        variant='h6'
                        sx={{
                          marginTop: "3px",
                          marginLeft: "10px",
                          fontSize: "16px",
                          textAlign: "left",
                          color: "#5677B9",
                          "@media (maxWidth: 468px)": {
                            fontSize: "14px",
                          },
                        }}>
                        {props?.job?.salary?.min}
                        {" - "}
                        {props?.job?.salary?.max
                          ? props?.job?.salary?.max
                          : props?.job?.salary?.max}{" "}
                        lpa
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={2} sm={1}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      disableFocusListener
                      disableTouchListener
                      title='Date'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            width: "18px",
                            height: "18px",
                            "@media (maxWidth: 468px)": {
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={date}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        marginTop: "3px",
                        marginLeft: "10px",
                        fontSize: "16px",
                        textAlign: "left",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                        },
                      }}>
                      Posted on{" "}
                      {moment(props?.job?.created_at).format("D MMM, YYYY")}
                    </Typography>{" "}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={1}>
                {" "}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2} sm={1}>
            <Tooltip
              enterNextDelay={1500}
              enterDelay={1500}
              leaveDelay={200}
              title='Job Description'
              placement='left'
              arrow>
              <IconButton>
                {" "}
                <Box
                  component='img'
                  sx={{
                    width: "18px",
                    height: "18px",
                  }}
                  src={Edu}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={10} sm={10}>
            <Box
              sx={{
                marginTop: "3px",
                marginLeft: "-23px",
                textAlign: "left",
                color: "#5677B9",
              }}>
              <Typography
                variant='p'
                sx={{
                  textAlign: "left",
                  color: "#5677B9",
                  fontWeight: "500",
                  marginBottom: "20px",
                  "@media (maxWidth: 468px)": {
                    fontSize: "14px",
                  },
                }}>
                {props?.job?.education?.degree}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          columns={{ xs: 3, sm: 8, md: 12, lg: 12 }}
          sx={{ "@media (maxWidth: 468px)": { display: "none" } }}>
          <Grid item xs={2} sm={1}>
            <Tooltip
              enterNextDelay={1500}
              enterDelay={1500}
              leaveDelay={200}
              title='Job Description'
              placement='left'
              arrow>
              <IconButton>
                {" "}
                <Box
                  component='img'
                  sx={{
                    marginLeft: "2px",
                    width: "18px",
                    height: "18px",
                  }}
                  src={jobdescription}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={10} sm={10}>
            <Box
              sx={{
                marginTop: "3px",
                marginLeft: "-23px",
                textAlign: "left",
              }}>
              <Typography
                variant='p'
                sx={{
                  textAlign: "left",
                  color: "#5677B9",
                  fontWeight: "500",
                  marginBottom: "20px",
                }}>
                {props?.job?.about_company?.length > 100
                  ? props?.job?.about_company?.substring(0, 100)
                  : props?.job?.about_company}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default JobSearchCard;
