import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Autocomplete,
  Stack,
} from "@mui/material";
import { FormInput } from "../../components/Input/FormInput";
import AntSwitch from "../../components/Switch/loginSwitch";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomizedSnackbars from "../../components/Snackbar/CustomizedSnackbars";
import { countryListnew } from "../../assets/Data/data";
import HomeBg from "../../assets/images/loginbg.png";
import { makeStyles } from "@mui/styles";
import Navbar from "../../components/Header/Navbar";
import OtpInput from "react-otp-input";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../../Store/candidate";
import OtpTimer from "../../components/OTP_Timer/OtpTimer";
const useStyles = makeStyles((theme) => ({
  containerbg: {
    marginTop: "-15px",
    backgroundImage: `url(${HomeBg})`,
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
    display: "block",
    backgroundPosition: "center center",
    position: "static",
    overflow: "auto",
  },
  main: {
    paddingTop: "60px ",
    position: "absolute",
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #223870",
    width: "50px",
    height: "40px",
    color: "#5071C4",
    backgroundColor: "#E0DFF4",
  },
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "8.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));
const LoginPageRecruiter = (props) => {
  const classes = useStyles();
  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: "IN",
    country_name: "India",
    iso3: "IND",
    phone_code: 91,
  });

  const [otp, setOtp] = useState("");
  const handleChangeOtp = (otp) => {
    setOtp(otp);
  };
  const [timer, setTimer] = useState(true);
  const timerZero = () => {
    setTimer(false);
  };
  const dispatch = useDispatch();

  const [loginToggle, setChecked] = React.useState(false);
  const handleChange = (event) => {
    console.log("handleChange", event.target.value);
    setChecked(event.target.checked);
  };
  const handleChangeText = () => {};

  const [email, setEmail] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const recruiterLoginOtpSend = useSelector(
    (state) => state?.auth?.recruiterLoginOtpSend
  );
  const recruiterMoblie = useSelector((state) => state?.auth?.recruiterMoblie);

  const recruiterEmail = useSelector((state) => state?.auth?.recruiterEmail);
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const navigate = useNavigate();
  const [msg, setMsg] = React.useState("");
  const verifyOtp = async () => {
    if (loginToggle) {
      const emailotp = {
        user_type: 3,
        email_id: recruiterEmail,
        email_otp: otp,
        is_secondary: false,
      };
      const otpmail = await dispatch(
        authActions.sendRecruiterEmailVerify(emailotp)
      );
      console.log("otpemail===================", otpmail);
      if (
        otpmail?.data?.status === true &&
        otpmail?.data?.data?.signup_status === true
      ) {
        navigate("/JobsPage?page_no=0");
        setMsg(otpmail.data?.msg);
      } else {
        console.log();
        setMsg(otpmail.data?.msg);
      }
    } else {
      const mobileotp = {
        user_type: 3,
        mobile_number: recruiterMoblie,
        mobile_otp: otp,
        is_secondary: false,
      };
      const otpmob = await dispatch(
        authActions.sendRecruiterMobileVerify(mobileotp)
      );
      console.log("otpemobile===================", otpmob);
      if (
        otpmob.data?.status === true &&
        otpmob?.data?.data?.signup_status === true
      ) {
        navigate("/JobsPage?page_no=0");
        setMsg(otpmob.data.msg);
      } else {
        setMsg(otpmob.data.msg);
      }
    }
  };

  // const [counter, setCounter] = React.useState(59);
  // React.useEffect(() => {
  //   const timer =
  //     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //   return () => clearInterval(timer);
  // }, [counter]);

  useEffect(() => {
    window.onbeforeunload = async function () {
      const reset = await dispatch(authActions.resetRecruiterLoginDetails());
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [dispatch]);
  const onSubmit = async (data) => {
    console.log(data, loginToggle);
    setMsg("");

    if (loginToggle) {
      const datasend = {
        signup_type: 2,
        user_type: 3,
        email_id: data?.email,
        is_secondary: false,
      };
      setEmail(data?.email);
      const sendotp = await dispatch(
        authActions.sendRecruiterEmailOtp(datasend)
      );
      console.log("sendotp", sendotp);
      setMsg(sendotp.response?.data?.msg);
    } else {
      const sendonmobile = {
        signup_type: 2,
        user_type: 3,
        country_code: +91,
        mobile_number: data?.mobile,
        is_secondary: false,
      };
      setMobile(data?.mobile);
      const sendotpmobile = await dispatch(
        authActions.sendRecruiterMobileOtp(sendonmobile)
      );
      setMsg(sendotpmobile.response?.data?.msg);
    }
  };

  const reSubmit = async (data) => {
    console.log(data, loginToggle);
    setMsg("");
    if (loginToggle) {
      const datasend = {
        signup_type: 2,
        user_type: 3,
        email_id: email,
        is_secondary: false,
      };
      const sendotp = await dispatch(
        authActions.sendRecruiterEmailOtp(datasend)
      );
      console.log("sendotp", sendotp);
      setMsg(sendotp.response?.data?.msg);
      const profiless = await dispatch(authActions.getRecruiterProfile());
    } else {
      const sendonmobile = {
        signup_type: 2,
        user_type: 3,
        country_code: +91,
        mobile_number: mobile,
        is_secondary: false,
      };

      const sendotpmobile = await dispatch(
        authActions.sendRecruiterMobileOtp(sendonmobile)
      );
      setMsg(sendotpmobile.response?.data?.msg);
      const profiless = await dispatch(authActions.getRecruiterProfile());
    }
  };

  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.containerbg}>
        <Grid container className={classes.main}>
          <Grid item xs={12} sm={6} md={6}></Grid>
          <Grid
            Item
            xs={12}
            md={4}
            sm={6}
            sx={{
              maxWidth: "430px",
              display: "flex",
              flexDirection: "column",
              marginRight: "80px",
              textAlign: "center",
              "@media (maxWidth: 468px)": { marginRight: "0" },
            }}>
            <Box sx={{ marginBottom: "30px" }}>
              {" "}
              <Typography
                variant='h4'
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                }}>
                Login as Recruiter
              </Typography>
            </Box>
            <Box py={1}>
              <Stack spacing={0} direction='row'>
                <Grid container sx={{ padding: "10px 110px" }}>
                  <Grid
                    xs={4.5}
                    item
                    sx={{
                      color: "#223870",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChangeText("checked");
                    }}>
                    {loginToggle ? (
                      <Typography
                        sx={{
                          color: "#5071C4",
                          fontSize: "14px",
                        }}
                        variant='p'>
                        Mobile No.
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "#223870",
                          fontSize: "14px",
                        }}
                        variant='p'>
                        Mobile No.
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <AntSwitch
                      checked={loginToggle}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={4.5}
                    sx={{
                      color: "#223870",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleChangeText("unchecked");
                    }}>
                    {loginToggle ? (
                      <Typography
                        sx={{
                          color: "#223870",
                          fontSize: "14px",
                        }}
                        variant='p'
                        component='p'>
                        Email ID
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "#5071C4",

                          fontSize: "14px",
                        }}
                        variant='p'
                        component='p'>
                        Email ID
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Stack>
            </Box>
            <form
              onSubmit={handleSubmit(onSubmit)}
              sx={{ width: "100%", minWidth: "430px" }}>
              {loginToggle ? (
                <Box sx={{ padding: "0 20px" }}>
                  {" "}
                  <Grid container direction='Column'>
                    {" "}
                    <Grid
                      Item
                      sx={{
                        textAlign: "left",
                      }}>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                        }}>
                        Email ID*
                      </Typography>{" "}
                    </Grid>
                    <Grid Item>
                      <Box
                        sm={12}
                        sx={{
                          width: "100%",
                        }}>
                        {" "}
                        <Stack direction='row' spacing={0}>
                          <FormInput
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              width: "100%",
                              borderRadius: "5px 0px 0px 5px",
                            }}
                            name='email'
                            fullWidth
                            onChange={(e) => setEmail(e.target.value)}
                            {...register("email", {
                              required: "Email id is required",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: "Please enter a valid email address",
                              },
                            })}
                          />

                          <Box
                            sx={{
                              backgroundColor: "#E0DFF4",
                              padding: "3px 0px",
                              borderRadius: "0 5px 5px 0px",
                            }}>
                            {" "}
                            <input
                              variant='standard'
                              type='submit'
                              value='Login via OTP'
                              style={{
                                border: "none",
                                marginRight: "4px",
                                backgroundColor: "#223870",
                                borderRadius: "3px",
                                color: "#FDCF57",
                                marginTop: "2px",
                                padding: "6px 8px 8px",
                                fontSize: "14px",
                                textTransform: "none",
                                "&:hover": {
                                  color: "#FDCF57",
                                  backgroundColor: "#223870",
                                },
                                "@media (maxWidth: 468px)": {
                                  marginTop: "-29px",
                                  textAlign: "center",
                                  marginRight: "-10px",
                                  padding: "0 10px",
                                },
                              }}
                            />
                          </Box>
                        </Stack>
                        {/* {errors.email && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.email.message}
                        </Box>
                      )} */}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box sx={{ padding: "0 20px" }}>
                  {" "}
                  <Grid container direction='Column'>
                    {" "}
                    <Grid
                      Item
                      sx={{
                        textAlign: "left",
                      }}>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          fontSize: "16px",
                          marginBottom: "10px",
                          color: "#304672",
                          fontWeight: "500",
                        }}>
                        Mobile No.*
                      </Typography>{" "}
                    </Grid>
                    <Grid container>
                      <Grid item sm={4} sx={{ paddingRight: "10px" }}>
                        <Autocomplete
                          id='country-select-demo'
                          sx={{ width: "100% " }}
                          value={selectedCountry}
                          options={countryListnew}
                          autoHighlight
                          disableClearable
                          variant='standard'
                          popupIcon={
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                              }}
                            />
                          }
                          name='country_code'
                          onChange={(_event, country) => {
                            setSelectedCountry(country);
                          }}
                          size='small'
                          getOptionLabel={(option) => {
                            return option.iso3 + " +" + option.phone_code;
                          }}
                          renderInput={(params) => (
                            <FormInput
                              sx={{ height: 43 }}
                              size='small'
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={8}>
                        <Stack direction='row' spacing={0}>
                          {" "}
                          <FormInput
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            sx={{
                              width: "100%",
                              borderRadius: "5px 0px 0px 5px",
                            }}
                            name='mobile'
                            onChange={(e) => setMobile(e.target.value)}
                            {...register("mobile", {
                              required: "Mobile number is required",
                              pattern: {
                                value: /^((\+91?)|\+)?[7-9][0-9]{9}$/,
                                message:
                                  "Please enter a valid 10-digit mobile number",
                              },
                            })}
                          />{" "}
                          <Box
                            sx={{
                              backgroundColor: "#E0DFF4",
                              padding: "3px 0px",
                              borderRadius: "0 5px 5px 0px",
                            }}>
                            {" "}
                            <input
                              variant='standard'
                              type='submit'
                              value='Login via OTP'
                              style={{
                                border: "none",
                                marginRight: "4px",
                                backgroundColor: "#223870",
                                borderRadius: "3px",
                                color: "#FDCF57",
                                marginTop: "2px",
                                padding: "6px 8px 8px",
                                fontSize: "14px",
                                textTransform: "none",
                                "&:hover": {
                                  color: "#FDCF57",
                                  backgroundColor: "#223870",
                                },
                                "@media (maxWidth: 468px)": {
                                  marginTop: "-29px",
                                  textAlign: "center",
                                  marginRight: "-10px",
                                  padding: "0 10px",
                                },
                              }}
                            />
                          </Box>
                        </Stack>

                        {errors.mobile && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.mobile.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </form>
            <Box sx={{ color: "#0747f7", fontWeight: "500", fontSize: "12px" }}>
              {msg}
            </Box>
            {recruiterLoginOtpSend && (
              <>
                <Box sx={{ marginTop: "40px", marginBottom: "10px" }}>
                  {" "}
                  <Typography
                    variant='p'
                    sx={{
                      fontSize: "16px",
                      color: "#223870",
                      fontWeight: "600",
                    }}>
                    Enter OTP
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <OtpInput
                    value={otp}
                    onChange={handleChangeOtp}
                    numInputs={6}
                    inputStyle={{
                      height: "35px",
                      width: "45px",
                      color: "#5071C4",
                      backgroundColor: (theme) =>
                        theme.Palette.background.input,
                      border: "1px solid #223870",
                      margin: "4px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    focusStyle={{
                      border: "1px solid #223870",
                    }}
                  />
                </Box>
                <Box sx={{ marginTop: "5px" }}>
                  <OtpTimer resendOtp={reSubmit} timerzero={timerZero} />
                </Box>

                <Box sx={{ marginTop: "30px" }}>
                  {" "}
                  <Button
                    onClick={verifyOtp}
                    type='submit'
                    sx={{
                      textTransform: "none",
                      textDecoration: "none",
                      padding: "4px 20px",
                      borderRadius: "3px ",
                      color: "#FDCF57",
                      fontWeight: "500",
                      fontSize: "20px",
                      backgroundColor: "#223870",
                      "&:hover": {
                        backgroundColor: "#223870",
                        color: "#FDCF57 ",
                      },
                    }}>
                    Login
                  </Button>
                </Box>
              </>
            )}
            <Box sx={{ marginTop: "20px" }}>
              <Typography
                variant='p'
                sx={{
                  color: "#223870",
                  fontWeight: "500",
                  fontSize: "16px",
                }}>
                Don't have an account?{" "}
                <a
                  style={{
                    color: "#223870",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                  href='/recruiter/register'>
                  Signup
                </a>
              </Typography>
            </Box>
          </Grid>
        </Grid>{" "}
      </Box>
    </>
  );
};
export default LoginPageRecruiter;
