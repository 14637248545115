const Palette = {
  mode: "light",
  text: {
    primary: "#223870",
    secondary: "#5071C4",
    yellow: "#F5BD31",
    white: "fff",
  },
  background: {
    input: "#E0DFF4",
    cards: "#DFE5F6",
    white: "#fff",
    navbar: "#E9E8F6",
    body: "#F6F8FD",
    button: "#223870",
    dialog: "#F7FAFF",
  },
};

export default Palette;
