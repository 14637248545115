import React, { useState } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CandidateTestimonialBg from "../../../assets/images/CandidateTestimonials.png";
import manthan from "../../../assets/images/Candidates/Manthan.jpg";
import pankaj from "../../../assets/images/Candidates/Pankaj.jpg";
import kumud from "../../../assets/images/Candidates/Kumud.jpg";
import chinmaya from "../../../assets/images/Candidates/Chinmay.jpg";
import vaibhav from "../../../assets/images/Candidates/Vaibhav.jpg";
import hriday from "../../../assets/images/Candidates/Hriday.jpg";
import dhana from "../../../assets/images/Candidates/Dhanyata.jpg";
import prasad from "../../../assets/images/Candidates/Prasad.jpg";
import girish from "../../../assets/images/Candidates/Girish.jpg";
import sanket from "../../../assets/images/Candidates/Sanket.jpg";
import amit from "../../../assets/images/Candidates/Amit_Wadekar.jpg";
import swapnil from "../../../assets/images/Candidates/Swapnil.jpg";
import aditiJ from "../../../assets/images/Candidates/Aditi_Joshi.jpg";
import prakash from "../../../assets/images/Candidates/Prakash_V.jpg";
import umang from "../../../assets/images/Candidates/Umang.jpg";
import nitya from "../../../assets/images/Candidates/Nitya.jpg";
import aditiA from "../../../assets/images/Candidates/Aditi_Agg.jpg";
import shiva from "../../../assets/images/Candidates/Shiva.jpg";
import sChandra from "../../../assets/images/Candidates/S_Chandra.jpg";
import isha from "../../../assets/images/Candidates/IshaPatil.jpg";
import divya from "../../../assets/images/Candidates/Divyani.jpg";
import soorya from "../../../assets/images/Candidates/Surya_Kri.jpg";
import rakesh from "../../../assets/images/Candidates/Rakesh_K.jpg";
import parag from "../../../assets/images/Candidates/Parag.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Testimonial from "./Testimonial";

const responsive = {
  LargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const useStyles = makeStyles((theme) => ({
  Background: {
    backgroundImage: `url(${CandidateTestimonialBg})`,
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
    padding: "50px 100px",
  },
  title: {
    color: "#223870",
    fontSize: "34px",
    textAlign: "center",
    fontWeight: "600",
    marginBottom: "10px",
  },
  subtitle: {
    color: "#5677B9",
    fontSize: "22px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0 230px 30px",
  },
  content: {
    color: "#304672",
    fontSize: "18px",
    lineHeight: "24px",
    textAlign: "left",
    marginTop: "20px",
  },
  name: {
    marginTop: "20px",
    color: "#223870",
    fontSize: "24px",
    fontWeight: "500",
    textAlign: "left",
  },
  designation: {
    color: "#4C68AE",
    fontSize: "20px",
    textAlign: "left",
  },
  quotation: {
    width: "40px",
    height: "40px",
  },
  "@media (min-width: 1640px)": {
    Background: {
      padding: "30px 0px",
    },
  },
  "@media (maxWidth: 468px)": {
    Background: {
      backgroundImage: "none",
      width: "100%",
      padding: "10px 20px",
    },
    title: {
      fontSize: "26px",
      lineHeight: "30px",
      marginTop: "50px",
    },
    subtitle: {
      fontSize: "18px",
      lineHeight: "20px",
      margin: "0 ",
    },
    content: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    name: {
      fontSize: "20px",
    },
    designation: {
      fontSize: "18px",
    },
    quotation: {
      width: "20px",
      height: "20px",
    },
  },
}));

const items = [
  {
    id: "1",
    image: manthan,
    content:
      "ExoTalent Consultancy helped me to get a job in reputed company.Mr. Amrit Mallick who was the coordinator for the whole process Made good coordination with me and the company while interview process.Guided me in professional manner to achieve my dream. Team has very strong in follow-up and very helpful. I will recommend my friends about this consultancy.",
    name: "Manthan Jape ",
    designation: "Senior Engineer I",
  },
  {
    id: "2",
    image: pankaj,
    content:
      "Great experience with this company. Hiring process was very smooth and well managed. Staff is friendly and supportive. Thanks to the Exotalent team.",
    name: "Pankaj Shidaye",
    designation: "Senior Engineer I",
    quotation: ``,
  },
  {
    id: "3",
    image: kumud,
    content:
      "Mansi Nigaltiya helped though out the process with John Deere and her commitment to help was beyond anyone’s expectation. Very glad to get that kind of service from the consultancy. Keep you the good work",
    name: "Kumud Singh",
    designation: "Senior Engineer I",
  },
  {
    id: "4",
    image: chinmaya,
    content:
      "It was a smooth experience with Exotalent CS team. Huge thanks to Daniel Sibiyal for quick clarification on queries & helped me to understand recruitment process better. Thanks again.",
    name: "Chinmay Deo",
    designation: "Senior Engineer I",
  },
  {
    id: "5",
    image: vaibhav,
    content:
      "ExoTalent landed me onto best Product Based Organisation. Eventually the HR team of ExoTalent are really helpfull in solving small doubts too.",
    name: "Vaibhav Shinde",
    designation: "Senior Engineer II",
  },
  {
    id: "6",
    image: hriday,
    content:
      "Had a fluid on-boarding experience thanks to Charul Sharma. Very delighted to be associated with Exotalent.",
    name: "Hriday Tejwani",
    designation: "Technical Information Specialist II",
  },
  {
    id: "7",
    image: dhana,
    content:
      "Interview Process and everything was very smooth . Shivanshu was there at every step to clarify my questions with the hiring manager.",
    name: "Dhanyata Kancharla",
    designation: "Lead Engineer I",
  },
  {
    id: "8",
    image: prasad,
    content:
      "Thank you Exo Talent for the job. Every helped me and thanks Shivanshu for all the process for my onboarding",
    name: "Prasad Bhagwat",
    designation: "Technical Information Specialist",
  },
  {
    id: "9",
    image: girish,
    content:
      "ExoTalent helped me to get a job in reputed company. Made good coordination with me and the company while interview process. Team is very strong in follow-up and very helpful. I will recommend my friends about this consultancy.",
    name: "Girish Chute",
    designation: "Senior Engineer I",
  },
  {
    id: "10",
    image: sanket,
    content:
      "I'm writing this review after getting placed at one of the most well known companies and best places to work by ExoTalent Consultancy Services. The consultants are very helpful and very professional in the placement and recruitment activities. I would like to thank the consultants for my successful placement especially Amita Singh for her helpful approach and smooth transition throughout the process. I'm really very happy with the placement services provided by the firm. I wish all the very best to Amita and ExoTalent for the future. Thank you very much! Keep up the good work.",
    name: "Sanket Tuplondhe",
    designation: "Senior Engineer - Routed Systems",
  },
  {
    id: "11",
    image: amit,
    content:
      "Amrit did his job great helped me throughout the process from starting till the day i joined he used to take care of all the necessary details required. Thank you Amrit for your support and help",
    name: "Amit Wadekar",
    designation: "Senior Engineer II",
  },
  {
    id: "12",
    image: swapnil,
    content:
      "Pretty Good consultant/ recruiter. All the staff provided a great help during all rounds of interviews. Efficient in follow ups with client recruitment and gave a good feedback on understanding the process throughout. I would give a 5 star for Quick response and clear communication along with sending gentle reminders throughout the process. Nakiya and Pooja helped me and were very efficient in delivering any workarounds!",
    name: "Swapnil Bagul",
    designation: "Lead Engineer II",
  },
  {
    id: "13",
    image: aditiJ,
    content:
      "New job search n joining is very stressful process but thanks to Anupama & team, my process was smooth and ontime.",
    name: "Aditi Joshi Potkar",
    designation: "Manager MFG Engineer",
  },
  {
    id: "14",
    image: prakash,
    content:
      "It was really great experience with Exotalent. I would like to thanks Varsha Nayak, Bhagyashree, Shruti and Richa madam for your support and encouragement from the time of scheduling interview till joining date. I really like the way you all approaching to candidate and providing all requir support at any time.Thank you all once again.",
    name: "Prakash Vaniya",
    designation: "Senior Engineer I",
  },
  {
    id: "15",
    image: umang,
    content:
      "Amazing end to end support provided. I was in contact with Puja Baranwal, she has always available for any query i had. Right from the 1st interview till the joining the management is amazing. They will not only guide you but also support you in many ways and make your journey as comfortable as possible!",
    name: "Umang Mahant",
    designation: "Data Wrangler I",
  },
  {
    id: "16",
    image: nitya,
    content: "Smooth processes and queries were handled clearly",
    name: "Nitya  Phulphagar",
    designation: "Sr. Executive Service",
  },

  {
    id: "20",
    image: aditiA,
    content:
      "Dui faucibus in ornare quam viverra orci sagittis eu. Nisl purus in mollis nunc sed id semper risus. Amet com modo nulla facilisi nullam vehicula",
    name: "Aditi Agarwal",
    designation: "Manager F&A",
  },
  {
    id: "18",
    image: shiva,
    content: "excellent support and very well coordinated",
    name: "Shiva Kumar",
    designation: "Specialist",
  },

  {
    id: "21",
    image: sChandra,
    content:
      "I'm sending this thank you note for the fantastic job opportunity you provided me for my career. Thank you for recognizing my skills and abilities and get a right path and following up about my concerns till joining from interview.",
    name: "S. Chandrakumar",
    designation: "Sr. Engineer",
  },
  {
    id: "22",
    image: isha,
    content:
      "ExoTalent referred me to a position in a reputable company. They helped schedule the interviews and answer my questions throughout the process. They consulted me till the very end and I was able to get my dream position. I would like to thank the team, especially Seema ma'am for her help and support!",
    name: "Isha Patil",
    designation: "Quantitative Analyst",
  },
  {
    id: "23",
    image: rakesh,
    content:
      "ExoTalent referred me to a reputable company. They helped schedule the interviews. They are well organised. They will response at any time you ping them regarding interview status. Thanks Exotalent",
    name: "Rakesh Kumar Barik",
    designation: " Sr. Software Test Engineer",
  },
  {
    id: "24",
    image: divya,
    content:
      "First I would like to say thanks to Exo Talent team for referring me the Job vacancy and being with me from the start of interviews till onboarding. I would specially thanks to Saraswati, who supported me right from profile shortlist to Onboarding. She always attended all my queries and got them resolved on time. I Wish her and ExoTalent team all the best wishes for their on boarding efforts. Thanks...",
    name: "Divyani Nalawade",
    designation: " Sr. Engineer",
  },
  {
    id: "25",
    image: soorya,
    content:
      "Exo talent consultancy was very helpful in getting me a position in my current organization. Their way of interacting with me till the on boarding  process was very organized, simple and was very friendly especially",
    name: "Sooryakrishnan.U",
    designation: "Specialist",
  },
  {
    id: "26",
    image: parag,
    content:
      "Dui faucibus in ornare quam viverra orci sagittis eu. Nisl purus in mollis nunc sed id semper risus. Amet com modo nulla facilisi nullam vehicula",
    name: "Parag Gupta",
    designation: "Sales Manager",
  },
];

function CandidateTestimonial() {
  const [showmore, setShowmore] = useState(false);
  const onClick = () => {
    setShowmore(!showmore);
  };
  const classes = useStyles();
  return (
    <>
      <Box className={classes.Background}>
        <Box className={classes.title}>What our candidates have to say </Box>

        <Box
          sx={{
            maxWidth: 1400,
            flexGrow: 1,
            margin: "0 auto",
            textAlign: "center",
            "@media (min-width: 1536px)": {
              maxWidth: 1800,
            },
          }}>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            // autoPlay={true}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            customTransition='all .5'
            transitionDuration={500}
            containerClass='carousel-container'
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            dotListClass='custom-dot-list-style'>
            {items.map((step, index) => (
              <Testimonial step={step} key={index} />
            ))}
          </Carousel>
        </Box>
      </Box>
    </>
  );
}

export default CandidateTestimonial;
