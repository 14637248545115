import { apiUrl } from "../../services";
import { http, http_noauth } from "../../services/client";
import { showMessage } from "../message";
import {
  setTokenResponse,
  setUserData,
  getAccessToken,
  getUserData,
} from "../../services/authData";

// SEND OTP SIGNUP

export const SEND_OTP_COMPANY_FULFILLED = "SEND_OTP_COMPANY_FULFILLED";
export const SEND_OTP_COMPANY_PENDING = "SEND_OTP_COMPANY_PENDING";
export const SEND_OTP_COMPANY_REJECTED = "SEND_OTP_COMPANY_REJECTED";

const sendCompanyEmailOtpRequest = () => ({
  type: SEND_OTP_COMPANY_PENDING,
});

const sendCompanyEmailOtpFailure = (error) => ({
  type: SEND_OTP_COMPANY_REJECTED,
  payload: { error },
});

const sendCompanyEmailOtpSuccess = (response, data) => ({
  type: SEND_OTP_COMPANY_FULFILLED,
  payload: {
    companyEmailOtp: true,
    ...response,
  },
});

export const sendCompanyEmailOtp = (data) => {
  return (dispatch) => {
    // debugger;
    console.log("send mob otp to company");
    dispatch(sendCompanyEmailOtpRequest());
    console.log("send mob otp to company@@@@@@@@@@");
    return http_noauth
      .post(apiUrl.SEND_COMPANY_EMAIL_OTP, data)
      .then((response) => {
        if (response) {
          console.log(response, "response of send email otp");
          console.log(response?.data?.data?.requestId, "request id");
          dispatch(sendCompanyEmailOtpSuccess(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(sendCompanyEmailOtpFailure(error));
        return error;
      });
  };
};

// VERIFY OTP SIGNUP
export const VERIFY_OTP_COMPANY_FULFILLED = "VERIFY_OTP_COMPANY_FULFILLED";
export const VERIFY_OTP_COMPANY_PENDING = "VERIFY_OTP_COMPANY_PENDING";
export const VERIFY_OTP_COMPANY_REJECTED = "VERIFY_OTP_COMPANY_REJECTED";

const verifyCompanyEmailOtpRequest = () => ({
  type: VERIFY_OTP_COMPANY_PENDING,
});

const verifyCompanyEmailOtpFailure = (error) => ({
  type: VERIFY_OTP_COMPANY_REJECTED,
  payload: { error },
});

const verifyCompanyEmailOtpSuccess = (response) => ({
  type: VERIFY_OTP_COMPANY_FULFILLED,

  payload: response,
});

export const verifyCompanyEmailOtp = (data) => {
  // debugger;
  return (dispatch) => {
    dispatch(verifyCompanyEmailOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_COMPANY_EMAIL_OTP, data)
      .then((response) => {
        console.log(
          "verifyCompanyEmailOtp",
          response.data.status,
          response?.data?.data
        );

        if (response?.data?.status === 1) {
          dispatch(verifyCompanyEmailOtpSuccess(response));
        } else {
          showMessage({
            dispatch,
            message: "User already signed Up",
            variant: "error",
          });
        }

        return response;
      })
      .catch((error) => {
        dispatch(verifyCompanyEmailOtpFailure(error));
        return error;
      });
  };
};
// SEND OTP LOGIN
export const SEND_OTP_COMPANY_LOGIN_FULFILLED =
  "SEND_OTP_COMPANY_LOGIN_FULFILLED";
export const SEND_OTP_COMPANY_LOGIN_PENDING = "SEND_OTP_COMPANY_LOGIN_PENDING";
export const SEND_OTP_COMPANY_LOGIN_REJECTED =
  "SEND_OTP_COMPANY_LOGIN_REJECTED";

const sendCompanyLoginOtpRequest = () => ({
  type: SEND_OTP_COMPANY_LOGIN_PENDING,
});

const sendCompanyLoginOtpFailure = (error) => ({
  type: SEND_OTP_COMPANY_LOGIN_REJECTED,
  payload: { error },
});

const sendCompanyLoginOtpSuccess = (response, data) => ({
  type: SEND_OTP_COMPANY_LOGIN_FULFILLED,
  payload: {
    companyLoginOtpSend: true,
    ...response,
  },
});

export const sendOtpCompanyLogin = (data) => {
  return (dispatch) => {
    // debugger;
    console.log("send mob otp to company");
    dispatch(sendCompanyLoginOtpRequest());
    console.log("send mob otp to company@@@@@@@@@@");
    return http_noauth
      .post(apiUrl.MOBILE_OTP, data)
      .then((response) => {
        if (response) {
          console.log(response, "response of send email otp");
          console.log(response?.data?.data?.requestId, "request id");
          dispatch(sendCompanyLoginOtpSuccess(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(sendCompanyLoginOtpFailure(error));
        return error;
      });
  };
};

export const CLOSE_COMPANY_EMAIL_DIALOG = "CLOSE_COMPANY_EMAIL_DIALOG";
export const closeCompanyEmailDialog = () => {
  debugger;
  return {
    type: CLOSE_COMPANY_EMAIL_DIALOG,
  };
};

// VERIFY OTP LOGIN
export const VERIFY_LOGIN_OTP_COMPANY_FULFILLED =
  "VERIFY_LOGIN_OTP_COMPANY_FULFILLED";
export const VERIFY_LOGIN_OTP_COMPANY_PENDING =
  "VERIFY_LOGIN_OTP_COMPANY_PENDING";
export const VERIFY_LOGIN_OTP_COMPANY_REJECTED =
  "VERIFY_LOGIN_OTP_COMPANY_REJECTED";

const verifyLoginCompanyEmailOtpRequest = () => ({
  type: VERIFY_LOGIN_OTP_COMPANY_PENDING,
});

const verifyLoginCompanyEmailOtpFailure = (error) => ({
  type: VERIFY_LOGIN_OTP_COMPANY_REJECTED,
  payload: { error },
});

const verifyLoginCompanyEmailOtpSuccess = (response) => ({
  type: VERIFY_LOGIN_OTP_COMPANY_FULFILLED,
  payload: response,
});

export const verifyCompanyOtpLogin = (data) => {
  debugger;
  return (dispatch) => {
    dispatch(verifyLoginCompanyEmailOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_MOBILE_OTP, data)
      .then((response) => {
        console.log("login Company token", response);
        console.log("verifyCompanyEmailOtpLogin", response);
        console.log(
          "acct exist???????????",
          response?.data?.data?.accountExists
        );
        if (
          response?.data?.status === 1 &&
          response?.data?.data?.accountExists === false
        ) {
          showMessage({
            dispatch,
            message: "User doesn't Signup, please signup first ",
            variant: "error",
          });
        } else if (
          response?.data?.status === 1 &&
          response?.data?.data?.accountExists === true
        ) {
          setTokenResponse(response?.data.data.session.token);
          setUserData(response?.data?.data);
          dispatch(verifyLoginCompanyEmailOtpSuccess(response));
          showMessage({
            dispatch,
            message: "Login Successfully ",
            variant: "success",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(verifyLoginCompanyEmailOtpFailure(error));
        return error;
      });
  };
};

// company_signup
export const SIGNUP_COMPANY_FULFILLED = "SIGNUP_COMPANY_FULFILLED";
export const SIGNUP_COMPANY_PENDING = "SIGNUP_COMPANY_PENDING";
export const SIGNUP_COMPANY_REJECTED = "SIGNUP_COMPANY_REJECTED";

const companySignupRequest = () => ({
  type: SIGNUP_COMPANY_PENDING,
});

const companySignupFailure = (error) => ({
  type: SIGNUP_COMPANY_REJECTED,
  payload: { error },
});

const companySignupFulfilled = (response) => ({
  type: SIGNUP_COMPANY_FULFILLED,
  payload: response,
  requestId: response.data?.data?.requestId,
});

export const signupCompany = (data) => {
  return (dispatch) => {
    dispatch(companySignupRequest());
    return http_noauth
      .post(apiUrl.CREATE_COMPANY, data)
      .then((response) => {
        console.log("signupCompany response", response?.data?.data);
        if (response) {
          setTokenResponse(response?.data?.data?.owner?.session?.token);
          setUserData(response?.data?.data);

          dispatch(companySignupFulfilled(response));
          showMessage({
            dispatch,
            message: "Signup Successfully",
            variant: "success",
          });
        } else {
          dispatch(companySignupFailure(response?.data));
          showMessage({
            dispatch,
            message: response?.data?.data,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(companySignupFailure(error));
        return error;
      });
  };
};
export const RESET_COMPANY_DETAILS = "RESET_COMPANY_DETAILS";
export const resetCompanyDetails = () => {
  return {
    type: RESET_COMPANY_DETAILS,
  };
};
// company_get_profile
export const GET_COMPANY_PROFILE_DETAILS_SUCCESS =
  "GET_COMPANY_PROFILE_DETAILS_SUCCESS";
export const GET_COMPANY_PROFILE_DETAILS_REQUEST =
  "GET_COMPANY_PROFILE_DETAILS_REQUEST";
export const GET_COMPANY_PROFILE_DETAILS_FAILURE =
  "GET_COMPANY_PROFILE_DETAILS_FAILURE";

const getCompanyRequest = () => ({
  type: GET_COMPANY_PROFILE_DETAILS_REQUEST,
});

const getCompanyFailure = (error) => ({
  type: GET_COMPANY_PROFILE_DETAILS_FAILURE,
  payload: { error: error },
});

const getCompanySuccess = (data) => ({
  type: GET_COMPANY_PROFILE_DETAILS_SUCCESS,
  payload: data,
});
// export const getCompanyProfile = () => {
//   debugger;

//   const userData = getUserData();

//   console.log(userData);
//   return (dispatch) => {
//     dispatch(getCompanyRequest());
//     const path = apiUrl.GET_COMPANY + userData?.company?.id;
//     console.log(path);
//     return http
//       .get(path)
//       .then((response) => {
//         console.log("response@@@@@", response);
//         if (response?.data?.status === 1) {
//           dispatch(getCompanySuccess(response));
//         }
//         return response;
//       })
//       .catch((error) => {
//         dispatch(getCompanyFailure(error));
//         console.log("error?????????????", error);
//       });
//   };
// };
export const getCompanyProfile = (companyId) => {
  return async (dispatch) => {
    dispatch(getCompanyRequest());

    try {
      const path = `${apiUrl.GET_COMPANY}/${companyId}`;
      const response = await http.get(path);

      console.log("response@@@@@", response);

      if (response?.data?.status === 1) {
        dispatch(getCompanySuccess(response));
      }

      return response;
    } catch (error) {
      dispatch(getCompanyFailure(error));
      console.log("error?????????????", error);
      return error;
    }
  };
};

// company update profile

export const COMPANY_UPDATE_PROFILE_FULFILLED =
  "COMPANY_UPDATE_PROFILE_FULFILLED";
export const COMPANY_UPDATE_PROFILE_PENDING = "COMPANY_UPDATE_PROFILE_PENDING";
export const COMPANY_UPDATE_PROFILE_REJECTED =
  "COMPANY_UPDATE_PROFILE_REJECTED";
const companyUpdateProfileRequest = () => ({
  type: COMPANY_UPDATE_PROFILE_PENDING,
});
const companyUpdateProfileFailure = (error) => ({
  type: COMPANY_UPDATE_PROFILE_REJECTED,
  payload: { error },
});
const companyUpdateProfileFulfilled = (response) => ({
  type: COMPANY_UPDATE_PROFILE_FULFILLED,
  payload: response,
});
export const companyUpdateProfile = (data) => {
  const userData = getUserData();
  console.log(data, "user data?????");
  console.log(userData.session.userId, "user data");
  return (dispatch) => {
    dispatch(companyUpdateProfileRequest());
    return http
      .put(apiUrl.USER + userData.session.userId, data)
      .then((response) => {
        console.log(response?.config, response?.headers);
        if (response?.data?.status === 1) {
          showMessage({
            dispatch,
            message: "Your profile is successfully updated",
            variant: "success",
          });
          dispatch(companyUpdateProfileFulfilled(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.data,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(companyUpdateProfileFailure(error));
        return error;
      });
  };
};
export const RESET_COMPANY_LOGIN_DETAILS = "RESET_COMPANY_LOGIN_DETAILS";
export const resetCompanyLoginDetails = () => {
  return {
    type: RESET_COMPANY_LOGIN_DETAILS,
  };
};
// Get Profile Super Admin
export const GET_SUPER_ADMIN_PROFILE_DETAILS_SUCCESS =
  "GET_SUPER_ADMIN_PROFILE_DETAILS_SUCCESS";
export const GET_SUPER_ADMIN_PROFILE_DETAILS_REQUEST =
  "GET_SUPER_ADMIN_PROFILE_DETAILS_REQUEST";
export const GET_SUPER_ADMIN_PROFILE_DETAILS_FAILURE =
  "GET_SUPER_ADMIN_PROFILE_DETAILS_FAILURE";

const getSuperAdminRequest = () => ({
  type: GET_SUPER_ADMIN_PROFILE_DETAILS_REQUEST,
});

const getSuperAdminFailure = (error) => ({
  type: GET_SUPER_ADMIN_PROFILE_DETAILS_FAILURE,
  payload: { error: error },
});

const getSuperAdminSuccess = (data) => ({
  type: GET_SUPER_ADMIN_PROFILE_DETAILS_SUCCESS,
  payload: data,
});
export const getSuperAdminProfile = () => {
  const token = getAccessToken();
  console.log(token);
  const userData = getUserData();
  console.log(userData);
  console.log(token, userData.session, "userand token");
  return (dispatch) => {
    dispatch(getSuperAdminRequest());
    return http
      .get(apiUrl.USER + userData.session.userId)
      .then((response) => {
        console.log("response@@@@@", response);
        if (response?.data?.status === 1) {
          dispatch(getSuperAdminSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getSuperAdminFailure(error));
        console.log("error?????????????", error);
      });
  };
};
