import Palette from "./palette";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";
const breakpoints = createBreakpoints({});

const Typography = {
  useNextVariants: true,
  fontFamily: "Poppins",
  h1: {
    color: Palette.text.primary,
    fontWeight: "500",
    fontSize: "40px",
    lineHeight: "40px",
  },
  h2: {
    color: Palette.text.primary,
    fontWeight: "500",
    lineHeight: "36px",
    fontSize: "36px",
  },
  h2s: {
    color: Palette.text.primary,
    fontWeight: "600",
    letterSpacing: "1px",
    [breakpoints.down("md")]: {
      fontSize: "24px",
      lineHeight: "24px",
    },
    [breakpoints.up("md")]: {
      lineHeight: "28px",
      fontSize: "28px",
    },
    [breakpoints.up("lg")]: {
      lineHeight: "30px",
      fontSize: "30px",
    },
  },

  h3: {
    color: Palette.text.primary,
    fontWeight: "600",
    lineHeight: "26px",
    fontSize: "26px",
    letterSpacing: "1px",
  },
  h3s: {
    color: Palette.text.white,
    textAlign: "center",
    fontWeight: "500",
    lineHeight: "24px",
    fontSize: "24px",
    letterSpacing: "1px",
  },
  h4: {
    color: Palette.text.primary,
    fontWeight: "400",
    lineHeight: "28px",
    fontSize: "32px",
  },

  h5: {
    color: Palette.text.primary,
    fontWeight: "400",
    lineHeight: "24px",
    fontSize: "24px",
  },

  h6: {
    color: Palette.text.primary,
    fontWeight: "500",
    fontSize: "22px",
    lineHeight: "22px",
    letterSpacing: "1px",
  },
  h7: {
    color: Palette.text.secondary,
    fontWeight: "none",
    fontSize: "22px",
    lineHeight: "22px",
    letterSpacing: "1px",
  },
  ps: {
    color: Palette.text.primary,
    fontSize: "13px",
    lineHeight: "10px",
    fontWeight: 400,
  },
  ps1: {
    color: Palette.text.primary,
    fontSize: "12px",
    lineHeight: "12px",
    fontWeight: 500,
  },
  ps2: {
    color: Palette.text.primary,
    fontWeight: 500,
    [breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "10px",
    },
    [breakpoints.up("md")]: {
      fontSize: "15px",
      lineHeight: "15px",
    },
    [breakpoints.up("lg")]: {
      fontSize: "18px",
      lineHeight: "18px",
    },
  },
  p: {
    color: Palette.text.secondary,
    fontWeight: "none",
    marginRight: "20px",
    [breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "10px",
    },
    [breakpoints.up("md")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
    [breakpoints.up("lg")]: {
      fontSize: "16px",
      lineHeight: "16px",
    },
  },
};
export default Typography;
