import React from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Stack,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { FormInput } from "../../../../components/Input/FormInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Delete from "../../../../assets/images/delete.png";
import Edit from "../../../../assets/images/edit.png";
import Block from "../../../../assets/images/key.png";
import Close from "../../../../assets/Icons/Close.png";
import { countryListnew } from "../../../../assets/Data/data";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const options = ["Create Job", "Manage Job", "Refer Profile"];
const data = [
  {
    id: 1,
    first_name: "Harika",
    last_name: "Shivan",
    designation: "Sr. Recruiter",
    access: "Create Jobs, Manage Status,Refer profile",
  },
  {
    id: 2,
    first_name: "Deeksha",
    last_name: "L J",
    designation: "Associate. Recruiter",
    access: "Create Jobs, Manage Status,Refer profile",
  },
  {
    id: 3,
    first_name: "Pavan",
    last_name: "Venkatesh",
    designation: "Sr. Recruiter",
    access: " Manage Status,Refer profile",
  },
  {
    id: 4,
    first_name: "Shrestha Mittal",
    last_name: "Shivan",
    designation: "Associate. Recruiter",
    access: "Create Jobs, Manage Status,Refer profile",
  },
  {
    id: 5,
    first_name: "Manisha",
    last_name: "Rai",
    designation: "Sr. Recruiter",
    access: "Create Jobs, Refer profile",
  },
  {
    id: 6,
    first_name: "Bhavani ",
    last_name: "Shekhar",
    designation: "Associate. Recruiter",
    access: "Create Jobs, Manage Status,Refer profile",
  },
];

const ManageAccessAdmin = (props) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onTouched" });
  const [access, setAccess] = React.useState([]);
  const isAllAccess = options.length > 0 && access?.length === options.length;
  const handleAccess = (event) => {
    console.log("vals", event.target);
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setAccess(access?.length === options.length ? [] : options);
      return;
    }
    setAccess(value);
    console.log("values", access);
  };
  const [selectedCountry, setSelectedCountry] = React.useState({
    id: 240,
    iso2: "IN",
    country_name: "India",
    iso3: "IND",
    phone_code: 91,
  });

  const [manageAccess, setManageAccess] = React.useState(false);
  const redirectManageAccess = () => {
    setManageAccess(true);
  };
  const handleCloseManageAccess = () => {
    setManageAccess(false);
  };
  const onSubmitAccessFor = async (data) => {
    console.log(data, "admin for data");
  };
  return (
    <>
      <Grid
        container
        direction='row'
        spacing={0}
        justify='center'
        alignItems='center'>
        {data?.map((data, index) => {
          return (
            <Grid item xs={12} sm={6} key={index} px={2}>
              <Stack direction='row' justifyContent='space-between'>
                <Box>
                  {" "}
                  <Typography
                    variant='h4'
                    sx={{
                      marginTop: "10px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    {" "}
                    {data?.first_name + " " + data?.last_name}
                  </Typography>
                </Box>
                <Box mt={2} mx={2}>
                  {" "}
                  <Stack direction='row'>
                    <Box
                      mx={1}
                      src={Edit}
                      component='img'
                      onClick={redirectManageAccess}
                      sx={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <Dialog
                      componentsProps={{
                        backdrop: {
                          style: {
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                      open={manageAccess}
                      onClose={handleCloseManageAccess}
                      sx={{
                        "& .MuiDialog-container": {
                          "& .MuiPaper-root": {
                            padding: "0 50px",
                            width: "100%",
                            maxWidth: "600px",
                            height: "450px",
                            borderRadius: "5px",
                            backgroundColor: (theme) =>
                              theme.Palette.background.dialog,
                          },
                        },
                      }}>
                      <DialogActions>
                        <Box
                          onClick={handleCloseManageAccess}
                          component='img'
                          src={Close}
                          sx={{
                            top: "10px",
                            right: "10px",
                            width: "20px",
                            height: "20px",
                            position: "absolute",
                          }}
                        />
                      </DialogActions>
                      <DialogTitle
                        sx={{
                          backgroundColor: "none",
                          color: "#5677B9",
                          textAlign: "center",
                          fontSize: "20px",
                          marginBottom: "10px",
                        }}>
                        Manage Access for
                      </DialogTitle>
                      <DialogContent>
                        <form onSubmit={handleSubmit(onSubmitAccessFor)}>
                          {" "}
                          <Box mt={1}>
                            <Typography
                              variant='p'
                              gutterBottom
                              sx={{
                                color: "#5677B9",
                                fontWeight: "500",
                                fontSize: "12px",
                              }}>
                              Designation
                            </Typography>{" "}
                            <FormInput
                              sx={{
                                backgroundColor: "#E0DFF4",
                                borderRadius: "5px",
                                height: "35px",
                              }}
                              size='small'
                              variant='outlined'
                              type='text'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              name='designation'
                              {...register("designation", {
                                required: "designationis required",
                              })}
                            />
                          </Box>
                          <Box mt={1}>
                            <Typography
                              variant='p'
                              gutterBottom
                              sx={{
                                color: "#5677B9",
                                fontWeight: "500",
                                fontSize: "12px",
                              }}>
                              Email Id*
                            </Typography>{" "}
                            <FormInput
                              sx={{
                                backgroundColor: "#E0DFF4",
                                borderRadius: "5px",
                                height: "35px",
                              }}
                              size='small'
                              variant='outlined'
                              type='text'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              name='email'
                              {...register("email", {
                                required: "Email Id is required",
                              })}
                            />
                            {errors.email && (
                              <Box sx={{ color: "red", fontSize: "12px" }}>
                                {" "}
                                {errors.email.message}
                              </Box>
                            )}
                          </Box>
                          <Box mt={1}>
                            <Typography
                              variant='p'
                              gutterBottom
                              sx={{
                                color: "#5677B9",
                                fontWeight: "500",
                                fontSize: "12px",
                              }}>
                              Mobile No.
                            </Typography>{" "}
                            <Grid container>
                              <Grid item sm={4} pr={2}>
                                <Autocomplete
                                  id='country-select-demo'
                                  sx={{ width: "100% " }}
                                  value={selectedCountry}
                                  options={countryListnew}
                                  disableClearable
                                  variant='standard'
                                  popupIcon={
                                    <KeyboardArrowDownIcon
                                      sx={{
                                        color: "#5071C4",
                                      }}
                                    />
                                  }
                                  name='country_code'
                                  onChange={(_event, country) => {
                                    setSelectedCountry(country);
                                  }}
                                  size='small'
                                  getOptionLabel={(option) => {
                                    return (
                                      option.iso3 + " +" + option.phone_code
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <FormInput
                                      sx={{
                                        backgroundColor: "#E0DFF4",
                                        borderRadius: "5px",
                                        height: "35px",
                                      }}
                                      size='small'
                                      {...params}
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "",
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item sm={8}>
                                <FormInput
                                  size='small'
                                  variant='outlined'
                                  type='text'
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  fullWidth
                                  sx={{
                                    width: "100%",
                                    height: "35px",
                                    color: "#5071C4",
                                    backgroundColor: "#E0DFF4",
                                    borderRadius: "5px",
                                  }}
                                  name='mobile'
                                  {...register("mobile", {
                                    required: "Mobile number is required",
                                    pattern: {
                                      value: /^((\+91?)|\+)?[7-9][0-9]{9}$/,
                                      message:
                                        "Please enter a valid 10-digit mobile number",
                                    },
                                  })}
                                />{" "}
                              </Grid>
                            </Grid>
                          </Box>
                          <Box
                            mt={3}
                            mb={3}
                            sx={{
                              textAlign: "center",
                            }}>
                            {" "}
                            <Button
                              type='submit'
                              sx={{
                                textTransform: "none",
                                textDecoration: "none",
                                padding: "3px 15px",
                                borderRadius: "5px ",
                                color: "#FDCF57",
                                fontWeight: "500",
                                fontSize: "18px",
                                backgroundColor: "#223870",
                                "&:hover": {
                                  backgroundColor: "#223870",
                                  color: "#F5BD31",
                                },
                              }}>
                              Save
                            </Button>
                          </Box>
                        </form>
                      </DialogContent>
                    </Dialog>
                    <Box
                      mx={1}
                      component='img'
                      src={Delete}
                      sx={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <Box
                      mx={1}
                      component='img'
                      src={Block}
                      sx={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </Stack>
                </Box>
              </Stack>
              <Typography
                variant='h4'
                sx={{
                  marginTop: "5px",
                  fontSize: "12px",
                  textAlign: "left",
                  color: "#5474C5",
                  fontWeight: "600",
                }}>
                {" "}
                {data?.designation}
              </Typography>

              <Box pt={1}>
                {" "}
                <Divider sx={{ backgroundColor: "#5474C5" }} />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ManageAccessAdmin;
