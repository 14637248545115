import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

export const Button = styled(MuiButton)(({ theme }) => ({
  borderRadius: "3px",
  fontSize: "16px",
  fontWeight: 500,
  backgroundColor: theme.Palette.background.button,
  color: theme.Palette.text.yellow,
  textTransform: "none",
  ":hover": {
    backgroundColor: theme.Palette.background.button,
    color: theme.Palette.text.yellow,
  },
  ":active": {
    backgroundColor: theme.Palette.background.button,
    color: theme.Palette.text.yellow,
  },
  ":focused": {
    backgroundColor: theme.Palette.background.button,
    color: theme.Palette.text.yellow,
  },
  ":selected": {
    backgroundColor: theme.Palette.background.button,
    color: theme.Palette.text.yellow,
  },
  [theme.breakpoints.down("md")]: { fontSize: "12px", padding: "2px 8px" },
  [theme.breakpoints.up("md")]: { padding: "3px 15px", fontSize: "16px" },
  [theme.breakpoints.up("lg")]: { padding: "3px 15px", fontSize: "16px" },
}));
