import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
} from "@mui/material";
import { Button } from "../../components/Button/button";
import { FormInput } from "../../components/Input/FormInput";
import Close from "../../assets/Icons/Close.png";
import formBg from "../../assets/Icons/mobileFormBG.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { countryListnew } from "../../assets/Data/data";
import { useNavigate } from "react-router-dom";
import HomeBg from "../../assets/images/loginbg.png";
import { makeStyles } from "@mui/styles";
import Navbar from "../../components/Header/Navbar";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../../Store/candidate";
import * as jobsActions from "../../Store/Jobs";
import OtpInput from "react-otp-input";
import OtpTimer from "../../components/OTP_Timer/OtpTimer";
import { v4 as uuidv4 } from "uuid";
import MuiAlert from "@mui/material/Alert";
import AWS from "aws-sdk";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
const useStyles = makeStyles((theme) => ({
  option: {
    padding: "10px 0px",
    margin: "1px !important",
  },
  containerbg: {
    marginTop: "-15px",
    backgroundImage: `url(${HomeBg})`,
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
    display: "block",
    backgroundPosition: "center center",
    position: "static",
    overflow: "auto",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  grid: {
    position: "absolute",
  },
  form: {
    paddingTop: "80px",
    paddingRight: "100px",
    position: "relative",
    width: "100%",
    height: "100vh",
    overflowY: "scroll",
  },

  input: {
    borderRadius: "5px",
    border: "1px solid #223870",
    width: "50px",
    height: "40px",
    color: "#5071C4",
    backgroundColor: "#E0DFF4",
  },
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "8.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  "@media (maxWidth: 468px)": {
    containerbg: {
      backgroundImage: `url(${formBg})`,
      marginTop: "80px",
    },
    form: {
      margin: "0",
      padding: "0 10px",
      width: "100%",
    },
  },
}));
const S3_BUCKET = "exotalent-cv";
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: "AKIAWCKLV3UAPEYYOZO3",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
const RecruiterRegister = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: "IN",
    country_name: "India",
    iso3: "IND",
    phone_code: 91,
  });
  const [message, setMessage] = React.useState("");
  const [otp, setOtp] = useState(false);
  const handleChangeOtp = (otp) => {
    setOtp(otp);
  };
  const [timer, setTimer] = useState(true);
  const timerZero = () => {
    setTimer(false);
  };
  const navigate = useNavigate();
  const redirectProfile = () => {
    navigate("/recruiterProfile");
  };
  const [setName] = React.useState();
  const handleChange = (event) => {
    setName(event.target.value);
  };
  const [msg, setMsg] = React.useState("");
  const [open, setOpen] = React.useState("");
  const [uploadjd, setUploadJd] = React.useState(null);
  const handleUploadJd = async (event) => {
    await setUploadJd(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    return uploadJd(event.target.files[0]);
  };
  const [filepath, setFilepath] = React.useState("");
  const uploadJd = async (file) => {
    let namekey = uuidv4() + file.name.replace(/ /g, "_");
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: namekey,
    };
    let objct = myBucket.putObject(params).promise();

    console.log("aws ", objct);
    objct
      .then(function (data) {
        console.log(
          "aws Successfully uploaded data to " + S3_BUCKET + "/" + namekey
        );
        console.log("aws Success", data);
        let path =
          "https://" +
          S3_BUCKET +
          ".s3." +
          REGION +
          ".amazonaws.com/" +
          namekey;
        setFilepath(path);
      })
      .catch(function (err) {
        console.log("aws err", err);
      });
  };
  const [openEmail, setOpenEmail] = React.useState(false);
  const [openMobile, setOpenMobile] = React.useState(false);
  const [email, setEmail] = React.useState();
  const [mobile, setMobile] = React.useState();
  const handleClickOpenEmail = async () => {
    setMessage("");
    if (atleastoneverify) {
      const data = {
        mobile_number: mobile,
        signup_type: 1,
        user_type: 3,
        email_id: email,
        is_secondary: false,
      };
      await dispatch(authActions.checkRecruiterEmail(data));
    } else {
      const data = {
        signup_type: 1,
        user_type: 3,
        email_id: email,
        is_secondary: false,
      };
      await dispatch(authActions.checkRecruiterEmail(data));
    }
  };
  const verifyRecruiterEmailOtp = async () => {
    console.log("otp", otp);
    setMessage("");
    const data = {
      email_otp: otp,
      user_type: 3,
      email_id: email,
      is_secondary: false,
    };

    const verifyotp = await dispatch(authActions.verifyRecruiterEmailOtp(data));
    console.log("verifyotp", verifyotp);
    setOtp("");
    setMessage(verifyotp?.data?.msg);
    console.log("verifyotp", verifyotp);
    handlexAuth(verifyotp?.headers["x-auth"]);
  };
  const handleClickOpenMobile = async () => {
    setMessage("");
    console.log("xauth 1....", xauth);
    if (atleastoneverify) {
      const data = {
        email_id: email,
        signup_type: 1,
        user_type: 3,
        country_code: +91,
        mobile_number: mobile,
        is_secondary: false,
      };
      await dispatch(authActions.checkRecruiterMobile(data));
      console.log();
    } else {
      const data = {
        signup_type: 1,
        user_type: 3,
        country_code: +91,
        mobile_number: mobile,
        is_secondary: false,
      };
      await dispatch(authActions.checkRecruiterMobile(data));
    }
  };
  const verifyRecruiterMobileOtp = async () => {
    console.log("otp", otp);
    setMessage("");
    const data = {
      user_type: 3,
      mobile_number: mobile,
      mobile_otp: otp,
      is_secondary: false,
    };
    const verifyotpMobile = await dispatch(
      authActions.verifyRecruiterMobileOtp(data)
    );
    setMessage(verifyotpMobile?.data?.msg);
    setOtp("");

    console.log("verifyotpMobile", verifyotpMobile);
    handlexAuth(verifyotpMobile?.headers["x-auth"]);
  };
  const handleCloseEmail = () => {
    setOpenEmail(false);
  };
  const handleCloseMobile = () => {
    setOpenMobile(false);
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const onSubmit = async (details) => {
    console.log("xauth rec....", xauth);
    console.log("detail", details);
    console.log("path", filepath);

    var config = {
      headers: {
        "x-auth": xauth,
      },
    };

    const data = {
      first_name: details?.firstName,
      last_name: details?.lastName,
      company: details?.company,
      pan_number: details?.pan,
      adhar_number: details?.aadhar,
      pan_file: filepath,
    };
    const signuprecruiter = await dispatch(
      authActions.signupRecruiter(data, config)
    );
    if (signuprecruiter.data?.status === true) {
      setOpen(true);
      const profiless = await dispatch(authActions.getRecruiterProfile());
      navigate("/recruiter/profile");
      setMsg(signuprecruiter.msg);
    } else {
      setMsg(signuprecruiter.msg);
    }
    console.log("signuprecruiter", signuprecruiter);
  };
  const [xauth, setXauth] = useState("");
  const handlexAuth = (data) => {
    setXauth(data);
  };

  useEffect(() => {
    window.onbeforeunload = async function () {
      const reset = await dispatch(authActions.resetRecruiterDetails());
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [dispatch]);
  const recruiterEmailVerification = useSelector(
    (state) => state?.auth?.recruiterEmailVerification
  );

  const recruiterEmailOtp = useSelector(
    (state) => state?.auth?.recruiterEmailOtp
  );

  const recruiterMobileVerification = useSelector(
    (state) => state?.auth?.recruiterMobileVerification
  );
  const recruiterMobileOtp = useSelector(
    (state) => state?.auth?.recruiterMobileOtp
  );
  const atleastoneverify = useSelector(
    (state) => state?.auth?.atleastoneverify
  );
  return (
    <>
      <Navbar />
      <Box className={classes.containerbg}>
        {" "}
        <Grid container className={classes.grid}>
          {" "}
          <Grid item xs={12} sm={7}></Grid>{" "}
          <Grid item xs={12} sm={5} className={classes.form}>
            <Typography
              variant='h4'
              sx={{
                fontSize: "30px",
                marginTop: "40px",
                marginBottom: "10px",
                textAlign: "center",
                color: "#223870",
                fontWeight: "600",
              }}>
              Recruiter Signup
            </Typography>
            <Box
              sx={{
                paddingBottom: "40px",
              }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  sm={12}
                  sx={{
                    marginTop: "25px",
                    width: "100%",
                  }}>
                  {" "}
                  <Grid item sm={6} md={6}>
                    <Grid container direction='column'>
                      {" "}
                      <Grid item>
                        {" "}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          First Name*
                        </Typography>{" "}
                      </Grid>
                      <Grid item sx={{ marginRight: "6px" }}>
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='firstName'
                          {...register("firstName", {
                            required: "First name is required",
                            minLength: {
                              value: 1,
                              message: "First name must be atleast 1 character",
                            },
                          })}
                        />
                        {errors.firstName && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.firstName.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <Grid container direction='column'>
                      {" "}
                      <Grid item>
                        {" "}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                            marginLeft: "8px",
                          }}>
                          Last Name*
                        </Typography>{" "}
                      </Grid>
                      <Grid item sx={{ marginLeft: "6px" }}>
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='lastName'
                          {...register("lastName", {
                            required: "Last name is required",
                            minLength: {
                              value: 1,
                              message: "Last name must be atleast 1 character",
                            },
                          })}
                        />
                        {errors.lastName && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.lastName.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    Item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Email ID*
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    <Box
                      sm={12}
                      sx={{
                        width: "100%",
                      }}>
                      {" "}
                      <Stack direction='row' spacing={0}>
                        <FormInput
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            width: "100%",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                          name='email'
                          onChange={(e) => setEmail(e.target.value)}
                          fullWidth

                          // {...register('email', {
                          //   required: 'Email id is required',
                          //   pattern: {
                          //     value: /^\S+@\S+$/i,
                          //     message: 'Please enter a valid email address',
                          //   },
                          // })}
                        />
                        <Box
                          sx={{
                            backgroundColor: "#E0DFF4",
                            padding: "3px 0px",
                            borderRadius: "0 5px 5px 0px",
                          }}>
                          {" "}
                          <Button
                            onClick={handleClickOpenEmail}
                            variant='standard'
                            disabled={recruiterEmailVerification}
                            sx={{
                              marginRight: "5px",
                              color: recruiterEmailVerification
                                ? "#FDCF57!important"
                                : "#FDCF57",
                              marginTop: "2px",
                              padding: "2px 20px",
                              "@media (maxWidth: 468px)": {
                                textAlign: "center",
                                padding: "0 10px",
                              },
                            }}>
                            {recruiterEmailVerification ? "Verified" : "Verify"}
                          </Button>
                          <Dialog
                            open={recruiterEmailOtp}
                            onClose={handleCloseEmail}
                            sx={{
                              "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                  width: "100%",
                                  maxWidth: "590px",
                                  height: "430px",
                                  borderRadius: "8px",
                                  backgroundColor: (theme) =>
                                    theme.Palette.background.dialog,
                                },
                              },
                            }}>
                            <DialogActions>
                              <Box
                                onClick={handleCloseEmail}
                                component='img'
                                src={Close}
                                sx={{
                                  top: "10px",
                                  right: "10px",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </DialogActions>
                            <DialogTitle
                              sx={{
                                color: "#223870",
                                textAlign: "center",
                                fontSize: "22px",
                                marginTop: "-15px",
                              }}>
                              Verify Email ID
                            </DialogTitle>
                            <DialogContent
                              sx={{
                                marginTop: "20px ",
                                textAlign: "center",
                              }}>
                              {" "}
                              <Box>
                                {" "}
                                <Typography
                                  variant='p'
                                  gutterBottom
                                  sx={{
                                    fontSize: "18px",
                                    color: "#5071C4",
                                    fontWeight: "500",
                                  }}>
                                  OTP sent to {email}
                                </Typography>{" "}
                              </Box>
                              <Box
                                sx={{
                                  marginTop: "20px",
                                  marginBottom: "10px",
                                }}>
                                {" "}
                                <Typography
                                  variant='h6'
                                  sx={{
                                    color: "#223870",
                                    fontWeight: "500",
                                  }}>
                                  Enter OTP
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}>
                                <OtpInput
                                  value={otp}
                                  onChange={handleChangeOtp}
                                  numInputs={6}
                                  inputStyle={{
                                    height: "35px",
                                    width: "45px",
                                    color: "#5071C4",
                                    backgroundColor: "#E0DFF4",
                                    border: "1px solid #223870",
                                    margin: "4px",
                                    fontSize: "18px",
                                    borderRadius: "3px",
                                  }}
                                  focusStyle={{
                                    border: "1px solid #223870",
                                  }}
                                />
                              </Box>
                              <Box>
                                {message === "Wrong OTP" && (
                                  <Typography
                                    variant='h5'
                                    sx={{
                                      color: "ff0000",
                                      fontWeight: "500",
                                      fontSize: "12px",
                                    }}>
                                    {message}
                                  </Typography>
                                )}
                              </Box>
                              <Box sx={{ marginTop: "5px" }}>
                                <OtpTimer
                                  resendOtp={handleClickOpenEmail}
                                  timerzero={timerZero}
                                />
                              </Box>
                              <Box sx={{ marginTop: "40px " }}>
                                {" "}
                                <Button
                                  onClick={verifyRecruiterEmailOtp}
                                  sx={{
                                    padding: "4px 20px",
                                  }}>
                                  Verify
                                </Button>
                              </Box>
                            </DialogContent>
                          </Dialog>
                        </Box>
                      </Stack>
                      {errors.email && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.email.message}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    Item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Mobile No.*
                    </Typography>{" "}
                  </Grid>
                  <Grid container>
                    <Grid item sm={4} sx={{ paddingRight: "10px" }}>
                      <Autocomplete
                        id='mobile-s-demo'
                        sx={{ width: "100% " }}
                        value={selectedCountry}
                        options={countryListnew}
                        autoHighlight
                        disableClearable
                        variant='standard'
                        popupIcon={
                          <KeyboardArrowDownIcon
                            sx={{
                              color: "#5071C4",
                            }}
                          />
                        }
                        name='country_code'
                        onChange={(_event, country) => {
                          console.log("country", country);
                          setSelectedCountry(country);
                        }}
                        size='small'
                        getOptionLabel={(option) => {
                          return option.iso3 + " +" + option.phone_code;
                        }}
                        renderInput={(params) => (
                          <FormInput
                            sx={{
                              backgroundColor: "#E0DFF4",
                              borderRadius: "5px",
                              height: 40.5,
                            }}
                            size='small'
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={8}>
                      <Stack direction='row' spacing={0}>
                        {" "}
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            width: "100%",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='mobile'
                          onChange={(e) => setMobile(e.target.value)}
                          // {...register('mobile', {
                          //   required: 'Mobile number is required',
                          //   pattern: {
                          //     value: /^((\+91?)|\+)?[7-9][0-9]{9}$/,
                          //     message:
                          //       'Please enter a valid 10-digit mobile number',
                          //   },
                          //   minLength: {
                          //     value: 10,
                          //     message: 'Mobile number must be consist 10 digit',
                          //   },
                          //   maxLength: {
                          //     value: 10,
                          //     message: 'Mobile number exceeds max digit',
                          //   },
                          // })}
                        />{" "}
                        <Box
                          sx={{
                            marginLeft: "-20px",
                            backgroundColor: "#E0DFF4",
                            padding: "3px 0px",
                            borderRadius: "0 5px 5px 0px",
                            "@media (maxWidth: 468px)": {
                              fontSize: "14px",
                            },
                          }}>
                          {" "}
                          <Button
                            onClick={handleClickOpenMobile}
                            variant='standard'
                            disabled={recruiterMobileVerification}
                            sx={{
                              marginRight: "4px",
                              borderRadius: "3px",
                              color: recruiterMobileVerification
                                ? "#FDCF57!important"
                                : "#FDCF57",
                              marginTop: "1px",
                              padding: "2px 20px",

                              "@media (maxWidth: 468px)": {
                                textAlign: "center",
                                padding: "0 10px",
                              },
                            }}>
                            {recruiterMobileVerification
                              ? "Verified"
                              : "Verify"}
                          </Button>
                          <Dialog
                            open={recruiterMobileOtp}
                            onClose={handleCloseMobile}
                            sx={{
                              "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                  width: "100%",
                                  maxWidth: "590px",
                                  height: "430px",
                                  borderRadius: "10px",
                                  backgroundColor: (theme) =>
                                    theme.Palette.background.dialog,
                                },
                              },
                            }}>
                            <DialogActions>
                              <Box
                                onClick={handleCloseMobile}
                                component='img'
                                src={Close}
                                sx={{
                                  top: "10px",
                                  right: "10px",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </DialogActions>
                            <DialogTitle
                              sx={{
                                color: "#223870",
                                textAlign: "center",
                                fontSize: "22px",
                                marginTop: "-15px",
                              }}>
                              Verify Mobile No.
                            </DialogTitle>
                            <DialogContent
                              sx={{
                                marginTop: "20px ",
                                textAlign: "center",
                              }}>
                              {" "}
                              <Box>
                                {" "}
                                <Typography
                                  variant='p'
                                  gutterBottom
                                  sx={{
                                    fontSize: "18px",
                                    color: "#5071C4",
                                    fontWeight: "500",
                                  }}>
                                  OTP sent to {mobile}
                                </Typography>{" "}
                              </Box>
                              <Box
                                sx={{
                                  marginTop: "20px",
                                  marginBottom: "10px",
                                }}>
                                {" "}
                                <Typography
                                  variant='h6'
                                  sx={{
                                    color: "#223870",
                                    fontWeight: "500",
                                  }}>
                                  Enter OTP
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}>
                                <OtpInput
                                  value={otp}
                                  onChange={handleChangeOtp}
                                  numInputs={6}
                                  inputStyle={{
                                    height: "35px",
                                    width: "45px",
                                    color: "#5071C4",
                                    backgroundColor: "#E0DFF4",
                                    border: "1px solid #223870",
                                    margin: "4px",
                                    fontSize: "18px",
                                    borderRadius: "3px",
                                  }}
                                  focusStyle={{
                                    border: "1px solid #223870",
                                  }}
                                />
                              </Box>
                              <Box>
                                {message === "Wrong OTP" && (
                                  <Typography
                                    variant='h5'
                                    sx={{
                                      color: "ff0000",
                                      fontWeight: "500",
                                      fontSize: "12px",
                                    }}>
                                    {message}
                                  </Typography>
                                )}
                              </Box>
                              <Box sx={{ marginTop: "5px" }}>
                                <OtpTimer
                                  resendOtp={handleClickOpenMobile}
                                  timerzero={timerZero}
                                />
                              </Box>
                              <Box sx={{ marginTop: "40px " }}>
                                {" "}
                                <Button
                                  onClick={verifyRecruiterMobileOtp}
                                  sx={{
                                    padding: "4px 20px",
                                    textTransform: "none",
                                    textDecoration: "none",
                                    borderRadius: "3px ",
                                    color: "#FDCF57",
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    backgroundColor: "#223870",
                                    "&:hover": {
                                      backgroundColor: "#223870",
                                      color: "#FDCF57 ",
                                    },
                                  }}>
                                  Verify
                                </Button>
                              </Box>
                            </DialogContent>
                          </Dialog>
                        </Box>
                      </Stack>
                      {errors.mobile && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.mobile.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    Item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      // component="div"
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Company
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    <FormInput
                      sx={{
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                      }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name='company'
                      {...register("company", {
                        required: "Company Name is required",
                        minLength: {
                          value: 3,
                          message: "Company Name must be atleast 3 characters",
                        },
                      })}
                    />
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    Item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      // component="div"
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      PAN No.*
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    <FormInput
                      sx={{
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                      }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name='pan'
                      {...register("pan", {
                        required: "pan is required",
                        minLength: {
                          value: 10,
                          message: "pan must be 10 characters",
                        },
                        maxLength: {
                          value: 10,
                          message: "pan must be 10 characters",
                        },
                      })}
                    />
                    {errors.pan && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.pan.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    Item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      // component="div"
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Aadhar No.*
                    </Typography>{" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        fontSize: "10px",
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      (use white space as mention in adhar card)
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    <FormInput
                      sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name='aadhar'
                      {...register("aadhar", {
                        required: "Aadhar number is required",
                        pattern: {
                          value: /^[0-9]{12}/,
                          //     value: /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/,
                          message: "Please enter a valid Aadhar number",
                        },
                      })}
                    />
                    {errors.aadhar && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.aadhar.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    Item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      // component="div"
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Pan Card*
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    <Box>
                      <label htmlFor='icon-button-image'></label>
                      <input
                        id='icon-button-image'
                        type='file'
                        style={{
                          width: "100%",
                          padding: "10px",
                          display: "block",
                          borderRadius: "5px",
                          backgroundColor: "#E0DFF4",
                          color: "#304672",
                          cursor: "pointer",
                        }}
                        required
                        onChange={handleUploadJd}
                        name='image'
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box>
                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name='acceptTerms'
                        defaultValue='false'
                        inputRef={register()}
                        render={({ field: { onChange } }) => (
                          <Checkbox
                            // checked={checked}
                            sx={{
                              color: "#223870",
                              "&.Mui-checked": {
                                color: "#223870",
                              },
                              "&$checked": {
                                color: "yellow",
                              },
                            }}
                            required
                            onChange={(e) => onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                    label={
                      <Typography
                        varient='p'
                        color={errors.acceptTerms ? "error" : "inherit"}
                        sx={{
                          fontSize: "12px",
                        }}>
                        I agree to the{" "}
                        <a href='/' style={{ color: "#223870" }}>
                          T&C and Privacy Policy
                        </a>{" "}
                        of the ExoTalent*
                      </Typography>
                    }
                  />
                  <br />
                  <Typography variant='inherit' color='textSecondary'>
                    {errors.acceptTerms
                      ? "(" + errors.acceptTerms.message + ")"
                      : ""}
                  </Typography>
                </Box>

                <Grid
                  container
                  direction='row'
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  {" "}
                  <Grid item>
                    <Button
                      //onClick={redirectProfile}
                      type='submit'
                      sx={{
                        textTransform: "none",
                        textDecoration: "none",
                        padding: "4px 20px",
                        borderRadius: "3px ",
                        color: "#FDCF57",
                        fontWeight: "500",
                        fontSize: "20px",
                        backgroundColor: "#223870",
                        "&:hover": {
                          backgroundColor: "#223870",
                          color: "#FDCF57 ",
                        },
                      }}>
                      Signup
                    </Button>
                  </Grid>
                  <Grid item>
                    <Box mt={2}>
                      <a
                        href='/recruiter/login'
                        style={{
                          fontSize: "16px",
                          color: "#223870",
                        }}>
                        Login
                      </a>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default RecruiterRegister;
