import React, { useEffect } from "react";
import { Grid, Box, Button, Typography, Stack } from "@mui/material";
import Logo from "../../../../assets/images/female.jpeg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../../components/Header/Navbar";
import wallet from "../../../../assets/Icons/Wallet.png";
import notification from "../../../../assets/Icons/notification.png";
import Avatar from "@mui/material/Avatar";
import JobCardMain from "../../../../components/jobCards/jobCardMain";
import * as authActions from "../../../../Store/candidate";
const useStyles = makeStyles((theme) => ({
  topbar: {
    position: "relative",
    marginTop: "80px",
    padding: "20px 70px",
    backgroundSize: "cover",
    backgroundColor: "#F0F1FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  imgFrame: {
    background: "#fff",
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    objectFit: "cover",
    margin: "0 auto",
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    backgroundSize: "cover",
  },
  text: { marginLeft: "30px" },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "55px",
    right: "100px",
  },
  body: {
    padding: "20px 80px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#F6F8FD",
    textAlign: "center",
  },
  jobbox: {
    marginLeft: "15px",
    padding: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },

  "@media (min-width: 1640px)": {
    mainHome: {
      marginTop: "80px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
  },
  "@media (maxWidth: 468px)": {
    mainHome: {
      padding: "50px 15px",
      backgroundImage: "none",
      backgroundColor: "#c6c2f6",
    },
    search: {
      marginRight: "0",
      padding: "3px 10px",
    },
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "35px",
    },
    container: {
      padding: "0 0px",
    },
  },
}));

const RecruiterProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirect = () => {
    navigate("/recruiter/profile/edit");
  };
  const profile = useSelector((state) => state?.auth?.recruiterProfile);
  useEffect(() => {
    async function recruiterProfile() {
      const recruiterdetail = await dispatch(authActions.getRecruiterProfile());
      console.log("profile deatil ==========", recruiterdetail);
    }
    recruiterProfile();
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <Box className={classes.container}>
        {" "}
        <Box className={classes.topbar}>
          <Stack direction='row'>
            <Box
              className={classes.imgFrame}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              {" "}
              <Avatar
                variant='square'
                src={
                  Logo
                  // candidateProfile?.profile_image
                  //   ? candidateProfile?.profile_image
                  //   : PersonIcon
                }
                sx={{ width: 120, height: 120 }}
              />
            </Box>
            <Box className={classes.text}>
              <Typography
                variant='h4'
                sx={{
                  marginTop: "10px",
                  fontSize: "28px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "600",
                }}>
                {profile?.first_name + " " + profile?.last_name}
              </Typography>
              <Typography
                variant='p'
                sx={{
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "500",
                }}>
                Recruiter - {profile.company}
              </Typography>
              <Stack
                direction='row'
                spacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ marginTop: "15px" }}>
                <Box
                  component='img'
                  sx={{
                    width: "20px",
                    height: "20px",
                    "@media (maxWidth: 468px)": {
                      width: "14px",
                      height: "14px",
                    },
                  }}
                  src={wallet}
                />
                <Box>
                  <Typography
                    variant='h7'
                    sx={{
                      marginTop: "-5px",
                      marginLeft: "-10px",
                      textAlign: "left",
                      color: "#223870",
                      fontWeight: "500",
                    }}>
                    {" "}
                    Rs.4000/-
                    {/* No. of employee */}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box className={classes.button}>
              {" "}
              <Box
                component='img'
                sx={{
                  marginRight: "20px",
                  width: "20px",
                  height: "20px",
                  "@media (maxWidth: 468px)": {
                    width: "14px",
                    height: "14px",
                  },
                }}
                src={notification}
              />
              <Button
                onClick={redirect}
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "8px 20px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "18px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                }}>
                Edit Profile
              </Button>
            </Box>
          </Stack>
        </Box>
        <Box className={classes.body}>
          <Box sx={{ marginTop: "20px" }}>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}>
              {" "}
              <Grid item xs={12} sm={6}>
                <Box className={classes.jobbox}>
                  <JobCardMain />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.jobbox}>
                  <JobCardMain />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}>
              {" "}
              <Grid item xs={12} sm={6}>
                <Box className={classes.jobbox}>
                  <JobCardMain />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.jobbox}>
                  <JobCardMain />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}>
              {" "}
              <Grid item xs={12} sm={6}>
                <Box className={classes.jobbox}>
                  <JobCardMain />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.jobbox}>
                  <JobCardMain />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default RecruiterProfile;
