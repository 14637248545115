export const api = {
  // USER
  MOBILE_OTP: `/v1/auth/otp`,
  VERIFY_MOBILE_OTP: `/v1/auth/otp/verify`,
  CANDIDATE_SIGNUP: `/v1/auth/register`,
  USER: `/v1/users/`,
  UPLOAD: "/v1/contents",
  GET_DESIGNATION: `/v1/configuration/api/v1/db/data/v1/linkcxo/designations`,
  GET_INDUSTRY: `/v1/configuration/api/v1/db/data/v1/linkcxo/industries`,
  GET_FUNCTION: `/v1/configuration/api/v1/db/data/v1/linkcxo/functions`,

  // COMPANY
  SEND_COMPANY_EMAIL_OTP: `/v1/companies/auth/otp`,
  VERIFY_COMPANY_EMAIL_OTP: `/v1/companies/auth/otp/verify`,
  CREATE_COMPANY: `/v1/exo/companies`,
  GET_COMPANY: "/v1/companies/",

  //=====================OLD===============================
  EMAIL_OTP: `/user/email/send_otp`,
  VERIFY_EMAIL_OTP: `/user/email/otp_verification`,
  // COMPANY_SIGNUP: `/user/company/signup`,
  VERIFY_GST: `/user/gst/verification`,
  COMPANY_CREATE_JOB: `/user/company/create_job`,
  CREATE_JOB: `/user/company/create_job`,
  UPDATE_JOB: `/user/company/update_job`,
  CANDIDATE_APPLY_JOB: `/user/candidate/apply_job`,
  CANDIDATE_WITHDRAW_JOB: `/user/candidate/withdraw_job`,
  COMPANY_GET_ALL_JOB: `/user/company/get_all_created_job`,
  COMPANY_GET_JOB_DESCRIPTION: `/user/company/get_job_description/`,
  JOB_CANDIDATE_PROFILE: `/user/candidate/get_profile`,
  COMPANY_GET_JOB_BY_FILTER: `/user/company/get_job_by_filter`,
  COMPANY_PROFILE_DETAILS: `/user/company/get_profile`,
  GET_ALL_APPLIED_JOB: `/user/candidate/get_all_applied_job`,
  APPLY_JOB_CANDIDATE: `user/candidate/apply_job`,
  GET_ALL_APPLIED_CANDIDATE: `/user/company/get_all_applied_candidate`,
  CANDIDATE_EDUCATION_LIST: `/helper/api/get_education_degree`,
  UPDATE_JOB_STATUS: `/user/company/update_job_status`,
  COMPANY_UPDATE_PROFILE: `/user/company/update_profile`,
  COMPANY_CANDIDATE_JOB_UPDATE_STATUS: `/user/company/change_interview_status`,
  COMPANY_CANDIDATE_APPLIED_STATUS: `user/company/applied_candidate_status`,
  CANDIDATE_JOB_APPLIED_STATUS: `user/candidate/applied_job_status`,

  GET_LOCATION: `/helper/api/get_city`,
  GET_COMPANY_SIZE: `/helper/api/get_company_size`,
  GET_COMPANY_TYPE: `/helper/api/get_company_type`,
  GET_JOB_TYPE: `/helper/api/get_job_type`,
  GET_COMPANY_REGISTRATION_TYPE: `/helper/api/get_company_registration_type`,
  GET_COMPANY_WORK_MODEL: `/helper/api/get_work_model`,
  RECRUITER_SIGNUP: `/user/recruiter/signup`,
  RECRUITER_GET_PROFILE: `/user/recruiter/get_profile`,
};
