import { combineReducers } from "redux";
import { candidatereducer as candidate } from "./candidate";
import { companyreducer as company } from "./company";
import { messagereducer as message } from "./message";
import { jobsreducer as jobs } from "./Jobs";
import { helperreducer as helper } from "./helper";

const reducers = {
  candidate,
  message,
  jobs,
  helper,
  company,
};
const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_FULFILLED") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
