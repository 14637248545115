import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, IconButton, Tooltip } from "@mui/material";
import experience from "../../../assets/Icons/Experience.png";
import jobtype from "../../../assets/Icons/JobType.png";
import industry from "../../../assets/Icons/Industry.png";
import location from "../../../assets/Icons/Location.png";
import date from "../../../assets/Icons/Calendar.png";
import designation from "../../../assets/Icons/Function.png";
import salary from "../../../assets/Icons/Salary.png";
import education from "../../../assets/Icons/Education.png";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import * as jobsActions from "../../../Store/Jobs";
import { useSelector, useDispatch } from "react-redux";
import { typet } from "../../../assets/Data/data";

import moment from "moment";
const useStyles = makeStyles((theme) => ({
  "@media (min-width: 1640px)": {},
  "@media (maxWidth: 468px)": {},
}));

function JobCandidateCardnew(props) {
  console.log("JobCandidateCard", props);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [jobdetails, setJobDetails] = useState("");
  const educationlist = useSelector((state) => state?.auth?.educationlist);
  const candidateDetails = async (e) => {
    e.stopPropagation();
    console.log("id", props?.data);
    await dispatch(jobsActions.saveJobCandidate(props?.data));
    window.scrollTo({ top: 0 });
    navigate("/candidate/details/" + props?.data?.user_id?._id);
  };

  useEffect(() => {
    async function jobDetails() {
      if (props?.data?.job_id) {
        const id = props?.data?.job_id;
        const jobList = await dispatch(jobsActions.getJobDescription(id));
        console.log("jobList", jobList);
        if (jobList?.data?.status === true) {
          setJobDetails(jobList?.data?.data[0]);
        }
      }
    }
    jobDetails();
  }, []);

  const getType = () => {
    let type = typet.find((item) => item._id === jobdetails?.type);
    console.log("type", type);
    return type?.value;
  };
  const getEducation = () => {
    if (props?.data?.user_id?.education?.length > 0) {
      console.log("getEducation", props?.data?.user_id?.education[0]?.degree);
      let degree = educationlist.find(
        (item) => item._id === props?.data?.user_id?.education[0]?.degree
      );

      return degree?.degree;
    }
  };

  return (
    <>
      <div
        onClick={candidateDetails}
        style={{ cursor: "pointer", margin: "0 4px 0px 4px" }}>
        {" "}
        <Grid container>
          <Grid item>
            <Typography
              variant='h5'
              sx={{
                position: "absolute",
                top: "10px",
                left: "20px",
                color: "#304672",
                fontWeight: "600",
                "@media (maxWidth: 468px)": {
                  top: "5px",
                  left: "5px",
                  marginTop: "10px",
                  fontSize: "16px",
                },
              }}>
              {props?.data?.user_id?.first_name
                ? props?.data?.user_id?.first_name
                : ""}{" "}
              {"  "}
              {props?.data?.user_id?.last_name
                ? props?.data?.user_id?.last_name
                : ""}
            </Typography>
            <Typography
              variant='h6'
              sx={{
                position: "absolute",
                top: "35px",
                left: "20px",
                color: "#304672",
                fontWeight: "600",
                "@media (maxWidth: 468px)": {
                  top: "25px",
                  left: "10px",
                  marginTop: "10px",
                  fontSize: "16px",
                },
              }}></Typography>
          </Grid>
          <Grid item>
            <Box
              component='img'
              src={
                props?.data?.user_id?.profile_image
                  ? props?.data?.user_id?.profile_image
                  : ""
              }
              sx={{
                position: "absolute",
                top: "20px",
                right: "20px",
                width: "60px",
                height: "60px",
                "@media (maxWidth: 468px)": {
                  top: "10px",
                  right: "10px",
                  width: "60px",
                  height: "60px",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ marginLeft: "5px" }}>
          <Grid item xs={12} sm={12}>
            <Grid
              container
              sx={{
                marginTop: "50px",
              }}>
              <Grid item sm={6} xs={6}>
                <Grid container sx={{ marginLeft: "5px" }}>
                  <Grid item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Location'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            marginTop: "-4px",
                            width: "18px",
                            height: "18px",
                            "@media (maxWidth: 468px)": {
                              marginTop: "-8px",
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={location}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: "16px",
                        textAlign: "left",
                        marginLeft: "-2px",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                          lineHeight: "18px",
                          textAlign: "left",
                        },
                      }}>
                      {props?.data?.location
                        ? props?.data?.location
                        : jobdetails?.location?.city}
                      {props?.data?.location}
                    </Typography>{" "}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: "5px" }}>
                  <Grid item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Job Type'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            marginTop: "-4px",
                            width: "18px",
                            height: "18px",
                            "@media (maxWidth: 468px)": {
                              marginTop: "-8px",
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={jobtype}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: "16px",
                        textAlign: "left",
                        marginLeft: "-2px",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                          lineHeight: "18px",
                          textAlign: "left",
                        },
                      }}>
                      {getType()}
                    </Typography>{" "}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: "5px" }}>
                  <Grid item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Industry'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            marginTop: "-4px",
                            width: "18px",
                            height: "18px",
                            "@media (maxWidth: 468px)": {
                              marginTop: "-8px",
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={industry}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: "16px",
                        textAlign: "left",
                        marginLeft: "-2px",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                          lineHeight: "18px",
                          textAlign: "left",
                        },
                      }}>
                      {jobdetails?.industry?.industry}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: "5px" }}>
                  <Grid item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Function'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            marginTop: "-4px",
                            width: "18px",
                            height: "18px",
                            "@media (maxWidth: 468px)": {
                              marginTop: "-8px",
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={designation}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: "16px",
                        textAlign: "left",
                        marginLeft: "-2px",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                          lineHeight: "18px",
                          textAlign: "left",
                        },
                      }}>
                      {jobdetails?.function?.function}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6} xs={6}>
                {" "}
                <Grid container sx={{ marginLeft: "5px" }}>
                  <Grid item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title='Total Experience'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            marginTop: "-4px",
                            width: "18px",
                            height: "18px",
                            "@media (maxWidth: 468px)": {
                              marginTop: "-8px",
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={experience}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: "16px",
                        textAlign: "left",
                        marginLeft: "-2px",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                          lineHeight: "18px",
                          textAlign: "left",
                        },
                      }}>
                      {props?.data?.total_relevant_experience} yrs exp.
                    </Typography>{" "}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: "5px" }}>
                  <Grid item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      title=' Salary'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            marginTop: "-4px",
                            width: "18px",
                            height: "18px",
                            "@media (maxWidth: 468px)": {
                              marginTop: "-8px",
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={salary}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: "16px",
                        textAlign: "left",
                        marginLeft: "-2px",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                          lineHeight: "18px",
                          textAlign: "left",
                        },
                      }}>
                      {props?.data?.expected_ctc} lpa
                    </Typography>{" "}
                  </Grid>
                </Grid>
                <Grid container sx={{ marginLeft: "5px" }}>
                  <Grid item xs={2} sm={2}>
                    <Tooltip
                      enterNextDelay={1500}
                      enterDelay={1500}
                      leaveDelay={200}
                      disableFocusListener
                      disableTouchListener
                      title='Date'
                      placement='left'
                      arrow>
                      <IconButton>
                        {" "}
                        <Box
                          component='img'
                          sx={{
                            marginTop: "-4px",
                            width: "16px",
                            height: "16px",
                            "@media (maxWidth: 468px)": {
                              marginTop: "-8px",
                              width: "14px",
                              height: "14px",
                            },
                          }}
                          src={date}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={10} sm={10}>
                    <Typography
                      variant='h6'
                      sx={{
                        fontSize: "16px",
                        textAlign: "left",
                        marginLeft: "-2px",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                          lineHeight: "18px",
                          textAlign: "left",
                        },
                      }}>
                      Applied on{" "}
                      {moment(
                        props?.data?.created_at
                          ? props?.data?.created_at
                          : jobdetails?.created_at
                      ).format("ll")}
                    </Typography>{" "}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={1} sm={1}>
              <Box
                component='img'
                sx={{
                  marginLeft: "10px",
                  marginTop: "3px",
                  width: "18px",
                  height: "18px",
                  "@media (maxWidth: 468px)": {
                    marginTop: "-5px",
                    width: "14px",
                    height: "14px",
                  },
                }}
                src={education}
              />
            </Grid>
            <Grid item xs={11} sm={11}>
              <Typography
                variant='h6'
                sx={{
                  marginLeft: "3px",
                  fontSize: "16px",
                  textAlign: "left",
                  color: "#5677B9",
                  "@media (maxWidth: 468px)": {
                    marginLeft: "3px",
                    fontSize: "14px",
                  },
                }}>
                {getEducation()}
              </Typography>{" "}
            </Grid>
          </Grid>
        </Grid>{" "}
      </div>
    </>
  );
}

export default JobCandidateCardnew;
