import { apiUrl } from "../../services";
import { http, http_noauth, http_linkcxo } from "../../services/client";
import { showMessage } from "../message";
import {
  setTokenResponse,
  setUserData,
  getAccessToken,
  getUserData,
} from "../../services/authData";

// candidate_send_otp_signup

export const SEND_OTP_CANDIDATE_MOBILE_FULFILLED =
  "SEND_OTP_CANDIDATE_MOBILE_FULFILLED";
export const SEND_OTP_CANDIDATE_MOBILE_PENDING =
  "SEND_OTP_CANDIDATE_MOBILE_PENDING";
export const SEND_OTP_CANDIDATE_MOBILE_REJECTED =
  "SEND_OTP_CANDIDATE_MOBILE_REJECTED";

const sendCandidateMobileOtpRequest = () => ({
  type: SEND_OTP_CANDIDATE_MOBILE_PENDING,
});

const sendCandidateMobileOtpFailure = (error) => ({
  type: SEND_OTP_CANDIDATE_MOBILE_REJECTED,
  payload: { error },
});

const sendCandidateMobileOtpSuccess = (response, data) => ({
  type: SEND_OTP_CANDIDATE_MOBILE_FULFILLED,
  payload: {
    candidateMobileOtp: true,
    ...response,
  },
});

export const checkCandidateMobile = (data) => {
  return (dispatch) => {
    console.log("send mob otp to candidate");
    dispatch(sendCandidateMobileOtpRequest());
    console.log("send mob otp to candidate@@@@@@@@@@");
    return http_noauth
      .post(apiUrl.MOBILE_OTP, data)
      .then((response) => {
        if (response) {
          console.log(response, "response of send mobile otp");
          console.log(response?.data?.data?.requestId, "request id");
          dispatch(sendCandidateMobileOtpSuccess(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(sendCandidateMobileOtpFailure(error));
        return error;
      });
  };
};
export const SEND_OTP_CANDIDATE_LOGIN_FULFILLED =
  "SEND_OTP_CANDIDATE_LOGIN_FULFILLED";
export const SEND_OTP_CANDIDATE_LOGIN_PENDING =
  "SEND_OTP_CANDIDATE_LOGIN_PENDING";
export const SEND_OTP_CANDIDATE_LOGIN_REJECTED =
  "SEND_OTP_CANDIDATE_LOGIN_REJECTED";

const sendCandidateLoginOtpRequest = () => ({
  type: SEND_OTP_CANDIDATE_LOGIN_PENDING,
});

const sendCandidateLoginOtpFailure = (error) => ({
  type: SEND_OTP_CANDIDATE_LOGIN_REJECTED,
  payload: { error },
});

const sendCandidateLoginOtpSuccess = (response, data) => ({
  type: SEND_OTP_CANDIDATE_LOGIN_FULFILLED,
  payload: {
    candidateLoginOtpSend: true,
    ...response,
  },
});

export const sendOtpCandidateLogin = (data) => {
  return (dispatch) => {
    console.log("send mob otp to candidate");
    dispatch(sendCandidateLoginOtpRequest());
    console.log("send mob otp to candidate@@@@@@@@@@");
    return http_noauth
      .post(apiUrl.MOBILE_OTP, data)
      .then((response) => {
        if (response) {
          console.log(response, "response of send mobile otp");
          console.log(response?.data?.data?.requestId, "request id");
          dispatch(sendCandidateLoginOtpSuccess(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(sendCandidateLoginOtpFailure(error));
        return error;
      });
  };
};

export const CLOSE_CANDIDATE_MOBILE_DIALOG = "CLOSE_CANDIDATE_MOBILE_DIALOG";
export const closeCandidateMobileDialog = () => {
  return {
    type: CLOSE_CANDIDATE_MOBILE_DIALOG,
  };
};

// candidate_verify_otp_signup
export const VERIFY_OTP_CANDIDATE_MOBILE_FULFILLED =
  "VERIFY_OTP_CANDIDATE_MOBILE_FULFILLED";
export const VERIFY_OTP_CANDIDATE_MOBILE_PENDING =
  "VERIFY_OTP_CANDIDATE_MOBILE_PENDING";
export const VERIFY_OTP_CANDIDATE_MOBILE_REJECTED =
  "VERIFY_OTP_CANDIDATE_MOBILE_REJECTED";

const verifyCandidateMobileOtpRequest = () => ({
  type: VERIFY_OTP_CANDIDATE_MOBILE_PENDING,
});

const verifyCandidateMobileOtpFailure = (error) => ({
  type: VERIFY_OTP_CANDIDATE_MOBILE_REJECTED,
  payload: { error },
});

const verifyCandidateMobileOtpSuccess = (response) => ({
  type: VERIFY_OTP_CANDIDATE_MOBILE_FULFILLED,
  candidateMobileOtp: true,
  payload: response,
});

export const verifyCandidateMobileOtp = (data) => {
  return (dispatch) => {
    dispatch(verifyCandidateMobileOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_MOBILE_OTP, data)
      .then((response) => {
        console.log(
          "verifyCandidateMobileOtp",
          response,
          response?.config?.headers
        );
        if (
          response?.data?.status === 1 &&
          response?.data?.data?.accountExists === false
        ) {
          dispatch(verifyCandidateMobileOtpSuccess(response));
        } else if (
          response?.data?.status === 1 &&
          response?.data?.data?.accountExists === true
        ) {
          showMessage({
            dispatch,
            message: "User already signed Up",
            variant: "error",
          });
        }

        return response;
      })
      .catch((error) => {
        dispatch(verifyCandidateMobileOtpFailure(error));
        return error;
      });
  };
};

// verify login otp
export const VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_FULFILLED =
  "VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_FULFILLED";
export const VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_PENDING =
  "VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_PENDING";
export const VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_REJECTED =
  "VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_REJECTED";

const verifyLoginCandidateMobileOtpRequest = () => ({
  type: VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_PENDING,
});

const verifyLoginCandidateMobileOtpFailure = (error) => ({
  type: VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_REJECTED,
  payload: { error },
});

const verifyLoginCandidateMobileOtpSuccess = (response) => ({
  type: VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_FULFILLED,
  payload: response,
});

export const verifyCandidateMobileOtpLogin = (data) => {
  return (dispatch) => {
    dispatch(verifyLoginCandidateMobileOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_MOBILE_OTP, data)
      .then((response) => {
        console.log("login Candidate token", response);
        console.log("verifyCandidateMobileOtpLogin", response);
        console.log(
          "acct exist???????????",
          response?.data?.data?.accountExists
        );
        if (
          response?.data?.status === 1 &&
          response?.data?.data?.accountExists === false
        ) {
          showMessage({
            dispatch,
            message: "User doesn't Signup, please signup first ",
            variant: "error",
          });
        } else if (
          response?.data?.status === 1 &&
          response?.data?.data?.accountExists === true
        ) {
          setTokenResponse(response?.data.data.session.token);
          setUserData(response?.data?.data);
          dispatch(verifyLoginCandidateMobileOtpSuccess(response));
          showMessage({
            dispatch,
            message: "Login Successfully ",
            variant: "success",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(verifyLoginCandidateMobileOtpFailure(error));
        return error;
      });
  };
};

// candidate_signup
export const SIGNUP_CANDIDATE_FULFILLED = "SIGNUP_CANDIDATE_FULFILLED";
export const SIGNUP_CANDIDATE_PENDING = "SIGNUP_CANDIDATE_PENDING";
export const SIGNUP_CANDIDATE_REJECTED = "SIGNUP_CANDIDATE_REJECTED";

const candidateSignupRequest = () => ({
  type: SIGNUP_CANDIDATE_PENDING,
});

const candidateSignupFailure = (error) => ({
  type: SIGNUP_CANDIDATE_REJECTED,
  payload: { error },
});

const candidateSignupFulfilled = (response) => ({
  type: SIGNUP_CANDIDATE_FULFILLED,
  payload: response,
  requestId: response.data?.data?.requestId,
});

export const signupCandidate = (data) => {
  return (dispatch) => {
    dispatch(candidateSignupRequest());
    return http_noauth
      .post(apiUrl.CANDIDATE_SIGNUP, data)
      .then((response) => {
        console.log("signupCandidate response", response?.data?.data);
        if (response) {
          setTokenResponse(response?.data.data.session.token);
          setUserData(response?.data?.data);

          dispatch(candidateSignupFulfilled(response));
          showMessage({
            dispatch,
            message: "Signup Successfully",
            variant: "success",
          });
        } else {
          dispatch(candidateSignupFailure(response?.data));
          showMessage({
            dispatch,
            message: response?.data?.data,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateSignupFailure(error));
        return error;
      });
  };
};
export const RESET_CANDIDATE_DETAILS = "RESET_CANDIDATE_DETAILS";
export const resetCandidateDetails = () => {
  return {
    type: RESET_CANDIDATE_DETAILS,
  };
};
// candidate_get_profile
export const GET_CANDIDATE_PROFILE_DETAILS_SUCCESS =
  "GET_CANDIDATE_PROFILE_DETAILS_SUCCESS";
export const GET_CANDIDATE_PROFILE_DETAILS_REQUEST =
  "GET_CANDIDATE_PROFILE_DETAILS_REQUEST";
export const GET_CANDIDATE_PROFILE_DETAILS_FAILURE =
  "GET_CANDIDATE_PROFILE_DETAILS_FAILURE";

const getCandidateRequest = () => ({
  type: GET_CANDIDATE_PROFILE_DETAILS_REQUEST,
});

const getCandidateFailure = (error) => ({
  type: GET_CANDIDATE_PROFILE_DETAILS_FAILURE,
  payload: { error: error },
});

const getCandidateSuccess = (data) => ({
  type: GET_CANDIDATE_PROFILE_DETAILS_SUCCESS,
  payload: data,
});
export const getCandidateProfile = () => {
  const token = getAccessToken();
  console.log(token);
  const userData = getUserData();
  console.log(userData);
  console.log(token, userData.session, "userand token");
  return (dispatch) => {
    dispatch(getCandidateRequest());
    return http
      .get(apiUrl.USER + userData.session.userId)
      .then((response) => {
        console.log("response@@@@@", response);
        if (response?.data?.status === 1) {
          dispatch(getCandidateSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getCandidateFailure(error));
        console.log("error?????????????", error);
      });
  };
};

// candidate update profile

export const CANDIDATE_UPDATE_PROFILE_FULFILLED =
  "CANDIDATE_UPDATE_PROFILE_FULFILLED";
export const CANDIDATE_UPDATE_PROFILE_PENDING =
  "CANDIDATE_UPDATE_PROFILE_PENDING";
export const CANDIDATE_UPDATE_PROFILE_REJECTED =
  "CANDIDATE_UPDATE_PROFILE_REJECTED";
const candidateUpdateProfileRequest = () => ({
  type: CANDIDATE_UPDATE_PROFILE_PENDING,
});
const candidateUpdateProfileFailure = (error) => ({
  type: CANDIDATE_UPDATE_PROFILE_REJECTED,
  payload: { error },
});
const candidateUpdateProfileFulfilled = (response) => ({
  type: CANDIDATE_UPDATE_PROFILE_FULFILLED,
  payload: response,
});
export const candidateUpdateProfile = (data) => {
  const userData = getUserData();
  console.log(data, "user data?????");
  console.log(userData.session.userId, "user data");
  return (dispatch) => {
    dispatch(candidateUpdateProfileRequest());
    return http
      .put(apiUrl.USER + userData.session.userId, data)
      .then((response) => {
        console.log(response?.config, response?.headers);
        if (response?.data?.status === 1) {
          showMessage({
            dispatch,
            message: "Your profile is successfully updated",
            variant: "success",
          });
          dispatch(candidateUpdateProfileFulfilled(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.data,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateUpdateProfileFailure(error));
        return error;
      });
  };
};
// add skills candidate profile
export const ADD_SKILL_SUCCESS = "ADD_SKILL_SUCCESS";
export const ADD_SKILL_REQUEST = "ADD_SKILL_REQUEST";
export const ADD_SKILL_FAILURE = "ADD_SKILL_FAILURE";

const addSkillRequest = () => ({
  type: ADD_SKILL_REQUEST,
});

const addSkillFailure = (error) => ({
  type: ADD_SKILL_FAILURE,
  payload: { error: error },
});

const addSkillSuccess = (skill) => {
  console.log("Add Skill Success Payload:", skill);
  return {
    type: ADD_SKILL_SUCCESS,
    payload: skill,
  };
};

export const addSkillCandidate = (data) => {
  // debugger;
  const userData = getUserData();
  console.log("Adding skill for user:", userData.session.userId);
  console.log("Skill data:", data);
  return (dispatch) => {
    dispatch(addSkillRequest());
    const path = apiUrl.USER + userData.session.userId + "/skills";
    return http
      .post(path, data)
      .then((response) => {
        console.log("response add skill", response.data.data);
        if (response?.data?.status === 1) {
          const skillId = response.data.data.id;
          dispatch(addSkillSuccess(skillId));

          return skillId;
        }
        return response;
      })
      .catch((error) => {
        dispatch(addSkillFailure(error));
        console.log("error", error);
      });
  };
};

// delete skills candidate profile
export const DELETE_SKILL_SUCCESS = "DELETE_SKILL_SUCCESS";
export const DELETE_SKILL_REQUEST = "DELETE_SKILL_REQUEST";
export const DELETE_SKILL_FAILURE = "DELETE_SKILL_FAILURE";

const deleteSkillRequest = () => ({
  type: DELETE_SKILL_REQUEST,
});

const deleteSkillFailure = (error) => ({
  type: DELETE_SKILL_FAILURE,
  payload: { error: error },
});

const deleteSkillSuccess = (skillId) => ({
  type: DELETE_SKILL_SUCCESS,
  payload: { skillId },
});

export const deleteSkillCandidate = (skillId) => {
  // debugger;
  const userData = getUserData();
  console.log("Deleting skill for user:", userData.session.userId);
  console.log("SkillId to delete:", skillId);
  return (dispatch) => {
    dispatch(deleteSkillRequest());
    const path = `${apiUrl.USER}${userData.session.userId}/skills/${skillId}`;
    return http
      .delete(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === 1) {
          dispatch(deleteSkillSuccess(skillId));
        }
        return response;
      })
      .catch((error) => {
        dispatch(deleteSkillFailure(error));
        console.log("error", error);
      });
  };
};

// get skills candidate profile
export const GET_SKILLS_SUCCESS = " GET_SKILLS_SUCCESS";
export const GET_SKILLS_REQUEST = " GET_SKILLS_REQUEST";
export const GET_SKILLS_FAILURE = " GET_SKILLS_FAILURE";

const getUserSkillsRequest = () => ({
  type: GET_SKILLS_REQUEST,
});

const getUserSkillsFailure = (error) => ({
  type: GET_SKILLS_FAILURE,
  payload: { error: error },
});

const getUserSkillsSuccess = (data) => ({
  type: GET_SKILLS_SUCCESS,
  payload: data,
});
export const getUserSkills = () => {
  // debugger;
  const userData = getUserData();
  return (dispatch) => {
    dispatch(getUserSkillsRequest());
    const path = apiUrl.USER + userData.session.userId + "/skills";
    return http
      .get(path)
      .then((response) => {
        console.log("response getskill", response);
        if (response?.data?.status === 1) {
          dispatch(getUserSkillsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getUserSkillsFailure(error));
        console.log("error", error);
      });
  };
};

// Add Education candidate
export const ADD_EDUCATION_FULFILLED = "ADD_EDUCATION_FULFILLED";
export const ADD_EDUCATION_PENDING = "ADD_EDUCATION_PENDING";
export const ADD_EDUCATION_REJECTED = "ADD_EDUCATION_REJECTED";
const addEducationRequest = () => ({
  type: ADD_EDUCATION_PENDING,
});
const addEducationFailure = (error) => ({
  type: ADD_EDUCATION_REJECTED,
  payload: { error },
});
const addEducationFulfilled = (response) => ({
  type: ADD_EDUCATION_FULFILLED,
  payload: response,
});
export const addEducationCandidate = (data) => {
  // debugger;
  const userData = getUserData();
  return (dispatch) => {
    dispatch(addEducationRequest());
    const path = apiUrl.USER + userData.session.userId + "/education";
    return http
      .post(path, data)
      .then((response) => {
        if (response?.data?.status === 1) {
          dispatch(addEducationFulfilled(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(addEducationFailure(error));
        return error;
      });
  };
};

// delete education candidate profile
export const DELETE_EDUCATION_SUCCESS = "DELETE_EDUCATION_SUCCESS";
export const DELETE_EDUCATION_REQUEST = "DELETE_EDUCATION_REQUEST";
export const DELETE_EDUCATION_FAILURE = "DELETE_EDUCATION_FAILURE";

const deleteEducationRequest = () => ({
  type: DELETE_EDUCATION_REQUEST,
});

const deleteEducationFailure = (error) => ({
  type: DELETE_EDUCATION_FAILURE,
  payload: { error: error },
});

const deleteEducationSuccess = (data) => ({
  type: DELETE_EDUCATION_SUCCESS,
  payload: data,
});
export const deleteEducation = (eduId) => {
  const userData = getUserData();
  return (dispatch) => {
    dispatch(deleteEducationRequest());
    const path = `${apiUrl.USER}${userData.session.userId}/education/${eduId}`;
    return http
      .delete(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === 1) {
          dispatch(deleteEducationSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(deleteEducationFailure(error));
        console.log("error", error);
      });
  };
};
// get education candidate profile
export const GET_EDUCATION_SUCCESS = " GET_EDUCATION_SUCCESS";
export const GET_EDUCATION_REQUEST = " GET_EDUCATION_REQUEST";
export const GET_EDUCATION_FAILURE = " GET_EDUCATION_FAILURE";

const getEducationsRequest = () => ({
  type: GET_EDUCATION_REQUEST,
});

const getEducationsFailure = (error) => ({
  type: GET_EDUCATION_FAILURE,
  payload: { error: error },
});

const getEducationsSuccess = (data) => {
  return {
    type: GET_EDUCATION_SUCCESS,
    payload: data,
  };
};
export const getUserEducation = () => {
  // debugger;
  const userData = getUserData();
  return (dispatch) => {
    dispatch(getEducationsRequest());
    const path = apiUrl.USER + userData.session.userId + "/education";
    return http
      .get(path)
      .then((response) => {
        console.log("response.data.data.data", response.data.data);
        if (response?.data?.status === 1) {
          dispatch(getEducationsSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getEducationsFailure(error));
        console.log("error", error);
      });
  };
};
// update education candidate profile
export const UPDATE_EDUCATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS";
export const UPDATE_EDUCATION_REQUEST = "UPDATE_EDUCATION_REQUEST";
export const UPDATE_EDUCATION_FAILURE = "UPDATE_EDUCATION_FAILURE";

const updateEducationRequest = () => ({
  type: UPDATE_EDUCATION_REQUEST,
});
const updateEducationFailure = (error) => ({
  type: UPDATE_EDUCATION_FAILURE,
  payload: { error: error },
});
const updateEducationSuccess = (data) => ({
  type: UPDATE_EDUCATION_SUCCESS,
  payload: data,
});
export const updateEducation = (data, eduId) => {
  const userData = getUserData();
  return (dispatch) => {
    // debugger;
    dispatch(updateEducationRequest());
    const path = `${apiUrl.USER}${userData.session.userId}/education/${eduId}`;
    return http
      .put(path, data)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === 1) {
          dispatch(updateEducationSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(updateEducationFailure(error));
        console.log("error", error);
      });
  };
};
// Add Experience candidate
export const ADD_EXPERIENCE_FULFILLED = "ADD_EXPERIENCE_FULFILLED";
export const ADD_EXPERIENCE_PENDING = "ADD_EXPERIENCE_PENDING";
export const ADD_EXPERIENCE_REJECTED = "ADD_EXPERIENCE_REJECTED";
const addExperienceRequest = () => ({
  type: ADD_EXPERIENCE_PENDING,
});
const addExperienceFailure = (error) => ({
  type: ADD_EXPERIENCE_REJECTED,
  payload: { error },
});
const addExperienceFulfilled = (response) => ({
  type: ADD_EXPERIENCE_FULFILLED,
  payload: response,
});
export const addExperienceCandidate = (data) => {
  // debugger;
  const userData = getUserData();
  return (dispatch) => {
    dispatch(addExperienceRequest());
    const path = apiUrl.USER + userData.session.userId + "/experience";
    return http
      .post(path, data)
      .then((response) => {
        if (response?.data?.status === 1) {
          dispatch(addExperienceFulfilled(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(addExperienceFailure(error));
        return error;
      });
  };
};

// delete education candidate profile
export const DELETE_EXPERIENCE_SUCCESS = "DELETE_EXPERIENCE_SUCCESS";
export const DELETE_EXPERIENCE_REQUEST = "DELETE_EXPERIENCE_REQUEST";
export const DELETE_EXPERIENCE_FAILURE = "DELETE_EXPERIENCE_FAILURE";

const deleteExperienceRequest = () => ({
  type: DELETE_EXPERIENCE_REQUEST,
});

const deleteExperienceFailure = (error) => ({
  type: DELETE_EXPERIENCE_FAILURE,
  payload: { error: error },
});

const deleteExperienceSuccess = (data) => ({
  type: DELETE_EXPERIENCE_SUCCESS,
  payload: data,
});
export const deleteExperience = (eduId) => {
  const userData = getUserData();
  return (dispatch) => {
    dispatch(deleteExperienceRequest());
    const path = `${apiUrl.USER}${userData.session.userId}/experience/${eduId}`;
    return http
      .delete(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === 1) {
          dispatch(deleteExperienceSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(deleteExperienceFailure(error));
        console.log("error", error);
      });
  };
};
// get education candidate profile
export const GET_EXPERIENCE_SUCCESS = " GET_EXPERIENCE_SUCCESS";
export const GET_EXPERIENCE_REQUEST = " GET_EXPERIENCE_REQUEST";
export const GET_EXPERIENCE_FAILURE = " GET_EXPERIENCE_FAILURE";

const getExperiencesRequest = () => ({
  type: GET_EXPERIENCE_REQUEST,
});

const getExperiencesFailure = (error) => ({
  type: GET_EXPERIENCE_FAILURE,
  payload: { error: error },
});

const getExperiencesSuccess = (data) => {
  return {
    type: GET_EXPERIENCE_SUCCESS,
    payload: data,
  };
};
export const getUserExperience = () => {
  // debugger;
  const userData = getUserData();
  return (dispatch) => {
    dispatch(getExperiencesRequest());
    const path = apiUrl.USER + userData.session.userId + "/experience";
    return http
      .get(path)
      .then((response) => {
        console.log("response.data.data.data", response?.data?.data);
        if (response?.data?.status === 1) {
          dispatch(getExperiencesSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getExperiencesFailure(error));
        console.log("error", error);
      });
  };
};
// update education candidate profile
export const UPDATE_EXPERIENCE_SUCCESS = "UPDATE_EXPERIENCE_SUCCESS";
export const UPDATE_EXPERIENCE_REQUEST = "UPDATE_EXPERIENCE_REQUEST";
export const UPDATE_EXPERIENCE_FAILURE = "UPDATE_EXPERIENCE_FAILURE";

const updateExperienceRequest = () => ({
  type: UPDATE_EXPERIENCE_REQUEST,
});

const updateExperienceFailure = (error) => ({
  type: UPDATE_EXPERIENCE_FAILURE,
  payload: { error: error },
});

const updateExperienceSuccess = (data) => ({
  type: UPDATE_EXPERIENCE_SUCCESS,
  payload: data,
});
export const updateExperience = (data, eduId) => {
  const userData = getUserData();
  return (dispatch) => {
    // debugger;
    dispatch(updateExperienceRequest());
    const path = `${apiUrl.USER}${userData.session.userId}/experience/${eduId}`;
    return http
      .put(path, data)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === 1) {
          dispatch(updateExperienceSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(updateExperienceFailure(error));
        console.log("error", error);
      });
  };
};

// Add preferences Candidate profile

export const ADD_PREFERENCE_FULFILLED = "ADD_PREFERENCE_FULFILLED";
export const ADD_PREFERENCE_PENDING = "ADD_PREFERENCE_PENDING";
export const ADD_PREFERENCE_REJECTED = "ADD_PREFERENCE_REJECTED";
const addPreferencesRequest = () => ({
  type: ADD_PREFERENCE_PENDING,
});
const addPreferencesFailure = (error) => ({
  type: ADD_PREFERENCE_REJECTED,
  payload: { error },
});
const addPreferencesFulfilled = (response) => ({
  type: ADD_PREFERENCE_FULFILLED,
  payload: response,
});
export const addPreferencesCandidate = (data) => {
  // debugger;
  const userData = getUserData();
  return (dispatch) => {
    dispatch(addPreferencesRequest());
    const path = apiUrl.USER + userData.session.userId + "/preferrences";
    return http
      .post(path, data)
      .then((response) => {
        if (response?.data?.status === 1) {
          dispatch(addPreferencesFulfilled(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(addPreferencesFailure(error));
        return error;
      });
  };
};
// update preferences candidate profile
export const UPDATE_PREFERENCE_SUCCESS = "UPDATE_PREFERENCE_SUCCESS";
export const UPDATE_PREFERENCE_REQUEST = "UPDATE_PREFERENCE_REQUEST";
export const UPDATE_PREFERENCE_FAILURE = "UPDATE_PREFERENCE_FAILURE";

const updatePreferenceRequest = () => ({
  type: UPDATE_PREFERENCE_REQUEST,
});

const updatePreferenceFailure = (error) => ({
  type: UPDATE_PREFERENCE_FAILURE,
  payload: { error: error },
});

const updatePreferenceSuccess = (data) => ({
  type: UPDATE_PREFERENCE_SUCCESS,
  payload: data,
});
export const updatePreference = (data, prefId) => {
  const userData = getUserData();

  return (dispatch) => {
    // debugger;
    dispatch(updatePreferenceRequest());
    const path = `${apiUrl.USER}${userData.session.userId}/preferrences/${prefId}`;
    return http
      .put(path, data)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === 1) {
          dispatch(updatePreferenceSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(updatePreferenceFailure(error));
        console.log("error", error);
      });
  };
};
// get preferences candidate profile
export const GET_PREFERENCE_SUCCESS = " GET_PREFERENCE_SUCCESS";
export const GET_PREFERENCE_REQUEST = " GET_PREFERENCE_REQUEST";
export const GET_PREFERENCE_FAILURE = " GET_PREFERENCE_FAILURE";

const getPreferencesRequest = () => ({
  type: GET_PREFERENCE_REQUEST,
});

const getPreferencesFailure = (error) => ({
  type: GET_PREFERENCE_FAILURE,
  payload: { error: error },
});

const getPreferencesSuccess = (data) => ({
  type: GET_PREFERENCE_SUCCESS,
  payload: data,
});
export const getPreferences = () => {
  const userData = getUserData();
  return (dispatch) => {
    dispatch(getPreferencesRequest());
    const path = apiUrl.USER + userData.session.userId + "/preferrences";
    return http
      .get(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === 1) {
          dispatch(getPreferencesSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getPreferencesFailure(error));
        console.log("error", error);
      });
  };
};

// delete preferences candidate profile
export const DELETE_PREFERENCE_SUCCESS = "DELETE_PREFERENCE_SUCCESS";
export const DELETE_PREFERENCE_REQUEST = "DELETE_PREFERENCE_REQUEST";
export const DELETE_PREFERENCE_FAILURE = "DELETE_PREFERENCE_FAILURE";

const deletePreferenceRequest = () => ({
  type: DELETE_PREFERENCE_REQUEST,
});

const deletePreferenceFailure = (error) => ({
  type: DELETE_PREFERENCE_FAILURE,
  payload: { error: error },
});

const deletePreferenceSuccess = (data) => ({
  type: DELETE_PREFERENCE_SUCCESS,
  payload: data,
});
export const deletePreference = (prefId) => {
  const userData = getUserData();
  return (dispatch) => {
    dispatch(deletePreferenceRequest());
    const path = `${apiUrl.USER}${userData.session.userId}/preferrences/${prefId}`;
    return http
      .delete(path)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === 1) {
          dispatch(deletePreferenceSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(deletePreferenceFailure(error));
        console.log("error", error);
      });
  };
};
export const UPLOAD_FILE_FULFILLED = "UPLOAD_FILE_FULFILLED";
export const UPLOAD_FILE_PENDING = "UPLOAD_FILE_PENDING";
export const UPLOAD_FILE_REJECTED = "UPLOAD_FILE_REJECTED";

const uploadFileRequest = () => ({
  type: UPLOAD_FILE_PENDING,
});

const uploadFileFailure = (error) => ({
  type: UPLOAD_FILE_REJECTED,
  payload: { error },
});

const uploadFileSuccess = (data) => ({
  type: UPLOAD_FILE_FULFILLED,
  payload: {
    data,
  },
});

export const uploadFile = (data) => {
  const token = getAccessToken();
  console.log(token);
  return (dispatch) => {
    console.log("send file");
    dispatch(uploadFileRequest());
    console.log("send file blob");
    const path = apiUrl.UPLOAD;
    return http_linkcxo
      .post(path, data)
      .then((response) => {
        if (response) {
          console.log(response, "upload file");
          console.log(response?.data?.data?.requestId, "request id");
          dispatch(uploadFileSuccess(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(uploadFileFailure(error));
        return error;
      });
  };
};
export const UPDATE_CANDIDATE_PROFILE = "UPDATE_CANDIDATE_PROFILE";

export const updateCandidateProfile = (candidateProfile) => ({
  type: UPDATE_CANDIDATE_PROFILE,
  payload: { candidateProfile },
});
//=====================================OLD==================
// candidate email  otp send

export const SEND_OTP_CANDIDATE_EMAIL_FULFILLED =
  "SEND_OTP_CANDIDATE_EMAIL_FULFILLED";
export const SEND_OTP_CANDIDATE_EMAIL_PENDING =
  "SEND_OTP_CANDIDATE_EMAIL_PENDING";
export const SEND_OTP_CANDIDATE_EMAIL_REJECTED =
  "SEND_OTP_CANDIDATE_EMAIL_REJECTED";

const sendCandidateEmailOtpRequest = () => ({
  type: SEND_OTP_CANDIDATE_EMAIL_PENDING,
});

const sendCandidateEmailOtpFailure = (error) => ({
  type: SEND_OTP_CANDIDATE_EMAIL_REJECTED,
  payload: { error },
});

const sendCandidateEmailOtpSuccess = (response, data) => ({
  type: SEND_OTP_CANDIDATE_EMAIL_FULFILLED,
  payload: { candidateEmailOtp: true, ...response, email: data?.email_id },
});

export const checkCandidateEmail = (data) => {
  return (dispatch) => {
    dispatch(sendCandidateEmailOtpRequest());
    return http_noauth
      .post(apiUrl.EMAIL_OTP, data)
      .then((response) => {
        if (response?.data?.status === 1) {
          dispatch(sendCandidateEmailOtpSuccess(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(sendCandidateEmailOtpFailure(error));
        return error;
      });
  };
};
export const CLOSE_CANDIDATE_EMAIL_DIALOG = "CLOSE_CANDIDATE_EMAIL_DIALOG";
export const closeCandidateEmailDialog = () => {
  return {
    type: CLOSE_CANDIDATE_EMAIL_DIALOG,
  };
};

// candidate email otp verification

export const VERIFY_OTP_CANDIDATE_EMAIL_FULFILLED =
  "VERIFY_OTP_CANDIDATE_EMAIL_FULFILLED";
export const VERIFY_OTP_CANDIDATE_EMAIL_PENDING =
  "VERIFY_OTP_CANDIDATE_EMAIL_PENDING";
export const VERIFY_OTP_CANDIDATE_EMAIL_REJECTED =
  "VERIFY_OTP_CANDIDATE_EMAIL_REJECTED";

const verifyCandidateEmailOtpRequest = () => ({
  type: VERIFY_OTP_CANDIDATE_EMAIL_PENDING,
});

const verifyCandidateEmailOtpFailure = (error) => ({
  type: VERIFY_OTP_CANDIDATE_EMAIL_REJECTED,
  payload: { error },
});

const verifyCandidateEmailOtpSuccess = (response) => ({
  type: VERIFY_OTP_CANDIDATE_EMAIL_FULFILLED,
  payload: response,
});

export const verifyCandidateEmailOtp = (data) => {
  return (dispatch) => {
    dispatch(verifyCandidateEmailOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_EMAIL_OTP, data)
      .then((response) => {
        console.log("header", response);
        if (response?.data?.status === 1) {
          dispatch(verifyCandidateEmailOtpSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(verifyCandidateEmailOtpFailure(error));
        return error;
      });
  };
};

export const LOGIN_EMAIL_OTP_CANDIDATE_FULFILLED =
  "LOGIN_EMAIL_OTP_CANDIDATE_FULFILLED";
export const LOGIN_EMAIL_OTP_CANDIDATE_PENDING =
  "LOGIN_EMAIL_OTP_CANDIDATE_PENDING";
export const LOGIN_EMAIL_OTP_CANDIDATE_REJECTED =
  "LOGIN_EMAIL_OTP_CANDIDATE_REJECTED";

const candidateLoginOtpRequest = () => ({
  type: LOGIN_EMAIL_OTP_CANDIDATE_PENDING,
});

const candidateLoginOtpFailure = (error) => ({
  type: LOGIN_EMAIL_OTP_CANDIDATE_REJECTED,
  payload: { error },
});

const candidateLoginOtpFulfilled = (response, data) => ({
  type: LOGIN_EMAIL_OTP_CANDIDATE_FULFILLED,
  payload: { response: response, candidateEmail: data?.email_id },
});

export const sendCandidateEmailOtp = (data) => {
  return (dispatch) => {
    dispatch(candidateLoginOtpRequest());
    return http_noauth
      .post(apiUrl.EMAIL_OTP, data)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === 1) {
          dispatch(candidateLoginOtpFulfilled(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateLoginOtpFailure(error));
        return error;
      });
  };
};

export const LOGIN_MOBILE_OTP_CANDIDATE_FULFILLED =
  "LOGIN_MOBILE_OTP_CANDIDATE_FULFILLED";
export const LOGIN_MOBILE_OTP_CANDIDATE_PENDING =
  "LOGIN_MOBILE_OTP_CANDIDATE_PENDING";
export const LOGIN_MOBILE_OTP_CANDIDATE_REJECTED =
  "LOGIN_MOBILE_OTP_CANDIDATE_REJECTED";

const candidateMobileLoginOtpRequest = () => ({
  type: LOGIN_MOBILE_OTP_CANDIDATE_PENDING,
});

const candidateMobileLoginOtpFailure = (error) => ({
  type: LOGIN_MOBILE_OTP_CANDIDATE_REJECTED,
  payload: { error },
});

const candidateMobileLoginOtpFulfilled = (response, data) => ({
  type: LOGIN_MOBILE_OTP_CANDIDATE_FULFILLED,
  payload: { response: response, candidateMoblie: data?.mobile_number },
});

export const sendCandidateMobileOtp = (data) => {
  return (dispatch) => {
    dispatch(candidateMobileLoginOtpRequest());
    return http_noauth
      .post(apiUrl.MOBILE_OTP, data)
      .then((response) => {
        if (response?.data?.status === 1) {
          dispatch(candidateMobileLoginOtpFulfilled(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateMobileLoginOtpFailure(error));
        return error;
      });
  };
};

export const LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_FULFILLED =
  "LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_FULFILLED";
export const LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_PENDING =
  "LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_PENDING";
export const LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_REJECTED =
  "LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_REJECTED";

const candidateLoginVerifyRequest = () => ({
  type: LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_PENDING,
});

const candidateLoginVerifyFailure = (error) => ({
  type: LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_REJECTED,
  payload: { error },
});

const candidateLoginVerifyFulfilled = (response) => ({
  type: LOGIN_EMAIL_OTP_VERIFY_CANDIDATE_FULFILLED,
  payload: response,
});

export const sendCandidateEmailVerify = (data) => {
  return (dispatch) => {
    dispatch(candidateLoginVerifyRequest());
    return http_noauth
      .post(apiUrl.VERIFY_EMAIL_OTP, data)
      .then((response) => {
        console.log(
          "sendCandidateEmailVerify",
          response?.config,
          response?.headers
        );
        if (
          response?.data?.status === 1 &&
          response?.data?.data?.signup_status === true
        ) {
          setTokenResponse(response?.headers["x-auth"]);
          dispatch(candidateLoginVerifyFulfilled(response));
        } else if (
          response?.data?.status === 1 &&
          response?.data?.data?.signup_status === false
        ) {
          dispatch(candidateLoginVerifyFailure(response));
          showMessage({
            dispatch,
            message: "User doesn't Signup yet, please signup first ",
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateLoginVerifyFailure(error));
        return error;
      });
  };
};

export const LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_FULFILLED =
  "LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_FULFILLED";
export const LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_PENDING =
  "LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_PENDING";
export const LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_REJECTED =
  "LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_REJECTED";

const candidateMobileLoginVerifyRequest = () => ({
  type: LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_PENDING,
});

const candidateMobileLoginVerifyFailure = (error) => ({
  type: LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_REJECTED,
  payload: { error },
});

const candidateMobileLoginVerifyFulfilled = (response) => ({
  type: LOGIN_MOBILE_OTP_VERIFY_CANDIDATE_FULFILLED,
  payload: response,
});

export const sendCandidateMobileVerify = (data) => {
  return (dispatch) => {
    dispatch(candidateMobileLoginVerifyRequest());
    return http_noauth
      .post(apiUrl.VERIFY_MOBILE_OTP, data)
      .then((response) => {
        console.log();
        if (
          response?.data?.status === 1 &&
          response?.data?.data?.signup_status === true
        ) {
          setTokenResponse(response?.headers["x-auth"]);
          dispatch(candidateMobileLoginVerifyFulfilled(response));
        } else if (
          response?.data?.status === 1 &&
          response?.data?.data?.signup_status === false
        ) {
          dispatch(candidateMobileLoginVerifyFailure(response));
          showMessage({
            dispatch,
            message: "User doesn't Signup yet, please signup first ",
            variant: "error",
          });
        } else {
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateMobileLoginVerifyFailure(error));
        return error;
      });
  };
};

export const RESET_CANDIDATE_LOGIN_DETAILS = "RESET_CANDIDATE_LOGIN_DETAILS";
export const resetCandidateLoginDetails = () => {
  return {
    type: RESET_CANDIDATE_LOGIN_DETAILS,
  };
};

export const SEND_OTP_COMPANY_EMAIL_FULFILLED =
  "SEND_OTP_COMPANY_EMAIL_FULFILLED";
export const SEND_OTP_COMPANY_EMAIL_PENDING = "SEND_OTP_COMPANY_EMAIL_PENDING";
export const SEND_OTP_COMPANY_EMAIL_REJECTED =
  "SEND_OTP_COMPANY_EMAIL_REJECTED";

const sendcompanyEmailOtpRequest = () => ({
  type: SEND_OTP_COMPANY_EMAIL_PENDING,
});

const sendcompanyEmailOtpFailure = (error) => ({
  type: SEND_OTP_COMPANY_EMAIL_REJECTED,
  payload: { error },
});

const sendcompanyEmailOtpSuccess = (response, data) => ({
  type: SEND_OTP_COMPANY_EMAIL_FULFILLED,
  payload: { companyEmailOtp: true, ...response, email: data?.email_id },
});

export const checkCompanyEmail = (data) => {
  return (dispatch) => {
    dispatch(sendcompanyEmailOtpRequest());
    return http_noauth
      .post(apiUrl.EMAIL_OTP, data)
      .then((response) => {
        dispatch(sendcompanyEmailOtpSuccess(response, data));
        return response;
      })
      .catch((error) => {
        dispatch(sendcompanyEmailOtpFailure(error));
        return error;
      });
  };
};
export const CLOSE_COMPANY_EMAIL_DIALOG = "CLOSE_COMPANY_EMAIL_DIALOG";
export const closecompanyEmailDialog = () => {
  return {
    type: CLOSE_COMPANY_EMAIL_DIALOG,
  };
};

export const VERIFY_OTP_COMPANY_EMAIL_FULFILLED =
  "VERIFY_OTP_COMPANY_EMAIL_FULFILLED";
export const VERIFY_OTP_COMPANY_EMAIL_PENDING =
  "VERIFY_OTP_COMPANY_EMAIL_PENDING";
export const VERIFY_OTP_COMPANY_EMAIL_REJECTED =
  "VERIFY_OTP_COMPANY_EMAIL_REJECTED";

const verifycompanyEmailOtpRequest = () => ({
  type: VERIFY_OTP_COMPANY_EMAIL_PENDING,
});

const verifycompanyEmailOtpFailure = (error) => ({
  type: VERIFY_OTP_COMPANY_EMAIL_REJECTED,
  payload: { error },
});

const verifycompanyEmailOtpSuccess = (response) => ({
  type: VERIFY_OTP_COMPANY_EMAIL_FULFILLED,
  payload: response,
});

export const verifyCompanyEmailOtp = (data) => {
  return (dispatch) => {
    dispatch(verifycompanyEmailOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_EMAIL_OTP, data)
      .then((response) => {
        console.log("header", response);
        if (response?.data?.status === 1) {
          dispatch(verifycompanyEmailOtpSuccess(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.msg,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(verifycompanyEmailOtpFailure(error));
        return error;
      });
  };
};

export const SEND_OTP_COMPANY_MOBILE_FULFILLED =
  "SEND_OTP_COMPANY_MOBILE_FULFILLED";
export const SEND_OTP_COMPANY_MOBILE_PENDING =
  "SEND_OTP_COMPANY_MOBILE_PENDING";
export const SEND_OTP_COMPANY_MOBILE_REJECTED =
  "SEND_OTP_COMPANY_MOBILE_REJECTED";

const sendcompanyMobileOtpRequest = () => ({
  type: SEND_OTP_COMPANY_MOBILE_PENDING,
});

const sendcompanyMobileOtpFailure = (error) => ({
  type: SEND_OTP_COMPANY_MOBILE_REJECTED,
  payload: { error },
});

const sendcompanyMobileOtpSuccess = (response, data) => ({
  type: SEND_OTP_COMPANY_MOBILE_FULFILLED,
  payload: {
    companyMobileOtp: true,
    ...response,
    mobile: data?.mobile_number,
  },
});

export const checkcompanyMobile = (data) => {
  return (dispatch) => {
    dispatch(sendcompanyMobileOtpRequest());
    return http_noauth
      .post(apiUrl.MOBILE_OTP, data)
      .then((response) => {
        console.log("checkcompanyMobile", response?.config?.headers);
        if (response?.data?.status === 1) {
          dispatch(sendcompanyMobileOtpSuccess(response, data));
        }
        showMessage({
          dispatch,
          message: response?.data?.msg,
          variant: "error",
        });
        return response;
      })
      .catch((error) => {
        dispatch(sendcompanyMobileOtpFailure(error));
        return error;
      });
  };
};
export const CLOSE_COMPANY_MOBILE_DIALOG = "CLOSE_COMPANY_MOBILE_DIALOG";
export const closecompanyMobileDialog = () => {
  return {
    type: CLOSE_COMPANY_MOBILE_DIALOG,
  };
};

export const VERIFY_OTP_COMPANY_MOBILE_FULFILLED =
  "VERIFY_OTP_COMPANY_MOBILE_FULFILLED";
export const VERIFY_OTP_COMPANY_MOBILE_PENDING =
  "VERIFY_OTP_COMPANY_MOBILE_PENDING";
export const VERIFY_OTP_COMPANY_MOBILE_REJECTED =
  "VERIFY_OTP_COMPANY_MOBILE_REJECTED";

const verifycompanyMobileOtpRequest = () => ({
  type: VERIFY_OTP_COMPANY_MOBILE_PENDING,
});

const verifycompanyMobileOtpFailure = (error) => ({
  type: VERIFY_OTP_COMPANY_MOBILE_REJECTED,
  payload: { error },
});

const verifycompanyMobileOtpSuccess = (response) => ({
  type: VERIFY_OTP_COMPANY_MOBILE_FULFILLED,
  payload: response,
});

export const verifycompanyMobileOtp = (data) => {
  return (dispatch) => {
    dispatch(verifycompanyMobileOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_MOBILE_OTP, data)
      .then((response) => {
        console.log(
          "verifycompanyMobileOtp",
          response,
          response?.config?.headers
        );
        if (response?.data?.status === 1) {
          dispatch(verifycompanyMobileOtpSuccess(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.msg,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(verifycompanyMobileOtpFailure(error));
        return error;
      });
  };
};

export const SIGNUP_COMPANY_FULFILLED = "SIGNUP_COMPANY_FULFILLED";
export const SIGNUP_COMPANY_PENDING = "SIGNUP_COMPANY_PENDING";
export const SIGNUP_COMPANY_REJECTED = "SIGNUP_COMPANY_REJECTED";

const companySignupRequest = () => ({
  type: SIGNUP_COMPANY_PENDING,
});

const companySignupFailure = (error) => ({
  type: SIGNUP_COMPANY_REJECTED,
  payload: { error },
});

const companySignupFulfilled = (response) => ({
  type: SIGNUP_COMPANY_FULFILLED,
  payload: response,
});

export const signupcompany = (data, config) => {
  return (dispatch) => {
    dispatch(companySignupRequest());
    return http_noauth
      .post(apiUrl.COMPANY_SIGNUP, data, config)
      .then((response) => {
        console.log(
          "signupcompany",
          response?.data?.msg,
          response?.config?.headers
        );
        if (response?.data?.status === 1) {
          setTokenResponse(response?.config?.headers["x-auth"]);
          dispatch(companySignupFulfilled(response));
          showMessage({
            dispatch,
            message: response?.data?.msg,
            variant: "success",
          });
        } else {
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        showMessage({
          dispatch,
          message: error?.data?.msg,
          variant: "error",
        });
        dispatch(companySignupFailure(error));
        return error;
      });
  };
};

// export const RESET_COMPANY_DETAILS = "RESET_COMPANY_DETAILS";
// export const resetcompanyDetails = () => {
//   return {
//     type: RESET_COMPANY_DETAILS,
//   };
// };

export const LOGIN_EMAIL_OTP_COMPANY_FULFILLED =
  "LOGIN_EMAIL_OTP_COMPANY_FULFILLED";
export const LOGIN_EMAIL_OTP_COMPANY_PENDING =
  "LOGIN_EMAIL_OTP_COMPANY_PENDING";
export const LOGIN_EMAIL_OTP_COMPANY_REJECTED =
  "LOGIN_EMAIL_OTP_COMPANY_REJECTED";

const companyLoginOtpRequest = () => ({
  type: LOGIN_EMAIL_OTP_COMPANY_PENDING,
});

const companyLoginOtpFailure = (error) => ({
  type: LOGIN_EMAIL_OTP_COMPANY_REJECTED,
  payload: { error },
});

const companyLoginOtpFulfilled = (response, data) => ({
  type: LOGIN_EMAIL_OTP_COMPANY_FULFILLED,
  payload: { response: response, companyEmail: data?.email_id },
});

export const sendCompanyEmailOtp = (data) => {
  return (dispatch) => {
    dispatch(companyLoginOtpRequest());
    return http_noauth
      .post(apiUrl.EMAIL_OTP, data)
      .then((response) => {
        if (response?.data?.status === 1) {
          dispatch(companyLoginOtpFulfilled(response, data));
        } else {
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(companyLoginOtpFailure(error));
        return error;
      });
  };
};

export const LOGIN_MOBILE_OTP_COMPANY_FULFILLED =
  "LOGIN_MOBILE_OTP_COMPANY_FULFILLED";
export const LOGIN_MOBILE_OTP_COMPANY_PENDING =
  "LOGIN_MOBILE_OTP_COMPANY_PENDING";
export const LOGIN_MOBILE_OTP_COMPANY_REJECTED =
  "LOGIN_MOBILE_OTP_COMPANY_REJECTED";

const companyMobileLoginOtpRequest = () => ({
  type: LOGIN_MOBILE_OTP_COMPANY_PENDING,
});

const companyMobileLoginOtpFailure = (error) => ({
  type: LOGIN_MOBILE_OTP_COMPANY_REJECTED,
  payload: { error },
});

const companyMobileLoginOtpFulfilled = (response, data) => ({
  type: LOGIN_MOBILE_OTP_COMPANY_FULFILLED,
  payload: { response: response, companyMoblie: data?.mobile_number },
});

export const sendCompanyMobileOtp = (data) => {
  return (dispatch) => {
    dispatch(companyMobileLoginOtpRequest());
    return http_noauth
      .post(apiUrl.MOBILE_OTP, data)
      .then((response) => {
        // setTokenResponse(response?.headers["x-auth"]);
        if (response?.data?.status === 1) {
          dispatch(companyMobileLoginOtpFulfilled(response, data));
        } else {
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(companyMobileLoginOtpFailure(error));
        return error;
      });
  };
};

export const LOGIN_EMAIL_OTP_VERIFY_COMPANY_FULFILLED =
  "LOGIN_EMAIL_OTP_VERIFY_COMPANY_FULFILLED";
export const LOGIN_EMAIL_OTP_VERIFY_COMPANY_PENDING =
  "LOGIN_EMAIL_OTP_VERIFY_COMPANY_PENDING";
export const LOGIN_EMAIL_OTP_VERIFY_COMPANY_REJECTED =
  "LOGIN_EMAIL_OTP_VERIFY_COMPANY_REJECTED";

export const LOGIN_EMAIL_OTP_VERIFY_COMPANY_SIGNUP_FAILED =
  "LOGIN_EMAIL_OTP_VERIFY_COMPANY_SIGNUP_FAILED";

const companyLoginVerifyRequest = () => ({
  type: LOGIN_EMAIL_OTP_VERIFY_COMPANY_PENDING,
});

const companyLoginVerifyFailure = (error) => ({
  type: LOGIN_EMAIL_OTP_VERIFY_COMPANY_REJECTED,
  payload: { error },
});

const companyLoginVerifyFulfilled = (response) => ({
  type: LOGIN_EMAIL_OTP_VERIFY_COMPANY_FULFILLED,
  payload: response,
});

const companyLoginVerifySignupFailed = (response) => ({
  type: LOGIN_EMAIL_OTP_VERIFY_COMPANY_SIGNUP_FAILED,
  payload: response,
});

export const sendCompanyEmailVerify = (data) => {
  return (dispatch) => {
    dispatch(companyLoginVerifyRequest());
    return http_noauth
      .post(apiUrl.VERIFY_EMAIL_OTP, data)
      .then((response) => {
        console.log(
          "sendcompanyEmailVerify",
          response?.config,
          response?.headers
        );

        if (
          response?.data?.status === 1 &&
          response?.data?.data?.signup_status === true
        ) {
          setTokenResponse(response?.headers["x-auth"]);
          dispatch(companyLoginVerifyFulfilled(response));
        } else if (
          response?.data?.status === 1 &&
          response?.data?.data?.signup_status === false
        ) {
          dispatch(companyLoginVerifySignupFailed(response));
          showMessage({
            dispatch,
            message: "User doesn't Signup yet, please signup first ",
            variant: "error",
          });
        } else {
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(companyLoginVerifyFailure(error));
        return error;
      });
  };
};

export const LOGIN_MOBILE_OTP_VERIFY_COMPANY_FULFILLED =
  "LOGIN_MOBILE_OTP_VERIFY_COMPANY_FULFILLED";
export const LOGIN_MOBILE_OTP_VERIFY_COMPANY_PENDING =
  "LOGIN_MOBILE_OTP_VERIFY_COMPANY_PENDING";
export const LOGIN_MOBILE_OTP_VERIFY_COMPANY_REJECTED =
  "LOGIN_MOBILE_OTP_VERIFY_COMPANY_REJECTED";

const companyMobileLoginVerifyRequest = () => ({
  type: LOGIN_MOBILE_OTP_VERIFY_COMPANY_PENDING,
});

const companyMobileLoginVerifyFailure = (error) => ({
  type: LOGIN_MOBILE_OTP_VERIFY_COMPANY_REJECTED,
  payload: { error },
});

const companyMobileLoginVerifyFulfilled = (response) => ({
  type: LOGIN_MOBILE_OTP_VERIFY_COMPANY_FULFILLED,
  payload: response,
});

export const sendCompanyMobileVerify = (data) => {
  return (dispatch) => {
    dispatch(companyMobileLoginVerifyRequest());
    return http_noauth
      .post(apiUrl.VERIFY_MOBILE_OTP, data)
      .then((response) => {
        console.log();

        if (
          response?.data?.status === 1 &&
          response?.data?.data?.signup_status === true
        ) {
          setTokenResponse(response?.headers["x-auth"]);
          dispatch(companyMobileLoginVerifyFulfilled(response));
        } else if (
          response?.data?.status === 1 &&
          response?.data?.data?.signup_status === false
        ) {
          dispatch(companyMobileLoginVerifyFailure(response));

          showMessage({
            dispatch,
            message: "User doesn't Signup yet, please signup first ",
            variant: "error",
          });
        } else {
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: "error",
          });
        }

        return response;
      })
      .catch((error) => {
        dispatch(companyMobileLoginVerifyFailure(error));
        return error;
      });
  };
};

// export const RESET_COMPANY_LOGIN_DETAILS = "RESET_COMPANY_LOGIN_DETAILS";
// export const resetCompanyLoginDetails = () => {
//   return {
//     type: RESET_COMPANY_LOGIN_DETAILS,
//   };
// };
export const STORE_FIRST_REGISTRATION_DATA = "STORE_FIRST_REGISTRATION_DATA";
export const dataRegistrationFirst = (data) => {
  return {
    type: STORE_FIRST_REGISTRATION_DATA,
    payload: data,
  };
};
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

const getProfileRequest = () => ({
  type: GET_PROFILE_REQUEST,
});

const getProfileFailure = (error) => ({
  type: GET_PROFILE_FAILURE,
  payload: { error: error },
});

const getProfileSuccess = (data) => ({
  type: GET_PROFILE_SUCCESS,
  payload: data,
});
export const getProfile = (payload) => {
  return (dispatch) => {
    dispatch(getProfileRequest());
    return http
      .get(apiUrl.COMPANY_PROFILE_DETAILS)
      .then((response) => {
        console.log("response", response);

        if (response?.data?.status === 1) {
          dispatch(getProfileSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getProfileFailure(error));
        console.log("error", error);
      });
  };
};

export const CANDIDATE_EDUCATION_LIST_FULFILLED =
  "CANDIDATE_EDUCATION_LIST_FULFILLED";
export const CANDIDATE_EDUCATION_LIST_PENDING =
  "CANDIDATE_EDUCATION_LIST_PENDING";
export const CANDIDATE_EDUCATION_LIST_REJECTED =
  "CANDIDATE_EDUCATION_LIST_REJECTED";
const candidateEducationListRequest = () => ({
  type: CANDIDATE_EDUCATION_LIST_PENDING,
});
const candidateEducationListFailure = (error) => ({
  type: CANDIDATE_EDUCATION_LIST_REJECTED,
  payload: { error },
});
const candidateEducationListFulfilled = (response) => ({
  type: CANDIDATE_EDUCATION_LIST_FULFILLED,
  payload: response,
});
export const candidateEducationList = (data) => {
  return (dispatch) => {
    dispatch(candidateEducationListRequest());
    return http_noauth
      .get(apiUrl.CANDIDATE_EDUCATION_LIST, data)
      .then((response) => {
        if (response?.data?.status === 1) {
          dispatch(candidateEducationListFulfilled(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(candidateEducationListFailure(error));
        return error;
      });
  };
};
export const COMPANY_UPDATE_PROFILE_FULFILLED =
  "COMPANY_UPDATE_PROFILE_FULFILLED";
export const COMPANY_UPDATE_PROFILE_PENDING = "COMPANY_UPDATE_PROFILE_PENDING";
export const COMPANY_UPDATE_PROFILE_REJECTED =
  "COMPANY_UPDATE_PROFILE_REJECTED";

const companyUpdateProfileRequest = () => ({
  type: COMPANY_UPDATE_PROFILE_PENDING,
});

const companyUpdateProfileFailure = (error) => ({
  type: COMPANY_UPDATE_PROFILE_REJECTED,
  payload: { error },
});

const companyUpdateProfileFulfilled = (response) => ({
  type: COMPANY_UPDATE_PROFILE_FULFILLED,
  payload: response,
});

export const companyUpdateProfile = (data) => {
  return (dispatch) => {
    dispatch(companyUpdateProfileRequest());
    return http
      .post(apiUrl.COMPANY_UPDATE_PROFILE, data)
      .then((response) => {
        if (response?.data?.status === 1) {
          showMessage({
            dispatch,
            message: "Company update Successfully",
            variant: "success",
          });
          dispatch(companyUpdateProfileFulfilled(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.data,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        console.log("error", error);
        showMessage({
          dispatch,
          message: error?.response?.data?.msg,
          variant: "error",
        });
        dispatch(companyUpdateProfileFailure(error));
        return error;
      });
  };
};
//===================RECRUITER==========================
export const SEND_OTP_RECRUITER_EMAIL_FULFILLED =
  "SEND_OTP_RECRUITER_EMAIL_FULFILLED";
export const SEND_OTP_RECRUITER_EMAIL_PENDING =
  "SEND_OTP_RECRUITER_EMAIL_PENDING";
export const SEND_OTP_RECRUITER_EMAIL_REJECTED =
  "SEND_OTP_RECRUITER_EMAIL_REJECTED";

const sendRecruiterEmailOtpRequest = () => ({
  type: SEND_OTP_RECRUITER_EMAIL_PENDING,
});

const sendRecruiterEmailOtpFailure = (error) => ({
  type: SEND_OTP_RECRUITER_EMAIL_REJECTED,
  payload: { error },
});

const sendRecruiterEmailOtpSuccess = (response, data) => ({
  type: SEND_OTP_RECRUITER_EMAIL_FULFILLED,
  payload: { recruiterEmailOtp: true, ...response, email: data?.email_id },
});

export const checkRecruiterEmail = (data) => {
  return (dispatch) => {
    dispatch(sendRecruiterEmailOtpRequest());
    return http_noauth
      .post(apiUrl.EMAIL_OTP, data)
      .then((response) => {
        if (response?.data?.status === 1) {
          dispatch(sendRecruiterEmailOtpSuccess(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(sendRecruiterEmailOtpFailure(error));
        return error;
      });
  };
};
export const CLOSE_RECRUITER_EMAIL_DIALOG = "CLOSE_RECRUITER_EMAIL_DIALOG";
export const closeRecruiterEmailDialog = () => {
  return {
    type: CLOSE_RECRUITER_EMAIL_DIALOG,
  };
};

export const RESET_RECRUITER_DETAILS = "RESET_RECRUITER_DETAILS";
export const resetRecruiterDetails = () => {
  return {
    type: RESET_RECRUITER_DETAILS,
  };
};

export const VERIFY_OTP_RECRUITER_EMAIL_FULFILLED =
  "VERIFY_OTP_RECRUITER_EMAIL_FULFILLED";
export const VERIFY_OTP_RECRUITER_EMAIL_PENDING =
  "VERIFY_OTP_RECRUITER_EMAIL_PENDING";
export const VERIFY_OTP_RECRUITER_EMAIL_REJECTED =
  "VERIFY_OTP_RECRUITER_EMAIL_REJECTED";

const verifyRecruiterEmailOtpRequest = () => ({
  type: VERIFY_OTP_RECRUITER_EMAIL_PENDING,
});

const verifyRecruiterEmailOtpFailure = (error) => ({
  type: VERIFY_OTP_RECRUITER_EMAIL_REJECTED,
  payload: { error },
});

const verifyRecruiterEmailOtpSuccess = (response) => ({
  type: VERIFY_OTP_RECRUITER_EMAIL_FULFILLED,
  payload: response,
});
export const verifyRecruiterEmailOtp = (data) => {
  return (dispatch) => {
    dispatch(verifyRecruiterEmailOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_EMAIL_OTP, data)
      .then((response) => {
        console.log("header", response);
        if (response?.data?.status === 1) {
          dispatch(verifyRecruiterEmailOtpSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(verifyRecruiterEmailOtpFailure(error));
        return error;
      });
  };
};

export const SEND_OTP_RECRUITER_MOBILE_FULFILLED =
  "SEND_OTP_RECRUITER_MOBILE_FULFILLED";
export const SEND_OTP_RECRUITER_MOBILE_PENDING =
  "SEND_OTP_RECRUITER_MOBILE_PENDING";
export const SEND_OTP_RECRUITER_MOBILE_REJECTED =
  "SEND_OTP_RECRUITER_MOBILE_REJECTED";

const sendRecruiterMobileOtpRequest = () => ({
  type: SEND_OTP_RECRUITER_MOBILE_PENDING,
});

const sendRecruiterMobileOtpFailure = (error) => ({
  type: SEND_OTP_RECRUITER_MOBILE_REJECTED,
  payload: { error },
});

const sendRecruiterMobileOtpSuccess = (response, data) => ({
  type: SEND_OTP_RECRUITER_MOBILE_FULFILLED,
  payload: {
    recruiterMobileOtp: true,
    ...response,
    mobile: data?.mobile_number,
  },
});

export const checkRecruiterMobile = (data) => {
  return (dispatch) => {
    dispatch(sendRecruiterMobileOtpRequest());
    return http_noauth
      .post(apiUrl.MOBILE_OTP, data)
      .then((response) => {
        console.log("checkRecruiterMobile", response?.config?.headers);
        if (response?.data?.status === 1) {
          dispatch(sendRecruiterMobileOtpSuccess(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(sendRecruiterMobileOtpFailure(error));
        return error;
      });
  };
};
export const CLOSE_RECRUITER_MOBILE_DIALOG = "CLOSE_RECRUITER_MOBILE_DIALOG";
export const closeRecruiterMobileDialog = () => {
  return {
    type: CLOSE_RECRUITER_MOBILE_DIALOG,
  };
};

// recruiter email otp verification

export const VERIFY_OTP_RECRUITER_MOBILE_FULFILLED =
  "VERIFY_OTP_RECRUITER_MOBILE_FULFILLED";
export const VERIFY_OTP_RECRUITER_MOBILE_PENDING =
  "VERIFY_OTP_RECRUITER_MOBILE_PENDING";
export const VERIFY_OTP_RECRUITER_MOBILE_REJECTED =
  "VERIFY_OTP_RECRUITER_MOBILE_REJECTED";

const verifyRecruiterMobileOtpRequest = () => ({
  type: VERIFY_OTP_RECRUITER_MOBILE_PENDING,
});

const verifyRecruiterMobileOtpFailure = (error) => ({
  type: VERIFY_OTP_RECRUITER_MOBILE_REJECTED,
  payload: { error },
});

const verifyRecruiterMobileOtpSuccess = (response) => ({
  type: VERIFY_OTP_RECRUITER_MOBILE_FULFILLED,
  payload: response,
});

export const verifyRecruiterMobileOtp = (data) => {
  return (dispatch) => {
    dispatch(verifyRecruiterMobileOtpRequest());
    return http_noauth
      .post(apiUrl.VERIFY_MOBILE_OTP, data)
      .then((response) => {
        console.log(
          "verifyRecruiterMobileOtp",
          response,
          response?.config?.headers
        );
        if (response?.data?.status === 1) {
          dispatch(verifyRecruiterMobileOtpSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(verifyRecruiterMobileOtpFailure(error));
        return error;
      });
  };
};

// signupRecruiter

export const SIGNUP_RECRUITER_FULFILLED = "SIGNUP_RECRUITER_FULFILLED";
export const SIGNUP_RECRUITER_PENDING = "SIGNUP_RECRUITER_PENDING";
export const SIGNUP_RECRUITER_REJECTED = "SIGNUP_RECRUITER_REJECTED";

const recruiterSignupRequest = () => ({
  type: SIGNUP_RECRUITER_PENDING,
});

const recruiterSignupFailure = (error) => ({
  type: SIGNUP_RECRUITER_REJECTED,
  payload: { error },
});

const recruiterSignupFulfilled = (response) => ({
  type: SIGNUP_RECRUITER_FULFILLED,
  payload: response,
});

export const signupRecruiter = (data, config) => {
  return (dispatch) => {
    dispatch(recruiterSignupRequest());
    return http_noauth
      .post(apiUrl.RECRUITER_SIGNUP, data, config)
      .then((response) => {
        console.log("signupRecruiter", response, response?.config?.headers);
        if (response?.data?.status === 1) {
          setTokenResponse(response?.config?.headers["x-auth"]);
          dispatch(recruiterSignupFulfilled(response));
          showMessage({
            dispatch,
            message: "Signup Successfully",
            variant: "success",
          });
        } else {
          dispatch(recruiterSignupFailure(response?.data));
          showMessage({
            dispatch,
            message: response?.data?.data,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(recruiterSignupFailure(error));
        return error;
      });
  };
};

//Recruiter logedIn

export const LOGIN_EMAIL_OTP_RECRUITER_FULFILLED =
  "LOGIN_EMAIL_OTP_RECRUITER_FULFILLED";
export const LOGIN_EMAIL_OTP_RECRUITER_PENDING =
  "LOGIN_EMAIL_OTP_RECRUITER_PENDING";
export const LOGIN_EMAIL_OTP_RECRUITER_REJECTED =
  "LOGIN_EMAIL_OTP_RECRUITER_REJECTED";

const recruiterLoginOtpRequest = () => ({
  type: LOGIN_EMAIL_OTP_RECRUITER_PENDING,
});

const recruiterLoginOtpFailure = (error) => ({
  type: LOGIN_EMAIL_OTP_RECRUITER_REJECTED,
  payload: { error },
});

const recruiterLoginOtpFulfilled = (response, data) => ({
  type: LOGIN_EMAIL_OTP_RECRUITER_FULFILLED,
  payload: { response: response, recruiterEmail: data?.email_id },
});

export const sendRecruiterEmailOtp = (data) => {
  return (dispatch) => {
    dispatch(recruiterLoginOtpRequest());
    return http_noauth
      .post(apiUrl.EMAIL_OTP, data)
      .then((response) => {
        console.log("response", response);
        if (response?.data?.status === 1) {
          dispatch(recruiterLoginOtpFulfilled(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(recruiterLoginOtpFailure(error));
        return error;
      });
  };
};

export const LOGIN_MOBILE_OTP_RECRUITER_FULFILLED =
  "LOGIN_MOBILE_OTP_RECRUITER_FULFILLED";
export const LOGIN_MOBILE_OTP_RECRUITER_PENDING =
  "LOGIN_MOBILE_OTP_RECRUITER_PENDING";
export const LOGIN_MOBILE_OTP_RECRUITER_REJECTED =
  "LOGIN_MOBILE_OTP_RECRUITER_REJECTED";

const recruiterMobileLoginOtpRequest = () => ({
  type: LOGIN_MOBILE_OTP_RECRUITER_PENDING,
});

const recruiterMobileLoginOtpFailure = (error) => ({
  type: LOGIN_MOBILE_OTP_RECRUITER_REJECTED,
  payload: { error },
});

const recruiterMobileLoginOtpFulfilled = (response, data) => ({
  type: LOGIN_MOBILE_OTP_RECRUITER_FULFILLED,
  payload: { response: response, recruiterMoblie: data?.mobile_number },
});

export const sendRecruiterMobileOtp = (data) => {
  // debugger;
  return (dispatch) => {
    dispatch(recruiterMobileLoginOtpRequest());
    return http_noauth
      .post(apiUrl.MOBILE_OTP, data)
      .then((response) => {
        // setTokenResponse(response?.headers["x-auth"]);

        if (response?.data?.status === 1) {
          dispatch(recruiterMobileLoginOtpFulfilled(response, data));
        }
        return response;
      })
      .catch((error) => {
        dispatch(recruiterMobileLoginOtpFailure(error));
        return error;
      });
  };
};

export const LOGIN_EMAIL_OTP_VERIFY_RECRUITER_FULFILLED =
  "LOGIN_EMAIL_OTP_VERIFY_RECRUITER_FULFILLED";
export const LOGIN_EMAIL_OTP_VERIFY_RECRUITER_PENDING =
  "LOGIN_EMAIL_OTP_VERIFY_RECRUITER_PENDING";
export const LOGIN_EMAIL_OTP_VERIFY_RECRUITER_REJECTED =
  "LOGIN_EMAIL_OTP_VERIFY_RECRUITER_REJECTED";

const recruiterLoginVerifyRequest = () => ({
  type: LOGIN_EMAIL_OTP_VERIFY_RECRUITER_PENDING,
});

const recruiterLoginVerifyFailure = (error) => ({
  type: LOGIN_EMAIL_OTP_VERIFY_RECRUITER_REJECTED,
  payload: { error },
});

const recruiterLoginVerifyFulfilled = (response) => ({
  type: LOGIN_EMAIL_OTP_VERIFY_RECRUITER_FULFILLED,
  payload: response,
});

export const sendRecruiterEmailVerify = (data) => {
  return (dispatch) => {
    dispatch(recruiterLoginVerifyRequest());
    return http_noauth
      .post(apiUrl.VERIFY_EMAIL_OTP, data)
      .then((response) => {
        console.log(
          "sendRecruiterEmailVerify",
          response?.config,
          response?.headers
        );
        if (
          response?.data?.status === 1 &&
          response?.data?.data?.signup_status === true
        ) {
          setTokenResponse(response?.headers["x-auth"]);
          dispatch(recruiterLoginVerifyFulfilled(response));
        } else if (
          response?.data?.status === 1 &&
          response?.data?.data?.signup_status === false
        ) {
          dispatch(recruiterLoginVerifyFailure(response));
          showMessage({
            dispatch,
            message: "User doesn't Signup yet, please signup first ",
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(recruiterLoginVerifyFailure(error));
        return error;
      });
  };
};

export const LOGIN_MOBILE_OTP_VERIFY_RECRUITER_FULFILLED =
  "LOGIN_MOBILE_OTP_VERIFY_RECRUITER_FULFILLED";
export const LOGIN_MOBILE_OTP_VERIFY_RECRUITER_PENDING =
  "LOGIN_MOBILE_OTP_VERIFY_RECRUITER_PENDING";
export const LOGIN_MOBILE_OTP_VERIFY_RECRUITER_REJECTED =
  "LOGIN_MOBILE_OTP_VERIFY_RECRUITER_REJECTED";

const recruiterMobileLoginVerifyRequest = () => ({
  type: LOGIN_MOBILE_OTP_VERIFY_RECRUITER_PENDING,
});

const recruiterMobileLoginVerifyFailure = (error) => ({
  type: LOGIN_MOBILE_OTP_VERIFY_RECRUITER_REJECTED,
  payload: { error },
});

const recruiterMobileLoginVerifyFulfilled = (response) => ({
  type: LOGIN_MOBILE_OTP_VERIFY_RECRUITER_FULFILLED,
  payload: response,
});

export const sendRecruiterMobileVerify = (data) => {
  return (dispatch) => {
    dispatch(recruiterMobileLoginVerifyRequest());
    return http_noauth
      .post(apiUrl.VERIFY_MOBILE_OTP, data)
      .then((response) => {
        console.log();
        if (
          response?.data?.status === 1 &&
          response?.data?.data?.signup_status === true
        ) {
          setTokenResponse(response?.headers["x-auth"]);
          dispatch(recruiterMobileLoginVerifyFulfilled(response));
        } else if (
          response?.data?.status === 1 &&
          response?.data?.data?.signup_status === false
        ) {
          dispatch(recruiterMobileLoginVerifyFailure(response));
          showMessage({
            dispatch,
            message: "User doesn't Signup yet, please signup first ",
            variant: "error",
          });
        } else {
          showMessage({
            dispatch,
            message: response?.response?.data?.msg,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(recruiterMobileLoginVerifyFailure(error));
        return error;
      });
  };
};

export const RESET_RECRUITER_LOGIN_DETAILS = "RESET_RECRUITER_LOGIN_DETAILS";
export const resetRecruiterLoginDetails = () => {
  return {
    type: RESET_RECRUITER_LOGIN_DETAILS,
  };
};
export const GET_RECRUITER_PROFILE_DETAILS_SUCCESS =
  "GET_RECRUITER_PROFILE_DETAILS_SUCCESS";
export const GET_RECRUITER_PROFILE_DETAILS_REQUEST =
  "GET_RECRUITER_PROFILE_DETAILS_REQUEST";
export const GET_RECRUITER_PROFILE_DETAILS_FAILURE =
  "GET_RECRUITER_PROFILE_DETAILS_FAILURE";

const getRecruiterRequest = () => ({
  type: GET_RECRUITER_PROFILE_DETAILS_REQUEST,
});

const getRecruiterFailure = (error) => ({
  type: GET_RECRUITER_PROFILE_DETAILS_FAILURE,
  payload: { error: error },
});

const getRecruiterSuccess = (data) => ({
  type: GET_RECRUITER_PROFILE_DETAILS_SUCCESS,
  payload: data,
});
export const getRecruiterProfile = (payload) => {
  //debugger;
  return (dispatch) => {
    dispatch(getRecruiterRequest());
    return http
      .post(apiUrl.RECRUITER_GET_PROFILE)
      .then((response) => {
        console.log("response rec", response);

        // setTokenResponse(response?.headers['x-auth']);
        if (response?.data?.status === 1) {
          dispatch(getRecruiterSuccess(response));
        }
        return response;
      })
      .catch((error) => {
        dispatch(getRecruiterFailure(error));
        console.log("error", error);
      });
  };
};

export const RECRUITER_UPDATE_PROFILE_FULFILLED =
  "RECRUITER_UPDATE_PROFILE_FULFILLED";
export const RECRUITER_UPDATE_PROFILE_PENDING =
  "RECRUITER_UPDATE_PROFILE_PENDING";
export const RECRUITER_UPDATE_PROFILE_REJECTED =
  "RECRUITER_UPDATE_PROFILE_REJECTED";
const recruiterUpdateProfileRequest = () => ({
  type: RECRUITER_UPDATE_PROFILE_PENDING,
});
const recruiterUpdateProfileFailure = (error) => ({
  type: RECRUITER_UPDATE_PROFILE_REJECTED,
  payload: { error },
});
const recruiterUpdateProfileFulfilled = (response) => ({
  type: RECRUITER_UPDATE_PROFILE_FULFILLED,
  payload: response,
});
export const recruiterUpdateProfile = (data) => {
  return (dispatch) => {
    dispatch(recruiterUpdateProfileRequest());
    return http
      .post(apiUrl.RECRUITER_UPDATE_PROFILE, data)
      .then((response) => {
        console.log(response?.config, response?.headers);
        if (response?.data?.status === 1) {
          showMessage({
            dispatch,
            message: "Successfully update",
            variant: "success",
          });
          dispatch(recruiterUpdateProfileFulfilled(response));
        } else {
          showMessage({
            dispatch,
            message: response?.data?.data,
            variant: "error",
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch(recruiterUpdateProfileFailure(error));
        return error;
      });
  };
};
