import {
  SEND_OTP_CANDIDATE_MOBILE_FULFILLED,
  SEND_OTP_CANDIDATE_MOBILE_PENDING,
  SEND_OTP_CANDIDATE_MOBILE_REJECTED,
  SEND_OTP_CANDIDATE_LOGIN_FULFILLED,
  SEND_OTP_CANDIDATE_LOGIN_PENDING,
  SEND_OTP_CANDIDATE_LOGIN_REJECTED,
  VERIFY_OTP_CANDIDATE_MOBILE_FULFILLED,
  VERIFY_OTP_CANDIDATE_MOBILE_PENDING,
  VERIFY_OTP_CANDIDATE_MOBILE_REJECTED,
  VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_FULFILLED,
  VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_PENDING,
  VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_REJECTED,
  CLOSE_CANDIDATE_MOBILE_DIALOG,
  SIGNUP_CANDIDATE_FULFILLED,
  SIGNUP_CANDIDATE_PENDING,
  SIGNUP_CANDIDATE_REJECTED,
  RESET_CANDIDATE_DETAILS,
  GET_CANDIDATE_PROFILE_DETAILS_SUCCESS,
  GET_CANDIDATE_PROFILE_DETAILS_REQUEST,
  GET_CANDIDATE_PROFILE_DETAILS_FAILURE,
  RESET_CANDIDATE_LOGIN_DETAILS,
  GET_SKILLS_SUCCESS,
  GET_SKILLS_REQUEST,
  GET_SKILLS_FAILURE,
  GET_EDUCATION_SUCCESS,
  GET_EDUCATION_REQUEST,
  GET_EDUCATION_FAILURE,
  GET_EXPERIENCE_SUCCESS,
  GET_EXPERIENCE_REQUEST,
  GET_EXPERIENCE_FAILURE,
  GET_PREFERENCE_SUCCESS,
  GET_PREFERENCE_REQUEST,
  GET_PREFERENCE_FAILURE,
  UPLOAD_FILE_PENDING,
  UPLOAD_FILE_REJECTED,
  UPLOAD_FILE_FULFILLED,
  UPDATE_CANDIDATE_PROFILE,
} from "./actions";
const initialSnackbarState = {
  loggedIn: false,
  otpsend: false,
  email: "",
  otptype: "",
  mobile: "",
  candidateMobileOtp: false,
  candidateMobileVerification: false,
  candidateLoginOtpSend: false,
  candidateSignUp: false,
  usertype: "guest",
  filePath: "",
  candidateProfile: {},
  candidateSkills: {},
  candidateEducations: null,
  candidateExperienceList: {},
  candidatePreferences: {},
};

export const candidatereducer = (state = initialSnackbarState, action) => {
  console.log("action type....", action.type);
  console.log("Current state kya hai:", state);
  switch (action.type) {
    case "closedialogemail": {
      return { ...state, otpsend: false };
    }
    case "closedialogmobile": {
      return { ...state, otpsend: false };
    }
    case "logout": {
      return {
        ...state,
        loggedIn: false,
        signup: false,
        usertype: "guest",
        candidateMobileOtp: false,
        candidateLoginOtpSend: false,
        candidateMobileVerification: false,
        candidateSignUp: false,
      };
    }

    case CLOSE_CANDIDATE_MOBILE_DIALOG:
      return { ...state, candidateMobileOtp: false };
    case SEND_OTP_CANDIDATE_MOBILE_PENDING:
      return { ...state, ...action.payload };
    case SEND_OTP_CANDIDATE_MOBILE_REJECTED:
      return { ...state, ...action.payload };
    case SEND_OTP_CANDIDATE_MOBILE_FULFILLED:
      return {
        ...state,
        ...action.payload,
        candidateMobileOtp: true,
        candidateMobileVerification: false,
      };
    case SEND_OTP_CANDIDATE_LOGIN_PENDING:
      return { ...state, ...action.payload };
    case SEND_OTP_CANDIDATE_LOGIN_REJECTED:
      return { ...state, ...action.payload };
    case SEND_OTP_CANDIDATE_LOGIN_FULFILLED:
      return {
        ...state,
        ...action.payload,
        candidateLoginOtpSend: true,
        candidateMobileVerification: false,
      };
    case VERIFY_OTP_CANDIDATE_MOBILE_PENDING:
      return { ...state, ...action.payload };
    case VERIFY_OTP_CANDIDATE_MOBILE_REJECTED:
      return { ...state, ...action.payload };
    case VERIFY_OTP_CANDIDATE_MOBILE_FULFILLED:
      return {
        ...state,
        ...action.payload,
        candidateMobileOtp: false,
        candidateMobileVerification: true,
      };
    case VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_PENDING:
      return { ...state, ...action.payload };
    case VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_REJECTED:
      return { ...state, ...action.payload };
    case VERIFY_LOGIN_OTP_CANDIDATE_MOBILE_FULFILLED:
      return {
        ...state,
        ...action.payload,
        candidateMobileOtp: false,
        candidateMobileVerification: true,
        loggedIn: true,
        usertype: "candidate",
      };

    case SIGNUP_CANDIDATE_FULFILLED:
      return {
        ...state,
        ...action.payload,
        candidateSignUp: true,
        loggedIn: true,
        usertype: "candidate",
      };
    case SIGNUP_CANDIDATE_PENDING:
      return { ...state, ...action.payload };
    case SIGNUP_CANDIDATE_REJECTED:
      return { ...state, ...action.payload };
    case UPLOAD_FILE_PENDING: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPLOAD_FILE_REJECTED: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPLOAD_FILE_FULFILLED: {
      const filePath = action.payload?.data?.data?.data[0]?.url;
      const fileName = action.payload?.data?.data?.data[0]?.orginalFileName;
      console.log(
        action.payload,
        action.payload?.data?.data?.data[0]?.url,
        action.payload?.data?.data?.data[0]?.orginalFileName,
        "action.payload file"
      );
      console.log("Current state", state);

      const updatedCandidateProfile = {
        ...state.candidateProfile,
        resume: filePath,
        resumeFileName: fileName,
      };

      console.log("Updated state", updatedCandidateProfile);

      return {
        ...state,
        ...action.payload,
        filePath: filePath,
        fileName: fileName,
        candidateProfile: updatedCandidateProfile,
        loading: false,
        errors: {},
      };
    }
    case RESET_CANDIDATE_DETAILS:
      return {
        ...state,
        email: "",
        otptype: "",
        mobile: "",
        candidateMobileVerification: false,
        candidateSignUp: false,
        candidateMobileOtp: false,
      };
    case RESET_CANDIDATE_LOGIN_DETAILS:
      return {
        ...state,
        candidateLoginOtpSend: false,
      };

    case GET_CANDIDATE_PROFILE_DETAILS_SUCCESS: {
      console.log(
        action.payload.data.data,
        state.fileName,
        "candidate profile===="
      );

      return {
        ...state,
        candidateProfile: {
          ...state.candidateProfile,
          ...action.payload?.data?.data,
          resume: state.filePath || state.candidateProfile.resume,
          resumeFileName:
            state.fileName || state?.candidateProfile?.resumeFileName,
        },
        loading: false,
        usertype: "candidate",
        errors: {},
      };
    }

    case GET_CANDIDATE_PROFILE_DETAILS_REQUEST: {
      return {
        ...state,
        ...action.payload,
        candidateProfile: {},
        loading: false,
        errors: {},
      };
    }
    case GET_CANDIDATE_PROFILE_DETAILS_FAILURE: {
      return {
        ...state,
        ...action.payload,
        candidateProfile: {},
        loading: false,
        errors: {},
      };
    }

    case GET_SKILLS_SUCCESS: {
      console.log(action.payload.data?.data, "action.payload");
      return {
        ...state,
        ...action.payload,
        candidateSkills: action.payload.data?.data,
        loading: false,
        errors: {},
      };
    }
    case GET_SKILLS_REQUEST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_SKILLS_FAILURE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_EDUCATION_SUCCESS: {
      console.log(action.payload.data?.data, "action.payload education");
      return {
        ...state,
        ...action.payload,
        candidateEducations: action.payload.data?.data,
        loading: false,
        errors: {},
      };
    }
    case GET_EDUCATION_REQUEST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_EDUCATION_FAILURE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_EXPERIENCE_SUCCESS: {
      console.log(action.payload.data?.data, "action.payload education");
      return {
        ...state,
        ...action.payload,
        candidateExperienceList: action.payload.data?.data,
        loading: false,
        errors: {},
      };
    }
    case GET_EXPERIENCE_REQUEST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_EXPERIENCE_FAILURE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_PREFERENCE_SUCCESS: {
      console.log(action.payload.data?.data, "action.payload");
      return {
        ...state,
        ...action.payload,
        candidatePreferences: action.payload.data?.data,
        loading: false,
        errors: {},
      };
    }
    case GET_PREFERENCE_REQUEST: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case GET_PREFERENCE_FAILURE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case UPDATE_CANDIDATE_PROFILE: {
      const { candidateProfile } = action.payload;
      return {
        ...state,
        candidateProfile,
      };
    }

    default:
      return state;
  }
};
