import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  MenuItem,
  Select,
  Stack,
  Grid,
  Autocomplete,
} from "@mui/material";
import { FormInput } from "../../../../components/Input/FormInput";
import { useSelector, useDispatch } from "react-redux";
import CustomizedSnackbars from "../../../../components/Snackbar/CustomizedSnackbars";
import CreateIcon from "@mui/icons-material/Create";
import { makeStyles } from "@mui/styles";
import SmallSwitch from "../../../../components/Switch/switch";
import { TextareaAutosize } from "@mui/base";
import { useForm } from "react-hook-form";
import Navbar from "../../../../components/Header/Navbar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import * as jobsActions from "../../../../Store/Jobs";
import * as authActions from "../../../../Store/candidate";
import * as helperActions from "../../../../Store/helper";
import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";
const useStyles = makeStyles((theme) => ({
  mainHome: {
    padding: "100px 130px 30px",
    backgroundSize: "cover",
    backgroundColor: "#F0F1FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },

  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "8.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  "@media (min-width: 1640px)": {
    mainHome: {
      marginTop: "40px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
  },
  "@media (maxWidth: 468px)": {
    mainHome: {
      width: "100%",
      padding: "90px 15px",
      backgroundImage: "none",
    },

    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "35px",
    },
  },
}));

const S3_BUCKET = "exotalent-cv";
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: "AKIAWCKLV3UAPEYYOZO3",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: "#aaa",
  },
}));

const EditJob = () => {
  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loginToggle, setChecked] = React.useState(true);
  const [upload, setUpload] = React.useState(null);
  const [uploadjd, setUploadJd] = React.useState(null);
  const [jdpath, setJdpath] = React.useState("");
  const [companyHide, setCompanyHide] = React.useState(false);
  const [salaryHide, setSalaryHide] = React.useState(false);
  const [job_location, setJobLocation] = React.useState({});
  const [skills, setSkills] = React.useState([]);

  const educationlist = useSelector((state) => state?.auth?.educationlist);
  const jobDetails = useSelector((state) => state?.jobs?.jobDetails);
  const companyIndustry = useSelector(
    (state) => state?.helper?.companyIndustry
  );
  const companyFunction = useSelector(
    (state) => state?.helper?.companyFunction
  );
  useSelector((state) => state?.helper?.companySize);
  const companytype = useSelector((state) => state?.helper?.companyType);
  const location = useSelector((state) => state?.helper?.location);
  const skillsdata = useSelector((state) => state?.helper?.skills);
  const jobcompanytype = useSelector((state) => state?.helper?.jobType);
  const workmodelcompany = useSelector((state) => state?.helper?.workmodel);

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };
  const [logopath, setLogopath] = React.useState("");

  const [hiringfor, setHiringfor] = React.useState("");
  const handleHiringfor = (event) => {
    setHiringfor(event.target.value);
  };
  const [jobtype, setJobtype] = React.useState("");
  const handleJobtype = (event) => {
    setJobtype(event.target.value);
  };
  const [workmodel, setWorkmodel] = React.useState("");
  const [companyType, setCompanyType] = React.useState("");
  const handleWorkmodel = (event) => {
    setWorkmodel(event.target.value);
  };
  const handleCompanyType = (event) => {
    setCompanyType(event.target.value);
  };
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const [minArrayValue, setMinArrayValue] = useState([
    0, 1, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
    23, 24, 25,
  ]);
  const [maxArrayValue, setMaxArrayValue] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25,
  ]);
  const [minyear, setMinyear] = useState();
  const [maxyear, setMaxyear] = useState();
  const minYearChange = (event) => {
    setMinyear(event.target.value);
    let temp = [];
    for (let i = event.target.value + 1; i < 25; i++) {
      temp.push(i);
    }
    setMaxArrayValue(temp);
  };
  const maxYearChange = (event) => {
    console.log("maxyear", maxyear, minyear);
    setMaxyear(event.target.value);
  };
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [minSalaryValue, setMinSalaryValue] = useState([
    0, 3, 5, 7, 10, 12, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100,
    150, 200,
  ]);
  const [maxSalaryValue, setMaxSalaryValue] = useState([
    0, 3, 5, 7, 10, 12, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100,
    150, 200,
  ]);
  const minSalaryChange = (event) => {
    console.log("minSalaryChange", event.target.value);

    setMinSalary(event.target.value);
    let temp = [];
    if (event.target.value < 100) {
      temp.push("open");
      temp.push("not_disclosed");
      for (let i = event.target.value + 5; i < 110; i = i + 5) {
        temp.push(i);
      }
      temp.push(150);
      setMaxSalaryValue(temp);
    }
    if (event.target.value === 100) {
      temp.push("open");
      temp.push(200);
      temp.push("not_disclosed");
      temp.push(150);
      temp.push(200);
      setMaxSalaryValue(temp);
    }
    if (event.target.value === 150) {
      temp.push("open");
      temp.push("not_disclosed");
      temp.push(200);
      setMaxSalaryValue(temp);
    }
    if (event.target.value === 200) {
      temp.push("open");
      temp.push("not_disclosed");
      setMaxSalaryValue(temp);
    } else {
      setMaxSalaryValue(temp);
    }
  };

  const maxSalaryChange = (event) => {
    setMaxSalary(event.target.value);
  };
  const [industry, setIndustry] = React.useState("");
  const [education, setEducation] = React.useState("");
  const handleEducation = (event) => {
    setEducation(event.target.value);
  };
  const handleIndustry = (event) => {
    setIndustry(event.target.value);
  };
  const [fun, setFun] = React.useState("");
  const handleFun = (event) => {
    setFun(event.target.value);
  };

  useEffect(() => {
    async function getEducationlist() {
      await dispatch(authActions.candidateEducationList());
    }
    getEducationlist();
  }, [dispatch]);
  useEffect(() => {
    async function getCompanyTypelist() {
      await dispatch(helperActions.getCompanyType());
    }
    getCompanyTypelist();
  }, [dispatch]);
  useEffect(() => {
    async function getCompanyWorkModelTypelist() {
      await dispatch(helperActions.getCompanyWorkModelType());
    }
    getCompanyWorkModelTypelist();
  }, [dispatch]);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const onSubmit = async (data) => {
    console.log(data);
    let description = "";
    let logo = "";

    if (loginToggle) {
      description = data?.description;
    } else {
      description = jdpath;
    }

    if (logopath?.length > 0) {
      logo = logopath;
    } else {
      logo = jobDetails?.company_logo;
    }

    let skillsArray = skills?.map((skill, index) => {
      return skill?._id;
    });
    const formData = {
      _id: jobDetails?._id,
      hiring_for: hiringfor,
      job_title: data?.jobTitle,
      company_name: data?.company,
      company_type: companyType,
      location: job_location?._id,
      education: education,
      industry: industry,
      function: fun,
      work_model: workmodel,
      experience_min: minyear,
      experience_max: maxyear,
      job_status: jobDetails?.job_status,
      salary_min: minSalary,
      salary_max: maxSalary,
      about_company: data?.about_company,
      skill: skillsArray,
      job_type: jobtype,
      job_description: description,
      company_logo: logo,
      is_salary_hide: salaryHide,
      is_company_name_hide: companyHide,
    };
    const createjob = await dispatch(jobsActions.updateJob(formData));
    if (createjob?.data?.status === true) {
      navigate("/companyJd/" + jobDetails?._id);
    }
  };

  const handleUploadLogo = async (event) => {
    await setUpload(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    return uploadLogo(event.target.files[0]);
  };

  const handleUploadJd = async (event) => {
    await setUploadJd(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    return uploadJd(event.target.files[0]);
  };

  const toggleCompanyName = () => {
    setCompanyHide(!companyHide);
  };

  const toggleSalary = () => {
    setSalaryHide(!salaryHide);
  };

  const uploadJd = async (file) => {
    let namekey = uuidv4() + "_lx_" + file.name.replace(/ /g, "_");
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: namekey,
    };

    let objct = myBucket.putObject(params).promise();
    // .on('httpUploadProgress', (evt) => {
    // setProgress(Math.round((evt.loaded / evt.total) * 100))
    // })
    // .send((err,data) => {
    // if (err) console.log('aws err',err)
    // if (data)console.log('aws data',data)
    //
    // })
    console.log("aws ", objct);
    objct
      .then(function (data) {
        console.log(
          "aws Successfully uploaded data to " + S3_BUCKET + "/" + namekey
        );
        console.log("aws Success", data);
        let path =
          "https://" +
          S3_BUCKET +
          ".s3." +
          REGION +
          ".amazonaws.com/" +
          namekey;
        setJdpath(path);
      })
      .catch(function (err) {
        console.log("aws err", err);
      });
  };

  const uploadLogo = async (file) => {
    let namekey = uuidv4() + "_lx_" + file.name.replace(/ /g, "_");
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: namekey,
    };

    let objct = myBucket.putObject(params).promise();
    // .on('httpUploadProgress', (evt) => {
    // setProgress(Math.round((evt.loaded / evt.total) * 100))
    // })
    // .send((err,data) => {
    // if (err) console.log('aws err',err)
    // if (data)console.log('aws data',data)
    //
    // })
    console.log("aws ", objct);
    objct
      .then(function (data) {
        console.log(
          "aws Successfully uploaded data to " + S3_BUCKET + "/" + namekey
        );
        console.log("aws Success", data);
        let path =
          "https://" +
          S3_BUCKET +
          ".s3." +
          REGION +
          ".amazonaws.com/" +
          namekey;
        setLogopath(path);
      })
      .catch(function (err) {
        console.log("aws err", err);
      });
  };

  const navigate = useNavigate();
  const redirect = () => {
    navigate("/companyJd");
  };
  useEffect(() => {
    async function companyType() {
      setHiringfor(jobDetails?.hiring_for);
      setCompanyType(jobDetails?.company_type);
      setIndustry(jobDetails?.industry?._id);
      setFun(jobDetails?.function?._id);
      setJobtype(jobDetails?.job_type?._id);
      setWorkmodel(jobDetails?.work_model);
      setEducation(jobDetails?.education);
      setMinyear(jobDetails?.experience?.min);
      setMaxyear(jobDetails?.experience?.max);
      setMinSalary(jobDetails?.salary?.min);
      setMaxSalary(jobDetails?.salary?.max);
      setCompanyHide(jobDetails?.is_company_name_hide);
      setSalaryHide(jobDetails?.is_salary_hide);
      setSkills(jobDetails?.skill);
      setJobLocation(jobDetails?.location);
      setEducation(jobDetails?.education?._id);

      if (jobDetails?.job_description?.includes("https://exotalent-")) {
        setChecked(false);
        setJdpath(jobDetails?.job_description);
      }
      console.log(
        jobDetails?.experience?.min,
        jobDetails?.experience?.max,
        minyear,
        maxyear
      );
    }
    companyType();
  }, [
    jobDetails?.company_type,
    jobDetails?.education,
    jobDetails?.experience?.max,
    jobDetails?.experience?.min,
    jobDetails?.function?._id,
    jobDetails?.hiring_for,
    jobDetails?.industry?._id,
    jobDetails?.is_company_name_hide,
    jobDetails?.is_salary_hide,
    jobDetails?.job_description,
    jobDetails?.job_type?._id,
    jobDetails?.location,
    jobDetails?.salary?.max,
    jobDetails?.salary?.min,
    jobDetails?.skill,
    jobDetails?.work_model,
    maxyear,
    minyear,
  ]);

  return (
    <>
      <Navbar />
      <Box className={classes.mainHome}>
        {/* <Box className={classes.container}> */}
        {openSnackbar && <CustomizedSnackbars />}
        <Typography
          variant='h4'
          gutterBottom
          sx={{
            textAlign: "center",
            color: "#223870",
            fontWeight: "500",
          }}>
          Update Job
        </Typography>{" "}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction='row'>
            {" "}
            <Grid
              Item
              xs={12}
              sm={6}
              sx={{
                textAlign: "left",
                paddingRight: "40px",
                "@media (maxWidth: 468px)": { paddingRight: "0" },
              }}>
              {" "}
              <Stack
                spacing={0}
                sx={{
                  marginTop: "10px",
                }}>
                {" "}
                <Box>
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Hiring For*
                  </Typography>{" "}
                  <Box>
                    <Select
                      sx={{
                        width: "100%",
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                      }}
                      required
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      value={hiringfor}
                      onChange={handleHiringfor}
                      className={classes.countrycode}>
                      <MenuItem value='Client'>Client</MenuItem>
                      <MenuItem value='In-House'>In-House</MenuItem>
                    </Select>
                  </Box>
                </Box>
              </Stack>
              <Stack spacing={0} sx={{ marginTop: "10px" }}>
                <Box>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Job Title*
                  </Typography>{" "}
                  <Box>
                    <FormInput
                      sx={{
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                        fontWeight: "500",
                      }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name='jobTitle'
                      defaultValue={jobDetails?.job_title}
                      {...register("jobTitle", {
                        required: "Job Title is required",
                      })}
                    />{" "}
                    {errors.jobTitle && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.jobTitle.message}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Stack>{" "}
              <Stack direction='row' sx={{ width: "110%" }}>
                <Stack spacing={0} sx={{ marginTop: "10px", width: "100%" }}>
                  <Box>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        textAlign: "left",
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Company*
                    </Typography>{" "}
                    <Box
                      sx={{
                        width: "100%",
                      }}>
                      <FormInput
                        sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                        size='small'
                        variant='outlined'
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        defaultValue={jobDetails?.company_name}
                        name='company'
                        {...register("company", {
                          required: "Company is required",
                        })}
                      />{" "}
                      {errors.company && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.company.message}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Stack>
                <Box
                  onClick={toggleCompanyName}
                  pt={5}
                  px={1}
                  sx={{ alignItems: "center", width: "10%" }}>
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}>
                    {companyHide ? "Hide" : "Show"}
                  </Typography>
                </Box>
              </Stack>
              <Stack spacing={0} sx={{ marginTop: "10px" }}>
                {" "}
                <Box>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Company Logo*
                    <span style={{ fontSize: "11px" }}>
                      (Max: 2MB in PNG or JPEG)
                    </span>
                  </Typography>{" "}
                  <Box
                    sx={{
                      width: "100%",
                    }}>
                    <Box
                      sx={{
                        width: "100%",
                        height: "40px",
                        display: "flex",
                        borderRadius: "5px",
                        backgroundColor: "#E0DFF4",
                        color: "#304672",
                        direction: "row",
                      }}>
                      {/* remove */}

                      {/* remove */}
                      <input
                        id='icon-button-image'
                        type='file'
                        style={{
                          width: "95px",
                          padding: "10px",
                          display: "block",
                          borderRadius: "5px",
                          backgroundColor: "#E0DFF4",
                          color: "#304672",
                          cursor: "pointer",
                        }}
                        onChange={handleUploadLogo}
                        name='image'
                      />
                      <Box p={1.2}>
                        {jobDetails?.company_logo?.split("_lx_")[1]}
                      </Box>
                      <AttachFileIcon
                        sx={{ float: "right", marginTop: "10px" }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Stack>
              <Stack
                spacing={0}
                sx={{
                  marginTop: "10px",
                }}>
                {" "}
                <Box>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Company Type*
                  </Typography>{" "}
                  <Box>
                    <Select
                      sx={{
                        width: "100%",
                        color: "#5071C4",

                        backgroundColor: "#E0DFF4",
                      }}
                      required
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",

                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      value={companyType}
                      onChange={handleCompanyType}
                      className={classes.countrycode}>
                      {companytype?.map((option) => {
                        return (
                          <MenuItem
                            key={option._id}
                            value={option._id}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {option?.company_type}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </Box>
              </Stack>
              <Stack spacing={0} sx={{ marginTop: "10px" }}>
                <Box>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Location*
                  </Typography>{" "}
                  <Box
                    sx={{
                      width: "100%",
                    }}>
                    <Autocomplete
                      id='country-sel'
                      sx={{ width: "100% " }}
                      value={job_location}
                      options={location}
                      autoHighlight
                      disableClearable
                      variant='standard'
                      popupIcon={
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                          }}
                        />
                      }
                      name='location'
                      defaultValue=''
                      onChange={(_event, location) => {
                        setJobLocation(location);
                      }}
                      size='small'
                      getOptionLabel={(option) => {
                        if (option) {
                          return option?.city;
                        } else {
                          return "";
                        }
                      }}
                      renderInput={(params) => (
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size='small'
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "",
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Stack>
              <Stack spacing={0} sx={{ marginTop: "10px" }}>
                {" "}
                <Box>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Industry*
                  </Typography>{" "}
                  <Box>
                    <Select
                      sx={{
                        width: "100%",
                        color: "#5071C4",

                        backgroundColor: "#E0DFF4",
                      }}
                      required
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      className={classes.countrycode}
                      value={industry}
                      onChange={handleIndustry}>
                      {companyIndustry?.map((option) => {
                        return (
                          <MenuItem
                            key={option._id}
                            value={option._id}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {option.industry}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </Box>
              </Stack>
              <Stack spacing={0} sx={{ marginTop: "10px" }}>
                {" "}
                <Box>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Function*
                  </Typography>{" "}
                  <Box>
                    <Select
                      sx={{
                        width: "100%",
                        color: "#5071C4",

                        backgroundColor: "#E0DFF4",
                      }}
                      required
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                          }}
                        />
                      )}
                      className={classes.countrycode}
                      labelId='demo-simple-select-standard-label'
                      id='demo-simple-select-standard'
                      value={fun}
                      onChange={handleFun}>
                      {companyFunction?.map((option) => {
                        return (
                          <MenuItem
                            key={option._id}
                            value={option._id}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {option.function}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </Box>
              </Stack>
              <Stack spacing={0} sx={{ marginTop: "10px" }}>
                {" "}
                <Box>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Job Type*
                  </Typography>{" "}
                  <Box>
                    <Select
                      sx={{
                        width: "100%",
                        color: "#5071C4",

                        backgroundColor: "#E0DFF4",
                      }}
                      required
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      value={jobtype}
                      onChange={handleJobtype}
                      className={classes.countrycode}>
                      {jobcompanytype?.map((option) => {
                        return (
                          <MenuItem
                            key={option._id}
                            value={option._id}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {option?.job_type}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </Box>
              </Stack>
              <Stack spacing={0} sx={{ marginTop: "10px" }}>
                {" "}
                <Box>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Work Model*
                  </Typography>{" "}
                  <Box>
                    <Select
                      sx={{
                        width: "100%",
                        color: "#5071C4",

                        backgroundColor: "#E0DFF4",
                      }}
                      required
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      value={workmodel}
                      onChange={handleWorkmodel}
                      className={classes.countrycode}>
                      {workmodelcompany?.map((option) => {
                        return (
                          <MenuItem
                            key={option._id}
                            value={option._id}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {option?.work_model}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid
              Item
              xs={12}
              sm={6}
              sx={{
                textAlign: "left",
                paddingLeft: "40px",
                "@media (maxWidth: 468px)": { paddingLeft: "0" },
              }}>
              {" "}
              <Stack spacing={0} sx={{ marginTop: "10px" }}>
                {" "}
                <Box>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Experience*
                  </Typography>{" "}
                  <Box>
                    <Stack direction='row'>
                      <Box sx={{ width: "50%", paddingRight: "8px" }}>
                        <Select
                          sx={{
                            width: "100%",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                          }}
                          required
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          className={classes.countrycode}
                          labelId='demo-simple-select-standard-label'
                          id='demo-simple-select-standard'
                          value={minyear}
                          displayEmpty
                          onChange={minYearChange}
                          defaultValue={jobDetails?.experience?.min}
                          renderValue={(selected) => {
                            if (!selected)
                              return <Placeholder>Min. exp</Placeholder>;
                            else
                              return (
                                selected +
                                " " +
                                (selected === 1 ? "year" : "years")
                              );
                          }}>
                          {minArrayValue
                            ? minArrayValue.map((item, key) => {
                                return (
                                  <MenuItem key={key} value={item}>
                                    {item} {item === 1 ? "year" : "years"}
                                  </MenuItem>
                                );
                              })
                            : ""}
                        </Select>
                      </Box>
                      <Box sx={{ width: "50%", paddingLeft: "8px" }}>
                        {" "}
                        <Select
                          sx={{
                            width: "100%",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                          }}
                          required
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          className={classes.countrycode}
                          labelId='demo-simple-select-standard-label'
                          id='demo-simple-select-standard 2'
                          value={maxyear}
                          onChange={maxYearChange}
                          displayEmpty
                          defaultValue={jobDetails?.experience?.max}
                          renderValue={(selected) => {
                            console.log("minyear", selected, maxyear, minyear);
                            if (!selected)
                              return <Placeholder>Max. exp</Placeholder>;
                            else
                              return (
                                selected +
                                " " +
                                (selected === 1 ? "year" : "years")
                              );
                          }}>
                          {maxArrayValue
                            ? maxArrayValue.map((item, key) => {
                                return (
                                  <MenuItem key={key} value={item}>
                                    {item} {item === 1 ? "year" : "years"}
                                  </MenuItem>
                                );
                              })
                            : ""}
                        </Select>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </Stack>
              <Stack direction='row' sx={{ width: "110%" }}>
                <Stack spacing={0} sx={{ marginTop: "10px", width: "100%" }}>
                  {" "}
                  <Box>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        textAlign: "left",
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Salary*
                    </Typography>{" "}
                    <Box>
                      <Stack direction='row' spacing={1}>
                        <Box sx={{ width: "50%" }}>
                          {" "}
                          <Select
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                            }}
                            required
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            className={classes.countrycode}
                            value={minSalary}
                            onChange={minSalaryChange}
                            displayEmpty
                            renderValue={(selected) => {
                              console.log(selected);
                              if (!selected)
                                return <Placeholder>Min. value</Placeholder>;
                              else
                                return (
                                  selected +
                                  " " +
                                  (selected === 1 ? "lac" : "lacs")
                                );
                            }}>
                            {minSalaryValue
                              ? minSalaryValue.map((item, key) => {
                                  if (item < 100) {
                                    return (
                                      <MenuItem key={key} value={item}>
                                        {item} {item === 0 ? "lac" : "lacs"}
                                      </MenuItem>
                                    );
                                  } else if (item >= 100) {
                                    return (
                                      <MenuItem key={key} value={item}>
                                        {item / 100} Cr
                                      </MenuItem>
                                    );
                                  }
                                })
                              : ""}
                          </Select>
                        </Box>
                        <Box sx={{ width: "50%", paddingLeft: "8px" }}>
                          {" "}
                          <Select
                            required
                            sx={{
                              marginRight: "10px",
                              width: "100%",
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                            }}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            className={classes.countrycode}
                            value={maxSalary}
                            onChange={maxSalaryChange}
                            displayEmpty
                            renderValue={(selected) => {
                              if (!selected)
                                return <Placeholder>Max. value</Placeholder>;
                              else
                                return (
                                  selected +
                                  " " +
                                  (selected === 0 ? "lac" : "lacs")
                                );
                            }}>
                            {maxSalaryValue
                              ? maxSalaryValue?.map((item, key) => {
                                  if (item < 100) {
                                    return (
                                      <MenuItem key={key} value={item}>
                                        {item} lacs
                                      </MenuItem>
                                    );
                                  } else if (item >= 100) {
                                    return (
                                      <MenuItem key={key} value={item}>
                                        {item / 100} Cr
                                      </MenuItem>
                                    );
                                  }
                                })
                              : ""}
                          </Select>
                        </Box>
                      </Stack>
                    </Box>
                  </Box>
                </Stack>

                <Box
                  onClick={toggleSalary}
                  sx={{ width: "10%", display: "flex", alignItems: "center" }}
                  mt={4}
                  px={2}>
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}>
                    {salaryHide ? "Hide" : "Show"}
                  </Typography>
                </Box>
              </Stack>
              <Stack spacing={0} sx={{ marginTop: "10px" }}>
                {" "}
                <Box>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Education*
                  </Typography>{" "}
                  <Box>
                    <Select
                      sx={{
                        width: "100%",
                        color: "#5071C4",

                        backgroundColor: "#E0DFF4",
                      }}
                      required
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      className={classes.countrycode}
                      value={education}
                      onChange={handleEducation}>
                      {educationlist?.map((option) => {
                        return (
                          <MenuItem
                            key={option._id}
                            value={option._id}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {option.degree}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </Box>
              </Stack>
              <Stack spacing={0} sx={{ marginTop: "10px" }}>
                <Box>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Skills*
                  </Typography>{" "}
                  <Box
                    sx={{
                      width: "100%",
                    }}>
                    <Autocomplete
                      id='country-select-demo'
                      sx={{ width: "100% " }}
                      multiple
                      value={skills}
                      options={skillsdata}
                      autoHighlight
                      disableClearable
                      variant='standard'
                      popupIcon={
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                          }}
                        />
                      }
                      name='job_skills'
                      defaultValue={[]}
                      onChange={(_event, skill) => {
                        console.log("skills", skill);
                        setSkills(skill);
                      }}
                      size='small'
                      getOptionLabel={(option) => {
                        console.log("getOptionLabel", option);
                        if (option) {
                          return option?.skill;
                        } else {
                          return "";
                        }
                      }}
                      renderInput={(params) => (
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size='small'
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "",
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Stack>
              <Stack spacing={0} sx={{ marginTop: "10px" }}>
                {" "}
                <Box>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    About the Company*
                  </Typography>{" "}
                  <Box>
                    <TextareaAutosize
                      aria-label='minimum height'
                      minRows={8}
                      style={{
                        border: "none",
                        borderRadius: "5px",
                        paddingTop: "8px",
                        paddingRight: "8px",
                        paddingLeft: "8px",
                        width: "100%",
                        backgroundColor: "#E0DFF4",
                        color: "#5071C4",
                      }}
                      defaultValue={jobDetails?.about_company}
                      name='about_company'
                      {...register("about_company", {
                        required: "About Company is required",
                      })}
                    />

                    {errors.about_company && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.about_company.message}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Stack>
              <Stack spacing={0} sx={{ marginTop: "4px" }}>
                {" "}
                <Box>
                  {" "}
                  <Stack direction='row' justifyContent='space-between'>
                    {" "}
                    <Box>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          textAlign: "left",
                          color: "#304672",
                          fontWeight: "500",
                        }}>
                        Job Description*
                      </Typography>{" "}
                    </Box>
                    <Box>
                      <AttachFileIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                          color: "#304672",
                          pointerEvents: "none",
                        }}
                      />
                      <SmallSwitch
                        onChange={handleToggle}
                        checked={loginToggle}
                      />
                      <CreateIcon
                        sx={{
                          color: "#304672",
                          pointerEvents: "none",
                        }}
                      />
                    </Box>
                  </Stack>
                  {loginToggle ? (
                    <Box>
                      <TextareaAutosize
                        aria-label='minimum height'
                        minRows={8}
                        style={{
                          border: "none",
                          borderRadius: "5px",
                          // marginTop: "10px",
                          paddingTop: "8px",
                          paddingRight: "8px",
                          paddingLeft: "8px",
                          width: "100%",
                          backgroundColor: "#E0DFF4",
                          color: "#5071C4",
                        }}
                        name='description'
                        defaultValue={
                          jobDetails?.job_description?.includes(
                            "https://exotalent-"
                          )
                            ? ""
                            : jobDetails?.job_description
                        }
                        {...register("description", {
                          required: "Job description is required",
                        })}
                      />
                      {errors.description && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.description.message}
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          height: "40px",
                          display: "flex",
                          borderRadius: "5px",
                          backgroundColor: "#E0DFF4",
                          color: "#304672",
                          direction: "row",
                        }}>
                        {/* remove */}

                        {/* remove */}
                        <input
                          id='icon-button-image'
                          type='file'
                          style={{
                            width: "95px",
                            padding: "10px",
                            display: "block",
                            borderRadius: "5px",
                            backgroundColor: "#E0DFF4",
                            color: "#304672",
                          }}
                          onChange={handleUploadJd}
                          name='image'
                        />

                        <Box p={1.2}>
                          {jobDetails?.job_description?.split("_lx_")[1]}
                        </Box>
                        <AttachFileIcon
                          sx={{ float: "right", marginTop: "10px" }}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Stack>
              <Box
                sx={{
                  marginTop: "40px",
                  textAlign: "center",
                }}>
                {" "}
                <input
                  type='submit'
                  value='Update'
                  style={{
                    textTransform: "none",
                    textDecoration: "none",
                    border: "none",
                    padding: "8px 25px",
                    borderRadius: "3px ",
                    color: "#FDCF57",
                    fontWeight: "500",
                    fontSize: "20px",
                    backgroundColor: "#223870",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#223870",
                      color: "#FDCF57 ",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};
export default EditJob;
