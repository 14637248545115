import { createTheme } from "@mui/material/styles";
import Palette from "./palette";
import typography from "./typography";
import createBreakpoints from "@mui/system/createTheme/createBreakpoints";
const breakpoints = createBreakpoints({});
const Theme = createTheme({
  breakpoints,
  typography,
  Palette,
  spacing: 8,
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#223870",
          "&.Mui-checked": {
            color: "#223870",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#5071C4",
          borderRadius: "5px",
          "&::placeholder": {
            color: "gray",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
          "& .MuiFilledInput-root": {
            color: "#5071C4",
            borderRadius: "5px",
          },
        },
      },
    },

    MuiDatePicker: {
      defaultProps: {
        OpenPickerButtonProps: {
          sx: {
            color: "#223870",
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
          backgroundColor: "#E8EEFB",
          color: "#5677B9",
          border: "1px solid #5677B9",
        },
        arrow: {
          fontSize: 16,
          width: 17,
          "&::before": {
            border: "1px solid #5677B9",
            backgroundColor: "#E8EEFB",
            boxSizing: "border-box",
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          "&.MuiDialogContent-root": {
            padding: "0px !important",
          },
        },
      },
    },
  },
  overrides: {
    MuiCssBseline: {
      "@glopbal": {
        img: {
          maxWidth: "100%",
          height: "auto",
        },
        a: { textDecoration: "none" },
        "::-webkit-scrollbar ": {
          width: "10px",
        },

        "::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },

        "::-webkit-scrollbar-thumb": {
          background: "#888",
        },

        "::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
    },
  },
});

export default Theme;
