import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import dropDown from "../../../../assets/Icons/Dropdown.png";
import UpArrow from "../../../../assets/Icons/UpArrow.png";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../../components/Header/Navbar";
import JobCandidateCardnew from "../../../../components/jobCards/Candidate/JobCandidateCardnew";
import { useSelector, useDispatch } from "react-redux";
import * as jobsActions from "../../../../Store/Jobs";
import * as authActions from "../../../../Store/candidate";
const useStyles = makeStyles((theme) => ({
  // container: { width: "auto", padding: "0", margin: "0" },

  topbar: {
    position: "relative",
    marginTop: "80px",
    padding: "20px 75px",
    backgroundSize: "cover",
    backgroundColor: "#DFE5F6",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  imgFrame: {
    background: "#fff",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    objectFit: "contain",
    margin: "0 auto",
    width: "110px",
    height: "110px",
    borderRadius: "5px",
    backgroundSize: "cover",
  },
  text: { marginLeft: "30px" },
  button: {
    position: "absolute",
    top: "55px",
    right: "100px",
  },
  body: {
    padding: "20px 0px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#F6F8FD",
    textAlign: "center",
  },
  jobbox: {
    marginLeft: "15px",
    padding: "20px 0px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },

  "@media (min-width: 1640px)": {
    mainHome: {
      marginTop: "80px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
  },
  "@media (maxWidth: 468px)": {
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "26px",
    },
    jobbox: {
      width: "100%",
      margin: " 20px 0px 0px 0px",
      padding: "0 5px",
    },
    container: {
      width: "100%",
      padding: "0 0px",
    },
    topbar: {
      position: "relative",
      padding: "20px 10px",
      height: "170px",
      width: "100%",
    },
    imgFrame: {
      width: "90px",
      height: "90px",
    },
    img: {
      width: "80px",
      height: "80px",
    },
    text: { marginLeft: "10px" },
    button: {
      position: "absolute",
      top: "120px",
      right: "20px",
    },
    body: {
      padding: "0",
      borderRadius: "10px",
    },
  },
}));

const JobCandidateList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();

  useSelector((state) => state?.auth?.profile);

  useEffect(() => {
    console.log("keywords", params);
    async function jobDetails() {}
    jobDetails();
  }, [dispatch, params]);

  useEffect(() => {
    console.log("keywords", params);
    async function jobDetails() {
      const id = "62eb98b0eb8841c395dd00f0";
      await dispatch(jobsActions.getJobCandidateProfile(id));
    }
    jobDetails();
  }, [dispatch, params]);

  useEffect(() => {
    async function companyProfile() {
      const profile = await dispatch(authActions.getProfile());
      console.log("closedJobList ==========", profile);
    }
    companyProfile();
  }, [dispatch]);

  const [numberOfOpenJobs, setNumberOfOpenJobs] = React.useState(2);
  const [numberOfInactiveJobs, setNumberOfInactiveJobs] = React.useState(2);
  const [numberOfClosedJobs, setNumberOfClosedJobs] = React.useState(2);
  const [noOfrejectedcandi, setNoOfrejectedcandi] = React.useState(2);
  // debugger;

  const jobCandidateApply = useSelector(
    (state) => state?.jobs?.jobCandidateApply
  );
  console.log("openJobs==================", jobCandidateApply);
  const inactiveJobs = useSelector(
    (state) => state?.jobs?.createdInactiveJobList
  );
  console.log("inactiveJobs==================", inactiveJobs);
  const jobCandidateShortlist = useSelector(
    (state) => state?.jobs?.jobCandidateShortlist
  );

  const jobCandidateHold = useSelector(
    (state) => state?.jobs?.jobCandidateHold
  );

  const jobCandidateReject = useSelector(
    (state) => state?.jobs?.jobCandidateReject
  );
  console.log("closedJobs==================", jobCandidateShortlist);
  const seeMoreOpen = () => {
    if (jobCandidateApply?.length > numberOfOpenJobs) {
      setNumberOfOpenJobs(jobCandidateApply?.length);
    }
  };
  const seeMoreInactive = () => {
    if (jobCandidateHold?.length > numberOfInactiveJobs) {
      setNumberOfInactiveJobs(jobCandidateHold?.length);
    }
  };
  const seeMoreClosed = () => {
    if (jobCandidateHold?.length > numberOfClosedJobs) {
      setNumberOfClosedJobs(jobCandidateHold?.length);
    }
  };

  const seeMoreRejected = () => {
    if (jobCandidateReject?.length > noOfrejectedcandi) {
      setNoOfrejectedcandi(jobCandidateReject?.length);
    }
  };
  const seeLessOpen = () => {
    setNumberOfOpenJobs(2);
  };

  const [screenSelect, setScreenSelect] = React.useState();

  const handleScreenSelect = (event) => {
    setScreenSelect(event.target.value);
  };
  //Added
  return (
    <>
      <Navbar />
      <Box className={classes.container}>
        {" "}
        <Box className={classes.body}>
          <Box>
            <Grid container px={1}>
              <Grid item sm={9}>
                {" "}
                <Typography
                  variant='h6'
                  sx={{
                    textAlign: "left",
                    color: "#223870",
                    fontWeight: "600",
                    marginBottom: "20px",
                  }}>
                  Candidates
                </Typography>
              </Grid>
              <Grid item sm={3}>
                {" "}
                <Stack direction='row'>
                  <Box mr={4} ml={4}>
                    {" "}
                    {/* <MenuButton
                      sx={{
                        width: 'auto',
                        fontWeight: 400,
                        display: 'flex',
                        justifyContent: 'left',
                      }}
                      onClick={handleClick1}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Applied ()
                    </MenuButton>
                    <DropdownMenu
                      id='basic-menu'
                      anchorEl={anchorEl1}
                      open={open1}
                      onClose={handleClose1}
                    >
                      <hr
                        style={{
                          backgroundColor: ' #CCD3E8',
                          border: '0',
                          height: '1px',
                        }}
                      />
                      <MenuItem onClose={handleClose1}>All ()</MenuItem>
                      <hr
                        style={{
                          backgroundColor: ' #CCD3E8',
                          border: '0',
                          height: '1px',
                        }}
                      />{' '}
                      <MenuItem onClose={handleClose1}>
                        Screen Select ()
                      </MenuItem>
                      <hr
                        style={{
                          backgroundColor: ' #CCD3E8',
                          border: '0',
                          height: '1px',
                        }}
                      />{' '}
                      <MenuItem onClose={handleClose1}>Round 1 ()</MenuItem>
                      <hr
                        style={{
                          backgroundColor: ' #CCD3E8',
                          border: '0',
                          height: '1px',
                        }}
                      />{' '}
                      <MenuItem onClose={handleClose1}>Round 2 ()</MenuItem>
                      <hr
                        style={{
                          backgroundColor: ' #CCD3E8',
                          border: '0',
                          height: '1px',
                        }}
                      />{' '}
                      <MenuItem onClose={handleClose1}>Offered ()</MenuItem>
                      <hr
                        style={{
                          backgroundColor: ' #CCD3E8',
                          border: '0',
                          height: '1px',
                        }}
                      />{' '}
                      <MenuItem onClose={handleClose1}>Accepted ()</MenuItem>
                      <hr
                        style={{
                          backgroundColor: ' #CCD3E8',
                          border: '0',
                          height: '1px',
                        }}
                      />{' '}
                      <MenuItem onClose={handleClose1}>Withdrawn ()</MenuItem>
                      <hr
                        style={{
                          backgroundColor: ' #CCD3E8',
                          border: '0',
                          height: '1px',
                        }}
                      />{' '}
                      <MenuItem onClose={handleClose1}>Rejected ()</MenuItem>
                    </DropdownMenu> */}
                    <Select
                      className={classes.dropdown}
                      value={screenSelect}
                      onChange={handleScreenSelect}
                      MenuProps={{
                        MenuListProps: { disablePadding: true },
                      }}
                      fullWidth
                      size='small'
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: (theme) => theme.Palette.text.black,
                            position: "absolute",
                            right: 10,
                            width: "20px",
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        fontSize: "14px",
                        width: "auto",
                        backgroundColor: "#DFE5F6",
                        color: "#223870",
                        height: "28px",
                        "& .MuiSelect-outlined": {
                          padding: "6px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}>
                      <MenuItem
                        value={0}
                        sx={{
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                        }}>
                        Applied()
                      </MenuItem>
                      <MenuItem
                        value={1}
                        sx={{
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                        }}>
                        Applied()
                      </MenuItem>
                      <hr
                        style={{
                          backgroundColor: " #CCD3E8",
                          border: "0",
                          height: "1px",
                        }}
                      />
                      <MenuItem
                        value={2}
                        sx={{
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                        }}>
                        Screen Select
                      </MenuItem>

                      <MenuItem
                        value={3}
                        sx={{
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                        }}>
                        Round 1
                      </MenuItem>

                      <MenuItem
                        value={3}
                        sx={{
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                        }}>
                        Round 1
                      </MenuItem>

                      <MenuItem
                        value={4}
                        sx={{
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                        }}>
                        Round 2
                      </MenuItem>

                      <MenuItem
                        value={5}
                        sx={{
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                        }}>
                        Offered
                      </MenuItem>

                      <MenuItem
                        value={6}
                        sx={{
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                        }}>
                        Accepted
                      </MenuItem>

                      <MenuItem
                        value={7}
                        sx={{
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                        }}>
                        Withdraw
                      </MenuItem>

                      <MenuItem
                        value={3}
                        sx={{
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                        }}>
                        Rejected
                      </MenuItem>
                    </Select>
                  </Box>
                  <Box>
                    <Select
                      // value={latest}
                      // onChange={handleLatest}
                      MenuProps={{
                        MenuListProps: { disablePadding: true },
                      }}
                      fullWidth
                      size='small'
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: (theme) => theme.Palette.text.black,
                            position: "absolute",
                            right: 10,
                            width: "20px",
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        fontSize: "14px",
                        width: "auto",
                        backgroundColor: "#DFE5F6",
                        color: "#223870",
                        height: "28px",
                        "& .MuiSelect-outlined": {
                          padding: "6px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}>
                      <MenuItem
                        value='latest'
                        sx={{
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                        }}>
                        Posted Latest
                      </MenuItem>
                      <hr
                        style={{
                          backgroundColor: " #CCD3E8",
                          border: "0",
                          height: "1px",
                        }}
                      />
                      <MenuItem
                        value='oldest'
                        sx={{
                          fontSize: "14px",
                          height: "25px",
                          width: "100%",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                        }}>
                        Posted Oldest
                      </MenuItem>
                    </Select>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              "@media (maxWidth: 468px)": { marginTop: "30px" },
            }}>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant='h6'
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}>
                Applied{" "}
                {jobCandidateApply?.length > 0 ? jobCandidateApply?.length : ""}
              </Typography>
              <Button
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  width: "20px",
                  height: "20px",
                  "@media (maxWidth: 468px)": {
                    width: "16px",
                    height: "16px",
                    top: "10px",
                  },
                }}
                onClick={seeMoreOpen}>
                {jobCandidateApply?.length > 0 &&
                jobCandidateApply?.length !== numberOfOpenJobs ? (
                  <Box
                    component='img'
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (maxWidth: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={dropDown}
                  />
                ) : (
                  <Box
                    component='img'
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (maxWidth: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={UpArrow}
                  />
                )}
              </Button>
              {jobCandidateApply?.length > 0 &&
                jobCandidateApply?.length === numberOfOpenJobs && (
                  <Button
                    sx={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      width: "20px",
                      height: "20px",
                      "@media (maxWidth: 468px)": {
                        width: "16px",
                        height: "16px",
                        top: "10px",
                      },
                    }}
                    onClick={seeLessOpen}>
                    <Box
                      component='img'
                      sx={{
                        width: "20px",
                        height: "20px",
                        "@media (maxWidth: 468px)": {
                          width: "16px",
                          height: "16px",
                        },
                      }}
                      src={UpArrow}
                    />
                  </Button>
                )}
            </Box>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}>
              {jobCandidateApply?.length > 0 &&
                jobCandidateApply
                  ?.slice(0, numberOfOpenJobs)
                  ?.map((list, index) => {
                    return (
                      <Grid item xs={12} sm={6} key={index}>
                        <Box className={classes.jobbox}>
                          <JobCandidateCardnew data={list} />
                        </Box>
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              "@media (maxWidth: 468px)": { marginTop: "30px" },
            }}>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant='h6'
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}>
                Shortlisted{" "}
                {jobCandidateShortlist?.length > 0
                  ? jobCandidateShortlist?.length
                  : ""}
              </Typography>
              <Button
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  width: "20px",
                  height: "20px",
                  "@media (maxWidth: 468px)": {
                    width: "16px",
                    height: "16px",
                    top: "10px",
                  },
                }}>
                {" "}
                <Box
                  component='img'
                  sx={{
                    width: "20px",
                    height: "20px",
                    "@media (maxWidth: 468px)": {
                      width: "16px",
                      height: "16px",
                    },
                  }}
                  src={dropDown}
                  onClick={seeMoreInactive}
                />
              </Button>
            </Box>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}>
              {jobCandidateShortlist?.length > 0 &&
                jobCandidateShortlist
                  ?.slice(0, numberOfOpenJobs)
                  ?.map((list, index) => {
                    return (
                      <Grid item xs={12} sm={6} key={index}>
                        <Box className={classes.jobbox}>
                          <JobCandidateCardnew data={list} />
                        </Box>
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              "@media (maxWidth: 468px)": { marginTop: "30px" },
            }}>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant='h6'
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}>
                Hold{" "}
                {jobCandidateHold?.length > 0 ? jobCandidateHold?.length : ""}
              </Typography>
              <Button
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  width: "20px",
                  height: "20px",
                  "@media (maxWidth: 468px)": {
                    width: "16px",
                    height: "16px",
                    top: "10px",
                  },
                }}>
                {" "}
                <Box
                  component='img'
                  sx={{
                    width: "20px",
                    height: "20px",
                    "@media (maxWidth: 468px)": {
                      width: "16px",
                      height: "16px",
                    },
                  }}
                  src={dropDown}
                  onClick={seeMoreClosed}
                />
              </Button>
            </Box>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}>
              {jobCandidateHold?.length > 0 &&
                jobCandidateHold
                  ?.slice(0, numberOfClosedJobs)
                  ?.map((list, index) => {
                    return (
                      <Grid item xs={12} sm={6} key={index}>
                        <Box className={classes.jobbox}>
                          <JobCandidateCardnew data={list} />
                        </Box>
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              "@media (maxWidth: 468px)": { marginTop: "30px" },
            }}>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant='h6'
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                  marginBottom: "20px",
                }}>
                Rejected{" "}
                {jobCandidateReject?.length > 0
                  ? jobCandidateReject?.length
                  : ""}
              </Typography>
              <Button
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  width: "20px",
                  height: "20px",
                  "@media (maxWidth: 468px)": {
                    width: "16px",
                    height: "16px",
                    top: "10px",
                  },
                }}>
                {" "}
                <Box
                  component='img'
                  sx={{
                    width: "20px",
                    height: "20px",
                    "@media (maxWidth: 468px)": {
                      width: "16px",
                      height: "16px",
                    },
                  }}
                  src={dropDown}
                  onClick={seeMoreRejected}
                />
              </Button>
            </Box>
            <Grid
              container
              direction='row'
              spacing={0}
              justify='center'
              alignItems='center'
              columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}>
              {jobCandidateReject?.length > 0 &&
                jobCandidateReject
                  ?.slice(0, noOfrejectedcandi)
                  ?.map((list, index) => {
                    return (
                      <Grid item xs={12} sm={6} key={index}>
                        <Box className={classes.jobbox}>
                          <JobCandidateCardnew data={list} />
                        </Box>
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default JobCandidateList;
