import React from "react";
import { Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IndustryBg from "../../../assets/images/IndustryVerticalsBG.png";
import Automotive from "../../../assets/images/Automotive.png";
import Digital from "../../../assets/images/Digital.png";
import Industrial from "../../../assets/images/Industrial.png";
import ChemicalIndustry from "../../../assets/images/ChemicalIndustry.png";
import InfrastructurePower from "../../../assets/images/Infrastructure&Power.png";
import FinancialServices from "../../../assets/images/FinancialServices.png";
import Healthcare from "../../../assets/images/Healthcare.png";
const useStyles = makeStyles((theme) => ({
  mainIndustry: {
    backgroundImage: `url(${IndustryBg})`,
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    padding: "80px 100px 50px",
    margin: "0 auto",
  },
  gridcontainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    margin: "0 auto",
  },
  title: {
    color: "#223870",
    fontSize: "34px",
    textAlign: "center",
    fontWeight: "600",
    marginBottom: "10px",
  },
  subtitle: {
    color: "#5677B9",
    fontSize: "22px",
    textAlign: "center",
    fontWeight: "500",
    marginBottom: "30px",
  },
  image: {
    width: "180px",
    height: "180px",
    marginTop: "50px",
  },

  text: {
    marginTop: "-15px",
    color: "#4B4FD9",
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "20px",
  },
  "@media (maxWidth: 468px)": {
    text: {
      fontSize: "16px",
      lineHeight: "18px",
    },
    mainIndustry: { padding: "85px 15px 30px" },
    title: {
      fontSize: "26px",
      lineHeight: "30px",
    },
    subtitle: {
      marginTop: "10px",
      fontSize: "18px",
      lineHeight: "22px",
    },
    heading: {
      fontSize: "20px",
      lineHeight: "22px",
    },
    image: {
      width: "120px",
      height: "120px",
      marginTop: "50px",
    },
  },
}));

export default function IndustryVerticals() {
  const classes = useStyles();
  return (
    <>
      <Box sx={{ flexGrow: 1 }} className={classes.mainIndustry} id='industry'>
        <Box className={classes.title}>Industry Verticals</Box>

        <Grid
          justify='center'
          alignItems='center'
          container
          className={classes.gridcontainer}>
          {" "}
          <Grid item xs={6} sm={3} className={classes.content}>
            <Box component='img' src={Automotive} className={classes.image} />
            <Box className={classes.text}>Automotive</Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.content}>
            <Box component='img' src={Digital} className={classes.image} />
            <Box className={classes.text}>Digital</Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.content}>
            <Box component='img' src={Industrial} className={classes.image} />
            <Box className={classes.text}>Industrial</Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.content}>
            <Box
              component='img'
              src={ChemicalIndustry}
              className={classes.image}
            />
            <Box className={classes.text}>Chemical Industry</Box>
          </Grid>
        </Grid>
        <Grid
          container
          justify='center'
          alignItems='center'
          className={classes.gridcontainer}>
          {" "}
          <Grid item xs={12} sm={1} className={classes.content}></Grid>
          <Grid item xs={6} sm={3} className={classes.content}>
            <Box
              component='img'
              src={InfrastructurePower}
              className={classes.image}
            />
            <Box className={classes.text}>Infrastructure & Power</Box>
          </Grid>
          <Grid item xs={6} sm={4} className={classes.content}>
            <Box
              component='img'
              src={FinancialServices}
              className={classes.image}
            />
            <Box className={classes.text}>Financial Services</Box>
          </Grid>
          <Grid item xs={6} sm={3} className={classes.content}>
            <Box component='img' src={Healthcare} className={classes.image} />
            <Box className={classes.text}>Healthcare</Box>
          </Grid>
          <Grid item xs={12} sm={1} className={classes.content}></Grid>
        </Grid>
      </Box>
    </>
  );
}
