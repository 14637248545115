import React, { useEffect } from "react";
import { Grid, Box, Typography, Tooltip, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../../components/Header/Navbar";
import CustomizedSnackbars from "../../../../components/Snackbar/CustomizedSnackbars";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import jobdescription from "../../../../assets/Icons/JobDescription.png";
import aboutCompany from "../../../../assets/Icons/AboutTheCompany.png";
import JobCardCompanyJd from "../../../../components/jobCards/Company/topCardCompanyJd";
// import JobCardCompanyJdMobile from "../../../../components/jobCards/Company/topCardCompanyJdMobile";
import JobCandidateList from "./JobCandidateList";
import { saveAs } from "file-saver";
import * as jobsActions from "../../../../Store/Jobs";
const useStyles = makeStyles((theme) => ({
  li: {
    marginBottom: "10px",
    marginLeft: "40px",
  },
  mainHome: {
    paddingTop: "80px",
    backgroundSize: "cover",
    backgroundColor: "#F6F8FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },

  container: {
    padding: "0 75px",
  },
  maincard: {
    height: "auto",
    marginBottom: "20px",
  },

  topbar: {
    padding: "20px",
    marginTop: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },
  jobbox: {
    padding: "20px",
    borderRadius: "10px",
    height: "auto",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },
  descriptionbox: {
    padding: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    backgroundColor: "#DFE5F6",
  },
  aboutbox: {
    padding: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    backgroundColor: "#DFE5F6",
  },

  "@media (min-width: 1600px)": {
    mainHome: {
      marginTop: "80px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
    topbar: {
      marginTop: "-150px",
    },
  },
  "@media (maxWidth: 468px)": {
    mainHome: {
      padding: "50px 10px",
    },
    search: {
      marginRight: "0",
      padding: "3px 10px",
    },
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "35px",
    },
    container: {
      padding: "0 0px",
    },
    topbar: {
      marginTop: "50px",
      padding: "20px 5px",
    },
    descriptionbox: {
      padding: "20px 5px",
    },
  },
}));

const CompanyJD = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let params = useParams();
  const dispatch = useDispatch();

  const jobDetails = useSelector((state) => state?.jobs?.jobDetails);
  useSelector((state) => state?.jobs?.candidateJobStatus);
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  useSelector((state) => state?.auth?.candidateProfile);
  useSelector((state) => state?.auth?.profile);
  const usertype = useSelector((state) => state?.auth?.usertype);
  useSelector((state) => state?.jobs?.job_creator);
  useEffect(() => {
    console.log("keywords", params);
  }, [params]);

  useEffect(() => {
    async function myStatusDetails() {
      const payload = {
        job_id: params?.id,
      };
      await dispatch(jobsActions.getCandidateJobAppliedStatus(payload));
    }
    myStatusDetails();
  }, [dispatch, params?.id]);

  const showPdf = () => {
    saveAs(jobDetails?.job_description, "job_description.pdf");
  };
  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.mainHome}>
        <Box className={classes.container}>
          <Box className={classes.maincard}>
            <Box
              className={classes.topbar}
              sx={{
                "@media (maxWidth: 468px)": {
                  display: "none",
                },
              }}>
              <JobCardCompanyJd />
            </Box>

            <Box className={classes.descriptionbox}>
              {" "}
              <Grid container sx={{ flexGrow: 1 }}>
                <Grid item xs={1} sm={1} pr={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title='Job Description'
                    placement='left'
                    arrow>
                    <IconButton>
                      {" "}
                      <Box
                        component='img'
                        sx={{
                          width: "20px",
                          height: "20px",
                          marginTop: "-2px",
                          "@media (maxWidth: 468px)": {
                            marginLeft: "-5px",
                            marginTop: "-5px",
                            width: "16px",
                            height: "16px",
                          },
                          "@media (min-width: 1600px)": { marginTop: "-3px" },
                        }}
                        src={jobdescription}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={3} sm={3} pl={0}>
                  <Typography
                    variant='h6'
                    sx={{
                      "@media (min-width: 1500px)": { marginLeft: "-83px" },
                      "@media (min-width: 1550px)": { marginLeft: "-85px" },
                      "@media (min-width: 1600px)": { marginLeft: "-70px" },
                      "@media (min-width: 1680px)": { marginLeft: "-93px" },
                      "@media (min-width: 1780px)": { marginLeft: "-105px" },
                      "@media (min-width: 1900px)": { marginLeft: "-108px" },
                      "@media (min-width: 2000px)": { marginLeft: "-118px" },
                      "@media (min-width: 2200px)": { marginLeft: "-138px" },
                      "@media (min-width: 2400px)": { marginLeft: "-145px" },
                      "@media (min-width: 2500px)": { marginLeft: "-148px" },
                      marginLeft: "-60px",
                      fontSize: "18px",
                      textAlign: "left",
                      color: "#304672",
                      "@media (maxWidth: 468px)": {
                        fontSize: "16px",
                        marginLeft: "0",
                      },
                    }}>
                    Job Description
                  </Typography>{" "}
                </Grid>
                <Grid item xs={8} sm={8}></Grid>
              </Grid>
              {jobDetails?.job_description?.includes("https://exotalent-cv") ? (
                <Box pl={1} onClick={showPdf}>
                  <Typography
                    variant='p'
                    sx={{
                      textAlign: "left",
                      color: "#5677B9",
                      fontWeight: "500",
                      marginBottom: "20px",
                      "@media (maxWidth: 468px)": {
                        fontSize: "14px",
                      },
                      cursor: "pointer",
                    }}>
                    Download JD
                  </Typography>
                </Box>
              ) : (
                <Box px={1.5} onClick={showPdf}>
                  <Typography
                    variant='p'
                    sx={{
                      textAlign: "left",
                      color: "#5677B9",
                      fontWeight: "500",
                      marginBottom: "20px",
                      "@media (maxWidth: 468px)": {
                        fontSize: "14px",
                      },
                    }}>
                    {jobDetails?.job_description}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box className={classes.descriptionbox}>
              {" "}
              <Grid container>
                <Grid item xs={1} sm={1} pr={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title='Location'
                    placement='left'
                    arrow>
                    <IconButton>
                      {" "}
                      <Box
                        component='img'
                        sx={{
                          marginTop: "-4px",
                          width: "18px",
                          height: "18px",
                          "@media (maxWidth: 468px)": {
                            marginLeft: "-5px",
                            marginTop: "-5px",
                            width: "16px",
                            height: "16px",
                          },
                          "@media (min-width: 1600px)": { marginTop: "-3px" },
                        }}
                        src={aboutCompany}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={11} sm={11}>
                  <Typography
                    variant='h6'
                    sx={{
                      "@media (min-width: 1500px)": { marginLeft: "-83px" },
                      "@media (min-width: 1550px)": { marginLeft: "-85px" },
                      "@media (min-width: 1600px)": { marginLeft: "-83px" },
                      "@media (min-width: 1680px)": { marginLeft: "-93px" },
                      "@media (min-width: 1780px)": { marginLeft: "-105px" },
                      "@media (min-width: 1900px)": { marginLeft: "-108px" },
                      "@media (min-width: 2000px)": { marginLeft: "-118px" },
                      "@media (min-width: 2200px)": { marginLeft: "-138px" },
                      "@media (min-width: 2400px)": { marginLeft: "-145px" },
                      "@media (min-width: 2500px)": { marginLeft: "-148px" },
                      marginLeft: "-60px",
                      fontSize: "18px",
                      textAlign: "left",
                      color: "#304672",
                      "@media (maxWidth: 468px)": {
                        fontSize: "16px",
                        marginLeft: "0",
                      },
                    }}>
                    About The Company
                  </Typography>{" "}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box px={2}>
                    <Typography variant='p' sx={{ color: "#5677B9" }}>
                      {jobDetails?.about_company}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {usertype === "company" && <JobCandidateList />}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default CompanyJD;
