import { styled } from "@mui/system";
import Chip from "@mui/material/Chip";

export const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: "15px",
  fontSize: "14px",
  fontWeight: 500,
  backgroundColor: "#E0DFF4",
  color: "#5071C4",
  padding: "6px",
  margin: "4px",
  "&:hover": {
    backgroundColor: "#E0DFF4",
  },
}));
