import React, { Fragment, useState, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import { HashLink as Link } from "react-router-hash-link";
import DrawerComponent from "./Drawer";
import Logo from "../../assets/images/LogowithTagline.png";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginLeft: "-1px",
    borderRadius: "3px",
    marginTop: theme.spacing(6),
    minWidth: 114,

    "& .MuiMenu-list": {
      padding: "0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(0),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const useStyles = makeStyles((theme) => ({
  appbarnavstyle: {
    padding: "0 47px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },

  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },

  img: {
    marginTop: "12px",
    width: "240px",
    height: "auto",
  },

  menu: {
    textDecoration: "none",
    textTransform: "none",
    fontWeight: "500",
    fontSize: "16px",
    padding: "0 15px",
    color: "#223870",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "none",
      color: "#223870",
    },
  },
  menu1: {
    textDecoration: "none",
    textTransform: "none",
    fontWeight: "500",
    fontSize: "16px",
    padding: "5px 15px",
    borderRadius: "4px",
    color: "#fff",
    backgroundColor: "#4B4FD9",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#4B4FD9",
      color: "#fff",
    },
  },
  "@media (min-width: 900px)": {
    logo1: {
      display: "none",
    },
    img1: {
      display: "none",
    },
  },
  "@media (max-width: 900px)": {
    logo1: {
      float: "right",
      marginLeft: "70%",
      flexGrow: "1",
      cursor: "pointer",
    },
    logo: {
      display: "none",
    },
    img1: {
      marginTop: "12px",
      width: "200px",
      height: "auto",
    },
  },
  "@media (max-width: 680px)": {
    logo1: {
      float: "right",
      marginLeft: "55%",
      flexGrow: "1",
      cursor: "pointer",
    },
    logo: {
      display: "none",
    },
    img1: {
      marginTop: "12px",

      width: "200px",
      height: "auto",
    },
  },
  "@media (maxWidth: 468px)": {
    logo1: {
      // float: "right",
      marginLeft: "45%",
      flexGrow: "1",
      cursor: "pointer",
    },
    appbarnavstyle: {
      paddingLeft: "0",
    },

    img1: {
      marginTop: "12px",
      width: "180px",
      height: "auto",
    },
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  const loggedInCandidate = useSelector((state) => state?.candidate?.loggedIn);
  const usertypeCandidate = useSelector((state) => state?.candidate?.usertype);
  const loggedInCompany = useSelector((state) => state?.company?.loggedIn);
  const usertypeCompany = useSelector((state) => state?.company?.usertype);
  const handleClickLogout = async () => {
    const logoddt = await dispatch({ type: "logout" });
    navigate("/");
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickCompanyProfile = async () => {
    navigate("/company/profile");
  };
  const handleClickCandidateProfile = async () => {
    navigate("/candidate/profile");
  };
  const handleClickRecruiterProfile = async () => {
    navigate("/recruiter/profile");
  };
  const headerButton = () => {
    if (usertypeCandidate === "candidate") {
      if (location?.pathname === "/candidate/profile") {
        return (
          <Button
            sx={{
              textTransform: "none",
              textDecoration: "none",
              padding: "3px 25px",
              borderRadius: "3px ",
              color: "#FDCF57",
              marginLeft: "20px",
              fontWeight: "600",
              fontSize: "18px",
              backgroundColor: "#223870",
              "&:hover": {
                backgroundColor: "#223870",
                color: "#FDCF57 ",
              },
              "@media (max-width: 1100px)": {
                padding: "3px 15px",
              },
            }}
            onClick={handleClickLogout}>
            Logout
          </Button>
        );
      } else {
        return (
          <Button
            sx={{
              textTransform: "none",
              textDecoration: "none",
              padding: "3px 25px",
              borderRadius: "3px ",
              color: "#FDCF57",
              marginLeft: "20px",
              fontWeight: "600",
              fontSize: "18px",
              backgroundColor: "#223870",
              "&:hover": {
                backgroundColor: "#223870",
                color: "#FDCF57 ",
              },
              "@media (max-width: 1100px)": {
                padding: "3px 15px",
              },
            }}
            onClick={handleClickCandidateProfile}>
            Profile
          </Button>
        );
      }
    } else if (usertypeCandidate === "recruiter") {
      if (location?.pathname === "/recruiter/profile") {
        return (
          <Button
            sx={{
              textTransform: "none",
              textDecoration: "none",
              padding: "3px 25px",
              borderRadius: "3px ",
              color: "#FDCF57",
              marginLeft: "20px",
              fontWeight: "600",
              fontSize: "18px",
              backgroundColor: "#223870",
              "&:hover": {
                backgroundColor: "#223870",
                color: "#FDCF57 ",
              },
              "@media (max-width: 1100px)": {
                padding: "3px 15px",
              },
            }}
            onClick={handleClickLogout}>
            Logout
          </Button>
        );
      } else {
        return (
          <Button
            sx={{
              textTransform: "none",
              textDecoration: "none",
              padding: "3px 25px",
              borderRadius: "3px ",
              color: "#FDCF57",
              marginLeft: "20px",
              fontWeight: "600",
              fontSize: "18px",
              backgroundColor: "#223870",
              "&:hover": {
                backgroundColor: "#223870",
                color: "#FDCF57 ",
              },
              "@media (max-width: 1100px)": {
                padding: "3px 15px",
              },
            }}
            onClick={handleClickRecruiterProfile}>
            Profile
          </Button>
        );
      }
    } else if (usertypeCompany === "company") {
      if (location?.pathname === "/company/profile") {
        return (
          <Button
            sx={{
              textTransform: "none",
              textDecoration: "none",
              padding: "3px 25px",
              borderRadius: "3px ",
              color: "#FDCF57",
              marginLeft: "20px",
              fontWeight: "600",
              fontSize: "18px",
              backgroundColor: "#223870",
              "&:hover": {
                backgroundColor: "#223870",
                color: "#FDCF57 ",
              },

              "@media (max-width: 1100px)": {
                padding: "3px 15px",
              },
            }}
            onClick={handleClickLogout}>
            Logout
          </Button>
        );
      } else {
        return (
          <Button
            sx={{
              textTransform: "none",
              textDecoration: "none",
              padding: "3px 25px",
              borderRadius: "3px ",
              color: "#FDCF57",
              marginLeft: "20px",
              fontWeight: "600",
              fontSize: "18px",
              backgroundColor: "#223870",
              "&:hover": {
                backgroundColor: "#223870",
                color: "#FDCF57 ",
              },

              "@media (max-width: 1100px)": {
                padding: "3px 15px",
              },
            }}
            onClick={handleClickCompanyProfile}>
            Profile
          </Button>
        );
      }
    } else if (usertypeCompany || usertypeCandidate === "guest") {
      return (
        <Fragment>
          {" "}
          <Button
            sx={{
              textTransform: "none",
              textDecoration: "none",
              padding: "3px 25px",
              borderRadius: "3px ",
              color: "#FDCF57",
              marginLeft: "20px",
              fontWeight: "600",
              fontSize: "18px",
              backgroundColor: "#223870",
              "&:hover": {
                backgroundColor: "#223870",
                color: "#FDCF57 ",
              },

              "@media (max-width: 1100px)": {
                padding: "3px 15px",
              },
            }}
            endIcon={<KeyboardArrowDownIcon />}
            onClick={handleClick}>
            Join As
          </Button>
          <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem
              sx={{
                backgroundColor: "#fff",
                color: "#5677B9 ",
                fontWeight: "500",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#223870",
                  color: "#FDCF57 ",
                },
              }}
              onClick={() => {
                navigate("/company/login");
              }}>
              Company
            </MenuItem>
            <MenuItem
              sx={{
                backgroundColor: "#fff",
                color: "#5677B9 ",
                fontWeight: "500",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "#223870",
                  color: "#FDCF57 ",
                },
              }}
              onClick={() => {
                navigate("/candidate/login");
              }}>
              Candidate
            </MenuItem>
          </StyledMenu>
        </Fragment>
      );
    }
  };

  const [activeComponent, setActiveComponent] = useState("home");

  const components = [
    { name: "home", range: [0, 499] },
    { name: "aboutus", range: [500, 999] },
    { name: "services", range: [1000, 1520] },
    { name: "industry", range: [1520, 2500] },
    { name: "clients", range: [2500, 3300] },
  ];

  const calculateActiveComponent = (scrollPosition) => {
    for (const component of components) {
      const [start, end] = component.range;
      if (scrollPosition >= start && scrollPosition <= end) {
        return component.name;
      }
    }
    return "home";
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const activeComponent = calculateActiveComponent(scrollPosition);
    setActiveComponent(activeComponent);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <AppBar
      className={classes.appbarnavstyle}
      position='fixed'
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "#E9E8F6",
      }}>
      <CssBaseline />
      <Toolbar>
        <Box className={classes.logo}>
          {" "}
          <Link to='/#home' smooth>
            {" "}
            <Box component='img' className={classes.img} src={Logo} />
          </Link>
        </Box>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div>
            <Button>
              <Link
                to='/#aboutus'
                className={
                  activeComponent === "aboutus" ? classes.menu1 : classes.menu
                }>
                About Us
              </Link>
            </Button>
            <Button>
              <Link
                to='/#services'
                className={
                  activeComponent === "services" ? classes.menu1 : classes.menu
                }>
                Services
              </Link>
            </Button>
            <Button>
              <Link
                to='/#industry'
                className={
                  activeComponent === "industry" ? classes.menu1 : classes.menu
                }>
                Industry Verticals
              </Link>
            </Button>
            <Button>
              <Link
                to='/#clients'
                className={
                  activeComponent === "clients" ? classes.menu1 : classes.menu
                }>
                Clients
              </Link>
            </Button>
            {location.pathname.includes("/Jobs") ? (
              <Button>
                {usertypeCompany !== "company" && (
                  <Link
                    to='/JobsPage?page_no=0'
                    className={classes.menu1}
                    onClick={scrollToTop}>
                    Jobs
                  </Link>
                )}
              </Button>
            ) : (
              <Button>
                {usertypeCompany !== "company" && (
                  <Link
                    to='/JobsPage?page_no=0'
                    className={classes.menu}
                    onClick={scrollToTop}>
                    Jobs
                  </Link>
                )}
              </Button>
            )}

            {loggedInCandidate || loggedInCompany ? (
              headerButton()
            ) : (
              <Fragment>
                {" "}
                <Button
                  sx={{
                    textTransform: "none",
                    textDecoration: "none",
                    padding: "4px 15px",
                    borderRadius: "3px ",
                    color: "#FDCF57",
                    marginLeft: "20px",
                    fontWeight: "600",
                    fontSize: "18px",
                    backgroundColor: "#223870",
                    "&:hover": {
                      backgroundColor: "#223870",
                      color: "#FDCF57 ",
                    },

                    "@media (max-width: 1100px)": {
                      padding: "3px 15px",
                    },
                  }}
                  endIcon={<KeyboardArrowDownIcon />}
                  onClick={handleClick}>
                  Join As
                </Button>
                <StyledMenu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}>
                  <MenuItem
                    sx={{
                      backgroundColor: "#E9E8F6",
                      color: "#5677B9 ",
                      fontWeight: "500",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor: "#223870",
                        color: "#FDCF57 ",
                      },
                    }}
                    onClick={() => {
                      navigate("/company/login");
                    }}>
                    Company
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: "#E9E8F6",
                      color: "#5677B9 ",
                      fontWeight: "500",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor: "#223870",
                        color: "#FDCF57 ",
                      },
                    }}
                    onClick={() => {
                      navigate("/candidate/login");
                    }}>
                    Candidate
                  </MenuItem>
                  <MenuItem
                    sx={{
                      backgroundColor: "#E9E8F6",
                      color: "#5677B9 ",
                      fontWeight: "500",
                      fontSize: "16px",
                      "&:hover": {
                        backgroundColor: "#223870",
                        color: "#FDCF57 ",
                      },
                    }}
                    onClick={() => {
                      navigate("/recruiter/login");
                    }}>
                    Recruiter
                  </MenuItem>
                </StyledMenu>
              </Fragment>
            )}
          </div>
        )}
        <Box className={classes.logo1}>
          {" "}
          <Link to='/#home' smooth>
            {" "}
            <Box component='img' className={classes.img1} src={Logo} />
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
