import { Box, Typography, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TwitterW from "../../assets/Icons/TwitterWhite.png";
import LinkedInW from "../../assets/Icons/LinkedInWhite.png";

const useStyles = makeStyles((theme) => ({
  back: {
    backgroundColor: "#223870",
    height: "auto",
    padding: "20px 50px",
    width: "100%",
    margin: "0",
  },

  city: {
    color: "#FDCF57",
    fontSize: "26px",
    fontWeight: "500",
  },
  address: {
    color: "#fff",
    fontSize: "16px",
    marginTop: "5px",
    paddingRight: "10px",
  },
  phone: {
    color: "#fff",
    fontSize: "16px",
    marginTop: "10px",
  },
  email: { color: "#fff", fontSize: "16px", marginLeft: "0px" },
  tc: { color: "#fff", fontSize: "14px", margin: "0" },
  mediaicon: {
    width: "30px",
    height: "30px",
  },
  "@media (maxWidth: 468px)": {
    back: {
      padding: "5px",
      width: "100%",
      margin: "0",
    },
    mediaicon: {
      marginLeft: "10px",
      marginRight: "20px",
    },
    gridcontainer: {
      width: "100%",
      margin: "0",
      padding: "20px 0px",
    },
    tc: { fontSize: "12px", margin: "0 10px" },
    city: {
      fontSize: "20px",
      // marginLeft: "10px",
    },
    address: {
      fontSize: "14px",
      paddingRight: "0",
      marginLeft: "10px",
    },
    phone: {
      fontSize: "14px",
      marginTop: "8px",
      // marginLeft: "10px",
    },
    email: { fontSize: "14px", marginLeft: "10px" },
  },
}));
const Footer = () => {
  const classes = useStyles();
  return (
    <Box sx={{ flexGrow: 1 }} className={classes.back} id='contactus'>
      {" "}
      <Typography
        variant='h4'
        gutterBottom
        component='div'
        className={classes.contact}
        sx={{
          textAlign: "center",
          color: "#fff",
          fontWeight: 600,
          lineHeight: "32px",
          fontSize: "36px",
          "@media (maxWidth: 468px)": { fontSize: "28px" },
        }}>
        Contact Us
      </Typography>
      <Stack direction='row' justifyContent='center' spacing={3}>
        <Box>
          {" "}
          <Box className={classes.city}>Mumbai</Box>{" "}
          <Box className={classes.address}>
            211, 2nd Floor, B Wing, Western Edge II, Behind Metro Super Market,
            Near Magathane, Borivali, Mumbai, Maharashtra - 400066.
          </Box>{" "}
          <Box className={classes.phone}>Phone: +91 9930408319</Box>
        </Box>
        <Box>
          <Box className={classes.city}> Navi Mumbai</Box>{" "}
          <Box className={classes.address}>
            AWFIS , 2nd Floor, Raghuleela, Arcade Vishwaroop, Infotech Pvt.
            Ltd., Plot No-34, 35, 38, Navi Mumbai, Maharashtra - 400703
          </Box>{" "}
          <Box className={classes.phone}>Phone: +91 9137779517</Box>
        </Box>
        <Box>
          {" "}
          <Box className={classes.city}>Pune</Box>{" "}
          <Box className={classes.address}>
            Solitaire Business Hub, Unit no. 6070B, H Wing, opp. NECO Garden
            Society, Clover Park, Viman Nagar, Pune, Maharashtra - 411014
          </Box>{" "}
          <Box className={classes.phone}>Phone: +91 9930408319</Box>
        </Box>
        <Box>
          {" "}
          <Box className={classes.city}>Bengaluru</Box>{" "}
          <Box className={classes.address}>
            133/1, 4th Floor, Yashkesh Tower, 10th Main, 14th Cross, 6th Sector, HSR Layout, Bengaluru Karnataka - 560102.
          </Box>{" "}
          <Box className={classes.phone}>Phone: +91 9513007253</Box>
        </Box>
        <Box>
          {" "}
          <Box className={classes.city}>Delhi/NCR</Box>{" "}
          <Box className={classes.address}>
          Awfis Majestic Signia, 1st Floor, Majestic Signia, Plot No. A-27, Block A, Industrial Area, Sector 62, Noida, Uttar Pradesh - 201309
          </Box>{" "}
          <Box className={classes.phone}>Phone: +91 9871311206</Box>{" "}
        </Box>
        <Box>
          {" "}
          <Box className={classes.city}>Email</Box>
          <Box className={classes.email}>info@exotalent.in</Box>{" "}
          <Box className={classes.email}>career@exotalent.in</Box>
          <Box sx={{ marginLeft: "30px", marginTop: "10px" }}>
            <Stack direction='row' spacing={2}>
              <a
                target='blank'
                href='https://twitter.com/exotalentindia'
                style={{ color: "white" }}>
                <Box
                  width='30px'
                  height='30px'
                  component='img'
                  src={TwitterW}
                />{" "}
              </a>{" "}
              <a
                target='blank'
                href='https://www.linkedin.com/company/exotalent-consultancy-services-llp/?originalSubdomain=in'
                style={{ color: "white" }}>
                <Box
                  width='30px'
                  height='30px'
                  component='img'
                  src={LinkedInW}
                />
              </a>
            </Stack>
          </Box>
        </Box>
      </Stack>
      <Box className={classes.tc} borderBottom={1} py={{ xs: 5, sm: 4 }}></Box>
      <Box className={classes.tc} textAlign='left' py={{ xs: 5, sm: 1 }}>
        &copy;&nbsp;2022 &nbsp;exotalent.in&nbsp;|&nbsp; All Rights Reserved
        &nbsp;|&nbsp;{" "}
        <a href='/' style={{ color: "white" }}>
          Privacy Policy
        </a>{" "}
        &nbsp;|&nbsp;{" "}
        <a href='/' style={{ color: "white" }}>
          Terms Of Use
        </a>
      </Box>
    </Box>
  );
};
export default Footer;
