import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  feedbackbox: {
    textAlign: "left",
    padding: "20px 30px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#DFE5F6",
  },

  "@media (min-width: 1600px)": {},
  "@media (maxWidth: 468px)": {},
}));

const FeedbackCard = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.feedbackbox}>
        <Box className={classes.heading}>
          {" "}
          <Typography variant='h4' gutterBottom sx={{ color: "#223870" }}>
            Round 1:
          </Typography>
        </Box>
        <Box className={classes.subheading}>
          {" "}
          <Typography variant='h6' gutterBottom sx={{ color: "#223870" }}>
            Panel: Param Singh, Roopa Kumar
          </Typography>
        </Box>
        <Box className={classes.content}>
          {" "}
          <Typography variant='p' gutterBottom sx={{ color: "#223870" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default FeedbackCard;
