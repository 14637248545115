import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  Dialog,
  FormControlLabel,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Autocomplete,
} from "@mui/material";
//revert old code
import { TextareaAutosize } from "@mui/base";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormInput } from "../../components/Input/FormInput";
import CustomizedSnackbars from "../../components/Snackbar/CustomizedSnackbars";
import Close from "../../assets/Icons/Close.png";
import formBg from "../../assets/Icons/mobileFormBG.png";
import { useNavigate } from "react-router-dom";
import { countryListnew } from "../../assets/Data/data";
import {
  RegistrationType,
  CompanyType,
  CompanySize,
  industryList1,
  functionList1,
} from "../../assets/Data/data";
import HomeBg from "../../assets/images/loginbg.png";
import { makeStyles } from "@mui/styles";
import Navbar from "../../components/Header/Navbar";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import * as companyActions from "../../Store/company";
import OtpInput from "react-otp-input";
import OtpTimer from "../../components/OTP_Timer/OtpTimer";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  containerbg: {
    marginTop: "-15px",
    backgroundImage: `url(${HomeBg})`,
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
    display: "block",
    backgroundPosition: "center center",
    position: "static",
    overflow: "auto",
    justifyContent: "center",
    alignItems: "center",
  },
  grid: {
    position: "absolute",
  },
  form: {
    paddingTop: "80px",
    paddingRight: "100px",
    position: "relative",
    width: "100%",
    height: "100vh",
    overflowY: "scroll",
  },

  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "8.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  "@media (maxWidth: 468px)": {
    containerbg: {
      backgroundImage: `url(${formBg})`,
      marginTop: "80px",
    },
    form: {
      margin: "0",
      padding: "0 10px",
      width: "100%",
    },
  },
}));

const CompanyRegister = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: "IN",
    country_name: "India",
    iso3: "IND",
    phone_code: 91,
  });
  const [compType, setCompType] = React.useState();
  const handleCompType = (event) => {
    setCompType(event.target.value);
  };
  const [regType, setRegType] = React.useState();
  const handleRegType = (event) => {
    setRegType(event.target.value);
  };
  const [compSize, setCompSize] = React.useState();
  const handleCompSize = (event) => {
    setCompSize(event.target.value);
  };
  const validateEmail = (value) => {
    const domain = getValues("domain");
    if (!domain) {
      return "Domain name is required";
    }
    const domainRegex = new RegExp(`@${domain}$`, "i");
    return domainRegex.test(value) || "Email must contain the specified domain";
  };
  const validateDomain = (value) => {
    return value ? true : "Domain name is required";
  };
  const [message, setMessage] = React.useState("");
  const [isVerifyDialogOpen, setIsVerifyDialogOpen] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({ mode: "onTouched" });
  const emailId = watch("emailId");
  const [requestId, setRequestId] = React.useState(false);
  const handleClickSendEmailotp = async () => {
    // debugger;
    console.log(emailId);
    if (!errors.emailId) {
      setMessage("");
      const data = {
        emailId: emailId,
      };
      const sendOtp = await dispatch(companyActions.sendCompanyEmailOtp(data));
      if (sendOtp && sendOtp.data && sendOtp.data?.data?.requestId) {
        setRequestId(sendOtp.data?.data?.requestId);

        setIsVerifyDialogOpen(true);
      }
    }
  };
  useSelector((state) => state?.company?.companySignUp);
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const companyEmailVerification = useSelector(
    (state) => state?.company?.companyEmailVerification
  );
  const handleClickVerifyEmail = async () => {
    console.log("otp???", otp);
    setMessage("");
    const data = {
      requestId: requestId,
      otp: Number(otp),
    };
    const verifyotp = await dispatch(
      companyActions.verifyCompanyEmailOtp(data)
    );
    setMessage(verifyotp?.data?.msg);
    setOtp("");
  };
  const handleCloseEmailDialog = async () => {
    await dispatch(companyActions.closeCompanyEmailDialog());
    setIsVerifyDialogOpen(false);
  };

  const [otp, setOtp] = useState("");
  const handleChangeOtp = (otp) => {
    setOtp(otp);
  };
  const [timer, setTimer] = useState(true);
  const timerZero = () => {
    setTimer(false);
  };
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    window.onbeforeunload = async function () {
      await dispatch(companyActions.resetCompanyDetails());
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const [xauth, setXauth] = useState("");
  const handlexAuth = (data) => {
    setXauth(data);
  };

  const [msg, setMsg] = React.useState("");
  useEffect(() => {
    if (companyEmailVerification) {
      setIsVerifyDialogOpen(false);
    }
  }, [companyEmailVerification]);

  const onSubmit = async (details) => {
    console.log("detail", details);

    const data = {
      requestId: requestId,
      firstName: details?.firstName,
      lastName: details?.lastName,
      location: details?.location,
      countryCode: 91,
      phoneNo: Number(details?.mobile),
      title: details?.companyName,
      tagline: details?.tagline,
      emailId: details?.emailId,
      domain: details?.domain,
      industry: details?.industry,
      function: details?.func,
      companytype: details?.companytype,
      size: details?.size,
      registrationType: details?.regtype,
      description: details?.about_company,
      authorization: true,
    };
    console.log(data, "data of company section");
    const signupcompany = await dispatch(companyActions.signupCompany(data));
    console.log(signupcompany, "signupcompany??");
    if (signupcompany.data?.status === 1) {
      const compId = signupcompany?.data?.data?.company?.id;
      setOpen(true);
      await dispatch(companyActions.getCompanyProfile(compId));
      navigate("/company/profile/edit");
    }
    setMsg(signupcompany?.msg);
    console.log("signupcandidate", signupcompany);
  };

  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.containerbg}>
        <Grid container className={classes.grid}>
          <Grid item xs={12} sm={7}></Grid>{" "}
          <Grid item xs={12} sm={5} className={classes.form}>
            <Typography
              variant="h4"
              sx={{
                marginTop: "50px",
                marginBottom: "30px",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              Create Company
            </Typography>
            <Box
              sx={{
                paddingBottom: "40px",
              }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  sm={12}
                  sx={{
                    marginTop: "25px",
                    width: "100%",
                  }}
                >
                  {" "}
                  <Grid
                    item
                    sm={6}
                    md={6}
                    sx={{ width: "50%", paddingRight: "4px" }}
                  >
                    <Grid container direction="column">
                      {" "}
                      <Grid item>
                        {" "}
                        <Typography
                          variant="p"
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}
                        >
                          First Name*
                        </Typography>{" "}
                      </Grid>
                      <Grid item>
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size="small"
                          variant="outlined"
                          type="text"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name="firstName"
                          {...register("firstName", {
                            required: "First name is required",
                            minLength: {
                              value: 1,
                              message: "First name must be atleast 1 character",
                            },
                          })}
                        />
                        {errors.firstName && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.firstName.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={6}
                    sx={{ width: "50%", paddingLeft: "4px" }}
                  >
                    <Grid container direction="column">
                      {" "}
                      <Grid item>
                        {" "}
                        <Typography
                          variant="p"
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}
                        >
                          Last Name*
                        </Typography>{" "}
                      </Grid>
                      <Grid item>
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size="small"
                          variant="outlined"
                          type="text"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name="lastName"
                          {...register("lastName", {
                            required: "Last name is required",
                            minLength: {
                              value: 1,
                              message: "Last name must be atleast 1 character",
                            },
                          })}
                        />
                        {errors.lastName && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.lastName.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid container direction='column'>
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Designation*
                    </Typography>{" "}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}>
                    <FormInput
                      sx={{
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                      }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name='designation'
                      {...register("designation", {
                        required: "Designation is required",
                        minLength: {
                          value: 1,
                        },
                        maxLength: {
                          value: 60,
                          message: "Maximum 60 characters allowed",
                        },
                      })}
                    />
                    {errors.designation && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.designation.message}
                      </Box>
                    )}
                  </Grid>
                </Grid> */}
                <Grid container direction="column">
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}
                    >
                      Designation*
                    </Typography>{" "}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}
                  >
                    <FormInput
                      sx={{
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                      }}
                      size="small"
                      variant="outlined"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name="location"
                      {...register("location", {
                        required: "location is required",
                        minLength: {
                          value: 1,
                        },
                        maxLength: {
                          value: 60,
                          message: "Maximum 60 characters allowed",
                        },
                      })}
                    />
                    {errors.location && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.location.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container direction="column">
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}
                    >
                      Company Name*
                    </Typography>{" "}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}
                  >
                    {" "}
                    <FormInput
                      sx={{
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                      }}
                      size="small"
                      variant="outlined"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name="companyName"
                      {...register("companyName", {
                        required: "Company Name is required",
                        minLength: {
                          value: 3,
                          message: "Company Name must be atleast 3 characters",
                        },
                        maxLength: {
                          value: 60,
                          message: "Maximum 60 characters allowed",
                        },
                      })}
                    />
                    {errors.companyName && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.companyName.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container direction="column">
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}
                    >
                      Tagline*
                    </Typography>{" "}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}
                  >
                    {" "}
                    <FormInput
                      sx={{
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                      }}
                      size="small"
                      variant="outlined"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name="tagline"
                      {...register("tagline", {
                        required: "Tagline is required",
                        minLength: {
                          value: 3,
                          message: "Tagline must be atleast 3 characters",
                        },
                        maxLength: {
                          value: 60,
                          message: "Maximum 60 characters allowed",
                        },
                      })}
                    />
                    {errors.tagline && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.tagline.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container direction="column">
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}
                    >
                      Domain Name*
                      <span
                        style={{
                          color: "#5071C4",
                          fontSize: "12px",
                        }}
                      >
                        (eg. domain name is linkcxo.com for emailId
                        xyz@linkcxo.com)*
                      </span>
                    </Typography>{" "}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}
                  >
                    {" "}
                    <FormInput
                      sx={{
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                      }}
                      size="small"
                      variant="outlined"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name="domain"
                      // {...register("domain", {
                      //   required: "Domain name is required",
                      // })}
                      {...register("domain", { validate: validateDomain })}
                    />
                    {errors.domain && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.domain.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  sm={12}
                  sx={{
                    marginTop: "10px",
                    width: "100%",
                    paddingRight: "8px",
                    "@media (maxWidth: 468px)": {
                      paddingRight: "none",
                    },
                  }}
                >
                  {" "}
                  <Grid item sm={6} md={6} sx={{ width: "50%" }}>
                    <Grid container direction="column">
                      {" "}
                      <Grid item>
                        {" "}
                        <Typography
                          variant="p"
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}
                        >
                          Industry*
                        </Typography>{" "}
                      </Grid>
                      <Grid item>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            width: "100%",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            fontWeight: "500",
                            "@media (maxWidth: 468px)": {
                              paddingRight: "none",
                            },
                          }}
                          name="industry"
                          className={classes.countrycode}
                          {...register("industry", {
                            required: "Select industry ",
                          })}
                        >
                          {industryList1?.map((option, index) => {
                            return (
                              <MenuItem
                                key={option._id}
                                value={option._id}
                                sx={{
                                  color: "#5071C4",
                                  fontWeight: "700",
                                }}
                              >
                                {option?.industry}
                              </MenuItem>
                            );
                          })}
                        </Select>{" "}
                        {errors.industry && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.industry.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} md={6} sx={{ width: "50%" }}>
                    <Grid container direction="column">
                      {" "}
                      <Grid item>
                        {" "}
                        <Typography
                          variant="p"
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                            marginLeft: "10px",
                          }}
                        >
                          Function*
                        </Typography>{" "}
                      </Grid>
                      <Grid item>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            width: "100%",
                            marginLeft: "8px",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            fontWeight: "500",
                          }}
                          name="func"
                          className={classes.countrycode}
                          {...register("func", {
                            required: "Select function ",
                          })}
                        >
                          {functionList1?.map((option) => {
                            return (
                              <MenuItem
                                key={option?._id}
                                value={option?._id}
                                sx={{
                                  color: "#5071C4",
                                  fontWeight: "700",
                                }}
                              >
                                {`${option?.function}`}
                              </MenuItem>
                            );
                          })}
                        </Select>{" "}
                        {errors.func && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.func.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction="Column">
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}
                    >
                      Company Registration Type*
                    </Typography>{" "}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                        fontWeight: "500",
                      }}
                      name="regtype"
                      className={classes.countrycode}
                      value={regType}
                      onChange={handleRegType}
                      {...register("regtype", {
                        required: "Select registration type of your company",
                      })}
                    >
                      {RegistrationType?.map((option) => {
                        return (
                          <MenuItem
                            key={option._id}
                            value={option.value}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}
                          >
                            {option.value}
                          </MenuItem>
                        );
                      })}
                    </Select>{" "}
                    {errors.regtype && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.regtype.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container direction="Column">
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}
                    >
                      Company Type*
                    </Typography>{" "}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                        fontWeight: "500",
                      }}
                      value={compType}
                      onChange={handleCompType}
                      className={classes.countrycode}
                      {...register("companytype", {
                        required: "Select type of your company",
                      })}
                    >
                      {CompanyType?.map((option) => {
                        return (
                          <MenuItem
                            key={option._id}
                            value={option.value}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}
                          >
                            {option.value}
                          </MenuItem>
                        );
                      })}
                    </Select>{" "}
                    {errors.companytype && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.companytype.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container direction="Column">
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}
                    >
                      Size*
                    </Typography>{" "}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}
                  >
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                        fontWeight: "500",
                      }}
                      name="size"
                      value={compSize}
                      onChange={handleCompSize}
                      className={classes.countrycode}
                      {...register("size", {
                        required: "Select size of your company ",
                      })}
                    >
                      {CompanySize?.map((option) => {
                        return (
                          <MenuItem
                            key={option._id}
                            value={option.value}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}
                          >
                            {option?.value}
                          </MenuItem>
                        );
                      })}
                    </Select>{" "}
                    {errors.size && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.size.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>

                <Grid container direction="column">
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}
                    >
                      About Company*
                    </Typography>{" "}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}
                  >
                    {" "}
                    <textarea
                      aria-label="minimum height"
                      rows="4"
                      size="small"
                      variant="outlined"
                      id="outlined-multiline-static"
                      style={{
                        border: "none",
                        borderRadius: "5px",
                        paddingTop: "8px",
                        paddingRight: "8px",
                        paddingLeft: "8px",
                        width: "100%",
                        backgroundColor: "#E0DFF4",
                        color: "#5071C4",
                        overflowY: "scroll",
                        outline: "none",
                      }}
                      fullWidth
                      name="about_company"
                      {...register("about_company", {
                        required: "About Company is required",
                        minLength: {
                          value: 3,
                          message:
                            "About Company must be at least 3 characters",
                        },
                      })}
                    />
                    {errors.about_company && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.about_company.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container direction="Column">
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}
                    >
                      Official Email ID*
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    <Box
                      sm={12}
                      sx={{
                        width: "100%",
                      }}
                    >
                      {" "}
                      <Stack direction="row" spacing={0}>
                        <FormInput
                          size="small"
                          variant="outlined"
                          type="text"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            width: "100%",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                          name="emailId"
                          fullWidth
                          {...register("emailId", {
                            required: "Email is required",
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                            validate: validateEmail,
                          })}
                        />

                        <Box
                          sx={{
                            backgroundColor: "#E0DFF4",
                            padding: "3px 0px",
                            borderRadius: "0 5px 5px 0px",
                          }}
                        >
                          {" "}
                          <Button
                            variant="standard"
                            onClick={handleClickSendEmailotp}
                            disabled={
                              companyEmailVerification || errors.emailId
                            }
                            sx={{
                              marginRight: "5px",
                              backgroundColor: "#223870",
                              borderRadius: "3px",
                              color: companyEmailVerification
                                ? "#FDCF57!important"
                                : "#FDCF57",
                              padding: "2px 20px",
                              fontSize: "16px",
                              textTransform: "capitalize",
                              "&:hover": {
                                color: "#FDCF57",
                                backgroundColor: "#223870",
                              },
                              "@media (maxWidth: 468px)": {
                                textAlign: "center",
                                padding: "0 10px",
                              },
                            }}
                          >
                            {companyEmailVerification ? "Verified" : "Verify"}
                          </Button>
                          <Dialog
                            open={isVerifyDialogOpen}
                            onClose={handleCloseEmailDialog}
                            sx={{
                              textAlign: "center",
                              "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                  width: "100%",
                                  maxWidth: "590px",
                                  height: "430px",
                                  borderRadius: "8px",
                                  backgroundColor: (theme) =>
                                    theme.Palette.background.dialog,
                                },
                              },
                            }}
                          >
                            <DialogActions>
                              <Box
                                onClick={handleCloseEmailDialog}
                                component="img"
                                src={Close}
                                sx={{
                                  top: "10px",
                                  right: "10px",
                                  width: "20px",
                                  height: "20px",
                                  cursor: "pointer",
                                }}
                              />
                            </DialogActions>
                            <DialogTitle
                              sx={{
                                color: "#223870",
                                textAlign: "center",
                                fontSize: "22px",
                                marginTop: "-15px",
                              }}
                            >
                              Verify Email ID
                            </DialogTitle>
                            <DialogContent
                              sx={{
                                marginTop: "20px ",
                                textAlign: "center",
                              }}
                            >
                              {" "}
                              <Box>
                                {" "}
                                <Typography
                                  variant="p"
                                  gutterBottom
                                  sx={{
                                    fontSize: "18px",
                                    color: "#5071C4",
                                    fontWeight: "500",
                                  }}
                                >
                                  OTP sent to your email {emailId}
                                </Typography>{" "}
                              </Box>
                              <Box
                                sx={{
                                  marginTop: "40px",
                                  marginBottom: "10px",
                                }}
                              >
                                {" "}
                                <Typography
                                  variant="h6"
                                  sx={{
                                    color: "#223870",
                                    fontWeight: "500",
                                  }}
                                >
                                  Enter OTP
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <OtpInput
                                  value={otp}
                                  onChange={handleChangeOtp}
                                  numInputs={6}
                                  inputStyle={{
                                    height: "35px",
                                    width: "45px",
                                    color: "#5071C4",
                                    backgroundColor: "#E0DFF4",
                                    border: "1px solid #223870",
                                    margin: "4px",
                                    fontSize: "18px",
                                    borderRadius: "3px",
                                  }}
                                  focusStyle={{
                                    border: "1px solid #223870",
                                  }}
                                />
                              </Box>
                              <Box>
                                {message === "Wrong OTP" && (
                                  <Typography
                                    variant="h5"
                                    sx={{
                                      color: "ff0000",
                                      fontWeight: "500",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {message}
                                  </Typography>
                                )}
                              </Box>
                              <Box sx={{ marginTop: "5px" }}>
                                <OtpTimer
                                  resendOtp={handleClickSendEmailotp}
                                  timerzero={timerZero}
                                />
                              </Box>
                              <Box sx={{ marginTop: "40px " }}>
                                {" "}
                                <Button
                                  onClick={handleClickVerifyEmail}
                                  sx={{
                                    padding: "4px 20px",
                                    textTransform: "none",
                                    textDecoration: "none",
                                    borderRadius: "3px ",
                                    color: "#FDCF57",
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    backgroundColor: "#223870",
                                    "&:hover": {
                                      backgroundColor: "#223870",
                                      color: "#FDCF57 ",
                                    },
                                  }}
                                >
                                  Verify
                                </Button>
                              </Box>
                            </DialogContent>
                          </Dialog>
                        </Box>
                      </Stack>
                      {!companyEmailVerification && !errors.emailId && (
                        <Typography sx={{ color: "red", fontSize: "12px" }}>
                          Please verify your Email Id.
                        </Typography>
                      )}
                      {!companyEmailVerification && errors.emailId && (
                        <Typography sx={{ color: "red", fontSize: "12px" }}>
                          {errors.emailId.message}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container direction="Column">
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}
                    >
                      Mobile No.*
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    {" "}
                    <Grid container>
                      <Grid item sm={4} sx={{ paddingRight: "10px" }}>
                        <Autocomplete
                          id="mob-select-demo"
                          sx={{ width: "100% " }}
                          value={selectedCountry}
                          options={countryListnew}
                          autoHighlight
                          disableClearable
                          variant="standard"
                          popupIcon={
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                              }}
                            />
                          }
                          name="country_code"
                          onChange={(_event, country) => {
                            setSelectedCountry(country);
                          }}
                          size="small"
                          getOptionLabel={(option) => {
                            return option.iso3 + " +" + option.phone_code;
                          }}
                          renderInput={(params) => (
                            <FormInput
                              sx={{
                                backgroundColor: "#E0DFF4",
                                borderRadius: "5px",
                                height: 38.5,
                              }}
                              size="small"
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={8}>
                        <Stack direction="row" spacing={0}>
                          <FormInput
                            size="small"
                            variant="outlined"
                            type="text"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                              borderRadius: "5px 0px 0px 5px",
                            }}
                            name="mobile"
                            fullWidth
                            {...register("mobile", {
                              required: "Mobile Number is required",
                              pattern: {
                                value: /^\d{10}$/,
                                message: "Enter a valid 10 digit mobile no.",
                              },
                            })}
                          />
                        </Stack>
                        {errors.mobile && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.mobile.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Box>
                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name="acceptTerms"
                        defaultValue="false"
                        inputRef={register()}
                        render={({ field: { onChange } }) => (
                          <Checkbox
                            required
                            onChange={(e) => onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                    label={
                      <Typography
                        varient="p"
                        color={errors.acceptTerms ? "error" : "inherit"}
                        sx={{
                          fontSize: "12px",
                        }}
                      >
                        I agree to the{" "}
                        <a href="/" style={{ color: "#223870" }}>
                          T&C and Privacy Policy
                        </a>{" "}
                        of the ExoTalent*
                      </Typography>
                    }
                  />
                  <br />
                  <Typography variant="inherit" color="textSecondary">
                    {errors.acceptTerms
                      ? "(" + errors.acceptTerms.message + ")"
                      : ""}
                  </Typography>
                </Box>
                <Grid
                  container
                  direction="Row"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  {" "}
                  <Grid
                    item
                    sx={{
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Button
                      type="submit"
                      sx={{
                        textTransform: "none",
                        textDecoration: "none",
                        padding: "3px 25px",
                        borderRadius: "3px ",
                        color: "#FDCF57",
                        fontWeight: "500",
                        fontSize: "20px",
                        backgroundColor: "#223870",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#223870",
                          color: "#FDCF57 ",
                        },
                      }}
                    >
                      Signup
                    </Button>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: "10px",
                        right: "5px",
                      }}
                    >
                      <a
                        href="/company/login"
                        style={{
                          fontSize: "16px",
                          color: "#223870",
                        }}
                      >
                        Login
                      </a>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default CompanyRegister;
