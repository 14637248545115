import React, { useState } from "react";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import Burger from "../Burger/Burger";

const DrawerComponent = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <IconButton edge='start' onClick={() => setOpenDrawer(!openDrawer)}>
        <Burger />
      </IconButton>
      <Drawer
        PaperProps={{
          textAlign: "center",
          sx: {
            backgroundColor: "#F3F2FB",
            marginTop: "71px",
            width: "100%",
            textAlign: "center",
          },
        }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to='/#aboutus'>
            <ListItemText
              style={{
                textAlign: "center",
                padding: "15px 0px",
                color: "#223870",
              }}>
              About Us
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to='#services'>
            <ListItemText
              style={{
                textAlign: "center",
                padding: "15px 0px",
                color: "#223870",
              }}>
              Services
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to='#industry'>
            <ListItemText
              style={{
                textAlign: "center",
                padding: "15px 0px",
                color: "#223870",
              }}>
              Industry Verticals
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to='#clients'>
            <ListItemText
              style={{
                textAlign: "center",
                padding: "15px 0px",
                color: "#223870",
              }}>
              Clients
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem
            onClick={() => setOpenDrawer(false)}
            component={Link}
            to='/JobsPage'>
            <ListItemText
              style={{
                textAlign: "center",
                padding: "15px 0px",
                color: "#223870",
              }}>
              Jobs
            </ListItemText>
          </ListItem>
          <Divider />
          {/* <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText style={{ textAlign: 'center', padding: '20px 0px' }}>
              {loggedIn ? (
                <Button
                  sx={{
                    textTransform: 'none',
                    textDecoration: 'none',
                    padding: '4px 20px',
                    borderRadius: '3px ',
                    color: '#FDCF57',
                    marginLeft: '20px',
                    fontWeight: '600',
                    fontSize: '18px',
                    backgroundColor: '#223870',
                    '&:hover': {
                      backgroundColor: '#223870',
                      color: '#FDCF57 ',
                    },
                    '@media (max-width: 1100px)': {
                      padding: '3px 15px',
                    },
                  }}
                  onClick={handleClickLogout}
                >
                  Logout
                </Button>
              ) : (
                <Fragment>
                  {' '}
                  <Button
                    sx={{
                      textTransform: 'none',
                      textDecoration: 'none',
                      padding: '3px 20px',
                      borderRadius: '3px ',
                      color: '#FDCF57',
                      fontWeight: '600',
                      fontSize: '18px',
                      backgroundColor: '#223870',
                      '&:hover': {
                        backgroundColor: '#223870',
                        color: '#FDCF57 ',
                      },
                    }}
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleClick}
                  >
                    Join
                  </Button>
                  <StyledMenuCss
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      sx={{
                        backgroundColor: '#223870',
                        color: '#FDCF57 ',
                        fontWeight: '500',
                        fontSize: '16px',
                        '&:hover': {
                          backgroundcolor: (theme) =>
                            theme.Palette.text.primary,
                          color: '#FDCF57 ',
                        },
                      }}
                      onClick={handleClose}
                    >
                      Company
                    </MenuItem>
                    <MenuItem
                      sx={{
                        backgroundColor: '#223870',
                        color: '#FDCF57 ',
                        fontWeight: '500',
                        fontSize: '16px',
                        '&:hover': {
                          backgroundcolor: (theme) =>
                            theme.Palette.text.primary,
                          color: '#FDCF57 ',
                        },
                      }}
                      onClick={handleClose}
                    >
                      Candidate
                    </MenuItem>
                    <MenuItem
                      sx={{
                        backgroundColor: '#223870',
                        color: '#FDCF57 ',
                        fontWeight: '500',
                        fontSize: '16px',
                        '&:hover': {
                          backgroundcolor: (theme) =>
                            theme.Palette.text.primary,
                          color: '#FDCF57 ',
                        },
                      }}
                      onClick={handleClose}
                    >
                      Recruiter
                    </MenuItem>
                  </StyledMenuCss>
                </Fragment>
              )}
            </ListItemText>
          </ListItem> */}

          <Divider />
        </List>
      </Drawer>
    </>
  );
};
export default DrawerComponent;
