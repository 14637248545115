import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Autocomplete,
  Stack,
} from "@mui/material";
import { FormInput } from "../../components/Input/FormInput";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomizedSnackbars from "../../components/Snackbar/CustomizedSnackbars";
import { countryListnew } from "../../assets/Data/data";
import HomeBg from "../../assets/images/loginbg.png";
import formBg from "../../assets/Icons/mobileFormBG.png";
import { makeStyles } from "@mui/styles";
import Navbar from "../../components/Header/Navbar";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import * as companyActions from "../../Store/company";
import OtpInput from "react-otp-input";
import OtpTimer from "../../components/OTP_Timer/OtpTimer";

const useStyles = makeStyles((theme) => ({
  containerbg: {
    marginTop: "-15px",
    backgroundImage: `url(${HomeBg})`,
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
    display: "block",
    backgroundPosition: "center center",
    position: "static",
    overflow: "auto",
  },
  main: {
    paddingTop: "60px ",
    position: "absolute",
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #223870",
    width: "50px",
    height: "40px",
    color: "#5071C4",
    backgroundColor: "#E0DFF4",
  },
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "8.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  "@media (maxWidth: 468px)": {
    containerbg: {
      backgroundImage: `url(${formBg})`,
      marginTop: "0",
      textAlign: "center",
      width: "100%",
    },
    form: {
      margin: "0",
      padding: "0 10px",
      width: "100%",
    },

    main: {
      width: "100%",
      padding: "150px 0px 0px 0px ",
      height: "auto",
      textAlign: "center",
    },

    input: {
      borderRadius: "5px",
      border: "1px solid #223870",
      width: "50px",
      height: "40px",
      color: "#5071C4",
      backgroundColor: "#E0DFF4",
    },
  },
}));

const LoginPageCompanySuperAdmin = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [msg, setMsg] = React.useState("");
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);

  useEffect(() => {
    window.onbeforeunload = async function () {
      await dispatch(companyActions.resetCompanyDetails());
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, [dispatch]);

  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: "IN",
    country_name: "India",
    iso3: "IND",
    phone_code: 91,
  });

  const [otp, setOtp] = useState("");

  const handleChangeOtp = (otp) => {
    setOtp(otp);
  };
  const [timer, setTimer] = useState(true);

  const timerZero = () => {
    setTimer(false);
  };

  const companyLoginOtpSend = useSelector(
    (state) => state?.company?.companyLoginOtpSend
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onTouched" });
  const companyId = useSelector((state) => state?.company?.companyId);
  const verifyOtp = async () => {
    const mobileotp = {
      requestId: requestId,
      otp: Number(otp),
    };
    const otpmob = await dispatch(
      companyActions.verifyCompanyOtpLogin(mobileotp)
    );
    console.log("otpemobile===================", otpmob);
    if (
      otpmob.data?.status === 1 &&
      otpmob?.data?.data?.accountExists === true
    ) {
      debugger;
      await dispatch(companyActions.getSuperAdminProfile(companyId));
      // navigate("/superadmin/profile");
      navigate("/company/profile/edit");
      setMsg(otpmob.data.msg);
    } else {
      setMsg(otpmob.data.msg);
    }
  };

  const [requestId, setRequestId] = React.useState(false);

  const mobile = watch("mobile");

  const onSubmit = async (data) => {
    const { mobile } = data;
    console.log(data, "send otp data??????");
    setMsg("");
    try {
      const sendotpmobile = { countryCode: 91, phoneNo: Number(mobile) };
      const sendonmobile = await dispatch(
        companyActions.sendOtpCompanyLogin(sendotpmobile)
      );
      if (sendonmobile?.data?.data?.requestId) {
        setRequestId(sendonmobile.data.data.requestId);
      }
      setMsg(sendonmobile?.response?.data?.msg);
    } catch (error) {
      console.error("Error in onSubmit:", error);
    }
  };

  const reSubmit = async (data) => {
    setMsg("");
    const sendonmobile = {
      countryCode: 91,
      phoneNo: Number(mobile),
    };
    const sendOtp = await dispatch(
      companyActions.sendOtpCompanyLogin(sendonmobile)
    );
    setMsg(sendOtp.response?.data?.msg);
    if (sendOtp && sendOtp.data && sendOtp.data?.data?.requestId) {
      setRequestId(sendOtp.data?.data?.requestId);

      const profiless = await dispatch(companyActions.getCompanyProfile());
    }
  };

  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.containerbg}>
        <Grid container className={classes.main}>
          <Grid item xs={12} sm={6} md={6}></Grid>
          <Grid
            Item
            xs={12}
            md={4}
            sm={6}
            sx={{
              maxWidth: "430px",
              display: "flex",
              flexDirection: "column",
              marginRight: "80px",
              textAlign: "center",
              "@media (maxWidth: 468px)": { marginRight: "0" },
            }}>
            <Box sx={{ marginBottom: "30px" }}>
              {" "}
              <Typography
                variant='h4'
                sx={{
                  color: "#223870",
                  fontWeight: "600",
                }}>
                Login as SuperAdmin
              </Typography>
            </Box>

            <form
              onSubmit={handleSubmit(onSubmit)}
              sx={{ width: "100%", minWidth: "430px" }}>
              <Stack sx={{ padding: "0 20px" }} direction='column'>
                <Box
                  mb={1}
                  ml={0.2}
                  sx={{
                    textAlign: "left",
                  }}>
                  {" "}
                  <Typography variant='ps2' gutterBottom>
                    Mobile No.*
                  </Typography>{" "}
                </Box>
                <Box>
                  {" "}
                  <Grid container>
                    <Grid item sm={4} sx={{ paddingRight: "10px" }}>
                      <Autocomplete
                        id='country-select-demo'
                        sx={{ width: "100% " }}
                        value={selectedCountry}
                        options={countryListnew}
                        autoHighlight
                        disableClearable
                        variant='standard'
                        popupIcon={
                          <KeyboardArrowDownIcon
                            sx={{
                              color: "#5071C4",
                            }}
                          />
                        }
                        name='country_code'
                        onChange={(_event, country) => {
                          setSelectedCountry(country);
                        }}
                        size='small'
                        getOptionLabel={(option) => {
                          return option.iso3 + " +" + option.phone_code;
                        }}
                        renderInput={(params) => (
                          <FormInput
                            sx={{
                              backgroundColor: "#E0DFF4",
                              borderRadius: "5px",
                              height: 43,
                            }}
                            size='small'
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={8}>
                      <Stack direction='row' spacing={0}>
                        {" "}
                        <FormInput
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          sx={{
                            width: "100%",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                          name='mobile'
                          {...register("mobile", {
                            required: "Mobile number is required",
                            pattern: {
                              value: /^\d{10}$/,
                              message:
                                "Please enter a valid 10-digit mobile number",
                            },
                          })}
                        />{" "}
                        <Box
                          sx={{
                            backgroundColor: "#E0DFF4",
                            padding: "3px 0px",
                            borderRadius: "0 5px 5px 0px",
                          }}>
                          {" "}
                          <input
                            variant='standard'
                            type='submit'
                            value='Login via OTP'
                            style={{
                              border: "none",
                              marginRight: "4px",
                              backgroundColor: "#223870",
                              borderRadius: "3px",
                              color: "#FDCF57",
                              marginTop: "2px",
                              padding: "6px 8px 8px",
                              fontSize: "14px",
                              textTransform: "none",
                              "&:hover": {
                                color: "#FDCF57",
                                backgroundColor: "#223870",
                              },
                              "@media (maxWidth: 468px)": {
                                marginTop: "-29px",
                                textAlign: "center",
                                marginRight: "-10px",
                                padding: "0 10px",
                              },
                            }}
                          />
                        </Box>
                      </Stack>

                      {errors.mobile && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.mobile.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
            </form>
            <Box sx={{ color: "#0747f7", fontWeight: "500", fontSize: "12px" }}>
              {msg}
            </Box>
            {companyLoginOtpSend && (
              <>
                <Box sx={{ marginTop: "40px", marginBottom: "10px" }}>
                  {" "}
                  <Typography
                    variant='p'
                    sx={{
                      fontSize: "16px",
                      color: "#223870",
                      fontWeight: "600",
                    }}>
                    Enter OTP
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}>
                  <OtpInput
                    value={otp}
                    onChange={handleChangeOtp}
                    numInputs={6}
                    inputStyle={{
                      height: "35px",
                      width: "45px",
                      color: "#5071C4",
                      backgroundColor: "#E0DFF4",
                      border: "1px solid #223870",
                      margin: "4px",
                      fontSize: "18px",
                      borderRadius: "3px",
                    }}
                    focusStyle={{
                      border: "1px solid #223870",
                    }}
                  />
                </Box>
                <Box sx={{ marginTop: "5px" }}>
                  <OtpTimer resendOtp={reSubmit} timerzero={timerZero} />
                </Box>
                <Box sx={{ marginTop: "30px" }}>
                  {" "}
                  <Button
                    onClick={verifyOtp}
                    type='submit'
                    sx={{
                      textTransform: "none",
                      textDecoration: "none",
                      padding: "4px 20px",
                      borderRadius: "3px ",
                      color: "#FDCF57",
                      fontWeight: "500",
                      fontSize: "20px",
                      backgroundColor: "#223870",
                      "&:hover": {
                        backgroundColor: "#223870",
                        color: "#FDCF57 ",
                      },
                    }}>
                    Login
                  </Button>
                </Box>
              </>
            )}
            <Box sx={{ marginTop: "20px" }}>
              <Typography
                variant='p'
                sx={{
                  color: "#223870",
                  fontWeight: "500",
                  fontSize: "16px",
                }}>
                Don't have an account?{" "}
                <a
                  style={{
                    color: "#223870",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                  href='/company/register'>
                  Signup
                </a>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default LoginPageCompanySuperAdmin;
