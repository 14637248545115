let _tokenResponse = null;
let _isLoggedIn = false;
let _userData = null;
let _pushNotification = true;
let _bearerToken = null;

const loadFromStorage = () => {
  if (_isLoggedIn) return;
  try {
    _tokenResponse = JSON.parse(localStorage.getItem("tokenResponse"));
    _userData = JSON.parse(localStorage.getItem("userData"));
    _bearerToken = localStorage.getItem("token");
    if (_tokenResponse) {
      _isLoggedIn = true;
    }
  } catch (err) {}
};

export const clearAll = () => {
  localStorage.removeItem("tokenResponse");
  localStorage.removeItem("userData");
  localStorage.removeItem("persist:root");
  _tokenResponse = null;
  _isLoggedIn = false;
  _userData = null;
};
export const setTokenResponse = (tokenResponse) => {
  console.log(
    tokenResponse,
    "this is tokrn @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@"
  );
  _tokenResponse = tokenResponse;
  _isLoggedIn = true;
  localStorage.setItem("tokenResponse", tokenResponse);
};
export const setUserData = (userData) => {
  _userData = userData;
  localStorage.setItem("userData", JSON.stringify(userData));
};
export const getUserData = () => {
  loadFromStorage();
  return JSON.parse(localStorage.getItem("userData"));
};
export const getLoggedIn = () => _isLoggedIn;
export const hasPermission = (permission) => {
  return _userData.groups.find((group) => {
    return group.permissions.includes(permission);
  });
};
export const setPushTokenResponse = (tokenResponse) => {
  localStorage.setItem("pushtokenResponse", false);
};
export const getAccessToken = () => {
  loadFromStorage();
  return localStorage.getItem("tokenResponse");
};
export const setBearerToken = (token) => {
  console.log("Setting bearer token:", token);
  _bearerToken = token;
  localStorage.setItem("token", token);
};
export const getBearerToken = () => {
  loadFromStorage();
  console.log("Retrieving bearer token:", _bearerToken);
  return _bearerToken;
};

export const getPushNotification = () => _pushNotification;
