import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Home from "../container/Home/Home";
import CompanyRegister from "../container/RegisterPage/companySignup";
import CandidateDetails from "../container/Pages/Candidate/candidateDetails/candidateDetails";
import CandidateRegister from "../container/RegisterPage/candidateSignup";
import RecruiterRegister from "../container/RegisterPage/recruiterSignup";
import LoginPageCandidate from "../container/LoginPage/loginPageCandidate";
import LoginPageCompany from "../container/LoginPage/loginPageCompany";
import LoginPageRecruiter from "../container/LoginPage/loginPageRecruiter";
import JobsPage from "../container/Pages/allJobView/JobsPage";
import JobSearchResult from "../container/Pages/jobSearchResult/JobSearchResultPage";
import CompanyPage from "../container/Pages/Company/companyPage/CompanyPage";
import EditCompany from "../container/Pages/Company/companyPage/editCompany";
import ProfileSuperAdmin from "../container/Pages/Company/superAdmin/profileSuperAdmin";
import CompanyRecruiterProfile from "../container/Pages/Company/companyRecruiter/CompanyRecruiterProfile";
import EditprofileSuperAdmin from "../container/Pages/Company/superAdmin/editprofileSuperAdmin";
import EditCompanyRecruiterProfile from "../container/Pages/Company/companyRecruiter/EditCompanyRecruiterProfile";
import CompanyJD from "../container/Pages/Company/companyJd/CompanyJd";
import CreateJob from "../container/Pages/Company/createJob/createJob";
import EditJob from "../container/Pages/Company/createJob/editJob";
import EditCandidateProfile from "../container/Pages/Candidate/candidateProfile/EditCandidateProfile";
import NewCandidateSignup from "../container/Pages/Candidate/candidateProfile/NewCandidateProfile";
import CandidateProfile from "../container/Pages/Candidate/candidateProfile/CandidateProfile";
import CandidateFeedback from "../container/Pages/Candidate/candidateFeedback/CandidateFeedback";
import RecruiterProfile from "../container/Pages/Recruiter/recruiterProfile/ RecruiterProfile";
import EditRecruiterProfile from "../container/Pages/Recruiter/recruiterProfile/editRecruiterProfile";
import AddScheduleCard from "../container/Pages/Candidate/candidateFeedback/schedule/addScheduleCard";
import CompanyRecruiterSignup from "../container/RegisterPage/companyRecruiterSignup";
import AdminSignup from "../container/RegisterPage/adminSignup";
import CompanyRecruiterLogin from "../container/LoginPage/CompanyRecruiterLogin";
import ReferJobs from "../container/Pages/Recruiter/referJobs/ReferJobs";

const BasicRoutes = () => {
  const userdata = useSelector((state) => state.auth);
  return (
    <Router>
      <Routes>
        <Route exact path='/' name='home' element={<Home />} />
        <Route
          exact
          path='/company/register'
          name='companySignup'
          element={<CompanyRegister />}
        />
        <Route
          exact
          path='/candidate/details/:id'
          name='candidateDetails'
          element={<CandidateDetails />}
        />
        <Route
          exact
          path='/candidate/register'
          name='candidateRegister'
          element={<CandidateRegister />}
        />
        <Route
          exact
          path='/recruiter/register'
          name='recruiterRegister'
          element={<RecruiterRegister />}
        />
        <Route exact path='/JobsPage' name='jobs' element={<JobsPage />} />
        <Route
          exact
          path='/Job/search-result'
          name='searchResult'
          element={<JobSearchResult />}
        />
        <Route
          exact
          path='/company/profile'
          name='companyProfile'
          element={<CompanyPage />}
        />
        <Route
          exact
          path='/companyJd/:id'
          name='companyJd'
          element={<CompanyJD />}
        />
        <Route
          exact
          path='/createJob'
          name='createJob'
          element={<CreateJob />}
        />
        <Route exact path='/editJob' name='editJob' element={<EditJob />} />
        <Route
          exact
          path='/company/profile/edit'
          name='editCompanyProfile'
          element={<EditCompany />}
        />
        <Route
          exact
          path='/candidate/profile'
          name='candidateProfile'
          element={<CandidateProfile />}
        />
        <Route
          exact
          path='/candidate/editprofile'
          name='editcandidateProfile'
          element={<EditCandidateProfile />}
        />
        <Route
          exact
          path='/candidate/newprofile'
          name='candidatenewProfile'
          element={<NewCandidateSignup />}
        />
        <Route
          exact
          path='/candidate/feedback'
          name='candidateFeedback'
          element={<CandidateFeedback />}
        />
        <Route
          exact
          path='/candidate/login'
          name='loginPageCandidate'
          element={<LoginPageCandidate />}
        />
        <Route
          exact
          path='/company/login'
          name='loginPageCompany'
          element={<LoginPageCompany />}
        />
        <Route
          exact
          path='/recruiter/login'
          name='loginPageRecruiter'
          element={<LoginPageRecruiter />}
        />
        <Route
          exact
          path='/recruiter/profile'
          name='recruiterProfile'
          element={<RecruiterProfile />}
        />
        <Route
          exact
          path='/recruiter/profile/edit'
          name='recruiterProfileEdit'
          element={<EditRecruiterProfile />}
        />
        <Route
          exact
          path='/candidate/interview-schedule'
          name='interviewSchedule'
          element={<AddScheduleCard />}
        />
        <Route
          exact
          path='/company/recruiter/signup'
          name='CompanyRecruiterSignup'
          element={<CompanyRecruiterSignup />}
        />
        <Route
          exact
          path='/company/admin/signup'
          name='AdminSignup'
          element={<AdminSignup />}
        />
        <Route
          exact
          path='/company/recruiter/login'
          name='CompanyRecruiterLogin'
          element={<CompanyRecruiterLogin />}
        />
        <Route
          exact
          path='/refer/job'
          name='ReferJobs'
          element={<ReferJobs />}
        />{" "}
        <Route
          exact
          path='/superadmin/profile'
          name='Company SuperAdmin'
          element={<ProfileSuperAdmin />}
        />
        <Route
          exact
          path='/superadmin/editprofile'
          name='Edit SuperAdmin'
          element={<EditprofileSuperAdmin />}
        />
        <Route
          exact
          path='/profile/recruiter'
          name='Company Recruiter'
          element={<CompanyRecruiterProfile />}
        />
        <Route
          exact
          path='/editProfile/recruiter'
          name='Edit Recruiter'
          element={<EditCompanyRecruiterProfile />}
        />
      </Routes>
    </Router>
  );
};

export default BasicRoutes;
