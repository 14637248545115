import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import theme from "./theme/theme";
import configureStore from "./Store";
import BasicRoutes from "./routes/index";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";

const { persistor, store } = configureStore();
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <BasicRoutes />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
