import React, { useState, useEffect } from "react";
import { Grid, Box, Button, Select, MenuItem, Stack } from "@mui/material";
import { FormInput } from "../../components/Input/FormInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import * as jobsActions from "../../Store/Jobs";
import * as helperActions from "../../Store/helper";
import { useDispatch } from "react-redux";
const useStyles = makeStyles((theme) => ({
  search: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alighItems: "center",
    backgroundColor: "#DFE5F6",
    borderRadius: "6px 0px 0px 6px",
    "& .MuiTextField-root": { padding: "8px " },
  },
  buttonbox: {
    width: "108px",
    height: "48px",
    backgroundColor: "#DFE5F6",
    padding: "0 3px 0px 0px",

    borderRadius: "0 6px 6px 0px",
  },
  countrycode1: {
    marginLeft: "5px",
    "& .MuiSelect-outlined": {
      backgroundColor: "#DFE5F6",
      padding: "10px 0px",
      "@media (maxWidth: 468px)": { padding: "15px 0px" },
    },
    "& .MuiSelect-outlined:hover:after": {
      backgroundColor: "#e9e8f6",
    },
    "& .MuiSelect-outlined:hover:before": {
      backgroundColor: "#e9e8f6",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  input: {
    "&::placeholder": {
      color: "#aaa",
      textAlign: "left",
    },
  },

  "@media (min-width: 1640px)": {},
  "@media (maxWidth: 468px)": {
    buttonbox: {
      width: "auto",
      marginTop: "10px",
      height: "auto",
      backgroundColor: "#e9e8f6",
      padding: "0",
    },
    input: {
      "&::placeholder": {
        fontSize: 12,
        color: "#aaa",
        textAlign: "left",
      },
    },
    search: {
      marginRight: "80px",
      "& .MuiTextField-root": { padding: "8px 0px" },
    },
  },
}));
const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: "#aaa",
    "@media (maxWidth: 468px)": {
      fontSize: "12px",
    },
  },
}));

function JobSearchBar(props) {
  const classes = useStyles("");
  const navigate = useNavigate();
  let locationurl = useLocation();
  const dispatch = useDispatch();
  const redirect = async () => {
    console.log("hi", locationurl.pathname);
    let data = {};
    if (pageno) {
      data["page_no"] = pageno;
    } else {
      data["page_no"] = 0;
    }
    if (jobTitle) {
      data["job_title"] = jobTitle;
    }
    if (location) {
      data["job_location"] = location;
    }
    if (minyear) {
      data["min_experience"] = minyear;
    }
    if (maxyear) {
      data["max_experience"] = maxyear;
    }
    if (locationurl.pathname.includes("JobSearchResult")) {
      console.log("search", data);

      const searchJobList = await dispatch(jobsActions.getSearchJobList(data));

      let paramsurl = "";
      for (let [key, value] of Object.entries(data)) {
        console.log(key, value);
        if (key === "page_no") {
          paramsurl = paramsurl + "?" + key + "=" + value;
        } else {
          paramsurl = paramsurl + "&" + key + "=" + value;
        }
      }

      window.scrollTo({
        top: 0,
      });
      navigate("/Job/search-result" + paramsurl);
    } else {
      let paramsurl = "";
      for (let [key, value] of Object.entries(data)) {
        console.log(key, value);
        if (key === "page_no") {
          paramsurl = paramsurl + "?" + key + "=" + value;
        } else {
          paramsurl = paramsurl + "&" + key + "=" + value;
        }
      }

      window.scrollTo({
        top: 0,
      });
      navigate("/Job/search-result" + paramsurl);
    }
  };

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };
  // const location = useSelector((state) => state?.helper?.location);

  const [pageno, setPageno] = useState(0);
  const [jobTitle, setJobTitle] = useState("");

  const [location, setLocation] = useState("");
  const [minArrayValue, setMinArrayValue] = React.useState([
    1, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
    24, 25,
  ]);
  const [maxArrayValue, setMaxArrayValue] = useState([
    1, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
    24, 25,
  ]);
  const [minyear, setMinyear] = useState("");
  const [maxyear, setMaxyear] = useState("");
  const minYearChange = (event) => {
    setMinyear(event.target.value);
    let temp = [];
    for (let i = event.target.value + 1; i < 25; i++) {
      temp.push(i);
    }
    setMaxArrayValue(temp);
  };
  const maxYearChange = (event) => {
    setMaxyear(event.target.value);
  };

  const locationSet = (event) => {
    setLocation(event.target.value);
  };
  const titleSet = (event) => {
    setJobTitle(event.target.value);
  };

  useEffect(() => {
    async function getLocationlist() {
      await dispatch(helperActions.getLocation());
    }
    getLocationlist();
  }, []);

  return (
    <>
      <Box
        sx={{
          marginRight: "160px",
          borderRadius: "6px",
          boxShadow: "0 3px 5px 0px rgba(0, 0, 0, 0.5)",
          "@media (maxWidth: 468px)": {
            padding: "0",
            marginRight: "0",
          },
          "@media (min-width: 1640px)": { marginRight: "150px" },
        }}>
        <Grid container className={classes.search}>
          <Grid item sm={11} sx={11}>
            <Grid container>
              <Grid item sm={3} sx={3}>
                <FormInput
                  placeholder='Job title'
                  variant='standard'
                  onChange={titleSet}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                  }}
                />
              </Grid>

              <Grid item sm={3} sx={3}>
                <Stack direction='row'>
                  <div
                    style={{
                      marginTop: "2px",
                      padding: "0",
                      width: "1px",
                      height: "40px",
                      backgroundColor: "#aaa",
                    }}></div>

                  <FormInput
                    placeholder='Location'
                    variant='standard'
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.input,
                      },
                    }}
                    onChange={locationSet}
                  />

                  {/* <Box sx={{width:'100%'}}>
                  {" "}
                   <Typography
                    variant="p"
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}
                  >
                    Location*
                  </Typography>{" "}
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >

                  <Autocomplete
                    id="country-select-demo"
                    sx={{ width: "100% " }}
                    value={job_location}
                    options={location}
                    autoHighlight
                    disableClearable
                    variant="standard"
                    popupIcon={
                      <KeyboardArrowDownIcon
                        sx={{
                          color: "#5071C4",
                        }}
                      />
                    }
                    name="location"
                    defaultValue=""
                    onChange={(_event, location) => {
                      setJobLocation(location);
                    }}
                    size="small"
                    getOptionLabel={(option) => {
                      if (option) {
                        return option?.city;
                      } else {
                        return "";
                      }
                    }}
                    renderInput={(params) => (
                       <FormInput

                        size="small"
                        
                        {...params}
                        
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "",
                        }}
                      />
                    )}
                  />
                </Box>
              </Box> */}
                </Stack>
              </Grid>
              <Grid item sm={3} sx={3}>
                <Stack direction='row'>
                  {" "}
                  <div
                    style={{
                      marginTop: "2px",
                      padding: "0",
                      width: "1px",
                      height: "auto",
                      backgroundColor: "#aaa",
                    }}></div>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          backgroundColor: "#e9e8f6",
                        },
                      },
                    }}
                    IconComponent={() => (
                      <KeyboardArrowDownIcon
                        sx={{
                          color: "#aaa",
                          position: "absolute",
                          right: 0,
                          pointerEvents: "none",
                          "@media (maxWidth: 468px)": {
                            right: 13,
                            width: "20px",
                            height: "20px",
                          },
                        }}
                      />
                    )}
                    displayEmpty
                    sx={{
                      color: "#223870",
                      fontWeight: "500",
                      "@media (maxWidth: 468px)": {
                        marginLeft: "-110px",
                        padding: "0",
                      },
                    }}
                    value={minyear}
                    onChange={minYearChange}
                    className={classes.countrycode1}
                    // renderValue={
                    //   minyear !== ""
                    //     ? undefined
                    //     : () => <Placeholder>Min. exp</Placeholder>
                    // }
                    renderValue={(selected) => {
                      console.log(selected);
                      if (!selected) return <Placeholder>Min. exp</Placeholder>;
                      else
                        return (
                          selected + " " + (selected === 1 ? "year" : "years")
                        );
                    }}>
                    {minArrayValue
                      ? minArrayValue.map((item, key) => {
                          return (
                            <MenuItem key={key} value={item}>
                              {item} {item === 1 ? "year" : "years"}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </Stack>
              </Grid>
              <Grid item sm={3} sx={3}>
                <Stack direction='row'>
                  {" "}
                  <div
                    style={{
                      marginTop: "2px",
                      padding: "0",
                      width: "1px",
                      height: "auto",
                      backgroundColor: "#aaa",
                    }}></div>
                  <Select
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          backgroundColor: "#e9e8f6",
                        },
                      },
                    }}
                    IconComponent={() => (
                      <KeyboardArrowDownIcon
                        sx={{
                          color: "#aaa",
                          position: "absolute",
                          right: 0,
                          pointerEvents: "none",

                          "@media (maxWidth: 468px)": {
                            right: 13,
                            width: "20px",
                            height: "20px",
                          },
                        }}
                      />
                    )}
                    displayEmpty
                    sx={{
                      color: "#223870",
                      "@media (maxWidth: 468px)": {
                        marginLeft: "-30px",
                        padding: "0",
                      },
                    }}
                    value={maxyear}
                    onChange={maxYearChange}
                    className={classes.countrycode1}
                    renderValue={(selected) => {
                      console.log(selected);
                      if (!selected) return <Placeholder>Max. exp</Placeholder>;
                      else
                        return (
                          selected + " " + (selected === 1 ? "year" : "years")
                        );
                    }}>
                    {maxArrayValue
                      ? maxArrayValue.map((item, key) => {
                          return (
                            <MenuItem key={key} value={item}>
                              {item} {item === 1 ? "year" : "years"}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </Stack>
              </Grid>
            </Grid>{" "}
          </Grid>
          <Grid item sm={1} sx={1}>
            {" "}
            <Box className={classes.buttonbox}>
              <Button
                onClick={redirect}
                variant='standard'
                sx={{
                  backgroundColor: "#223870",
                  marginTop: "3px",
                  borderRadius: "3px",
                  color: "#FDCF57",
                  padding: "3px 20px",
                  fontSize: "20px",
                  textTransform: "capitalize",
                  "&:hover": {
                    color: "#FDCF57",
                    backgroundColor: "#223870",
                  },
                  "@media (maxWidth: 468px)": {
                    fontSize: "14px",
                    padding: "3px 10px",
                  },
                  "@media (min-width: 1640px)": { marginLeft: "25px" },
                  "@media (min-width: 1900px)": { marginLeft: "38px" },
                }}>
                Search
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default JobSearchBar;
