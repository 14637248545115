import { Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Card1Bg from "../../../assets/images/PermanentStaffing.png";
import Card2Bg from "../../../assets/images/ContigentStaffing.png";
import Card3Bg from "../../../assets/images/ExecutiveSearch.png";
import Card4Bg from "../../../assets/images/ConsultingServices.png";
import Card5Bg from "../../../assets/images/Niche&DiversityHiring.png";
import Card6Bg from "../../../assets/images/RecruitmentProcessingOutsourcing.png";

const useStyles = makeStyles((theme) => ({
  serviceContainer: {
    padding: "50px auto",
    backgroundColor: "#F3F2FB",
    height: "auto",
    width: "100%",
    textAlign: "center",
  },
  title: {
    paddingTop: "80px",
    color: "#223870",
    fontSize: "34px",
    textAlign: "center",
    fontWeight: "600",
  },
  title1: {
    paddingTop: "5px",
    color: "#223870",
    fontSize: "34px",
    textAlign: "center",
    fontWeight: "600",
  },
  cards: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0px",
    textAlign: "center",
    width: "100%",
  },
  card: {
    margin: "auto 20px",
    width: "auto",
    height: "220px",
    marginBottom: "14px",
    borderRadius: "0 12px 12px 12px",
    padding: "20px ",
    "&.withBg": {
      backgroundSize: "cover",
    },
  },
  heading: {
    fontSize: "22px",
    lineHeight: "22px",
    fontWeight: "500",
    color: "#fdcf57",
    marginBottom: "10px",
  },
  text: {
    margin: "10px 20px 0px 50px",
    color: "#fff",
    fontSize: "17px",
    fontWeight: "400",
    lineHeight: "20px",
    textAlign: "left",
  },
  text1: {
    marginTop: "15px",
    color: "#fff",
    fontSize: "17px",
    fontWeight: "400",
    lineHeight: "20px",
    textAlign: "center",
  },
}));

const Services = () => {
  const classes = useStyles();
  const { card, card1, card2, card3, card4, card5, card6 } = classes;

  return (
    <>
      <Box className={classes.serviceContainer} id='services'>
        <Box className={classes.title}>Services Offered</Box>
        <Grid
          container
          spacing={0}
          justify='space-between'
          alignItems='stretch'
          columns={{ xs: 2, sm: 9, md: 14, lg: 14 }}
          className={classes.cards}>
          <Grid item xs={2} sm={4} md={4} style={{ textAlign: "center" }}>
            <Box
              className={`${card} ${card1} withBg`}
              style={{ backgroundImage: `url(${Card1Bg})` }}>
              <Box className={classes.heading} style={{ color: "#4B4FD9" }}>
                Recruitment
              </Box>
              <Box className={classes.text} style={{ color: "#304672" }}>
                <ul>
                  <li>End to End Staffing</li>
                  <li>Permanent</li>
                  <li>Contractual</li>
                  <li>Campus hiring</li>
                </ul>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={4} style={{ textAlign: "center" }}>
            <Box
              className={`${card} ${card2} withBg`}
              style={{ backgroundImage: `url(${Card2Bg})` }}>
              <Box className={classes.heading} style={{ color: "#4B4FD9" }}>
                RPO
              </Box>
              <Box className={classes.text} style={{ color: "#304672" }}>
                <ul>
                  <li>
                    Deep dive services in high volume/strategic recruitment
                  </li>
                  <li>Cost Effective</li>
                  <li>Time Effective</li>
                  <li>Process Effective Consulting</li>
                </ul>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={4} style={{ textAlign: "center" }}>
            <Box
              className={`${card} ${card3} withBg`}
              style={{ backgroundImage: `url(${Card3Bg})` }}>
              <Box className={classes.heading} style={{ color: "#4B4FD9" }}>
                HRM Consulting
              </Box>
              <Box className={classes.text} style={{ color: "#304672" }}>
                <ul>
                  <li>Succession planning</li>
                  <li>Org restructuring</li>
                  <li>Policy restructuring</li>
                  <li>Employee engagement</li>
                  <li>Compensation benchmarking</li>
                  <li>Competitor analysis</li>
                </ul>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.title1}>Key differentiators</Box>
        <Grid
          container
          spacing={0}
          justify='space-between'
          alignItems='stretch'
          columns={{ xs: 2, sm: 9, md: 14, lg: 14 }}
          className={classes.cards}>
          <Grid item xs={2} sm={4} md={4} style={{ textAlign: "center" }}>
            <Box
              className={`${card} ${card4} withBg`}
              style={{ backgroundImage: `url(${Card4Bg})` }}>
              <Box className={classes.heading} style={{ color: "#4B4FD9" }}>
                AI enabled screening platform
              </Box>
              <Box className={classes.text1} style={{ color: "#304672" }}>
                Our AI enabled platform helps to shortlist the ideal candidates,
                as well as automate manual tasks in the recruitment process.
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={4} style={{ textAlign: "center" }}>
            <Box
              className={`${card} ${card5} withBg`}
              style={{ backgroundImage: `url(${Card5Bg})` }}>
              <Box className={classes.heading} style={{ color: "#4B4FD9" }}>
                Team of industry experts
              </Box>
              <Box className={classes.text1} style={{ color: "#304672" }}>
                Our team is comprised of industry experts who are passionate
                about their work, have excelled in their field, and have an
                extensive background of over 20+ years of industry exposure.
              </Box>
            </Box>
          </Grid>
          <Grid item xs={2} sm={4} md={4} style={{ textAlign: "center" }}>
            <Box
              className={`${card} ${card6} withBg`}
              style={{ backgroundImage: `url(${Card6Bg})` }}>
              <Box className={classes.heading} style={{ color: "#4B4FD9" }}>
                Candidate management
              </Box>
              <Box className={classes.text1} style={{ color: "#304672" }}>
                Our candidate management teams help in building and maintaining
                a healthy relationship with candidates, and this helps to reduce
                the decline ratio.
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Services;
