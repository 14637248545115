import React, { useState } from "react";
import Box from "@mui/material/Box";
import Payal from "../../../assets/images/Clients/Payal_S.png";
import kamla from "../../../assets/images/Clients/Kamla.jpg";
import santosh from "../../../assets/images/Clients/Santosh.jpg";
import murtaza from "../../../assets/images/Clients/Murtaza.jpg";
import { makeStyles } from "@mui/styles";
import ClientTestimonialBg from "../../../assets/images/ClientTestimonialsBG.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import TestimonialClient from "./TestimonialClient";

const responsive = {
  LargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  Background: {
    backgroundImage: `url(${ClientTestimonialBg})`,
    backgroundSize: "cover",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
    padding: "50px 80px",
  },
  title: {
    color: "#223870",
    fontSize: "34px",
    textAlign: "center",
    fontWeight: "600",
    marginBottom: "10px",
  },
  subtitle: {
    color: "#5677B9",
    fontSize: "22px",
    textAlign: "center",
    fontWeight: "500",
    margin: "0 230px 30px",
  },
  content: {
    color: "#304672",
    fontSize: "18px",
    lineHeight: "24px",
    marginBottom: "20px",
  },
  name: {
    color: "#223870",
    fontSize: "24px",
    fontWeight: "500",
  },
  designation: {
    color: "#4C68AE",
    fontSize: "20px",
  },
  quotation: {
    float: "right",
  },
  "@media (maxWidth: 468px)": {
    Background: {
      backgroundImage: "none",
      width: "100%",
      padding: "10px 20px",
    },
    title: {
      fontSize: "26px",
      lineHeight: "30px",
      margin: "20px 0px",
    },
    subtitle: {
      fontSize: "20px",
      lineHeight: "22px",
      margin: "0 0px 30px 0px",
    },
    content: {
      color: "#304672",
      fontSize: "18px",
      lineHeight: "24px",
      marginBottom: "20px",
    },
    name: {
      color: "#223870",
      fontSize: "24px",
      fontWeight: "500",
    },
    designation: {
      color: "#4C68AE",
      fontSize: "20px",
    },
    quotation: {
      float: "right",
    },
  },
  "@media (min-width: 1640px)": {
    Background: {
      padding: "30px 0px",
    },
  },
}));

const items = [
  {
    image: Payal,
    content:
      "I completely agree our partnership is highly valued and the journey of the last 5 years has been enriching and rewarding. The external talent market dynamics in the last 2-3 years has been drastically changing, it's a VUCA world, it's the candidates world with multiple offers in hand, war for talent etc , but must say ExoTalent has always taken the challenge and never did we dry up on the inflow of resumes. I highly appreciate the hard work done by Richa , Bhagya and the entire backend team. Thank you Hema for taking the ownership of hiring the right talent for John Deere.",
    name: "Payal S",
    designation: "Head Talent Acquisition India - John Deere",
    // quotation: `,,`,
  },

  {
    image: kamla,
    content:
      "I have been working with ExoTalent for the past 4 years, Your conversion rate from profile to shortlist is pretty good. The quality of service mainly for mid-senior positions and IT space is very good and your response is very quick in every stage of recruitment. Thank you for being our partners in our growth Journey.",
    name: "Kamalapriya Mohanarangan",
    designation: "Sr. Manager HR - Yokogawa",
    // quotation: `,,`,
  },
  {
    image: murtaza,
    content:
      "I have found Exo Talent to be professional and efficient at what they do. They are eager to listen and improve which provides a continuous feedback to adapt with changing times and continue to excel ",
    name: "Murtaza Hita",
    designation: "Engineering Manager",
    // quotation: `,,`,
  },
  {
    image: santosh,
    content:
      "The team is mature and understand the needs quickly. The response time and communication is also great. They have good processes established",
    name: "Santhosh Venkateshaiah",
    designation: "Manufacturing Quality Engineering Supervisor",
    // quotation: `,,`,
  },
];

function ClientTestimonial() {
  const [showmore, setShowmore] = useState(false);
  const classes = useStyles();
  return (
    <>
      <Box className={classes.Background}>
        <Box className={classes.title}>What our clients have to say</Box>
        {/* <Box className={classes.subtitle}>
          Elit duis tristique sollicitudin nibh sit amet commodo nulla. Ut
          tortor pretium. Quis viverra nibh cras pulvinar mattis nunc.
        </Box> */}
        <Box
          sx={{
            maxWidth: 1400,
            flexGrow: 1,
            margin: "0 auto",
            textAlign: "center",
            "@media (min-width: 1536px)": {
              maxWidth: 1800,
            },
          }}>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            // autoPlay={true}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            // customTransition="all .5"
            // transitionDuration={500}
            containerClass='carousel-container'
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            dotListClass='custom-dot-list-style'>
            {items?.map((step, index) => (
              <TestimonialClient step={step} key={index} />
            ))}
          </Carousel>
        </Box>
      </Box>
    </>
  );
}

export default ClientTestimonial;
