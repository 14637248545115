import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Stack,
  MenuItem,
  Select,
  Menu,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  ListItemIcon,
  ListItemText,
  Autocomplete,
} from "@mui/material";
import { FormInput } from "../../../../components/Input/FormInput";
import Checkbox from "@mui/material/Checkbox";
import { useForm } from "react-hook-form";
import Close from "../../../../assets/Icons/Close.png";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { countryListnew } from "../../../../assets/Data/data";
import Logo from "../../../../assets/images/LogowithTagline.png";
import dropDown from "../../../../assets/Icons/Dropdown.png";
import UpArrow from "../../../../assets/Icons/UpArrow.png";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../../components/Header/Navbar";
import JobCardMain from "../../../../components/jobCards/jobCardMain";
import CustomizedSnackbars from "../../../../components/Snackbar/CustomizedSnackbars";
import { useSelector, useDispatch } from "react-redux";
import * as jobsActions from "../../../../Store/Jobs";
import * as authActions from "../../../../Store/candidate";
import ManageAccessAdmin from "../ManageAccessCompany/ManageAccessAdmin";
import ManageAccessRecruiter from "../ManageAccessCompany/ManageAccessRecruiter";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const useStyles = makeStyles((theme) => ({
  topbar: {
    position: "relative",
    marginTop: "80px",
    padding: "20px 75px",
    backgroundSize: "cover",
    backgroundColor: "#DFE5F6",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  imgFrame: {
    background: "#fff",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    objectFit: "contain",
    margin: "0 auto",
    width: "110px",
    height: "110px",
    borderRadius: "5px",
    backgroundSize: "cover",
  },
  text: { marginLeft: "30px" },
  button: {
    position: "absolute",
    top: "55px",
    right: "100px",
    display: "flex",
  },
  body: {
    padding: "20px 80px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#F6F8FD",
    textAlign: "center",
  },
  jobbox: {
    margin: "8px",
    padding: "20px 0px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },

  "@media (min-width: 1640px)": {
    jobbox: {
      marginLeft: "15px",
      padding: " 30px 0px",
    },
    mainHome: {
      marginTop: "80px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
  },
  "@media (maxWidth: 468px)": {
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "26px",
    },
    jobbox: {
      width: "100%",
      margin: " 20px 0px 0px 0px",
      padding: "0 5px",
    },
    container: {
      width: "100%",
      padding: "0 0px",
    },
    topbar: {
      position: "relative",
      padding: "20px 10px",
      height: "170px",
      width: "100%",
    },
    imgFrame: {
      width: "90px",
      height: "90px",
    },
    img: {
      width: "80px",
      height: "80px",
    },
    text: { marginLeft: "10px" },
    button: {
      position: "absolute",
      top: "120px",
      right: "20px",
    },
    body: {
      padding: "0",
      borderRadius: "10px",
    },
  },
}));
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "3px",
    marginTop: theme.spacing(17.6),
    minWidth: 152,
    textAlign: "center",
    "& .MuiMenu-list": {
      padding: "0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(0),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const options = ["Create Job", "Manage Job", "Refer Profile"];
const CompanyPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowAccess(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const redirect = () => {
    navigate("/createJob");
    setShowAccess(false);
  };
  const redirectedit = () => {
    navigate("/company/profile/edit");
    setShowAccess(false);
  };
  const redirectCompanyJd = () => {
    navigate("/companyJd");
  };
  const [pageNoOpen, setPageNoOpen] = React.useState(0);
  const [pageNoClosed, setPageNoClosed] = React.useState(0);
  const [pageNoInactive, setPageNoInactive] = React.useState(0);
  const [pageNoAll, setPageNoAll] = React.useState(0);
  const profile = useSelector((state) => state?.auth?.profile);
  useEffect(() => {
    async function listofOpenJobs() {
      const data = {
        job_status: "open",
        page_no: pageNoOpen,
      };
      const jobList = await dispatch(jobsActions.getAllOpenJobs(data));
      console.log("jobList==========", jobList);
    }
    listofOpenJobs();
  }, [dispatch, pageNoOpen]);
  useEffect(() => {
    async function listofInactiveJobs() {
      const data = {
        job_status: "inactive",
        page_no: pageNoInactive,
      };
      const inactiveJobList = await dispatch(
        jobsActions.getAllInactiveJobs(data)
      );
      console.log("inactiveJobList ==========", inactiveJobList);
    }
    listofInactiveJobs();
  }, [dispatch, pageNoInactive]);
  useEffect(() => {
    async function listofClosedJobs() {
      const data = {
        job_status: "closed",
        page_no: pageNoClosed,
      };
      const closedJobList = await dispatch(jobsActions.getAllClosedJobs(data));
      console.log("closedJobList ==========", closedJobList);
    }
    listofClosedJobs();
  }, [dispatch, pageNoClosed]);
  useEffect(() => {
    async function companyProfile() {
      const profile = await dispatch(authActions.getProfile());
      console.log("closedJobList ==========", profile);
    }
    companyProfile();
  }, [dispatch]);

  const [numberOfOpenJobs, setNumberOfOpenJobs] = React.useState(2);
  const [numberOfInactiveJobs, setNumberOfInactiveJobs] = React.useState(2);
  const [numberOfClosedJobs, setNumberOfClosedJobs] = React.useState(2);
  const [numberOfAllJobs, setNumberOfAllJobs] = React.useState(2);
  // debugger;
  const openJobs = useSelector((state) => state?.jobs?.createdOpenJobList);
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  console.log("openJobs==================", openJobs);
  const inactiveJobs = useSelector(
    (state) => state?.jobs?.createdInactiveJobList
  );
  console.log("inactiveJobs==================", inactiveJobs);
  const closedJobs = useSelector((state) => state?.jobs?.createdClosedJobList);
  console.log("closedJobs==================", closedJobs);
  const allJobs = openJobs + inactiveJobs + closedJobs;
  const seeMoreOpen = () => {
    if (openJobs?.length > numberOfOpenJobs) {
      setNumberOfOpenJobs(openJobs?.length);
    }
  };
  const seeMoreInactive = () => {
    if (inactiveJobs?.length > numberOfInactiveJobs) {
      setNumberOfInactiveJobs(inactiveJobs?.length);
    }
  };
  const seeMoreClosed = () => {
    if (closedJobs?.length > numberOfClosedJobs) {
      setNumberOfClosedJobs(closedJobs?.length);
    }
  };

  const seeLessOpen = () => {
    setNumberOfOpenJobs(2);
  };

  //Added
  const [showAccess, setShowAccess] = React.useState(false);
  const handleAdminAccess = () => {
    setShowAccess(true);
    setAnchorEl(null);
  };

  const [createRecruiter, setCreateRecruiter] = React.useState(false);
  const redirectCreateRecruiter = () => {
    setCreateRecruiter(true);
    setShowAccess(false);
  };
  const handleCloseCreateRecruiter = () => {
    setCreateRecruiter(false);
    setAnchorEl(null);
  };
  const [createAdmin, setCreateAdmin] = React.useState(false);
  const redirectCreateAdmin = () => {
    setCreateAdmin(true);
    setShowAccess(false);
  };
  const handleCloseCreateAdmin = () => {
    setCreateAdmin(false);
    setAnchorEl(null);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onTouched" });
  const [access, setAccess] = React.useState([]);
  const isAllAccess = options.length > 0 && access?.length === options.length;
  const handleAccess = (event) => {
    console.log("vals", event.target);
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setAccess(access?.length === options.length ? [] : options);
      return;
    }
    setAccess(value);
    console.log("values", access);
  };
  const [mobile, setMobile] = React.useState("");
  const [selectedCountry, setSelectedCountry] = React.useState({
    id: 240,
    iso2: "IN",
    country_name: "India",
    iso3: "IND",
    phone_code: 91,
  });
  const [self, setSelf] = React.useState("self");

  const handleSelf = (event) => {
    setSelf(event.target.value);
  };
  const [openJob, setOpenJob] = React.useState(1);

  const handleOpen = (event) => {
    setOpenJob(event.target.value);
  };
  const [latest, setLatest] = React.useState("latest");

  const handleLatest = (event) => {
    setLatest(event.target.value);
  };
  const [repoManager, setRepoManager] = React.useState("");
  const handleRepoManager = (event) => {
    setRepoManager(event.target.value);
  };

  const onSubmitAdmin = async (data) => {
    console.log(data, "admin data");
  };
  const onSubmitRecruiter = async (data, access, repoManager) => {
    console.log(access, repoManager, data, "recruiter data");
  };

  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.container}>
        {" "}
        <Box className={classes.topbar}>
          <Stack direction='row' px={1}>
            <Box className={classes.imgFrame}>
              {" "}
              <Box
                component='img'
                className={classes.img}
                src={profile?.logo ? profile?.logo : Logo}
              />
            </Box>
            <Box className={classes.text}>
              <Typography
                variant='h4'
                sx={{
                  marginTop: "10px",
                  fontSize: "28px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "600",
                  "@media (maxWidth: 468px)": {
                    marginTop: "5px",
                    fontSize: "18px",
                  },
                }}>
                {profile?.company_name ? profile?.company_name : ""}
              </Typography>
              <Typography
                variant='p'
                sx={{
                  fontSize: "18px",
                  textAlign: "left",
                  color: "#223870",
                  fontWeight: "normal",
                  "@media (maxWidth: 468px)": {
                    fontSize: "16px",
                  },
                }}>
                {/* {profile?.tagline ? profile?.tagline?.join(" | ") : ""} */}
              </Typography>
              <Stack
                direction='row'
                spacing={{ xs: 2, sm: 4, md: 4 }}
                sx={{
                  marginTop: "15px",
                  "@media (maxWidth: 468px)": {
                    marginTop: "5px",
                  },
                }}>
                <Box>
                  <Typography
                    variant='h7'
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                      "@media (maxWidth: 468px)": {
                        fontSize: "14px",
                      },
                    }}>
                    {" "}
                    {profile?.industry?.industry}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant='h7'
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                      "@media (maxWidth: 468px)": {
                        fontSize: "14px",
                      },
                    }}>
                    {profile?.size?.min} - {profile?.size?.max} Employees
                  </Typography>
                </Box>
                <Box>
                  {" "}
                  <Typography
                    variant='h7'
                    sx={{
                      textAlign: "left",
                      color: "#5071C4",
                      fontWeight: "500",
                      "@media (maxWidth: 468px)": {
                        fontSize: "14px",
                      },
                    }}>
                    {profile?.website ? profile?.website : ""}
                  </Typography>
                </Box>
              </Stack>
            </Box>

            <Box className={classes.button}>
              {" "}
              <Button
                onClick={redirect}
                sx={{
                  marginRight: "20px",
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "4px 16px",
                  width: "140px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "18px",
                  height: "48px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                  "@media (maxWidth: 468px)": {
                    padding: "4px 10px",
                    fontSize: "14px",
                  },
                }}>
                Create Job
              </Button>
              <Button
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "4px 16px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  width: "152px",
                  height: "48px",
                  fontWeight: "500",
                  fontSize: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                  "@media (maxWidth: 468px)": {
                    padding: "4px 10px",
                    fontSize: "14px",
                  },
                }}
                endIcon={
                  <KeyboardArrowDownIcon
                    sx={{
                      position: "absolute",
                      top: 14,
                      right: 8,
                    }}
                  />
                }
                onClick={handleClick}>
                More
              </Button>
              <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                    fontWeight: "500",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#223870",
                      color: "#FDCF57 ",
                    },
                  }}
                  onClick={redirectCreateAdmin}>
                  Create Admin
                </MenuItem>
                <Dialog
                  open={createAdmin}
                  onClose={handleCloseCreateAdmin}
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        padding: "0 50px",
                        width: "100%",
                        maxWidth: "600px",
                        height: "450px",
                        borderRadius: "5px",
                        backgroundColor: (theme) =>
                          theme.Palette.background.dialog,
                      },
                    },
                  }}>
                  <DialogActions>
                    <Box
                      onClick={handleCloseCreateAdmin}
                      component='img'
                      src={Close}
                      sx={{
                        top: "10px",
                        right: "10px",
                        width: "20px",
                        height: "20px",
                        position: "absolute",
                      }}
                    />
                  </DialogActions>
                  <DialogTitle
                    sx={{
                      backgroundColor: "none",
                      color: "#5677B9",
                      textAlign: "center",
                      fontSize: "20px",
                      marginBottom: "10px",
                    }}>
                    Create Admin
                  </DialogTitle>
                  <DialogContent>
                    <form onSubmit={handleSubmit(onSubmitAdmin)}>
                      {" "}
                      <Stack direction='row'>
                        <Box pr={0.5}>
                          <Typography
                            variant='p'
                            gutterBottom
                            sx={{
                              color: "#5677B9",
                              fontWeight: "500",
                              fontSize: "12px",
                            }}>
                            First Name*
                          </Typography>{" "}
                          <FormInput
                            sx={{
                              backgroundColor: "#E0DFF4",
                              borderRadius: "5px",
                              height: "35px",
                            }}
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            name='firstName'
                            {...register("firstName", {
                              required: "First name is required",
                            })}
                          />
                          {errors.firstName && (
                            <Box sx={{ color: "red", fontSize: "12px" }}>
                              {" "}
                              {errors.firstName.message}
                            </Box>
                          )}
                        </Box>
                        <Box pl={0.5}>
                          <Typography
                            variant='p'
                            gutterBottom
                            sx={{
                              color: "#5677B9",
                              fontWeight: "500",
                              marginLeft: "8px",
                              fontSize: "12px",
                            }}>
                            Last Name*
                          </Typography>{" "}
                          <FormInput
                            sx={{
                              backgroundColor: "#E0DFF4",
                              borderRadius: "5px",
                              height: "35px",
                            }}
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            name='lastName'
                            {...register("lastName", {
                              required: "Last name is required",
                            })}
                          />
                        </Box>
                      </Stack>
                      <Box mt={1}>
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#5677B9",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}>
                          Designation
                        </Typography>{" "}
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                            height: "35px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='designation'
                          {...register("designation", {
                            required: "designationis required",
                          })}
                        />
                      </Box>
                      <Box mt={1}>
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#5677B9",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}>
                          Email Id*
                        </Typography>{" "}
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                            height: "35px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='email'
                          {...register("email", {
                            required: "Email Id is required",
                          })}
                        />
                        {errors.email && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.email.message}
                          </Box>
                        )}
                      </Box>
                      <Box mt={1}>
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#5677B9",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}>
                          Mobile No.
                        </Typography>{" "}
                        <Grid container>
                          <Grid item sm={4} pr={2}>
                            <Autocomplete
                              id='country-select-demo'
                              sx={{ width: "100% " }}
                              value={selectedCountry}
                              options={countryListnew}
                              disableClearable
                              variant='standard'
                              popupIcon={
                                <KeyboardArrowDownIcon
                                  sx={{
                                    color: "#5071C4",
                                  }}
                                />
                              }
                              name='country_code'
                              onChange={(_event, country) => {
                                setSelectedCountry(country);
                              }}
                              size='small'
                              getOptionLabel={(option) => {
                                return option.iso3 + " +" + option.phone_code;
                              }}
                              renderInput={(params) => (
                                <FormInput
                                  sx={{
                                    backgroundColor: "#E0DFF4",
                                    borderRadius: "5px",
                                    height: "35px",
                                  }}
                                  size='small'
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "",
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item sm={8}>
                            <FormInput
                              size='small'
                              variant='outlined'
                              type='text'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              sx={{
                                width: "100%",
                                height: "35px",
                                color: "#5071C4",
                                backgroundColor: "#E0DFF4",
                                borderRadius: "5px",
                              }}
                              name='mobile'
                              {...register("mobile", {
                                required: "Mobile number is required",
                                pattern: {
                                  value: /^((\+91?)|\+)?[7-9][0-9]{9}$/,
                                  message:
                                    "Please enter a valid 10-digit mobile number",
                                },
                              })}
                            />{" "}
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        mt={3}
                        mb={3}
                        sx={{
                          textAlign: "center",
                        }}>
                        {" "}
                        <Button
                          type='submit'
                          sx={{
                            textTransform: "none",
                            textDecoration: "none",
                            padding: "3px 15px",
                            borderRadius: "5px ",
                            color: "#FDCF57",
                            fontWeight: "500",
                            fontSize: "18px",
                            backgroundColor: "#223870",
                            "&:hover": {
                              backgroundColor: "#223870",
                              color: "#F5BD31",
                            },
                          }}>
                          Send Mail
                        </Button>
                      </Box>
                    </form>
                  </DialogContent>
                </Dialog>
                <MenuItem
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                    fontWeight: "500",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#223870",
                      color: "#FDCF57 ",
                    },
                  }}
                  onClick={redirectCreateRecruiter}>
                  Create Recruiter
                </MenuItem>
                <Dialog
                  open={createRecruiter}
                  onClose={handleCloseCreateRecruiter}
                  sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        padding: "0 50px",
                        width: "100%",
                        maxWidth: "600px",
                        height: "520px",
                        borderRadius: "5px",
                        backgroundColor: (theme) =>
                          theme.Palette.background.dialog,
                      },
                    },
                  }}>
                  <DialogActions>
                    <Box
                      onClick={handleCloseCreateRecruiter}
                      component='img'
                      src={Close}
                      sx={{
                        top: "10px",
                        right: "10px",
                        width: "20px",
                        height: "20px",
                        position: "absolute",
                      }}
                    />
                  </DialogActions>
                  <DialogTitle
                    sx={{
                      backgroundColor: "none",
                      color: "#5677B9",
                      textAlign: "center",
                      fontSize: "20px",
                      marginBottom: "10px",
                    }}>
                    Create Recruiter
                  </DialogTitle>
                  <DialogContent>
                    <form onSubmit={handleSubmit(onSubmitRecruiter)}>
                      {" "}
                      <Stack direction='row'>
                        <Box pr={0.5}>
                          <Typography
                            variant='p'
                            gutterBottom
                            sx={{
                              color: "#5677B9",
                              fontWeight: "500",
                              fontSize: "12px",
                            }}>
                            First Name*
                          </Typography>{" "}
                          <FormInput
                            sx={{
                              backgroundColor: "#E0DFF4",
                              borderRadius: "5px",
                              height: "35px",
                            }}
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            name='firstName'
                            {...register("firstName", {
                              required: "First name is required",
                            })}
                          />
                          {errors.firstName && (
                            <Box sx={{ color: "red", fontSize: "12px" }}>
                              {" "}
                              {errors.firstName.message}
                            </Box>
                          )}
                        </Box>
                        <Box pl={0.5}>
                          <Typography
                            variant='p'
                            gutterBottom
                            sx={{
                              color: "#5677B9",
                              fontWeight: "500",
                              marginLeft: "8px",
                              fontSize: "12px",
                            }}>
                            Last Name*
                          </Typography>{" "}
                          <FormInput
                            sx={{
                              backgroundColor: "#E0DFF4",
                              borderRadius: "5px",
                              height: "35px",
                            }}
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            name='lastName'
                            {...register("lastName", {
                              required: "Last name is required",
                            })}
                          />
                        </Box>
                      </Stack>
                      <Box mt={1}>
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#5677B9",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}>
                          Designation
                        </Typography>{" "}
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                            height: "35px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='designation'
                          {...register("designation", {
                            required: "designationis required",
                          })}
                        />
                      </Box>
                      <Box mt={1}>
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#5677B9",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}>
                          Reporting Manager*
                        </Typography>{" "}
                        <Select
                          style={{
                            width: "100%",
                            height: 36,
                          }}
                          MenuProps={{
                            MenuListProps: { disablePadding: true },
                            PaperProps: {
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 150,
                              },
                            },
                          }}
                          fullWidth
                          displayEmpty
                          value={repoManager}
                          onChange={handleRepoManager}
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                //   color: (theme) => theme.Palette.text.black,
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            "& .MuiSelect-outlined": {
                              padding: "8.5px",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            width: "100%",
                            color: "#5677B9",
                            backgroundColor: "#E0DFF4",
                            fontWeight: 400,
                          }}
                          // renderValue={(selected) => {
                          //   console.log(selected, 'selected value');
                          //   return selected.length === 0
                          //     ? ''
                          //     : selected + ',' + ' ';
                          // }}
                        >
                          {/* {industryList
                            ? industryList?.map((item, key) => { */}
                          {/* return ( */}
                          <MenuItem value='a'>Ananth Reddy</MenuItem>
                          <MenuItem value='b'>Bhagya Shree</MenuItem>
                        </Select>
                      </Box>
                      <Box mt={1}>
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#5677B9",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}>
                          Access*
                        </Typography>{" "}
                        <Select
                          style={{
                            width: "100%",
                            height: 36,
                          }}
                          MenuProps={{
                            MenuListProps: { disablePadding: true },
                            PaperProps: {
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 150,
                              },
                            },
                          }}
                          fullWidth
                          value={access || []}
                          displayEmpty
                          multiple
                          onChange={handleAccess}
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            "& .MuiSelect-outlined": {
                              padding: "8.5px",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            width: "100%",
                            color: "#5677B9",
                            backgroundColor: "#E0DFF4",
                            fontWeight: 400,
                          }}
                          renderValue={(selected) => selected + ","}>
                          <MenuItem value='all'>
                            <ListItemIcon>
                              <Checkbox
                                checked={isAllAccess}
                                indeterminate={
                                  access?.length > 0 &&
                                  access.length < options.length
                                }
                              />
                            </ListItemIcon>
                            <ListItemText primary='Select All' />
                          </MenuItem>
                          {options.map((option) => (
                            <MenuItem key={option.id} value={option}>
                              <ListItemIcon>
                                <Checkbox checked={access?.includes(option)} />
                              </ListItemIcon>
                              <ListItemText primary={option.title}>
                                {option}
                              </ListItemText>
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box mt={1}>
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#5677B9",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}>
                          Email Id*
                        </Typography>{" "}
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                            height: "35px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='email'
                          {...register("email", {
                            required: "Email Id is required",
                          })}
                        />
                        {errors.email && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.email.message}
                          </Box>
                        )}
                      </Box>
                      <Box mt={1}>
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#5677B9",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}>
                          Mobile No.
                        </Typography>{" "}
                        <Grid container>
                          <Grid item sm={4} pr={2}>
                            <Autocomplete
                              id='country-select-demo'
                              sx={{ width: "100% " }}
                              value={selectedCountry}
                              options={countryListnew}
                              disableClearable
                              variant='standard'
                              popupIcon={
                                <KeyboardArrowDownIcon
                                  sx={{
                                    color: "#5071C4",
                                  }}
                                />
                              }
                              name='country_code'
                              onChange={(_event, country) => {
                                setSelectedCountry(country);
                              }}
                              size='small'
                              getOptionLabel={(option) => {
                                return option.iso3 + " +" + option.phone_code;
                              }}
                              renderInput={(params) => (
                                <FormInput
                                  sx={{
                                    backgroundColor: "#E0DFF4",
                                    borderRadius: "5px",
                                    height: "35px",
                                  }}
                                  size='small'
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "",
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item sm={8}>
                            <FormInput
                              size='small'
                              variant='outlined'
                              type='text'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              fullWidth
                              sx={{
                                width: "100%",
                                height: "35px",
                                color: "#5071C4",
                                backgroundColor: "#E0DFF4",
                                borderRadius: "5px",
                              }}
                              name='mobile'
                              onChange={(e) => setMobile(e.target.value)}
                              {...register("mobile", {
                                required: "Mobile number is required",
                                pattern: {
                                  value: /^((\+91?)|\+)?[7-9][0-9]{9}$/,
                                  message:
                                    "Please enter a valid 10-digit mobile number",
                                },
                              })}
                            />{" "}
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        mt={3}
                        mb={3}
                        sx={{
                          textAlign: "center",
                        }}>
                        {" "}
                        <Button
                          type='submit'
                          sx={{
                            textTransform: "none",
                            textDecoration: "none",
                            padding: "3px 15px",
                            borderRadius: "5px ",
                            color: "#FDCF57",
                            fontWeight: "500",
                            fontSize: "18px",
                            backgroundColor: "#223870",
                            "&:hover": {
                              backgroundColor: "#223870",
                              color: "#F5BD31",
                            },
                          }}>
                          Send Mail
                        </Button>
                      </Box>
                    </form>
                  </DialogContent>
                </Dialog>
                <MenuItem
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                    fontWeight: "500",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#223870",
                      color: "#FDCF57 ",
                    },
                  }}
                  onClick={handleAdminAccess}>
                  Manage Access
                </MenuItem>

                <MenuItem
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                    fontWeight: "500",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#223870",
                      color: "#FDCF57 ",
                    },
                  }}
                  onClick={redirectedit}>
                  Edit Profile
                </MenuItem>
              </StyledMenu>
            </Box>
          </Stack>
        </Box>
        {showAccess ? (
          <Box className={classes.body}>
            <Box px={2} pb={3}>
              {" "}
              <Typography
                variant='h4'
                sx={{
                  marginTop: "10px",
                  fontSize: "22px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "600",
                }}>
                {" "}
                Manage Access
              </Typography>
              <Typography
                variant='h4'
                sx={{
                  marginTop: "20px",
                  fontSize: "20px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "500",
                }}>
                {" "}
                Admins
              </Typography>
            </Box>
            <ManageAccessAdmin />
            <Box px={2} py={3}>
              <Typography
                variant='h4'
                sx={{
                  marginTop: "10px",
                  fontSize: "20px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "500",
                }}>
                {" "}
                Recruiters
              </Typography>
            </Box>
            <ManageAccessRecruiter />
          </Box>
        ) : (
          <Box className={classes.body}>
            <Box>
              <Grid container px={1}>
                <Grid item sm={7}>
                  {" "}
                  <Typography
                    variant='h6'
                    sx={{
                      textAlign: "left",
                      color: "#223870",
                      fontWeight: "600",
                      marginBottom: "20px",
                    }}>
                    Jobs
                  </Typography>
                </Grid>
                <Grid item sm={5}>
                  {" "}
                  <Stack direction='row'>
                    <Box mr={2}>
                      {" "}
                      <Select
                        className={classes.dropdown}
                        value={self}
                        onChange={handleSelf}
                        MenuProps={{
                          MenuListProps: { disablePadding: true },
                        }}
                        fullWidth
                        size='small'
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: (theme) => theme.Palette.text.black,
                              position: "absolute",
                              right: 10,
                              width: "20px",
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        sx={{
                          fontSize: "14px",
                          width: "auto",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                          height: "28px",
                          "& .MuiSelect-outlined": {
                            padding: "6px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}>
                        <MenuItem
                          value='self'
                          sx={{
                            fontSize: "14px",
                            height: "25px",
                            width: "100%",
                            backgroundColor: "#DFE5F6",
                            color: "#223870",
                          }}>
                          Self
                        </MenuItem>
                        <hr
                          style={{
                            backgroundColor: " #CCD3E8",
                            border: "0",
                            height: "1px",
                          }}
                        />
                        <MenuItem
                          value='assigned'
                          sx={{
                            fontSize: "14px",
                            height: "25px",
                            width: "100%",
                            backgroundColor: "#DFE5F6",
                            color: "#223870",
                          }}>
                          Assigned
                        </MenuItem>
                      </Select>
                    </Box>
                    <Box mr={2}>
                      {" "}
                      <Select
                        className={classes.dropdown}
                        value={openJob}
                        onChange={handleOpen}
                        MenuProps={{
                          MenuListProps: { disablePadding: true },
                        }}
                        fullWidth
                        size='small'
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: (theme) => theme.Palette.text.black,
                              position: "absolute",
                              right: 10,
                              width: "20px",
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        sx={{
                          fontSize: "14px",
                          width: "auto",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                          height: "28px",
                          "& .MuiSelect-outlined": {
                            padding: "6px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}>
                        <MenuItem
                          value={0}
                          sx={{
                            fontSize: "14px",
                            height: "25px",
                            width: "100%",
                            backgroundColor: "#DFE5F6",
                            color: "#223870",
                          }}>
                          All ({allJobs?.length > 0 ? allJobs?.length : 0})
                        </MenuItem>
                        <MenuItem
                          value={1}
                          sx={{
                            fontSize: "14px",
                            height: "25px",
                            width: "100%",
                            backgroundColor: "#DFE5F6",
                            color: "#223870",
                          }}>
                          Open ({openJobs?.length > 0 ? openJobs?.length : "0"})
                        </MenuItem>

                        <hr
                          style={{
                            backgroundColor: " #CCD3E8",
                            border: "0",
                            height: "1px",
                          }}
                        />
                        <MenuItem
                          value={2}
                          sx={{
                            fontSize: "14px",
                            height: "25px",
                            width: "100%",
                            backgroundColor: "#DFE5F6",
                            color: "#223870",
                          }}>
                          inactive (
                          {inactiveJobs?.length > 0
                            ? inactiveJobs?.length
                            : "0"}
                          ){" "}
                        </MenuItem>
                        <hr
                          style={{
                            backgroundColor: " #CCD3E8",
                            border: "0",
                            height: "1px",
                          }}
                        />
                        <MenuItem
                          value={3}
                          sx={{
                            fontSize: "14px",
                            height: "25px",
                            width: "100%",
                            backgroundColor: "#DFE5F6",
                            color: "#223870",
                          }}>
                          closed (
                          {closedJobs?.length > 0 ? closedJobs?.length : "0"})
                        </MenuItem>
                      </Select>
                    </Box>
                    <Box>
                      <Select
                        className={classes.dropdown}
                        value={latest}
                        onChange={handleLatest}
                        MenuProps={{
                          MenuListProps: { disablePadding: true },
                        }}
                        fullWidth
                        size='small'
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: (theme) => theme.Palette.text.black,
                              position: "absolute",
                              right: 10,
                              width: "20px",
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        sx={{
                          fontSize: "14px",
                          width: "auto",
                          backgroundColor: "#DFE5F6",
                          color: "#223870",
                          height: "28px",
                          "& .MuiSelect-outlined": {
                            padding: "6px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}>
                        <MenuItem
                          value='latest'
                          sx={{
                            fontSize: "14px",
                            height: "25px",
                            width: "100%",
                            backgroundColor: "#DFE5F6",
                            color: "#223870",
                          }}>
                          Posted Latest
                        </MenuItem>
                        <hr
                          style={{
                            backgroundColor: " #CCD3E8",
                            border: "0",
                            height: "1px",
                          }}
                        />
                        <MenuItem
                          value='oldest'
                          sx={{
                            fontSize: "14px",
                            height: "25px",
                            width: "100%",
                            backgroundColor: "#DFE5F6",
                            color: "#223870",
                          }}>
                          Posted Oldest
                        </MenuItem>
                      </Select>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                "@media (maxWidth: 468px)": { marginTop: "10px" },
              }}>
              <Box sx={{ position: "relative" }}>
                <Button
                  sx={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    width: "20px",
                    height: "20px",
                    "@media (maxWidth: 468px)": {
                      width: "16px",
                      height: "16px",
                      top: "10px",
                    },
                  }}
                  onClick={seeMoreOpen}>
                  {openJobs?.length > 1 &&
                  openJobs?.length !== numberOfOpenJobs ? (
                    <Box
                      component='img'
                      sx={{
                        width: "20px",
                        height: "20px",
                        "@media (maxWidth: 468px)": {
                          width: "16px",
                          height: "16px",
                        },
                      }}
                      src={dropDown}
                    />
                  ) : (
                    <Box
                      component='img'
                      sx={{
                        width: "20px",
                        height: "20px",
                        "@media (maxWidth: 468px)": {
                          width: "16px",
                          height: "16px",
                        },
                      }}
                      src={UpArrow}
                    />
                  )}
                </Button>

                {openJobs?.length > 0 &&
                  openJobs?.length === numberOfOpenJobs && (
                    <Button
                      sx={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        width: "20px",
                        height: "20px",
                        "@media (maxWidth: 468px)": {
                          width: "16px",
                          height: "16px",
                          top: "10px",
                        },
                      }}
                      onClick={seeLessOpen}>
                      <Box
                        component='img'
                        sx={{
                          width: "20px",
                          height: "20px",
                          "@media (maxWidth: 468px)": {
                            width: "16px",
                            height: "16px",
                          },
                        }}
                        src={UpArrow}
                      />
                    </Button>
                  )}
              </Box>
              <Grid
                container
                direction='row'
                spacing={0}
                justify='center'
                alignItems='center'
                // columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}
              >
                {openJobs?.length > 0 &&
                  openJobs?.slice(0, numberOfOpenJobs)?.map((list, index) => {
                    return (
                      <Grid item xs={12} sm={6} key={index}>
                        <Box
                          className={classes.jobbox}
                          onClick={redirectCompanyJd}>
                          <JobCardMain data={list} />
                        </Box>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
            <Box
              sx={{
                //  marginTop: '20px',
                "@media (maxWidth: 468px)": { marginTop: "10px" },
              }}>
              <Box sx={{ position: "relative" }}>
                {/* <Typography
              variant='h6'
              sx={{
                color: '#223870',
                fontWeight: '600',
                marginBottom: '20px',
              }}
            >
              Inactive (
              {inactiveJobs?.length > 0 ? inactiveJobs?.length : '0'})
            </Typography> */}
                <Button
                  sx={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    width: "20px",
                    height: "20px",
                    "@media (maxWidth: 468px)": {
                      width: "16px",
                      height: "16px",
                      top: "10px",
                    },
                  }}>
                  {" "}
                  <Box
                    component='img'
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (maxWidth: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={dropDown}
                    onClick={seeMoreInactive}
                  />
                </Button>
              </Box>
              <Grid
                container
                direction='row'
                spacing={0}
                justify='center'
                alignItems='center'
                columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}>
                {inactiveJobs?.length > 0 &&
                  inactiveJobs
                    ?.slice(0, numberOfInactiveJobs)
                    ?.map((list, index) => {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <Box className={classes.jobbox}>
                            <JobCardMain data={list} />
                          </Box>
                        </Grid>
                      );
                    })}
              </Grid>
            </Box>
            <Box
              sx={{
                // marginTop: '20px',
                "@media (maxWidth: 468px)": { marginTop: "10px" },
              }}>
              <Box sx={{ position: "relative" }}>
                {/* <Typography
              variant='h6'
              sx={{
                color: '#223870',
                fontWeight: '600',
                marginBottom: '20px',
              }}
            >
              Closed ({closedJobs?.length > 0 ? closedJobs?.length : '0'})
            </Typography> */}
                <Button
                  sx={{
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                    width: "20px",
                    height: "20px",
                    "@media (maxWidth: 468px)": {
                      width: "16px",
                      height: "16px",
                      top: "10px",
                    },
                  }}>
                  {" "}
                  <Box
                    component='img'
                    sx={{
                      width: "20px",
                      height: "20px",
                      "@media (maxWidth: 468px)": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                    src={dropDown}
                    onClick={seeMoreClosed}
                  />
                </Button>
              </Box>
              <Grid
                container
                direction='row'
                spacing={0}
                justify='center'
                alignItems='center'
                columns={{ xs: 2, sm: 9, md: 12, lg: 12 }}>
                {closedJobs?.length > 0 &&
                  closedJobs
                    ?.slice(0, numberOfClosedJobs)
                    ?.map((list, index) => {
                      return (
                        <Grid item xs={12} sm={6} key={index}>
                          <Box className={classes.jobbox}>
                            <JobCardMain data={list} />
                          </Box>
                        </Grid>
                      );
                    })}
              </Grid>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
export default CompanyPage;
