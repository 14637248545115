import React from "react";
import { Grid, Box } from "@mui/material";
import StatBg from "../../../assets/images/StatsBG.png";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainStat: {
    backgroundImage: `url(${StatBg})`,
    backgroundColor: "#5677B9",
    opacity: "0.98",
    height: "auto",
    width: "100%",
    padding: "100px 0px",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
  },
  content: {
    padding: "0 20px",
  },
  head: {
    fontSize: "30px",
    fontWeight: " 500",
    color: "#fdcf57",
    marginTop: "20px",
  },
  text: {
    color: "white",
    fontSize: "22px",
    fontWeight: "400",
  },
  "@media (maxWidth: 468px)": {
    head: {
      fontSize: "26px",
    },
    mainStat: {
      backgroundImage: "none",
      backgroundColor: "#5677B9",
      padding: "30px 0px",
      textAlign: "center",
    },
    text: {
      fontSize: "16px",
      lineHeight: "18px",
    },
    serviceContainer: { padding: "0 15px" },
    title: { textAlign: "center" },
    subtitle: { textAlign: "center" },
    heading: { textAlign: "center" },
  },
}));

const Stat = () => {
  const classes = useStyles();
  return (
    <>
      <Box sx={{ flexGrow: 1 }} className={classes.mainStat}>
        <Grid sm={12} md={12} container>
          {" "}
          <Grid item xs={6} sm={2} className={classes.content}>
            <Box className={classes.head}>48 hrs</Box>
            <Box className={classes.text}>Average TAT</Box>
          </Grid>{" "}
          <Grid item xs={6} sm={2} className={classes.content}>
            <Box className={classes.head}>100+</Box>
            <Box className={classes.text}>Active Clients</Box>
          </Grid>
          <Grid item xs={6} sm={2} className={classes.content}>
            <Box className={classes.head}>Above 80%</Box>
            <Box className={classes.text}>Profile Relevancy</Box>
          </Grid>
          <Grid item xs={6} sm={2} className={classes.content}>
            <Box className={classes.head}>Above 30%</Box>
            <Box className={classes.text}>Diversity Ratio</Box>
          </Grid>
          <Grid item xs={6} sm={2} className={classes.content}>
            <Box className={classes.head}>5000+</Box>
            <Box className={classes.text}>Total Candidate's Places</Box>
          </Grid>
          <Grid item xs={6} sm={2} className={classes.content}>
            <Box className={classes.head}>15 Days</Box>
            <Box className={classes.text}>Average TAT for Selection</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default Stat;
