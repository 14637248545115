import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Typography,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
} from "@mui/material";
import { FormInput } from "../../../../../components/Input/FormInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Close from "../../../../../assets/Icons/Close.png";
import addMore from "../../../../../assets/Icons/addMore.png";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  addmore: {
    borderRadius: "10px",
    marginTop: "10px",
    height: "auto",
    backgroundColor: "#DFE5F6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
  },
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "8.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  "@media (min-width: 1600px)": {},
  "@media (maxWidth: 468px)": {},
}));

const AddFeedback = () => {
  const classes = useStyles();
  // const navigate = useNavigate();
  const [result, setResult] = React.useState("clear");

  const handleResult = (event) => {
    setResult(event.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [jobstatus, setJobstatus] = React.useState("Open");

  const handleChange = (event) => {
    setJobstatus(event.target.value);
  };
  return (
    <>
      <Box>
        <Box className={classes.addmore}>
          <Box onClick={handleClickOpen}>
            {" "}
            <Box
              component='img'
              src={addMore}
              sx={{
                width: "16px",
                height: "16px",
                marginTop: "5px",
                marginRight: "5px",
              }}
            />
            <Typography
              variant='p'
              gutterBottom
              sx={{ color: "#5071C4", fontWeight: "600", fontSize: "16px" }}>
              Add Interview Feedback
            </Typography>
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  padding: "0 20px",
                  width: "100%",
                  maxWidth: "960px",
                  height: "460px",
                  borderRadius: "10px",
                  backgroundColor: (theme) => theme.Palette.background.dialog,
                },
              },
            }}>
            <DialogActions>
              <Box
                onClick={handleClose}
                component='img'
                src={Close}
                sx={{
                  top: "10px",
                  right: "10px",
                  width: "20px",
                  height: "20px",
                  position: "absolute",
                }}
              />
            </DialogActions>
            <DialogTitle
              sx={{
                backgroundColor: "none",
                color: "#223870",
                textAlign: "center",
                fontSize: "22px",
                marginBottom: "15px",
              }}>
              Add Feedback
            </DialogTitle>
            <DialogContent>
              <Stack
                direction='row'
                sx={{ display: "flex", justifyContent: "space-around" }}>
                <Box>
                  <Stack spacing={0}>
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        textAlign: "left",
                        color: "#304672",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}>
                      Panel*
                    </Typography>{" "}
                    <Box
                      sx={{
                        "& > :not(style)": {
                          width: "45ch",
                        },
                      }}>
                      <FormInput
                        sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                        size='small'
                        variant='outlined'
                        type='text'
                        name='round'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        onChange={handleChange}
                      />
                    </Box>
                  </Stack>{" "}
                </Box>
                <Box>
                  {" "}
                  <Stack spacing={0}>
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        textAlign: "left",
                        color: "#304672",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}>
                      Result*
                    </Typography>{" "}
                    <Box
                      sx={{
                        "& > :not(style)": {
                          width: "45ch",
                        },
                      }}>
                      <Select
                        required
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: "#5071C4",
                              position: "absolute",
                              right: 10,
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        sx={{
                          width: "100%",
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                          fontWeight: "500",
                        }}
                        value={result}
                        onChange={handleResult}
                        className={classes.countrycode}>
                        <MenuItem value='clear'>Cleared</MenuItem>
                        <MenuItem value='not-clear'>Not Cleared</MenuItem>
                      </Select>
                    </Box>
                  </Stack>{" "}
                </Box>
              </Stack>
              <Box ml={2} mt={2}>
                {" "}
                <Typography
                  variant='p'
                  gutterBottom
                  sx={{
                    textAlign: "left",
                    color: "#304672",
                    fontWeight: "500",
                    fontSize: "14px",
                  }}>
                  Comments
                </Typography>{" "}
                <Box>
                  <FormControl variant='standard' sx={{ m: 0, mb: 2, mr: 2 }}>
                    <FormInput
                      // sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                      variant='standard'
                      fullWidth
                      multiline
                      rows={6}
                      maxRows={8}
                      sx={{
                        border: "none",
                        borderRadius: "5px",
                        paddingTop: "8px",
                        paddingRight: "8px",
                        paddingLeft: "8px",
                        width: "58.5vw",
                        backgroundColor: "#E0DFF4",
                        color: "#5071C4",
                      }}
                      // value={articleBody}
                      // onChange={handleChange}
                      placeholder='Start typing here...'
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  marginLeft: "30px",
                  marginTop: "10px",
                  textAlign: "center",
                }}>
                {" "}
                <Button
                  type='submit'
                  sx={{
                    textTransform: "none",
                    textDecoration: "none",
                    padding: "3px 35px",
                    borderRadius: "5px ",
                    color: "#FDCF57",
                    fontWeight: "500",
                    fontSize: "20px",
                    backgroundColor: "#223870",
                    "&:hover": {
                      backgroundColor: "#223870",
                      color: "#F5BD31",
                    },
                  }}>
                  Save
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
    </>
  );
};
export default AddFeedback;
