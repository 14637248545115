import axios from "axios";
import config from "./apiConfig";
import { setAuthorizationHeader, setAuthHeader } from "./interceptors";

const http_noauth = axios.create({
  baseURL: config.serverBaseUrl,
});
const http = axios.create({
  baseURL: config.serverBaseUrl,
});
const http_local = axios.create({
  baseURL: config.serverBaseUrlng,
});

const http_linkcxo = axios.create({
  baseURL: config.serverBaseUrl2,
});
http_linkcxo.interceptors.request.use(setAuthHeader);

http.interceptors.request.use(setAuthorizationHeader);
http_local.interceptors.request.use(setAuthorizationHeader);

http_noauth.interceptors.response.use(
  (response) => {
    console.log("http_noauth", response);
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      console.log("authorisation failed");
    }
    return error;
  }
);

export { http_noauth, http, http_local, http_linkcxo };
