import * as React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  padding: 0,
  border: "1px solid #223870",
  height: "12px",
  width: "30px",
  borderRadius: "50px",
  backgroundColor: "#fff",
  "& .MuiSwitch-root": {
    border: "3px solid #223870",
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    marginTop: -1,
    transitionDuration: "300ms",

    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#fff",
        opacity: 1,
        border: "0",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#223870",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 12,
    height: 12,
    color: "#223870",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#fff",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function SmallSwitch(props) {
  return (
    <IOSSwitch
      sx={{ margin: "0 5px 10px 5px" }}
      {...props}
      checked={props.checked}
      onChange={props.onChange}
    />
  );
}
