import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  MenuItem,
  IconButton,
  Tooltip,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Menu,
} from "@mui/material";
import { FormInput } from "../../../components/Input/FormInput";
import { styled, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import experience from "../../../assets/Icons/Experience.png";
import jobtype from "../../../assets/Icons/JobType.png";
import industry from "../../../assets/Icons/Industry.png";
import locationIcon from "../../../assets/Icons/Location.png";
import date from "../../../assets/Icons/Calendar.png";
import designation from "../../../assets/Icons/Function.png";
import salary from "../../../assets/Icons/Salary.png";
import skills from "../../../assets/Icons/Skills.png";
import applicant from "../../../assets/Icons/Applicants.png";
import { Experience } from "../../../assets/Data/data";
import Close from "../../../assets/Icons/Close.png";
import { useForm } from "react-hook-form";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Edu from "../../../assets/Icons/Education.png";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { NoticePeriod } from "../../../assets/Data/data";
import * as jobsActions from "../../../Store/Jobs";
import { v4 as uuidv4 } from "uuid";
import ReferJobs from "../../../container/Pages/Recruiter/referJobs/ReferJobs";
import AWS from "aws-sdk";

const useStyles = makeStyles((theme) => ({
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "9px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "3px",
    marginTop: theme.spacing(8.6),
    minWidth: 120,
    textAlign: "center",
    "& .MuiMenu-list": {
      padding: "0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(0),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const S3_BUCKET = "exotalent-cv";
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: "AKIAWCKLV3UAPEYYOZO3",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const JobCardCompanyJd = (props) => {
  const classes = useStyles();
  const [status, setStatus] = React.useState("open");
  const usertype = useSelector((state) => state?.auth?.usertype);
  const [dialogShow, setdialogShow] = React.useState(false);

  const [uploadjd, setUploadJd] = React.useState(null);
  const [filepath, setFilepath] = React.useState("");
  const [noticePeriod, setNoticePeriod] = React.useState(0);
  const [experienceyear, setExperience] = React.useState();
  const [value, setValue] = React.useState(new Date());
  const profile = useSelector((state) => state?.auth?.profile);
  const [tempstatus, settempStatus] = React.useState("applied");
  const [showDialog, setShowDialog] = React.useState(false);
  const [showwithdrawDialog, setShowwithdrawDialog] = React.useState(false);

  const [showAssignDialog, setShowAssignDialog] = React.useState(false);
  const redirectAssign = () => {
    setShowAssignDialog(true);
  };
  const handleCloseAssign = () => {
    setShowAssignDialog(false);
  };

  const [showReferDialog, setShowReferDialog] = React.useState(false);
  const redirectRefer = () => {
    setShowReferDialog(true);
  };
  const handleCloseRefer = () => {
    setShowReferDialog(false);
  };

  const [assign, setAssign] = React.useState("exo");
  const handleAssign = (e) => {
    setAssign(e.target.value);
    if (e.target.value == "recruiter") {
      setAssignRecruiter(true);
    } else {
      setAssignRecruiter(false);
    }
  };
  const [assignRecruiter, setAssignRecruiter] = React.useState(false);
  const [recruiterEmail, setRecruiterEmail] = React.useState("haripriya");
  const handleRecruiterEmail = (e) => {
    setRecruiterEmail(e.target.value);
  };
  //Added
  const candidateProfile = useSelector(
    (state) => state?.auth?.candidateProfile
  );

  const candidateJobStatus = useSelector(
    (state) => state?.jobs?.candidateJobStatus
  );

  const candidateJobshow = useSelector(
    (state) => state?.jobs?.candidateJobshow
  );
  const jobDetails = useSelector((state) => state?.jobs?.jobDetails);
  const job_creator = useSelector((state) => state?.jobs?.job_creator);
  console.log("jobDetails", jobDetails, job_creator);
  const dispatch = useDispatch();
  let params = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  useEffect(() => {
    console.log("keywords", params);
    async function jobDetails() {
      const id = params?.id;
      const jobdetails = await dispatch(jobsActions.getJobDescription(id));
      console.log("jobdetails", jobdetails);
      setStatus(jobdetails?.data?.data[0].job_status);
      console.log("jobdetails", status);
    }
    jobDetails();
  }, []);

  useEffect(() => {
    async function profileJobApplied() {
      const data = {
        user_id: candidateProfile?._id,
        page_no: 0,
        interview_status: "applied",
      };
      if (usertype === "candidate") {
        await dispatch(jobsActions.getAllCandidateAppliedOpenJobs(data));
      }
    }
    profileJobApplied();
  }, []);

  useEffect(() => {
    async function profileJobShortlist() {
      const data = {
        user_id: candidateProfile?._id,
        page_no: 0,
        interview_status: "shortlisted",
      };
      if (usertype === "candidate") {
        await dispatch(jobsActions.getAllCandidateShortlistOpenJobs(data));
      }
    }
    profileJobShortlist();
  }, []);

  useEffect(() => {
    async function profileJobRejected() {
      const data = {
        user_id: candidateProfile?._id,
        page_no: 0,
        interview_status: "rejected",
      };
      if (usertype === "candidate") {
        await dispatch(jobsActions.getAllCandidateRejectedOpenJobs(data));
      }
    }
    profileJobRejected();
  }, []);

  useEffect(() => {
    async function profileJobHold() {
      const data = {
        user_id: candidateProfile?._id,
        page_no: 0,
        interview_status: "hold",
      };
      if (usertype === "candidate") {
        await dispatch(jobsActions.getAllCandidateHoldOpenJobs(data));
      }
    }
    profileJobHold();
  }, []);

  useEffect(() => {
    async function listofOpenJobs() {
      const data = {
        job_id: params?.id,
        interview_status: "applied",
        page_no: 0,
      };
      const jobList = await dispatch(
        jobsActions.getAllCompanyCandidateAppliedOpenJobs(data)
      );
      console.log("jobList==========", jobList);
    }
    listofOpenJobs();
  }, []);
  useEffect(() => {
    async function listofInactiveJobs() {
      const data = {
        job_id: params?.id,
        interview_status: "shortlisted",
        page_no: 0,
      };
      const inactiveJobList = await dispatch(
        jobsActions.getAllCompanyCandidateShortlistOpenJobs(data)
      );
      console.log("inactiveJobList ==========", inactiveJobList);
    }
    listofInactiveJobs();
  }, []);
  useEffect(() => {
    async function listofClosedJobs() {
      const data = {
        job_id: params?.id,
        interview_status: "onhold",
        page_no: 0,
      };
      const closedJobList = await dispatch(
        jobsActions.getAllCompanyCandidateHoldOpenJobs(data)
      );
      console.log("closedJobList ==========", closedJobList);
    }
    listofClosedJobs();
  }, []);
  useEffect(() => {
    async function listofRejectedJobs() {
      const data = {
        job_id: params?.id,
        interview_status: "rejected",
        page_no: 0,
      };
      const closedJobList = await dispatch(
        jobsActions.getAllCompanyCandidateRejectOpenJobs(data)
      );
      console.log("closedJobList ==========", closedJobList);
    }
    listofRejectedJobs();
  }, []);

  const npChange = (event) => {
    console.log("npChange1", event.target.value);
    setNoticePeriod(event.target.value);
  };
  const handleSelect = async (event) => {
    setShowDialog(true);
    settempStatus(event.target.value);
  };
  const navigate = useNavigate();

  const redirectApply = () => {
    setdialogShow(true);
  };

  const redirectWithdraw = () => {
    setShowwithdrawDialog(true);
  };
  const handlewithdrawOk = async () => {
    setShowwithdrawDialog(false);
    const data = {
      id: jobDetails?._id,
    };
    await dispatch(jobsActions.candidateWithdrawJob(data));

    const payload = {
      job_id: params?.id,
    };
    await dispatch(jobsActions.getCandidateJobAppliedStatus(payload));
  };

  const redirectEdit = () => {
    navigate("/editJob");
  };

  const redirectLogin = () => {
    navigate("/candidate/login");
  };
  const handleCloseApply = () => {
    setdialogShow(false);
  };

  const experienceChange = () => {};

  const handleOk = async () => {
    setShowDialog(false);
    setStatus(tempstatus);

    const data = {
      job_id: jobDetails?._id,
      job_status: tempstatus,
    };
    await dispatch(jobsActions.updateJobStatus(data));
  };
  const handleCancel = () => {
    setShowDialog(false);
    setShowwithdrawDialog(false);
  };

  const handleUploadJd = async (event) => {
    await setUploadJd(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    return uploadJd(event.target.files[0]);
  };
  const uploadJd = async (file) => {
    let namekey = uuidv4() + "_lx_" + file.name.replace(/ /g, "_");
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: namekey,
    };

    let objct = myBucket.putObject(params).promise();

    objct
      .then(function (data) {
        console.log(
          "aws Successfully uploaded data to " + S3_BUCKET + "/" + namekey
        );
        console.log("aws Success", data);
        let path =
          "https://" +
          S3_BUCKET +
          ".s3." +
          REGION +
          ".amazonaws.com/" +
          namekey;
        setFilepath(path);
      })
      .catch(function (err) {
        console.log("aws err", err);
      });
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const getSkills = () => {
    let skill_data = jobDetails?.skill?.map((skill, index) => {
      console.log(skill);
      return skill?.skill;
    });
    console.log("getSkills", skill_data);
    return skill_data?.join(", ");
  };

  const appliedOrnot = () => {
    console.log("candidateJobshow", candidateJobshow);

    if (
      candidateJobStatus?.interview_status === "applied" ||
      candidateJobStatus?.interview_status === "hold" ||
      candidateJobStatus?.interview_status === "rejected" ||
      candidateJobStatus?.interview_status === "shortlisted"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const showButton = () => {
    if (usertype === "candidate") {
      return (
        <Grid
          Item
          xs={12}
          sm={4}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}>
          {" "}
          {appliedOrnot() ? (
            <Stack direction='row'>
              <Box
                p={1}
                sx={{
                  display: "flex",
                  direction: "row",
                  alignItems: "center",
                }}>
                <Typography
                  variant='h5'
                  sx={{
                    marginLeft: "20px",
                    textAlign: "left",
                    color: "#304672",

                    "@media (maxWidth: 468px)": {
                      marginTop: "15px",
                      marginLeft: "0",
                      fontSize: "18px",
                    },
                  }}>
                  {candidateJobStatus?.interview_status ? "Status: " : ""}
                </Typography>

                <Typography
                  variant='h5'
                  sx={{
                    marginLeft: "10px",
                    textAlign: "left",
                    color: "#5677B9",
                    textTransform: "capitalize",
                    fontSize: "16px",

                    "@media (maxWidth: 468px)": {
                      marginTop: "15px",
                      marginLeft: "0",
                      fontSize: "16px",
                    },
                  }}>
                  {candidateJobStatus?.interview_status
                    ? candidateJobStatus?.interview_status
                    : ""}
                </Typography>
              </Box>
              <Button
                onClick={redirectWithdraw}
                disabled={candidateJobStatus?.withdraw_status}
                type='submit'
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "5px 30px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "16px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                  "@media (min-width: 1600px)": {
                    fontSize: "20px",
                  },
                }}>
                Withdraw
              </Button>
            </Stack>
          ) : (
            <Button
              onClick={redirectApply}
              type='submit'
              sx={{
                textTransform: "none",
                textDecoration: "none",
                padding: "5px 35px",
                borderRadius: "3px ",
                color: "#FDCF57",
                fontWeight: "500",
                fontSize: "16px",
                backgroundColor: "#223870",
                "&:hover": {
                  backgroundColor: "#223870",
                  color: "#FDCF57 ",
                },
                "@media (min-width: 1600px)": {
                  fontSize: "20px",
                },
              }}>
              Apply
            </Button>
          )}
        </Grid>
      );
    } else if (usertype === "company" && job_creator?.creator) {
      return (
        <Grid item xs={12} sm={4} direction='column'>
          <Grid item mb={1}>
            {" "}
            {jobDetails?.job_status !== "closed" && (
              <Box>
                <Button
                  onClick={redirectRefer}
                  type='submit'
                  sx={{
                    textTransform: "none",
                    textDecoration: "none",
                    width: "120px",
                    height: "40px",
                    borderRadius: "3px ",
                    color: "#FDCF57",
                    fontWeight: "500",
                    fontSize: "18px",
                    backgroundColor: "#223870",
                    "&:hover": {
                      backgroundColor: "#223870",
                      color: "#FDCF57 ",
                    },
                  }}>
                  Refer
                </Button>
                <Dialog
                  open={showReferDialog}
                  onClose={handleCloseRefer}
                  sx={{
                    overflowY: "hidden",
                    "& .MuiPaper-root": {
                      backgroundColor: "#F7FAFF",
                      overflow: "hidden",
                      borderRadius: "5px",
                    },
                    "& .MuiDialog-paper": {
                      width: 1000,
                      // height: 500,
                      maxHeight: "100%",
                      margin: "20px 0px",
                      maxWidth: "100%",
                    },
                  }}>
                  <DialogActions>
                    <Box
                      onClick={handleCloseRefer}
                      component='img'
                      src={Close}
                      sx={{
                        top: "10px",
                        right: "10px",
                        width: "20px",
                        height: "20px",
                        position: "absolute",
                      }}
                    />
                  </DialogActions>
                  <DialogTitle
                    sx={{
                      backgroundColor: "none",
                      color: "#5677B9",
                      textAlign: "center",
                      fontSize: "22px",
                      marginBottom: "15px",
                    }}>
                    Candidate Details
                  </DialogTitle>
                  <DialogContent>
                    <ReferJobs />
                  </DialogContent>
                </Dialog>
              </Box>
            )}
          </Grid>
          <Grid item mb={1}>
            {" "}
            <Box
              sx={{
                "& > :not(style)": {
                  width: "120px",
                  height: "40px",
                },
              }}>
              <Select
                required
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    sx={{
                      color: "#FDCF57 ",
                      position: "absolute",
                      right: 8,
                      pointerEvents: "none",
                    }}
                  />
                )}
                sx={{
                  borderRadius: "3px",
                  textAlign: "left",
                  fontSize: "18px",
                  width: "120px",
                  padding: "0 8px",
                  color: "#FDCF57 ",
                  backgroundColor: "#223870",
                  fontWeight: "500",
                }}
                value={status}
                onChange={handleSelect}
                className={classes.countrycode}>
                <MenuItem value='open'>Open</MenuItem>
                <MenuItem value='closed'>Closed</MenuItem>
                <MenuItem value='inactive'>Inactive</MenuItem>
              </Select>
            </Box>
          </Grid>

          <Grid item>
            {" "}
            <Button
              sx={{
                textTransform: "none",
                textDecoration: "none",
                padding: "4px 16px",
                borderRadius: "3px ",
                color: "#FDCF57",
                width: "120px",
                height: "40px",
                fontWeight: "500",
                fontSize: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                backgroundColor: "#223870",
                "&:hover": {
                  backgroundColor: "#223870",
                  color: "#FDCF57 ",
                },
                "@media (maxWidth: 468px)": {
                  padding: "4px 10px",
                  fontSize: "14px",
                },
              }}
              endIcon={
                <KeyboardArrowDownIcon
                  sx={{
                    position: "absolute",
                    top: 14,
                    right: 8,
                  }}
                />
              }
              onClick={handleClick}>
              More
            </Button>
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem
                sx={{
                  textAlign: "center",
                  backgroundColor: "#223870",
                  color: "#FDCF57 ",
                  fontWeight: "500",
                  fontSize: "16px",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                }}
                onClick={redirectAssign}>
                Assign
              </MenuItem>

              <Dialog
                open={showAssignDialog}
                onClose={handleCloseAssign}
                sx={{
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      padding: "0 20px",
                      width: "100%",
                      maxWidth: "600px",
                      height: "350px",
                      borderRadius: "5px",
                      backgroundColor: (theme) =>
                        theme.Palette.background.dialog,
                    },
                  },
                }}>
                <DialogActions>
                  <Box
                    onClick={handleCloseAssign}
                    component='img'
                    src={Close}
                    sx={{
                      top: "10px",
                      right: "10px",
                      width: "20px",
                      height: "20px",
                      position: "absolute",
                    }}
                  />
                </DialogActions>
                <DialogTitle
                  sx={{
                    backgroundColor: "none",
                    color: "#5677B9",
                    textAlign: "center",
                    fontSize: "22px",
                    marginBottom: "15px",
                  }}>
                  Assign this job
                </DialogTitle>
                <DialogContent>
                  <Stack
                    direction='row'
                    sx={{ display: "flex", justifyContent: "space-around" }}>
                    <Box>
                      {" "}
                      <Stack spacing={0}>
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            textAlign: "left",
                            color: "#5677B9",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}>
                          Assign job to
                        </Typography>{" "}
                        <Box
                          sx={{
                            "& > :not(style)": {
                              width: "45ch",
                            },
                          }}>
                          <Select
                            required
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                              fontWeight: "500",
                            }}
                            value={assign}
                            onChange={handleAssign}
                            className={classes.countrycode}>
                            <MenuItem value='exo'>Exotalent</MenuItem>
                            <MenuItem value='recruiter'>Recruiter</MenuItem>
                          </Select>
                        </Box>
                      </Stack>{" "}
                    </Box>
                  </Stack>

                  {assignRecruiter && (
                    <Box>
                      {" "}
                      <Stack
                        direction='row'
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}>
                        <Box>
                          {" "}
                          <Stack spacing={0} mt={2}>
                            <Typography
                              variant='p'
                              gutterBottom
                              sx={{
                                textAlign: "left",
                                color: "#5677B9",
                                fontWeight: "500",
                                fontSize: "14px",
                              }}>
                              Recruiter Email ID
                            </Typography>{" "}
                            <Box
                              sx={{
                                "& > :not(style)": {
                                  width: "45ch",
                                },
                              }}>
                              <Select
                                required
                                IconComponent={() => (
                                  <KeyboardArrowDownIcon
                                    sx={{
                                      color: "#5071C4",
                                      position: "absolute",
                                      right: 10,
                                      pointerEvents: "none",
                                    }}
                                  />
                                )}
                                sx={{
                                  width: "100%",
                                  color: "#5071C4",
                                  backgroundColor: "#E0DFF4",
                                  fontWeight: "500",
                                }}
                                value={recruiterEmail}
                                onChange={handleRecruiterEmail}
                                className={classes.countrycode}>
                                <MenuItem value='shreeRam'>
                                  shreeram@exotalent.in
                                </MenuItem>
                                <MenuItem value='haripriya'>
                                  haripriya@exotalent.in
                                </MenuItem>
                              </Select>
                            </Box>
                          </Stack>{" "}
                        </Box>
                      </Stack>
                    </Box>
                  )}
                  <Box
                    mt={5}
                    mb={3}
                    sx={{
                      textAlign: "center",
                    }}>
                    {" "}
                    <Button
                      // onClick={submitAssign}
                      sx={{
                        textTransform: "none",
                        textDecoration: "none",
                        padding: "3px 30px",
                        borderRadius: "5px ",
                        color: "#FDCF57",
                        fontWeight: "500",
                        fontSize: "18px",
                        backgroundColor: "#223870",
                        "&:hover": {
                          backgroundColor: "#223870",
                          color: "#F5BD31",
                        },
                      }}>
                      Assign
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog>

              {jobDetails?.job_status !== "closed" && (
                <MenuItem
                  sx={{
                    textAlign: "center",
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                    fontWeight: "500",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "#223870",
                      color: "#FDCF57 ",
                    },
                  }}
                  onClick={redirectEdit}>
                  Edit Profile
                </MenuItem>
              )}
            </StyledMenu>
          </Grid>
        </Grid>
      );
    } else if (usertype === "guest") {
      return (
        <Grid
          Item
          xs={12}
          sm={4}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}>
          <Grid
            container
            justify='center'
            alignItems='center'
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}>
            <Grid item>
              {" "}
              <Button
                onClick={redirectLogin}
                type='submit'
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "3px 30px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "16px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                  "@media (min-width: 1600px)": {
                    fontSize: "20px",
                  },
                }}>
                Apply
              </Button>
            </Grid>
          </Grid>
        </Grid>
      );
    } else if (usertype === "recruiter") {
      return (
        <Grid
          Item
          xs={12}
          sm={4}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}>
          <Grid
            container
            justify='center'
            alignItems='center'
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}>
            <Grid item>
              {" "}
              {jobDetails?.job_status !== "closed" && (
                <Box>
                  <Button
                    onClick={redirectRefer}
                    type='submit'
                    sx={{
                      textTransform: "none",
                      textDecoration: "none",
                      width: "120px",
                      height: "40px",
                      borderRadius: "3px ",
                      color: "#FDCF57",
                      fontWeight: "500",
                      fontSize: "18px",
                      backgroundColor: "#223870",
                      "&:hover": {
                        backgroundColor: "#223870",
                        color: "#FDCF57 ",
                      },
                    }}>
                    Refer
                  </Button>
                  <Dialog
                    open={showReferDialog}
                    onClose={handleCloseRefer}
                    sx={{
                      overflowY: "hidden",
                      "& .MuiPaper-root": {
                        backgroundColor: (theme) =>
                          theme.Palette.background.dialog,
                        overflow: "hidden",
                        borderRadius: "5px",
                      },
                      "& .MuiDialog-paper": {
                        width: 800,
                        height: "100vh",
                        maxHeight: "100%",
                        margin: "20px 0px",
                      },
                    }}>
                    <DialogActions>
                      <Box
                        onClick={handleCloseRefer}
                        component='img'
                        src={Close}
                        sx={{
                          top: "10px",
                          right: "10px",
                          width: "20px",
                          height: "20px",
                          position: "absolute",
                        }}
                      />
                    </DialogActions>
                    <DialogTitle
                      sx={{
                        backgroundColor: "none",
                        color: "#5677B9",
                        textAlign: "center",
                        fontSize: "22px",
                        marginBottom: "15px",
                      }}>
                      Candidate Details
                    </DialogTitle>
                    <DialogContent>
                      <ReferJobs />
                    </DialogContent>
                  </Dialog>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={12} sm={4}>
          <Grid
            container
            justify='center'
            alignItems='center'
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}>
            <Grid item></Grid>
            <Grid item> </Grid>
          </Grid>
        </Grid>
      );
    }
  };
  const onSubmit = async (data) => {
    console.log("JobCardCompanyJd", data);
    let last_working_date = null;
    if (noticePeriod === 0) {
      last_working_date = value;
    } else {
      last_working_date = null;
    }
    let file = candidateProfile?.cv;
    if (filepath?.length > 0) {
      file = filepath;
    }
    const dataapply = {
      id: jobDetails?._id,
      user_id: profile._id,
      total_relevant_experience: data?.years,
      expected_ctc: data?.expectedCtc,
      notice_period: noticePeriod,
      linkedin_profile: data?.linkedin,
      last_working_date: last_working_date,
      cv_attached: file,
    };
    const applyjob = await dispatch(jobsActions.candidateJobApply(dataapply));
    const payload = {
      job_id: jobDetails?._id,
    };
    await dispatch(jobsActions.getCandidateJobAppliedStatus(payload));
    if (applyjob?.data?.status === true) {
      setdialogShow(false);
    }
  };
  //added
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // added
  return (
    <>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth='xs'
        open={showwithdrawDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent dividers>
          Are you sure to withdraw from job
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} sx={{ cursor: "pointer" }}>
            Cancel
          </Button>
          <Button onClick={handlewithdrawOk}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth='xs'
        open={showDialog}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent dividers>
          Are you sure to change the status
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={dialogShow}
        onClose={handleCloseApply}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "750px",
              height: "400px",
              borderRadius: "8px",
              backgroundColor: (theme) => theme.Palette.background.dialog,
            },
          },
        }}>
        <DialogActions>
          <Box
            onClick={handleCloseApply}
            component='img'
            src={Close}
            sx={{
              top: "10px",
              right: "10px",
              width: "18px",
              height: "18px",
            }}
          />
        </DialogActions>
        <DialogTitle
          sx={{
            color: "#5677B9",
            textAlign: "center",
            fontSize: "20px",
            marginTop: "-15px",
          }}>
          Please tell us more about you
        </DialogTitle>
        <DialogContent
          sx={{
            marginTop: "10px ",
          }}>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              {" "}
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  padding: "0 30px",
                }}
                container
                direction='row'>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    paddingRight: "20px",
                    width: "100%",
                    margin: "0",
                  }}>
                  <Box>
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        fontSize: "14px",
                        marginLeft: "2px",
                        color: "#304672",
                        fontWeight: "500",
                        textAlign: "left",
                      }}>
                      Total Relevant Experience*
                    </Typography>{" "}
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        width: "100%",
                        marginRight: "10px",
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                        fontWeight: "500",
                      }}
                      value={experienceyear}
                      name='years'
                      onChange={experienceChange}
                      className={classes.countrycode}
                      {...register("years", {
                        required: "Select your experience in years ",
                      })}>
                      {Experience?.map((option) => {
                        return (
                          <MenuItem
                            key={option.label}
                            value={option.label}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {option.value ?? option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>{" "}
                    {errors.years && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.years.message}
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ marginTop: "5px" }}>
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        fontSize: "14px",
                        marginLeft: "2px",
                        color: "#304672",
                        fontWeight: "500",
                        textAlign: "left",
                      }}>
                      Notice Period*
                    </Typography>{" "}
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        width: "100%",
                        marginRight: "10px",
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                        fontWeight: "500",
                      }}
                      value={noticePeriod}
                      name='noticePeriod'
                      onChange={npChange}
                      className={classes.countrycode}>
                      {NoticePeriod?.map((option) => {
                        return (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>{" "}
                    {errors.noticePeriod && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.noticePeriod.message}
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ height: "62px" }}>
                    {noticePeriod === 0 && (
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            marginLeft: "2px",
                            color: "#304672",
                            fontWeight: "500",
                            textAlign: "left",
                            marginTop: "5px",
                          }}>
                          When is your last working day?
                        </Typography>{" "}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            // label="Date mobile"
                            inputFormat='MM/dd/yyyy'
                            value={value}
                            onChange={handleChange}
                            renderInput={(params) => (
                              <FormInput
                                size='small'
                                variant='outlined'
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6}
                  style={{
                    paddingLeft: "20px",
                    width: "100%",
                  }}>
                  <Box sx={{ marginTop: "0" }}>
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        marginLeft: "2px",
                        color: "#304672",
                        fontWeight: "500",
                        textAlign: "left",
                        fontSize: "14px",
                      }}>
                      Expected CTC* (In lacs)
                    </Typography>{" "}
                    <FormInput
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name='expectedCtc'
                      {...register("expectedCtc", {
                        required: "expected CTC is required",
                      })}
                    />
                    {errors.expectedCtc && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.expectedCtc.message}
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ marginTop: "5px" }}>
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        fontSize: "14px",
                        marginLeft: "2px",
                        color: "#304672",
                        fontWeight: "500",
                        textAlign: "left",
                        marginTop: "10px",
                      }}>
                      LinkedIn Profile Link*
                    </Typography>{" "}
                    <FormInput
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name='linkedin'
                      {...register("linkedin", {
                        required: "linkedin is required",
                      })}
                    />
                    {errors.linkedin && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.linkedin.message}
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ marginTop: "4px" }}>
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        fontSize: "14px",
                        marginLeft: "2px",
                        color: "#304672",
                        fontWeight: "500",
                        textAlign: "left",
                      }}>
                      Resume*
                    </Typography>{" "}
                    <Box
                      sx={{
                        backgroundColor: "#E0DFF4",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderRadius: "5px",
                      }}>
                      <input
                        id='icon-button-image'
                        type='file'
                        style={{
                          padding: "9px",
                          display: "block",
                          borderRadius: "5px",
                          backgroundColor: "#E0DFF4",
                          color: "#304672",
                        }}
                        onChange={handleUploadJd}
                        name='image'
                      />
                      <Box>
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#615d5d",
                            fontSize: "10px",
                            color: "#304672",
                          }}>
                          {candidateProfile?.cv}
                          {candidateProfile?.cv
                            ?.split("_lx_")[1]
                            .substring(20, 0)}
                        </Typography>
                      </Box>

                      <AttachFileIcon
                        sx={{ float: "right", marginTop: "4px" }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    {errors.browse?.type === "required" && "Upload your cv"}
                  </Box>
                  {/* <Box>
                    {" "}
                    <Typography
                      variant="p"
                      gutterBottom
                      sx={{
                        color: "#615d5d",
                        fontSize: "10px",
                      }}
                    >
                      DOC, DOCx, PDF, RTF l Max: 2 MB
                    </Typography>
                  </Box> */}
                </Grid>
                <Box sx={{ marginTop: "20px" }}>
                  {" "}
                  <input
                    type='submit'
                    value='Apply'
                    style={{
                      display: "flex",
                      border: "none",
                      justifyContent: "center",
                      width: "120px",
                      backgroundColor: "#223870",
                      borderRadius: "3px",
                      color: "#FDCF57",
                      marginTop: "3px",
                      padding: "6px 0px 8px",
                      fontSize: "18px",
                      textTransform: "none",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#FDCF57",
                        backgroundColor: "#223870",
                      },
                      "@media (maxWidth: 468px)": {
                        marginTop: "-29px",
                        textAlign: "center",
                        marginRight: "-10px",
                        padding: "0 10px",
                      },
                    }}
                  />
                </Box>
              </Grid>
            </form>
          </Box>
        </DialogContent>
      </Dialog>

      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid item>
          <Typography
            variant='h5'
            sx={{
              marginTop: "0",
              marginLeft: "10px",
              textAlign: "left",
              color: "#304672",
              fontWeight: "600",
              "@media (maxWidth: 468px)": {
                marginTop: "0",
                marginLeft: "0",
                fontSize: "22px",
              },
            }}>
            {jobDetails?.job_title}
          </Typography>
          {jobDetails?.is_company_name_hide ? (
            <Typography
              variant='h5'
              sx={{
                textAlign: "left",
                marginLeft: "12px",
                color: "#304672",
                fontSize: "18px",
                "@media (maxWidth: 468px)": {
                  marginTop: "15px",
                  marginLeft: "0",
                  fontSize: "18px",
                },
              }}>
              Confidential
            </Typography>
          ) : (
            <Typography
              variant='h5'
              sx={{
                textAlign: "left",
                marginLeft: "12px",
                color: "#304672",
                fontSize: "18px",
                "@media (maxWidth: 468px)": {
                  marginTop: "15px",
                  marginLeft: "0",
                  fontSize: "18px",
                },
              }}>
              {jobDetails?.company_name ? jobDetails?.company_name : "N.A"}
            </Typography>
          )}
        </Grid>

        <Grid item>
          {jobDetails?.is_company_name_hide ? (
            <Box
              sx={{
                top: "20px",
                right: "20px",
                width: "80px",
                height: "80px",
                backgroundColor: "#ffffff",
                "@media (maxWidth: 468px)": {
                  top: "10px",
                  right: "10px",
                  width: "60px",
                  height: "60px",
                },
              }}>
              <Typography
                variant='h6'
                sx={{
                  padding: "30px 0px",
                  fontSize: "12px",
                  textAlign: "center",
                  marginLeft: "-2px",
                  color: "#F00",
                  "@media (maxWidth: 468px)": {
                    fontSize: "8px",
                    lineHeight: "18px",
                    textAlign: "left",
                  },
                }}>
                Confidential
              </Typography>
            </Box>
          ) : (
            <Box
              component='img'
              src={jobDetails?.company_logo}
              sx={{
                top: "20px",
                right: "20px",
                width: "80px",
                height: "80px",
                "@media (maxWidth: 468px)": {
                  right: "15px",
                  width: "60px",
                  height: "60px",
                },
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "-30px" }}>
        <Grid item xs={12} sm={8}>
          <Grid container sx={{ marginTop: "8px" }}>
            <Grid item sm={4}>
              {" "}
              <Grid container>
                <Grid item xs={1} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title='Total Experience'
                    placement='left'
                    arrow>
                    <IconButton>
                      {" "}
                      <Box
                        component='img'
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                          "@media (maxWidth: 468px)": {
                            width: "15px",
                            height: "15px",
                          },
                        }}
                        src={experience}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={11} sm={11}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginLeft: "10px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                      "@media (maxWidth: 468px)": { fontSize: "14px" },
                      "@media (min-width: 1900px)": { marginLeft: "5px" },
                    }}>
                    {jobDetails?.experience?.min} -{" "}
                    {jobDetails?.experience?.max} yrs exp.
                  </Typography>{" "}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title='Job Type'
                    placement='left'
                    arrow>
                    <IconButton>
                      {" "}
                      <Box
                        component='img'
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                          "@media (maxWidth: 468px)": {},
                        }}
                        src={jobtype}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={11} sm={11}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginLeft: "12px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                      "@media (maxWidth: 468px)": { fontSize: "14px" },
                      "@media (min-width: 1900px)": { marginLeft: "5px" },
                    }}>
                    {jobDetails?.job_type?.job_type}
                  </Typography>{" "}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title='Industry'
                    placement='left'
                    arrow>
                    <IconButton>
                      {" "}
                      <Box
                        component='img'
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                          "@media (maxWidth: 468px)": {},
                        }}
                        src={industry}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={11} sm={11}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginLeft: "12px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                      "@media (maxWidth: 468px)": { fontSize: "14px" },
                      "@media (min-width: 1900px)": { marginLeft: "5px" },
                    }}>
                    {jobDetails?.industry?.industry?.length > 28
                      ? jobDetails?.industry?.industry?.substring(0, 28)
                      : jobDetails?.industry?.industry}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4}>
              {" "}
              <Grid container>
                <Grid item xs={1} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title='Location'
                    placement='left'
                    arrow>
                    <IconButton>
                      {" "}
                      <Box
                        component='img'
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                          "@media (maxWidth: 468px)": {},
                        }}
                        src={locationIcon}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={11} sm={11}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginLeft: "10px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                      "@media (maxWidth: 468px)": { fontSize: "14px" },
                      "@media (min-width: 1900px)": { marginLeft: "5px" },
                    }}>
                    {jobDetails?.location?.city}
                  </Typography>{" "}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    disableFocusListener
                    disableTouchListener
                    title='Date'
                    placement='left'
                    arrow>
                    <IconButton>
                      {" "}
                      <Box
                        component='img'
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                        }}
                        src={date}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={11} sm={11}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginLeft: "12px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                      "@media (maxWidth: 468px)": { fontSize: "14px" },
                      "@media (min-width: 1900px)": { marginLeft: "5px" },
                    }}>
                    Posted on{" "}
                    {moment(jobDetails?.created_at).format("D MMM, YYYY")}
                  </Typography>{" "}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title='Function'
                    placement='left'
                    arrow>
                    <IconButton>
                      {" "}
                      <Box
                        component='img'
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                        }}
                        src={designation}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={11} sm={11}>
                  <Typography
                    variant='h6'
                    sx={{
                      marginLeft: "12px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#5677B9",
                      "@media (maxWidth: 468px)": { fontSize: "14px" },
                      "@media (min-width: 1900px)": { marginLeft: "5px" },
                    }}>
                    {" "}
                    {jobDetails?.function?.function?.length > 30
                      ? jobDetails?.function?.function?.substring(0, 30)
                      : jobDetails?.function?.function}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={4}>
              {" "}
              <Grid container>
                <Grid item xs={1} sm={1}>
                  <Tooltip
                    enterNextDelay={1500}
                    enterDelay={1500}
                    leaveDelay={200}
                    title=' Salary'
                    placement='left'
                    arrow>
                    <IconButton>
                      {" "}
                      <Box
                        component='img'
                        sx={{
                          marginTop: "-5px",
                          width: "18px",
                          height: "18px",
                        }}
                        src={salary}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={11} sm={11}>
                  {jobDetails?.is_salary_hide ? (
                    <Typography
                      variant='h5'
                      sx={{
                        textAlign: "left",
                        marginLeft: "12px",
                        color: "#5677B9",
                        fontSize: "18px",
                        "@media (maxWidth: 468px)": {
                          marginTop: "15px",
                          marginLeft: "0",
                          fontSize: "18px",
                        },
                      }}>
                      Confidential
                    </Typography>
                  ) : (
                    <Typography
                      variant='h6'
                      sx={{
                        marginLeft: "12px",
                        fontSize: "16px",
                        textAlign: "left",
                        color: "#5677B9",
                        "@media (maxWidth: 468px)": { fontSize: "14px" },
                        "@media (min-width: 1900px)": { marginLeft: "5px" },
                      }}>
                      {jobDetails?.salary?.min} - {jobDetails?.salary?.max} lpa
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                {job_creator?.creator ? (
                  <>
                    <Grid item xs={12} sm={2}>
                      <Tooltip
                        enterNextDelay={1500}
                        enterDelay={1500}
                        leaveDelay={200}
                        title=' Total applicants'
                        placement='left'
                        arrow>
                        <IconButton>
                          {" "}
                          <Box
                            component='img'
                            sx={{
                              marginTop: "-5px",
                              width: "18px",
                              height: "18px",
                            }}
                            src={applicant}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <Typography
                        variant='h6'
                        sx={{
                          marginLeft: "-10px",
                          fontSize: "16px",
                          textAlign: "left",
                          color: "#5677B9",
                        }}>
                        {job_creator?.count}{" "}
                        {job_creator?.count > 1 ? "Applicants" : "Applicant"}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6}>
            <Grid item xs={1} sm={1}>
              <Tooltip
                enterNextDelay={1500}
                enterDelay={1500}
                leaveDelay={200}
                title='Education'
                placement='left'
                arrow>
                <IconButton>
                  {" "}
                  <Box
                    component='img'
                    sx={{
                      marginLeft: "-1px",
                      marginTop: "-5px",
                      width: "19px",
                      height: "19px",
                      "@media (min-width: 1600px)": { marginTop: "-5px" },
                    }}
                    src={Edu}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={11}>
              <Box
                sx={{
                  fontSize: "16px",
                  textAlign: "left",
                  color: "#5677B9",
                  "@media (maxWidth: 468px)": { fontSize: "14px" },
                  "@media (min-width: 1600px)": { marginLeft: "4px" },
                  "@media (min-width: 1900px)": { marginLeft: "-10px" },
                }}>
                <Typography
                  variant='p'
                  sx={{
                    textAlign: "left",
                    color: "#5677B9",
                    fontWeight: "500",
                    marginBottom: "20px",
                    "@media (maxWidth: 468px)": {
                      fontSize: "14px",
                    },
                  }}>
                  {jobDetails?.education?.degree}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container xs={12} sm={8}>
            <Grid item xs={1} sm={1}>
              <Tooltip
                enterNextDelay={1500}
                enterDelay={1500}
                leaveDelay={200}
                title='skills'
                placement='left'
                arrow>
                <IconButton>
                  {" "}
                  <Box
                    component='img'
                    sx={{
                      marginLeft: "-1px",
                      marginTop: "-5px",
                      width: "17px",
                      height: "17px",
                      "@media (min-width: 1600px)": {
                        marginTop: "-5px",
                      },
                    }}
                    src={skills}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={11} sm={11}>
              <Box
                sx={{
                  marginLeft: "-10px",
                  fontSize: "16px",
                  textAlign: "left",
                  color: "#5677B9",
                  "@media (maxWidth: 468px)": { fontSize: "14px" },
                  "@media (min-width: 1300px)": { marginLeft: "-8px" },
                  "@media (min-width: 1450px)": { marginLeft: "-15px" },
                  "@media (min-width: 1600px)": { marginLeft: "-15px" },
                  "@media (min-width: 1900px)": { marginLeft: "-27px" },
                }}>
                <Typography
                  variant='p'
                  sx={{
                    textAlign: "left",
                    color: "#5677B9",
                    fontWeight: "500",
                    marginBottom: "20px",
                    "@media (maxWidth: 468px)": {
                      fontSize: "14px",
                    },
                  }}>
                  {getSkills()}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2.5}></Grid>
        <Grid item xs={12} sm={0.5} mt={4} ml={4.5}>
          {showButton()}
        </Grid>
      </Grid>
    </>
  );
};
export default JobCardCompanyJd;
