import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import FeedbackCard from './feedback/feedbackCard';
import AddFeedback from './feedback/addFeedback';
import AddScheduleCard from './schedule/addScheduleCard';
import RescheduleCard from './schedule/rescheduleCard';
import ViewScheduleCard from './schedule/viewScheduleCard';
const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#223870',
  },
  '& .MuiTabs-flexContainer': {
    width: 'fit-content',
    padding: '2px 3px',
    borderRadius: 4,
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    width: 129,
    minHeight: 29,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    color: '#223870',
    fontFamily: [
      '-apple-system',
      'Montserrat',
      'BlinkMacSystemFont',
      '"Segoe UI"',
    ].join(','),
    fontSize: '22px',
    '&:hover': {
      color: '#223870',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#223870',
      backgroundColor: 'none',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabComponent() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <StyledTab label='Feedback' {...a11yProps(0)} />
          <StyledTab label='Schedule' {...a11yProps(1)} />
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box sx={{ margin: '20px 0' }}>
          {' '}
          <AddFeedback />
        </Box>

        <FeedbackCard />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box mb={3}>
          <AddScheduleCard />{' '}
        </Box>
        <Box mb={3}>
          <RescheduleCard />{' '}
        </Box>
        <Box mb={3}>
          <ViewScheduleCard />{' '}
        </Box>
      </TabPanel>
    </Box>
  );
}
