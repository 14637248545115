import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

export const FormInput = styled(TextField)(({ theme }) => {
  return {
    color: theme.Palette.text.secondary,
    backgroundColor: theme.Palette.background.input,
    borderRadius: "3px",
    padding: "0px auto",
    width: "100%",
    "& .MuiInputBase-root": {
      height: 37,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderRadius: "5px",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        borderRadius: "5px",
      },
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "8px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "15px",
    },
  };
});
