import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Autocomplete,
  Divider,
} from "@mui/material";
import { FormInput } from "../../../../components/Input/FormInput";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../../components/Header/Navbar";
import Close from "../../../../assets/Icons/Close.png";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { countryListnew } from "../../../../assets/Data/data";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useForm } from "react-hook-form";
import CustomizedSnackbars from "../../../../components/Snackbar/CustomizedSnackbars";
import OtpTimer from "../../../../components/OTP_Timer/OtpTimer";
import OtpInput from "react-otp-input";
import Avatar from "@mui/material/Avatar";
import Editicon from "../../../../assets/Icons/Edit_White.png";
import PersonIcon from "@mui/icons-material/Person";
import * as jobsActions from "../../../../Store/Jobs";
import * as authActions from "../../../../Store/candidate";
import * as helperActions from "../../../../Store/helper";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import { saveAs } from "file-saver";

const S3_BUCKET = "exotalent-cv";
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: "AKIAWCKLV3UAPEYYOZO3",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
const useStyles = makeStyles((theme) => ({
  // container: { width: "auto", padding: "0", margin: "0" },
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "9px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  topbar: {
    position: "relative",
    marginTop: "80px ",
    padding: "20px 200px",
    backgroundSize: "cover",
    backgroundColor: "#F0F1FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  imgFrame: {
    position: "relative",
    background: "#fff",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    objectFit: "cover",
    margin: "0 auto",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    backgroundSize: "cover",
  },
  imgFrame1: {
    bottom: "5px",
    right: "5px",
    position: "absolute",
    background: "#304672",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img1: {
    objectFit: "cover",
    margin: "0 auto",
    width: "15px",
    height: "15px",
    backgroundSize: "cover",
  },
  text: { marginLeft: "30px" },
  button: {
    position: "absolute",
    top: "55px",
    right: "100px",
  },
  body: {
    padding: "20px 80px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#F6F8FD",
    textAlign: "center",
  },
  jobbox: {
    marginLeft: "15px",
    padding: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },

  "@media (min-width: 1600px)": {
    mainHome: {
      marginTop: "80px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
    body: {
      height: "auto",
    },
  },
  "@media (maxWidth: 468px)": {
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "26px",
    },
    jobbox: {
      width: "100%",
      margin: "0",
      padding: "0 5px",
    },
    container: {
      width: "100%",
      padding: "0 0px",
    },
    topbar: {
      position: "relative",
      padding: "20px 10px",
      height: "170px",
      width: "100%",
    },
    imgFrame: {
      width: "90px",
      height: "90px",
    },
    img: {
      width: "80px",
      height: "80px",
    },
    text: { marginLeft: "10px" },
    button: {
      position: "absolute",
      top: "120px",
      right: "20px",
    },
    body: {
      padding: "0",
      borderRadius: "10px",
    },
  },
}));

const EditRecruiterProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const candidateProfile = useSelector(
    (state) => state?.auth?.candidateProfile
  );
  const [message, setMessage] = React.useState("");
  const [otp, setOtp] = useState("");
  const handleChangeOtp = (otp) => {
    setOtp(otp);
  };
  const [email, setEmail] = React.useState();
  const [mobile, setMobile] = React.useState();
  const [timer, setTimer] = useState(true);
  const timerZero = () => {
    setTimer(false);
  };
  const handleCloseMobile = async () => {
    // const closeMobile = await dispatch(
    //   authActions.closeCandidateMobileDialog()
    // );
  };
  const handleCloseEmail = async () => {
    //const closeEmail = await dispatch(authActions.closeCandidateEmailDialog());
    // setOpenEmail(false);
  };
  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: "IN",
    country_name: "India",
    iso3: "IND",
    phone_code: 91,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const [uploadpan, setUploadPan] = React.useState("");
  const [filepath, setFilepath] = React.useState("");
  const handleUploadPan = async (event) => {
    await setUploadPan(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL1(event.target.files[0]);
    return uploadPan(event.target.files[0]);
  };
  const uploadPan = async (file) => {
    let namekey = uuidv4() + "_lx_" + file.name.replace(/ /g, "_");
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: namekey,
    };

    let objct = myBucket.putObject(params).promise();

    console.log("aws ", objct);
    objct
      .then(function (data) {
        console.log(
          "aws Successfully uploaded data to " + S3_BUCKET + "/" + namekey
        );
        console.log("aws Success", data);
        let path =
          "https://" +
          S3_BUCKET +
          ".s3." +
          REGION +
          ".amazonaws.com/" +
          namekey;
        setFilepath(path);
      })
      .catch(function (err) {
        console.log("aws err", err);
      });
  };
  const saveAsf = () => {
    saveAs(
      recruiterProfile?.pan_file
      // recruiterProfile?.pan_file?.split('_lx_')[1]
    );
  };
  const [imagepath, setImagepath] = React.useState("");

  const uploadImage = async (file) => {
    let namekey = uuidv4() + "_ind_rec_" + file.name.replace(/ /g, "_");
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: namekey,
    };
    let objct = myBucket.putObject(params).promise();
    console.log("aws ", objct);
    objct
      .then(function (data) {
        console.log(
          "aws Successfully uploaded data to " + S3_BUCKET + "/" + namekey
        );
        console.log("aws Success", data);
        let path2 =
          "https://" +
          S3_BUCKET +
          ".s3." +
          REGION +
          ".amazonaws.com/" +
          namekey;
        setImagepath(path2);
      })
      .catch(function (err) {
        console.log("aws err", err);
      });
  };
  const handleUploadImage = async (event) => {
    await setUploadImage(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    return uploadImage(event.target.files[0]);
  };

  const recruiterProfile = useSelector(
    (state) => state?.auth?.recruiterProfile
  );

  const [uploadimage, setUploadImage] = React.useState(null);
  useEffect(() => {
    async function recruiterProfile() {
      const recruiterdetail = await dispatch(authActions.getRecruiterProfile());
      console.log("profile deatil ==========", recruiterdetail);
    }
    setUploadPan(recruiterProfile?.pan_file);
    recruiterProfile();
  }, [dispatch]);
  const onSubmit = async (data) => {
    let image = recruiterProfile?.profile_image;
    if (imagepath?.length > 0) {
      image = imagepath;
    }
    let pan_file = candidateProfile?.pan_file;
    if (filepath?.length > 0) {
      pan_file = filepath;
    }
    const updatedata = {
      first_name: recruiterProfile?.first_name,
      last_name: recruiterProfile?.last_name,
      profile_image: image,
      // bank_name: bank_name,
      // acct_no: acct_no,
      // branch_name: branch_name,
      // ifsc_code: ifsc_code,
      pan_file: pan_file,
    };
    const updaterecruiter = await dispatch(
      authActions.recruiterUpdateProfile(updatedata)
    );
    if (updaterecruiter?.data?.status === true) {
      navigate("/recruiter/profile");
    }
  };
  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.container}>
        {" "}
        <Box className={classes.topbar}>
          <Stack direction='row'>
            <Box
              className={classes.imgFrame}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              {console.log("hj", errors)}
              {imagepath ? (
                <Avatar
                  className={classes.img}
                  variant='square'
                  src={imagepath}
                  sx={{ width: 120, height: 120, position: "absolute" }}
                />
              ) : (
                <Avatar
                  className={classes.img}
                  variant='square'
                  src={
                    recruiterProfile?.profile_image
                      ? recruiterProfile?.profile_image
                      : PersonIcon
                  }
                  sx={{ width: 120, height: 120, position: "absolute" }}
                />
              )}
              <Box sx={{ position: "relative", top: "45px", right: "-43px" }}>
                <label htmlFor='icon-button-file'>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      height: "30px",
                      maxWidth: "min-content",
                      minWidth: "30px",
                      cursor: "pointer",
                      backgroundColor: "#304672",
                      borderRadius: "50%",
                    }}>
                    <Box
                      component='img'
                      className={classes.img1}
                      src={Editicon}
                    />
                  </Box>
                </label>
                <input
                  accept='image/*'
                  id='icon-button-file'
                  type='file'
                  style={{ display: "none" }}
                  onChange={handleUploadImage}
                />
              </Box>
            </Box>
            <Box
              className={classes.text}
              sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant='h4'
                sx={{
                  marginTop: "10px",
                  fontSize: "28px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "600",
                }}>
                {" "}
                {recruiterProfile?.first_name} {recruiterProfile?.last_name}
              </Typography>
              <Stack
                direction='row'
                spacing={{ xs: 2, sm: 4, md: 10 }}
                sx={{ marginTop: "15px" }}></Stack>
            </Box>
          </Stack>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Box>
                <Box pb={2} pt={5}>
                  <Typography
                    variant='h5'
                    sx={{
                      fontSize: "22px",
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Account Details
                  </Typography>
                </Box>
                <Grid container pt={1}>
                  <Grid item sx={6} sm={6} pr={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Mobile No.*
                    </Typography>{" "}
                    <Grid container>
                      <Grid item sm={4} sx={{ paddingRight: "10px" }}>
                        <Autocomplete
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                          id='country-select-demo'
                          value={selectedCountry}
                          options={countryListnew}
                          autoHighlight
                          disableClearable
                          variant='standard'
                          popupIcon={
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                              }}
                            />
                          }
                          name='country_code'
                          defaultValue='91'
                          onChange={(_event, country) => {
                            console.log("country", country);
                            setSelectedCountry(country);
                          }}
                          size='small'
                          getOptionLabel={(option) => {
                            return option?.iso3 + " +" + option?.phone_code;
                          }}
                          renderInput={(params) => (
                            <FormInput
                              sx={{ height: 43 }}
                              size='small'
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={8}>
                        <Stack direction='row' spacing={0}>
                          {" "}
                          <FormInput
                            sx={{
                              backgroundColor: "#E0DFF4",
                              borderRadius: "5px",
                              width: "100%",
                            }}
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            onChange={(e) => setMobile(e.target.value)}
                            name='mobile'
                            defaultValue={recruiterProfile?.mobile_number}
                          />{" "}
                          <Box
                            sx={{
                              marginLeft: "-20px",
                              height: "42px",
                              backgroundColor: "#E0DFF4",
                              padding: "3px 0px",
                              borderRadius: "0 5px 5px 0px",
                            }}>
                            {" "}
                            <Button
                              variant='standard'
                              sx={{
                                marginRight: "5px",
                                backgroundColor: "#223870",
                                borderRadius: "3px",
                                color: "#FDCF57",

                                marginTop: "2px",
                                padding: "2px 20px",
                                fontSize: "16px",
                                textTransform: "capitalize",
                                "&:hover": {
                                  color: "#FDCF57",
                                  backgroundColor: "#223870",
                                },
                                "@media (maxWidth: 468px)": {
                                  textAlign: "center",
                                  padding: "0 10px",
                                },
                              }}>
                              {" "}
                              {recruiterProfile?.is_mobile_verified
                                ? "Verified"
                                : "Verify"}
                            </Button>
                            <Dialog
                              // open={candidateMobileOtp}
                              onClose={handleCloseMobile}
                              sx={{
                                "& .MuiDialog-container": {
                                  "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "590px",
                                    height: "430px",
                                    borderRadius: "10px",
                                    backgroundColor: (theme) =>
                                      theme.Palette.background.dialog,
                                  },
                                },
                              }}>
                              <DialogActions>
                                <Box
                                  onClick={handleCloseMobile}
                                  component='img'
                                  src={Close}
                                  sx={{
                                    top: "10px",
                                    right: "10px",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              </DialogActions>
                              <DialogTitle
                                sx={{
                                  color: "#223870",
                                  textAlign: "center",
                                  fontSize: "22px",
                                  marginTop: "-15px",
                                }}>
                                Verify Mobile No.
                              </DialogTitle>
                              <DialogContent
                                sx={{
                                  marginTop: "20px ",
                                  textAlign: "center",
                                }}>
                                {" "}
                                <Box>
                                  {" "}
                                  <Typography
                                    variant='p'
                                    gutterBottom
                                    sx={{
                                      fontSize: "18px",
                                      color: "#5071C4",
                                      fontWeight: "500",
                                    }}>
                                    OTP sent to {mobile}
                                  </Typography>{" "}
                                </Box>
                                <Box
                                  sx={{
                                    marginTop: "20px",
                                    marginBottom: "10px",
                                  }}>
                                  {" "}
                                  <Typography
                                    variant='h6'
                                    sx={{
                                      color: "#223870",
                                      fontWeight: "500",
                                    }}>
                                    Enter OTP
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}>
                                  <OtpInput
                                    value={otp}
                                    onChange={handleChangeOtp}
                                    numInputs={6}
                                    inputStyle={{
                                      height: "35px",
                                      width: "45px",
                                      color: "#5071C4",
                                      backgroundColor: "#E0DFF4",
                                      border: "1px solid #223870",
                                      margin: "4px",
                                      fontSize: "18px",
                                      borderRadius: "3px",
                                    }}
                                    focusStyle={{
                                      border: "1px solid #223870",
                                    }}
                                  />
                                </Box>
                                <Box>
                                  {message === "Wrong OTP" && (
                                    <Typography
                                      variant='h5'
                                      sx={{
                                        color: "ff0000",
                                        fontWeight: "500",
                                        fontSize: "12px",
                                      }}>
                                      {message}
                                    </Typography>
                                  )}
                                </Box>
                                <Box sx={{ marginTop: "5px" }}>
                                  <OtpTimer timerzero={timerZero} />
                                </Box>
                                <Box sx={{ marginTop: "40px " }}>
                                  {" "}
                                  <Button
                                    // onClick={verifyCandidateMobileOtp}
                                    sx={{
                                      padding: "4px 20px",
                                      textTransform: "none",
                                      textDecoration: "none",
                                      borderRadius: "3px ",
                                      color: "#FDCF57",
                                      fontWeight: "500",
                                      fontSize: "18px",
                                      backgroundColor: "#223870",
                                      "&:hover": {
                                        backgroundColor: "#223870",
                                        color: "#FDCF57 ",
                                      },
                                    }}>
                                    Verify
                                  </Button>
                                </Box>
                              </DialogContent>
                            </Dialog>
                          </Box>
                        </Stack>
                        {errors.mobile && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.mobile.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                    {errors.years && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.degree.message}
                      </Box>
                    )}
                  </Grid>
                  <Grid item sx={6} sm={6} pl={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Email ID*
                    </Typography>{" "}
                    <Stack direction='row' spacing={0}>
                      <FormInput
                        size='small'
                        variant='outlined'
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                          width: "100%",
                          borderRadius: "5px 0px 0px 5px",
                        }}
                        name='email'
                        defaultValue={recruiterProfile?.email_id}
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Box
                        sx={{
                          height: "42px",
                          backgroundColor: "#E0DFF4",
                          padding: "3px 0px",
                          borderRadius: "0 5px 5px 0px",
                        }}>
                        {" "}
                        <Button
                          variant='standard'
                          sx={{
                            marginRight: "5px",
                            backgroundColor: "#223870",
                            borderRadius: "3px",
                            color: "#FDCF57",
                            marginTop: "2px",
                            padding: "2px 20px",
                            fontSize: "16px",
                            textTransform: "capitalize",
                            "&:hover": {
                              color: "#FDCF57",
                              backgroundColor: "#223870",
                            },
                            "@media (maxWidth: 468px)": {
                              textAlign: "center",
                              padding: "0 10px",
                            },
                          }}>
                          {recruiterProfile?.is_email_verified
                            ? "Verified"
                            : "Verify"}
                        </Button>
                        <Dialog
                          onClose={handleCloseEmail}
                          sx={{
                            "& .MuiDialog-container": {
                              "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "590px",
                                height: "430px",
                                borderRadius: "8px",
                                backgroundColor: "#E8EEFB",
                              },
                            },
                          }}>
                          <DialogActions>
                            <Box
                              onClick={handleCloseEmail}
                              component='img'
                              src={Close}
                              sx={{
                                top: "10px",
                                right: "10px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </DialogActions>
                          <DialogTitle
                            sx={{
                              color: "#223870",
                              textAlign: "center",
                              fontSize: "22px",
                              marginTop: "-15px",
                            }}>
                            Verify Email ID
                          </DialogTitle>
                          <DialogContent
                            sx={{
                              marginTop: "20px ",
                              textAlign: "center",
                            }}>
                            {" "}
                            <Box>
                              {" "}
                              <Typography
                                variant='p'
                                gutterBottom
                                sx={{
                                  fontSize: "18px",
                                  color: "#5071C4",
                                  fontWeight: "500",
                                }}>
                                OTP sent to {email}
                              </Typography>{" "}
                            </Box>
                            <Box
                              sx={{ marginTop: "20px", marginBottom: "10px" }}>
                              {" "}
                              <Typography
                                variant='h6'
                                sx={{
                                  color: "#223870",
                                  fontWeight: "500",
                                }}>
                                Enter OTP
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}>
                              <OtpInput
                                value={otp}
                                onChange={handleChangeOtp}
                                numInputs={6}
                                inputStyle={{
                                  height: "35px",
                                  width: "45px",
                                  color: "#5071C4",
                                  backgroundColor: "#E0DFF4",
                                  border: "1px solid #223870",
                                  margin: "4px",
                                  fontSize: "18px",
                                  borderRadius: "3px",
                                }}
                                focusStyle={{
                                  border: "1px solid #223870",
                                }}
                              />
                            </Box>
                            <Box>
                              {message === "Wrong OTP" && (
                                <Typography
                                  variant='h5'
                                  sx={{
                                    color: "ff0000",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                  }}>
                                  {message}
                                </Typography>
                              )}
                            </Box>
                            <Box sx={{ marginTop: "5px" }}>
                              <OtpTimer
                                // resendOtp={handleClickOpenEmail}
                                timerzero={timerZero}
                              />
                            </Box>
                            <Box sx={{ marginTop: "40px " }}>
                              {" "}
                              <Button
                                // onClick={verifyCandidateEmailOtp}
                                sx={{
                                  padding: "4px 20px",
                                  textTransform: "none",
                                  textDecoration: "none",
                                  borderRadius: "3px ",
                                  color: "#FDCF57",
                                  fontWeight: "500",
                                  fontSize: "18px",
                                  backgroundColor: "#223870",
                                  "&:hover": {
                                    backgroundColor: "#223870",
                                    color: "#FDCF57 ",
                                  },
                                }}>
                                Verify
                              </Button>
                            </Box>
                          </DialogContent>
                        </Dialog>
                      </Box>
                    </Stack>
                    {errors.email && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.email.message}
                      </Box>
                    )}
                  </Grid>{" "}
                </Grid>
                <Grid container pt={1}>
                  <Grid item sx={6} sm={6} pr={3}>
                    {" "}
                    <Typography
                      variant='p'
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Company*
                    </Typography>{" "}
                    <FormInput
                      sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      defaultValue={
                        recruiterProfile?.company
                          ? recruiterProfile?.company
                          : ""
                      }
                      name='company'
                      {...register("company", {
                        required: "Please enter your Company name",
                      })}
                    />{" "}
                    {errors?.company && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors?.company.message}
                      </Box>
                    )}
                  </Grid>
                  <Grid item sx={6} sm={6} pl={3}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Aadhar No.*
                    </Typography>{" "}
                    <FormInput
                      sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      defaultValue={
                        recruiterProfile?.adhar_number
                          ? recruiterProfile?.adhar_number
                          : ""
                      }
                      name='aadhar'
                      {...register("aadhar", {
                        required: "Aadhar number is required",
                        pattern: {
                          value: /^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$/,
                          message: "Please enter a valid Aadhar number",
                        },
                      })}
                    />
                    {errors.aadhar && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.aadhar.message}
                      </Box>
                    )}
                  </Grid>{" "}
                </Grid>
                <Grid container pt={1}>
                  <Grid item sx={6} sm={6} pr={3}>
                    {" "}
                    <Typography
                      variant='p'
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Pan No.*
                    </Typography>{" "}
                    <FormInput
                      sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      defaultValue={
                        recruiterProfile?.pan_number
                          ? recruiterProfile?.pan_number
                          : ""
                      }
                      name='pan'
                      {...register("pan", {
                        required: "PAN number is required",
                        pattern: {
                          value: /([A-Z]){5}([0-9]){4}([A-Z]){1}$/,
                          message: "Please enter a valid PAN number",
                        },
                      })}
                    />
                    {errors.pan && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.pan.message}
                      </Box>
                    )}
                  </Grid>
                  <Grid item sx={6} sm={6} pl={3}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Pan Card*
                    </Typography>{" "}
                    <Box>
                      <input
                        id='icon-button-image'
                        type='file'
                        style={{
                          width: "100%",
                          padding: "10px",
                          display: "block",
                          borderRadius: "5px",
                          backgroundColor: "#E0DFF4",
                          color: "#304672",
                          cursor: "pointer",
                        }}
                        onChange={handleUploadPan}
                        name='pan_file'
                      />
                      <Box
                        p={1}
                        onClick={saveAsf}
                        sx={{
                          color: "#304672",
                          cursor: "pointer",
                          fontSize: "12px",
                          textDecoration: "underline",
                        }}>
                        {recruiterProfile?.pan_file}
                      </Box>
                    </Box>
                    <Box sx={{ color: "red", fontSize: "12px" }}>
                      {" "}
                      {errors.browse?.type === "required" &&
                        "Upload your pan card"}
                    </Box>
                  </Grid>{" "}
                </Grid>
              </Box>
              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </Box>
            <Box>
              <Box>
                <Box pt={2} pb={4}>
                  <Typography
                    variant='h5'
                    sx={{
                      fontSize: "22px",
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Bank Details
                  </Typography>
                </Box>
              </Box>

              <Box>
                {" "}
                <Grid container pt={1}>
                  <Grid item sx={6} sm={6} pr={3}>
                    {" "}
                    <Typography
                      variant='p'
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Bank Name
                    </Typography>{" "}
                    <FormInput
                      sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      //   defaultValue={experience?.bank_name}
                      name='bank_name'
                    />{" "}
                  </Grid>
                  <Grid item sx={6} sm={6} pl={3}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Account No.
                    </Typography>{" "}
                    <FormInput
                      sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      //  onChange={handleChange}
                      name='acct_no'
                    />
                  </Grid>{" "}
                </Grid>
                <Grid container pt={1}>
                  <Grid item sx={6} sm={6} pr={3}>
                    {" "}
                    <Typography
                      variant='p'
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Branch
                    </Typography>{" "}
                    <FormInput
                      sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      //   defaultValue={experience?.bank_name}
                      name='branch_name'
                    />{" "}
                  </Grid>
                  <Grid item sx={6} sm={6} pl={3}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      IFSC Code
                    </Typography>{" "}
                    <FormInput
                      sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      //  onChange={handleChange}
                      name='ifsc_code'
                    />
                  </Grid>{" "}
                </Grid>
              </Box>
              <Box pt={2} pb={2}></Box>
            </Box>
            <Grid
              container
              direction='Row'
              sx={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}>
              {" "}
              <Grid
                item
                sx={{
                  justifyContent: "center",
                }}>
                {" "}
                <Button
                  //onClick={redirectCandidateProfile}
                  type='submit'
                  sx={{
                    //position: "relative",
                    textTransform: "none",
                    textDecoration: "none",
                    padding: "3px 30px",
                    borderRadius: "3px ",
                    color: "#FDCF57",
                    fontWeight: "500",
                    fontSize: "20px",
                    backgroundColor: "#223870",
                    "&:hover": {
                      backgroundColor: "#223870",
                      color: "#FDCF57 ",
                    },
                  }}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default EditRecruiterProfile;
