import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  MenuItem,
  Select,
  Stack,
  Grid,
  Autocomplete,
} from "@mui/material";
import { FormInput } from "../../../../components/Input/FormInput";
import { Experience } from "../../../../assets/Data/data";
import { NoticePeriod } from "../../../../assets/Data/data";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useSelector, useDispatch } from "react-redux";
import { countryListnew } from "../../../../assets/Data/data";
import { Months } from "../../../../assets/Data/data";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import * as jobsActions from "../../../../Store/Jobs";
import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";

const S3_BUCKET = "exotalent-cv";
const REGION = "ap-south-1";

AWS.config.update({
  accessKeyId: "AKIAWCKLV3UAPEYYOZO3",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});
const useStyles = makeStyles((theme) => ({
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "9px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));
const ReferJobs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [filepath, setFilepath] = React.useState("");
  const [noticePeriod, setNoticePeriod] = React.useState(0);
  const [value, setValue] = React.useState(new Date());
  const [uploadjd, setUploadJd] = React.useState(null);
  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: "IN",
    country_name: "India",
    iso3: "IND",
    phone_code: 91,
  });
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const [months, setMonths] = React.useState();
  const handleMonths = (event) => {
    setMonths(event.target.value);
  };

  const [current_location, setCurrentLocation] = React.useState("");

  const handleUploadJd = async (event) => {
    await setUploadJd(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    return uploadJd(event.target.files[0]);
  };
  const uploadJd = async (file) => {
    let namekey = uuidv4() + "_lx_" + file.name.replace(/ /g, "_");
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: namekey,
    };

    let objct = myBucket.putObject(params).promise();

    objct
      .then(function (data) {
        console.log(
          "aws Successfully uploaded data to " + S3_BUCKET + "/" + namekey
        );
        console.log("aws Success", data);
        let path =
          "https://" +
          S3_BUCKET +
          ".s3." +
          REGION +
          ".amazonaws.com/" +
          namekey;
        setFilepath(path);
      })
      .catch(function (err) {
        console.log("aws err", err);
      });
  };

  const candidateProfile = useSelector(
    (state) => state?.auth?.candidateProfile
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onTouched" });
  const npChange = (event) => {
    console.log("npChange1", event.target.value);
    setNoticePeriod(event.target.value);
  };

  const location = useSelector((state) => state?.jobs?.location);
  useEffect(() => {
    async function getLocationlist() {
      await dispatch(jobsActions.getLocation());
    }
    getLocationlist();
  }, [dispatch]);
  const onSubmit = async (data) => {
    console.log("where is data", data);
  };

  return (
    <>
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-around",
            padding: "0 30px",
          }}
          container
          direction='row'>
          <Grid item xs={6} md={6} pr={2.5}>
            <Grid container direction='Column'>
              {" "}
              <Grid item>
                {" "}
                <Typography
                  variant='p'
                  gutterBottom
                  sx={{
                    fontSize: "14px",
                    marginLeft: "2px",
                    color: "#304672",
                    fontWeight: "500",
                    textAlign: "left",
                  }}>
                  Mobile No.*
                </Typography>{" "}
              </Grid>
              <Grid container>
                <Grid item sm={4} sx={{ paddingRight: "10px" }}>
                  <Autocomplete
                    id='mobile-s-demo'
                    sx={{ width: "100% " }}
                    value={selectedCountry}
                    options={countryListnew}
                    autoHighlight
                    disableClearable
                    variant='standard'
                    popupIcon={
                      <KeyboardArrowDownIcon
                        sx={{
                          color: "#5071C4",
                        }}
                      />
                    }
                    name='country_code'
                    onChange={(_event, country) => {
                      console.log("country", country);
                      setSelectedCountry(country);
                    }}
                    size='small'
                    getOptionLabel={(option) => {
                      return option.iso3 + " +" + option.phone_code;
                    }}
                    renderInput={(params) => (
                      <FormInput
                        sx={{
                          backgroundColor: "#E0DFF4",
                          borderRadius: "5px",
                          height: 43,
                        }}
                        size='small'
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item sm={8}>
                  <Stack direction='row' spacing={0}>
                    {" "}
                    <FormInput
                      type='number'
                      sx={{
                        backgroundColor: "#E0DFF4",
                        width: "100%",
                        borderRadius: "5px 0px 0px 5px",
                      }}
                      size='small'
                      variant='outlined'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name='mobile'
                      {...register("mobile", {
                        required: "Mobile number is required",
                        pattern: {
                          value: /^((\+91?)|\+)?[7-9][0-9]{9}$/,
                          message:
                            "Please enter a valid 10-digit mobile number",
                        },
                        minLength: {
                          value: 10,
                          message: "Mobile number must be consist 10 digit",
                        },
                        maxLength: {
                          value: 10,
                          message: "Mobile number exceeds max digit",
                        },
                      })}
                    />{" "}
                    <Box
                      sx={{
                        marginLeft: "-20px",
                        backgroundColor: "#E0DFF4",
                        padding: "3px 0px",
                        borderRadius: "0 5px 5px 0px",
                        "@media (maxWidth: 468px)": {
                          fontSize: "14px",
                        },
                      }}>
                      {" "}
                      <Button
                        //   onClick={handleClickOpenMobile}
                        variant='standard'
                        //    disabled={candidateMobileVerification}
                        sx={{
                          marginRight: "4px",
                          backgroundColor: "#223870",
                          borderRadius: "3px",
                          color: "#FDCF57",
                          // candidateMobileVerification
                          //   ? '#FDCF57!important'
                          //   : '#FDCF57',
                          marginTop: "1px",
                          padding: "2px 20px",
                          fontSize: "16px",
                          textTransform: "capitalize",
                          "&:hover": {
                            color: "#FDCF57",
                            backgroundColor: "#223870",
                          },
                          "@media (maxWidth: 468px)": {
                            textAlign: "center",
                            padding: "0 10px",
                          },
                        }}>
                        {" "}
                        Search
                      </Button>
                    </Box>
                  </Stack>
                  {errors.mobile && (
                    <Box sx={{ color: "red", fontSize: "12px" }}>
                      {" "}
                      {errors.mobile.message}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                width: "100%",
                marginTop: "5px",
              }}>
              {" "}
              <Grid
                item
                sm={6}
                md={6}
                sx={{
                  width: "50%",
                }}>
                <Grid container direction='column'>
                  {" "}
                  <Grid item sx={{ marginTop: "5px" }}>
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        fontSize: "14px",
                        marginLeft: "2px",
                        color: "#304672",
                        fontWeight: "500",
                        textAlign: "left",
                      }}>
                      First Name*
                    </Typography>{" "}
                  </Grid>
                  <Grid item sx={{ marginRight: "6px" }}>
                    <FormInput
                      sx={{
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                      }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name='firstName'
                      {...register("firstName", {
                        required: "First name is required",
                        minLength: {
                          value: 1,
                          message: "First name must be atleast 1 character",
                        },
                      })}
                    />
                    {errors.firstName && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.firstName.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={6}
                md={6}
                pr={1}
                sx={{
                  width: "50%",
                }}>
                <Grid container direction='column' sx={{ marginLeft: "6px" }}>
                  {" "}
                  <Grid item>
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        fontSize: "14px",
                        color: "#304672",
                        fontWeight: "500",
                        textAlign: "left",
                      }}>
                      Last Name*
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    <FormInput
                      sx={{
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                      }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name='lastName'
                      {...register("lastName", {
                        required: "Last name is required",
                        minLength: {
                          value: 1,
                          message: "Last name must be atleast 1 character",
                        },
                      })}
                    />
                    {errors.lastName && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.lastName.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction='Column'>
              {" "}
              <Grid item sx={{ marginTop: "5px" }}>
                <Typography
                  variant='p'
                  gutterBottom
                  sx={{
                    fontSize: "14px",
                    marginLeft: "2px",
                    color: "#304672",
                    fontWeight: "500",
                    textAlign: "left",
                  }}>
                  Experience*
                </Typography>{" "}
              </Grid>
              <Grid item>
                {" "}
                <Grid
                  container
                  sx={{
                    width: "100%",
                    paddingRight: "10px",
                  }}>
                  <Grid item sm={6} md={6} sx={{ width: "50%" }}>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        width: "100%",
                        marginRight: "10px",
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                        fontWeight: "500",
                      }}
                      name='years'
                      className={classes.countrycode}
                      {...register("years", {
                        required: "Select your experience in years ",
                      })}>
                      {Experience?.map((option) => {
                        return (
                          <MenuItem
                            key={option.label}
                            value={option.label}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {option.value ?? option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>{" "}
                    {errors.years && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.years.message}
                      </Box>
                    )}
                  </Grid>
                  <Grid item sm={6} md={6} sx={{ width: "50%" }}>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        width: "100%",
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                        fontWeight: "500",
                        marginLeft: "10px",
                      }}
                      name='months'
                      value={months}
                      onChange={handleMonths}
                      className={classes.countrycode}
                      {...register("months", {
                        required: "Select your experience in months",
                      })}>
                      {Months?.map((option) => {
                        return (
                          <MenuItem
                            key={option.label}
                            value={option.label}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {option.value ?? option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>{" "}
                    {errors.months && (
                      <Box
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "10px",
                        }}>
                        {" "}
                        {errors.months.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              sm={12}
              sx={{
                marginTop: "5px",
                width: "100%",
              }}>
              {" "}
              <Grid item sm={6} md={6} sx={{ width: "50%" }}>
                <Grid container direction='column'>
                  {" "}
                  <Grid item>
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        fontSize: "14px",
                        marginLeft: "2px",
                        color: "#304672",
                        fontWeight: "500",
                        textAlign: "left",
                      }}>
                      Current CTC (in lacs)*
                    </Typography>{" "}
                  </Grid>
                  <Grid item sx={{ marginRight: "6px" }}>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        width: "100%",
                        marginRight: "10px",
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                        fontWeight: "500",
                      }}
                      name='current_ctc'
                      className={classes.countrycode}
                      {...register("current_ctc", {
                        required: "enter your current ctc ",
                      })}>
                      {[...Array(50)]?.map((option, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={index}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {index}
                          </MenuItem>
                        );
                      })}
                    </Select>{" "}
                    {errors.current_ctc && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.current_ctc.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={6} md={6} sx={{ width: "50%" }}>
                <Grid container direction='column'>
                  {" "}
                  <Grid item>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        fontSize: "12px",
                        color: "#304672",
                        fontWeight: "500",
                        marginLeft: "12px",
                      }}>
                      (in thousands)*
                    </Typography>{" "}
                  </Grid>
                  <Grid item sx={{ marginLeft: "6px" }}>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        width: "100%",
                        marginRight: "10px",
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                        fontWeight: "500",
                      }}
                      name='current_ctc_thousand'
                      className={classes.countrycode}
                      {...register("current_ctc_thousand", {
                        required: "enter your current ctc in thousand",
                      })}>
                      {[...Array(10)]?.map((option, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={index}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {index * 10}
                          </MenuItem>
                        );
                      })}
                    </Select>{" "}
                    {errors.current_ctc_thousand && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.current_ctc_thousand.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sm={12}
                md={12}
                sx={{ width: "50%", marginTop: "5px" }}>
                <Grid container direction='column'>
                  {" "}
                  <Grid item>
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        fontSize: "14px",
                        marginLeft: "2px",
                        color: "#304672",
                        fontWeight: "500",
                        textAlign: "left",
                      }}>
                      Current Location*
                    </Typography>{" "}
                  </Grid>
                  <Grid item sx={{ marginLeft: "0" }}>
                    <Autocomplete
                      id='location-demo'
                      sx={{
                        width: "100% ",
                      }}
                      value={current_location}
                      options={location}
                      autoHighlight
                      disableClearable
                      variant='standard'
                      popupIcon={
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                          }}
                        />
                      }
                      name='location'
                      onChange={(_event, location) => {
                        setCurrentLocation(location);
                      }}
                      size='small'
                      getOptionLabel={(option) => {
                        if (option) {
                          return option?.city;
                        } else {
                          return "";
                        }
                      }}
                      renderInput={(params) => (
                        <FormInput
                          size='small'
                          required
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "",
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ marginTop: "5px" }}>
              <Typography
                variant='p'
                gutterBottom
                sx={{
                  fontSize: "14px",
                  marginLeft: "2px",
                  color: "#304672",
                  fontWeight: "500",
                  textAlign: "left",
                }}>
                Total Relevant Experience*
              </Typography>{" "}
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    sx={{
                      color: "#5071C4",
                      position: "absolute",
                      right: 10,
                      pointerEvents: "none",
                    }}
                  />
                )}
                sx={{
                  width: "100%",
                  marginRight: "10px",
                  color: "#5071C4",
                  backgroundColor: "#E0DFF4",
                  fontWeight: "500",
                }}
                name='relvexp'
                className={classes.countrycode}
                {...register("relvexp", {
                  required: "Select your experience in years ",
                })}>
                {Experience?.map((option) => {
                  return (
                    <MenuItem
                      key={option.label}
                      value={option.label}
                      sx={{
                        color: "#5071C4",
                        fontWeight: "700",
                      }}>
                      {option.value ?? option.label}
                    </MenuItem>
                  );
                })}
              </Select>{" "}
              {errors.years && (
                <Box sx={{ color: "red", fontSize: "12px" }}>
                  {" "}
                  {errors.years.message}
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={6} md={6} pl={2.5}>
            <Grid container direction='Column'>
              {" "}
              <Grid item>
                {" "}
                <Typography
                  variant='p'
                  gutterBottom
                  sx={{
                    fontSize: "14px",
                    marginLeft: "2px",
                    color: "#304672",
                    fontWeight: "500",
                    textAlign: "left",
                  }}>
                  Email ID*
                </Typography>{" "}
              </Grid>
              <Grid item>
                <Box
                  sm={12}
                  sx={{
                    width: "100%",
                  }}>
                  {" "}
                  <Stack direction='row' spacing={0}>
                    <FormInput
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                        width: "100%",
                        borderRadius: "5px 0px 0px 5px",
                      }}
                      name='email'
                      fullWidth
                      {...register("email", {
                        required: "Email id is required",
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: "Please enter a valid email address",
                        },
                      })}
                    />
                    <Box
                      sx={{
                        backgroundColor: "#E0DFF4",
                        padding: "3px 0px",
                        borderRadius: "0 5px 5px 0px",
                      }}>
                      {" "}
                      <Button
                        // onClick={handleClickOpenEmail}
                        variant='standard'
                        sx={{
                          marginRight: "5px",
                          backgroundColor: "#223870",
                          borderRadius: "3px",
                          color: "#FDCF57",
                          marginTop: "2px",
                          padding: "2px 20px",
                          fontSize: "16px",
                          textTransform: "capitalize",
                          "&:hover": {
                            color: "#FDCF57",
                            backgroundColor: "#223870",
                          },
                          "@media (maxWidth: 468px)": {
                            textAlign: "center",
                            padding: "0 10px",
                          },
                        }}>
                        Search
                      </Button>
                    </Box>
                  </Stack>
                  {errors.email && (
                    <Box sx={{ color: "red", fontSize: "12px" }}>
                      {" "}
                      {errors.email.message}
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ marginTop: "5px" }}>
              <Typography
                variant='p'
                gutterBottom
                sx={{
                  fontSize: "14px",
                  marginLeft: "2px",
                  color: "#304672",
                  fontWeight: "500",
                  textAlign: "left",
                }}>
                Notice Period*
              </Typography>{" "}
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon
                    sx={{
                      color: "#5071C4",
                      position: "absolute",
                      right: 10,
                      pointerEvents: "none",
                    }}
                  />
                )}
                sx={{
                  width: "100%",
                  marginRight: "10px",
                  color: "#5071C4",
                  backgroundColor: "#E0DFF4",
                  fontWeight: "500",
                }}
                required
                value={noticePeriod}
                name='noticePeriod'
                onChange={npChange}
                className={classes.countrycode}>
                {NoticePeriod?.map((option) => {
                  return (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      sx={{
                        color: "#5071C4",
                        fontWeight: "700",
                      }}>
                      {option.label}
                    </MenuItem>
                  );
                })}
              </Select>{" "}
              {errors.noticePeriod && (
                <Box sx={{ color: "red", fontSize: "12px" }}>
                  {" "}
                  {errors.noticePeriod.message}
                </Box>
              )}
            </Box>
            <Box sx={{ height: "62px" }}>
              {noticePeriod === 0 && (
                <Box>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      marginLeft: "2px",
                      color: "#304672",
                      fontWeight: "500",
                      textAlign: "left",
                      marginTop: "5px",
                    }}>
                    When is your last working day?
                  </Typography>{" "}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      inputFormat='MM/dd/yyyy'
                      value={value}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <FormInput
                          sx={{
                            width: "100%",
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size='small'
                          variant='outlined'
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              )}
            </Box>
            <Box sx={{ marginTop: "5px" }}>
              <Typography
                variant='p'
                gutterBottom
                sx={{
                  marginLeft: "2px",
                  color: "#304672",
                  fontWeight: "500",
                  textAlign: "left",
                  fontSize: "14px",
                }}>
                Expected CTC* (In lacs)
              </Typography>{" "}
              <FormInput
                sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                size='small'
                variant='outlined'
                type='text'
                InputLabelProps={{
                  shrink: true,
                }}
                required
                fullWidth
                name='expectedCtc'
                {...register("expectedCtc", {
                  required: "expected CTC is required",
                })}
              />
              {errors.expectedCtc && (
                <Box sx={{ color: "red", fontSize: "12px" }}>
                  {" "}
                  {errors.expectedCtc.message}
                </Box>
              )}
            </Box>
            <Box sx={{ marginTop: "5px" }}>
              <Typography
                variant='p'
                gutterBottom
                sx={{
                  fontSize: "14px",
                  marginLeft: "2px",
                  color: "#304672",
                  fontWeight: "500",
                  textAlign: "left",
                  marginTop: "10px",
                }}>
                LinkedIn Profile Link*
              </Typography>{" "}
              <FormInput
                sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                size='small'
                variant='outlined'
                type='text'
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                name='linkedin'
                {...register("linkedin", {
                  required: "linkedin is required",
                })}
              />
              {errors.linkedin && (
                <Box sx={{ color: "red", fontSize: "12px" }}>
                  {" "}
                  {errors.linkedin.message}
                </Box>
              )}
            </Box>

            <Box sx={{ marginTop: "4px" }}>
              <Typography
                variant='p'
                gutterBottom
                sx={{
                  fontSize: "14px",
                  marginLeft: "2px",
                  color: "#304672",
                  fontWeight: "500",
                  textAlign: "left",
                }}>
                Resume*
              </Typography>{" "}
              <Box
                sx={{
                  backgroundColor: "#E0DFF4",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "5px",
                }}>
                <input
                  required
                  id='icon-button-image'
                  type='file'
                  style={{
                    padding: "9px",
                    display: "block",
                    borderRadius: "5px",
                    backgroundColor: "#E0DFF4",
                    color: "#304672",
                  }}
                  onChange={handleUploadJd}
                  name='image'
                />
                <Box>
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      color: "#615d5d",
                      fontSize: "10px",
                      color: "#304672",
                    }}>
                    {candidateProfile?.cv}
                    {candidateProfile?.cv?.split("_lx_")[1].substring(20, 0)}
                  </Typography>
                </Box>

                <AttachFileIcon sx={{ float: "right", marginTop: "4px" }} />
              </Box>
            </Box>
            <Box sx={{ color: "red", fontSize: "12px" }}>
              {" "}
              {errors.browse?.type === "required" && "Upload your cv"}
            </Box>
          </Grid>
          <Box sx={{ marginTop: "20px" }}>
            {" "}
            <input
              type='submit'
              value='Refer'
              style={{
                display: "flex",
                border: "none",
                justifyContent: "center",
                width: "120px",
                backgroundColor: "#223870",
                borderRadius: "3px",
                color: "#FDCF57",
                marginTop: "3px",
                padding: "6px 0px 8px",
                fontSize: "18px",
                textTransform: "none",
                cursor: "pointer",
                "&:hover": {
                  color: "#FDCF57",
                  backgroundColor: "#223870",
                },
                "@media (maxWidth: 468px)": {
                  marginTop: "-29px",
                  textAlign: "center",
                  marginRight: "-10px",
                  padding: "0 10px",
                },
              }}
            />
          </Box>
        </Grid>
      </form>
    </>
  );
};

export default ReferJobs;
