import * as React from "react";
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/footer/footer";
import AboutUs from "../../container/Home/About/AboutUs";
import Services from "../../container/Home/Services/services";
import IndustryVerticals from "../../container/Home/IndustryVertical/industryvertical";
import Stat from "../../container/Home/Stat/statistics";
import CandidateTestimonial from "../../container/Home/CandidateTestimonial/candidate";
import ClientTestimonial from "../../container/Home/ClientTestimonial/Client";
import Partner from "./ClientsLogo/clientsLogo";

const Home = () => {
  return (
    <>
      <Navbar />
      <AboutUs />
      <Services />
      <IndustryVerticals />
      <Stat />
      <Partner />
      <ClientTestimonial />
      <CandidateTestimonial />
      <Footer />
    </>
  );
};

export default Home;
