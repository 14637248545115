import {
  SEND_OTP_COMPANY_FULFILLED,
  SEND_OTP_COMPANY_PENDING,
  SEND_OTP_COMPANY_REJECTED,
  SEND_OTP_COMPANY_LOGIN_FULFILLED,
  SEND_OTP_COMPANY_LOGIN_PENDING,
  SEND_OTP_COMPANY_LOGIN_REJECTED,
  VERIFY_OTP_COMPANY_FULFILLED,
  VERIFY_OTP_COMPANY_PENDING,
  VERIFY_OTP_COMPANY_REJECTED,
  VERIFY_LOGIN_OTP_COMPANY_FULFILLED,
  VERIFY_LOGIN_OTP_COMPANY_PENDING,
  VERIFY_LOGIN_OTP_COMPANY_REJECTED,
  CLOSE_COMPANY_EMAIL_DIALOG,
  SIGNUP_COMPANY_FULFILLED,
  SIGNUP_COMPANY_PENDING,
  SIGNUP_COMPANY_REJECTED,
  RESET_COMPANY_DETAILS,
  GET_COMPANY_PROFILE_DETAILS_SUCCESS,
  GET_COMPANY_PROFILE_DETAILS_REQUEST,
  GET_COMPANY_PROFILE_DETAILS_FAILURE,
  GET_SUPER_ADMIN_PROFILE_DETAILS_SUCCESS,
  GET_SUPER_ADMIN_PROFILE_DETAILS_REQUEST,
  GET_SUPER_ADMIN_PROFILE_DETAILS_FAILURE,
  RESET_COMPANY_LOGIN_DETAILS,
} from "./actions";
const initialSnackbarState = {
  loggedIn: false,
  otpsend: false,
  email: "",
  otptype: "",
  mobile: "",
  companyEmailVerification: false,
  companySignUp: false,
  companyEmailOtp: false,
  usertype: "guest",
  companyLoginOtpSend: false,
  companyLoginOtpVerify: false,
  companyDetails: {},
  companyId: "",
  superAdminProfile: {},
};

export const companyreducer = (state = initialSnackbarState, action) => {
  console.log("action type....", action.type);
  switch (action.type) {
    case "closedialogemail": {
      return { ...state, otpsend: false };
    }
    case "closedialogmobile": {
      return { ...state, otpsend: false };
    }
    case "logout": {
      return {
        ...state,
        loggedIn: false,
        signup: false,
        usertype: "guest",
        companyEmailOtp: false,
        companyLoginOtpSend: false,
        companyEmailVerification: false,
        companyLoginOtpVerify: false,
        companySignUp: false,
      };
    }
    case CLOSE_COMPANY_EMAIL_DIALOG:
      return { ...state, companyEmailOtp: false };
    case SEND_OTP_COMPANY_PENDING:
      return { ...state, ...action.payload };
    case SEND_OTP_COMPANY_REJECTED:
      return { ...state, ...action.payload };
    case SEND_OTP_COMPANY_FULFILLED:
      return {
        ...state,
        ...action.payload,
        companyEmailOtp: true,
        companyEmailVerification: false,
        companyLoginOtpSend: false,
        companyLoginOtpVerify: false,
        usertype: "company",
      };
    case SEND_OTP_COMPANY_LOGIN_PENDING:
      return { ...state, ...action.payload };
    case SEND_OTP_COMPANY_LOGIN_REJECTED:
      return { ...state, ...action.payload };
    case SEND_OTP_COMPANY_LOGIN_FULFILLED:
      return {
        ...state,
        ...action.payload,
        companyEmailOtp: false,
        companyEmailVerification: false,
        companyLoginOtpSend: true,
        companyLoginOtpVerify: false,
      };
    case VERIFY_OTP_COMPANY_PENDING:
      return { ...state, ...action.payload };
    case VERIFY_OTP_COMPANY_REJECTED:
      return { ...state, ...action.payload };
    case VERIFY_OTP_COMPANY_FULFILLED:
      return {
        ...state,
        ...action.payload,
        companyEmailOtp: false,
        companyEmailVerification: true,
        companyLoginOtpSend: false,
        companyLoginOtpVerify: false,
      };
    case VERIFY_LOGIN_OTP_COMPANY_PENDING:
      return { ...state, ...action.payload };
    case VERIFY_LOGIN_OTP_COMPANY_REJECTED:
      return { ...state, ...action.payload };
    case VERIFY_LOGIN_OTP_COMPANY_FULFILLED:
      return {
        ...state,
        ...action.payload,
        companyEmailOtp: false,
        companyEmailVerification: false,
        companyLoginOtpSend: false,
        companyLoginOtpVerify: true,
        loggedIn: true,
        usertype: "company",
      };
    case SIGNUP_COMPANY_FULFILLED:
      return {
        ...state,
        ...action.payload,
        companyId: action.payload?.data?.data?.company?.id,
        companySignUp: true,
        loggedIn: true,
        usertype: "company",
      };
    case SIGNUP_COMPANY_PENDING:
      return { ...state, ...action.payload };
    case SIGNUP_COMPANY_REJECTED:
      return { ...state, ...action.payload };
    case RESET_COMPANY_DETAILS:
      return {
        ...state,
        email: "",
        otptype: "",
        mobile: "",

        companyEmailOtp: false,
        companyEmailVerification: false,
        companyLoginOtpSend: false,
        companyLoginOtpVerify: false,
      };
    case RESET_COMPANY_LOGIN_DETAILS:
      return {
        ...state,
        companyEmailOtp: false,
        companyEmailVerification: false,
        companyLoginOtpSend: false,
        companyLoginOtpVerify: false,
      };
    case GET_COMPANY_PROFILE_DETAILS_SUCCESS: {
      console.log(action, "company profile??");
      return {
        ...state,
        companyDetails: action.payload?.data?.data,
        loading: false,
        errors: {},
      };
    }
    case GET_COMPANY_PROFILE_DETAILS_REQUEST: {
      return {
        ...state,
        ...action.payload,
        companyDetails: {},
        loading: false,
        errors: {},
      };
    }
    case GET_COMPANY_PROFILE_DETAILS_FAILURE: {
      return {
        ...state,
        ...action.payload,
        companyDetails: {},
        loading: false,
        errors: {},
      };
    }
    case GET_SUPER_ADMIN_PROFILE_DETAILS_SUCCESS: {
      console.log(action, "superadmin profile??");
      // console.log(action.payload.data.data, "company profile?????????????");
      return {
        ...state,
        superAdminProfile: action.payload?.data?.data,
        loading: false,
        usertype: "company",
        errors: {},
      };
    }
    case GET_SUPER_ADMIN_PROFILE_DETAILS_REQUEST: {
      return {
        ...state,
        ...action.payload,
        superAdminProfile: {},
        loading: false,
        errors: {},
      };
    }
    case GET_SUPER_ADMIN_PROFILE_DETAILS_FAILURE: {
      return {
        ...state,
        ...action.payload,
        superAdminProfile: {},
        loading: false,
        errors: {},
      };
    }

    default:
      return state;
  }
};
