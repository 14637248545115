import React, { Component } from "react";
import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

export default class OtpTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showfield: false,
      minutes: this.props.minutes ? this.props.minutes : 1,
      seconds: this.props.seconds ? this.props.seconds : 59,
      timer: true,
    };

    this.handleClick = this.handleClick.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    clearInterval(this.myInterval);
    this.otptimer();
  }
  reset() {
    this.props.resendOtp();
    this.setState({ minutes: 1, seconds: 59, timer: true }, this.otptimer());
  }
  otptimer() {
    clearInterval(this.myInterval);
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          this.setState({ timer: false });
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.myInterval);
  }
  handleClick(event) {
    this.setState({
      minutes: this.props.minutes ? this.props.minutes : 0,
      seconds: this.props.seconds ? this.props.seconds : 30,
    });
    this.props.resend();
    this.otptimer();
    event.preventDefault();
  }
  render() {
    const textStyle = {
      fontSize: "16px",
      fontFamily: "Poppins",
      lineHeight: "22px",
      color: this.props.textColor ? this.props.textColor : "#223870",
    };

    return (
      <div style={textStyle}>
        <Box py={1} sx={{ textAlign: "center" }}>
          <Typography
            variant='h7'
            component='h7'
            fontFamily='Montserrat, sans-serif'>
            {this.state.minutes < 10
              ? `0${this.state.minutes}`
              : this.state.minutes}
            :
            {this.state.seconds < 10
              ? `0${this.state.seconds}`
              : this.state.seconds}
          </Typography>
        </Box>
        <Box py={1} sx={{ display: "flex", justifyContent: "center" }}>
          <Stack spacing={1.5} direction='row'>
            <Box>
              <Typography variant='h7' component='h7'>
                Didn't receive an OTP?
              </Typography>
            </Box>
            {this.state.timer ? (
              <Box></Box>
            ) : (
              <Box sx={{ cursor: "pointer" }} onClick={this.reset}>
                <Typography
                  variant='h6s'
                  component='h6'
                  sx={{ fontWeight: "800", fontSize: "16px" }}>
                  Resend
                </Typography>
              </Box>
            )}
          </Stack>
        </Box>
      </div>
    );
  }
}
