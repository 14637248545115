import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  IconButton,
  Autocomplete,
  Divider,
} from "@mui/material";
import { FormInput } from "../../../../components/Input/FormInput";
import { StyledChip } from "../../../../components/Chip/chip";
import DeleteIcon from "../../../../assets/Icons/Delete.png";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Navbar from "../../../../components/Header/Navbar";
import Close from "../../../../assets/Icons/Close.png";
import * as candidateActions from "../../../../Store/candidate";
import * as helperActions from "../../../../Store/helper";
import { useSelector, useDispatch } from "react-redux";
import { countryListnew } from "../../../../assets/Data/data";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useForm, Controller, useFormContext } from "react-hook-form";
import { Experience } from "../../../../assets/Data/data";
import {
  Months,
  industryList1,
  functionList1,
} from "../../../../assets/Data/data";
import { month } from "../../../../assets/Data/data";
import CustomizedSnackbars from "../../../../components/Snackbar/CustomizedSnackbars";
import OtpTimer from "../../../../components/OTP_Timer/OtpTimer";
import OtpInput from "react-otp-input";
import { passing_year_list } from "../../../../assets/Data/data";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import Editicon from "../../../../assets/Icons/Edit_White.png";

const useStyles = makeStyles((theme) => ({
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "7.8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  topbar: {
    position: "relative",
    marginTop: "80px ",
    padding: "20px 120px",
    backgroundSize: "cover",
    backgroundColor: "#F0F1FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  imgFrame: {
    position: "relative",
    background: "#fff",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    objectFit: "cover",
    margin: "0 auto",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    backgroundSize: "cover",
  },
  imgFrame1: {
    bottom: "5px",
    right: "5px",
    position: "absolute",
    background: "#304672",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img1: {
    objectFit: "cover",
    margin: "0 auto",
    width: "15px",
    height: "15px",
    backgroundSize: "cover",
  },
  text: { marginLeft: "30px" },
  button: {
    position: "absolute",
    top: "55px",
    right: "100px",
  },
  body: {
    padding: "20px 80px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#F6F8FD",
    textAlign: "center",
  },
  jobbox: {
    marginLeft: "15px",
    padding: "20px",
    marginBottom: "20px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#DFE5F6",
    position: "relative",
  },

  "@media (min-width: 1640px)": {
    mainHome: {
      marginTop: "80px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
  },
  "@media (maxWidth: 468px)": {
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "26px",
    },
    jobbox: {
      width: "100%",
      margin: "0",
      padding: "0 5px",
    },
    container: {
      width: "100%",
      padding: "0 0px",
    },
    topbar: {
      position: "relative",
      padding: "20px 10px",
      height: "170px",
      width: "100%",
    },
    imgFrame: {
      width: "90px",
      height: "90px",
    },
    img: {
      width: "80px",
      height: "80px",
    },
    text: { marginLeft: "10px" },
    button: {
      position: "absolute",
      top: "120px",
      right: "20px",
    },
    body: {
      padding: "0",
      borderRadius: "10px",
    },
  },
}));

const EditCandidateProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userPreference = useSelector(
    (state) => state?.candidate?.candidatePreferences
  );
  console.log(userPreference[0]?.id, "user pref??");
  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: "IN",
    country_name: "India",
    iso3: "IND",
    phone_code: 91,
  });
  const [timer, setTimer] = useState(true);
  const timerZero = () => {
    setTimer(false);
  };
  const handleCloseMobile = async () => {};
  const [otp, setOtp] = useState("");
  const handleChangeOtp = (otp) => {
    setOtp(otp);
  };
  const [mobile, setMobile] = React.useState();
  const [imagepath, setImagepath] = React.useState("");
  const [selectedFun, setSelectedFun] = React.useState(
    userPreference[0]?.function || []
  );
  const [selectedIndustry, setSelectedIndustry] = React.useState(
    userPreference[0]?.industry || []
  );
  const [selectedDesignation, setSelectedDesignation] = React.useState(
    userPreference[0]?.industry || []
  );
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const candidateProfile = useSelector(
    (state) => state?.candidate?.candidateProfile
  );
  //File
  const [selectedFile, setSelectedFile] = useState(null);
  const [defaultFileName, setDefaultFileName] = useState(
    candidateProfile?.resumeFileName || ""
  );

  const handleUploadCv = (event) => {
    const file = event.target.files[0];

    setSelectedFile(file);

    if (file) {
      setDefaultFileName(file.name);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    setDefaultFileName(candidateProfile?.resumeFileName || "");
  }, [candidateProfile]);

  const fileInputRef = useRef();

  //SKILLS

  const [skills, setSkills] = React.useState("");
  const [chips, setChips] = useState([]);
  const allSkills = useSelector((state) => state?.candidate?.candidateSkills);
  const skillsArray = Object.values(allSkills);
  console.log(allSkills, "all skills");
  console.log(typeof allSkills, "type of skills??????");
  const addSkills = async () => {
    if (skills.trim() !== "") {
      await dispatch(
        candidateActions.addSkillCandidate({ skill: skills.trim() })
      );
      await dispatch(candidateActions.getUserSkills());
      setChips([...chips, skills.trim()]);
      setSkills("");
    }
  };
  const handleDeleteChip = async (id) => {
    const deletedSkill = id;
    console.log(chips, deletedSkill, "chip??");
    await dispatch(candidateActions.deleteSkillCandidate(deletedSkill));
    await dispatch(candidateActions.getUserSkills());
  };
  useEffect(() => {
    async function userSkills() {
      await dispatch(candidateActions.getUserSkills());
    }
    userSkills();
  }, []);
  const handleSkillChange = (event) => {
    setSkills(event.target.value);
  };
  //PREFERENCE

  useEffect(() => {
    async function userPreference() {
      await dispatch(candidateActions.getPreferences());
    }
    userPreference();
  }, []);
  //EDUCATION
  const existingEducation = useSelector(
    (state) => state?.candidate?.candidateEducations
  );
  const initialEducationEntry = { degree: "", passingYear: "", university: "" };

  const [educationEntries, setEducationEntries] = useState(
    existingEducation && existingEducation.length
      ? existingEducation
      : [initialEducationEntry]
  );
  console.log(educationEntries, "educationEntries??");
  const handleAddEducation = () => {
    setEducationEntries([
      ...educationEntries,
      { degree: "", passingYear: "", university: "" },
    ]);
  };

  const handleChange = (index, fieldName, value) => {
    const newEntries = [...educationEntries];
    newEntries[index] = {
      ...newEntries[index],
      [fieldName]: value,
    };
    setEducationEntries(newEntries);
  };
  const handleDeleteEducation = async (index) => {
    const educationToDelete = educationEntries[index];
    if (educationToDelete.id) {
      await dispatch(candidateActions.deleteEducation(educationToDelete.id));

      const updatedEducation = await dispatch(
        candidateActions.getUserEducation()
      );
      if (updatedEducation && updatedEducation.data) {
        setEducationEntries(updatedEducation.data.data);
      }
    }
    const newEntries = [...educationEntries];
    newEntries.splice(index, 1);
    setEducationEntries(newEntries);
  };

  // update education

  const updateEducation = async (index) => {
    const educationToUpdate = educationEntries[index];
    const isUpdate =
      educationToUpdate.id &&
      existingEducation.some((edu) => edu.id === educationToUpdate.id);
    const eduId = isUpdate ? educationToUpdate.id : undefined;
    const formData = {
      institute: educationToUpdate.institute,
      degree: educationToUpdate.degree,
      endDate: {
        month: "",
        year: educationToUpdate.endDate?.year,
      },
    };

    if (isUpdate) {
      await dispatch(candidateActions.updateEducation(formData, eduId));

      const updatedEducation = await dispatch(
        candidateActions.getUserEducation()
      );
      if (updatedEducation && updatedEducation?.data) {
        setEducationEntries(updatedEducation?.data?.data);
      }
    } else {
      await dispatch(candidateActions.addEducationCandidate(formData));

      const createdEducation = await dispatch(
        candidateActions.getUserEducation()
      );
      if (createdEducation && createdEducation?.data) {
        setEducationEntries(createdEducation?.data?.data);
      }
    }
  };

  //EXPERIENCE

  const existingExperiences = useSelector(
    (state) => state?.candidate?.candidateExperienceList
  );

  const [experienceEntries, setExperienceEntries] = useState(
    existingExperiences && existingExperiences.length ? existingExperiences : []
  );
  console.log(experienceEntries, "existingExperiences??");
  const handleAddExperience = () => {
    setExperienceEntries([
      ...experienceEntries,
      {
        organization: "",
        startDate: { month: "", year: "" },
        endDate: { month: "", year: "" },
        title: "",
      },
    ]);
  };
  const handleInputChange = (index, fieldName, value) => {
    const newEntries = [...experienceEntries];
    newEntries[index] = {
      ...newEntries[index],
      [fieldName]: value,
    };

    if (fieldName === "startDate") {
      newEntries[index] = {
        ...newEntries[index],
        startDate: {
          ...newEntries[index].startDate,
          month: value.month,
          year: value.year,
        },
      };
    }

    if (fieldName === "endDate") {
      newEntries[index] = {
        ...newEntries[index],
        endDate: {
          ...newEntries[index].endDate,
          month: value.month,
          year: value.year,
        },
      };
    }

    setExperienceEntries(newEntries);
  };

  const handleDeleteExperience = async (index) => {
    const experienceToDelete = experienceEntries[index];
    console.log("experience Delete??", experienceToDelete);
    if (experienceToDelete.id) {
      await dispatch(candidateActions.deleteExperience(experienceToDelete.id));
      const updatedExperience = await dispatch(
        candidateActions.getUserExperience()
      );
      if (updatedExperience && updatedExperience.data) {
        setExperienceEntries(updatedExperience?.data?.data);
      }
    }
    const newEntries = [...experienceEntries];
    newEntries.splice(index, 1);
    setExperienceEntries(newEntries);
  };

  const updateExperienceCandidate = async (index) => {
    const experienceToUpdate = experienceEntries[index];
    console.log("Before isUpdate check:", experienceToUpdate);
    const isUpdate =
      experienceToUpdate.id &&
      existingExperiences.some((exp) => exp.id === experienceToUpdate.id);

    const expId = isUpdate ? experienceToUpdate.id : undefined;

    const formData = {
      organization: experienceToUpdate.organization,
      title: experienceToUpdate.title,
      startDate: {
        month: experienceToUpdate.startDate?.month,
        year: experienceToUpdate.startDate?.year,
      },
      endDate: {
        month: experienceToUpdate.endDate?.month,
        year: experienceToUpdate.endDate?.year,
      },
    };
    console.log("form Data exp:", formData);
    if (isUpdate) {
      await dispatch(candidateActions.updateExperience(formData, expId));

      const updatedExperience = await dispatch(
        candidateActions.getUserExperience()
      );
      if (updatedExperience && updatedExperience.data) {
        setExperienceEntries(updatedExperience?.data?.data);
      }
    } else {
      await dispatch(candidateActions.addExperienceCandidate(formData));

      const createdExperience = await dispatch(
        candidateActions.getUserExperience()
      );
      if (createdExperience && createdExperience.data) {
        setExperienceEntries(createdExperience?.data?.data);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(candidateActions.getUserExperience());
    };
    fetchData();
  }, [dispatch]);

  //profile

  console.log(candidateProfile, "candi prof?/");
  const areBothFieldsBlank =
    (!candidateProfile?.designation ||
      candidateProfile.designation.trim() === "") &&
    (!candidateProfile?.organization ||
      candidateProfile.organization.trim() === "");
  console.log("Default Month Value:", candidateProfile);
  const industryList = useSelector((state) => state?.helper?.industries);
  const funcList = useSelector((state) => state?.helper?.functions);
  console.log(
    candidateProfile.id,
    candidateProfile?.resume,
    "candidate profile kya hai???"
  );
  console.log(industryList, "industryList kya hai???");
  console.log(funcList, "funcList kya hai???");

  const authToken =
    "HXZwdhCzJriME7FDkpJEdcbURsYOyPoPI18shO3in9RRcheG37WHn2LcLy1zZIs5ThsFFOXeNLeNmiClOenejj3DrCywullHHUsYP9I9n2aHdDxiOBCGVKRX1P2XmtfT";

  useEffect(() => {
    async function getFunctionlist() {
      await dispatch(helperActions.getIndustry(authToken));
    }
    getFunctionlist();
  }, [dispatch, authToken]);

  useEffect(() => {
    console.log("authToken", authToken);
    async function getindustrylist() {
      await dispatch(helperActions.getFunction(authToken));
    }
    getindustrylist();
  }, [dispatch, authToken]);
  const [checkboxVal, setCheckboxVal] = React.useState({});
  const {
    register,

    handleSubmit,
    watch,

    formState: { errors },
  } = useForm({ mode: "onTouched" });
  const methods = useFormContext();
  useEffect(() => {
    const getProfile = async () => {
      try {
        const candidateDetail = await dispatch(
          candidateActions.getCandidateProfile()
        );
        console.log("get candidateDetail??????????", candidateDetail);
      } catch (error) {
        console.error("Error fetching candidate profile:", error);
      }
    };
    getProfile();
  }, []);
  const functionChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedFun(typeof value === "string" ? value.split(",") : value);
  };
  const industryChange = (event) => {
    console.log("selected industry?", selectedIndustry);
    const {
      target: { value },
    } = event;
    setSelectedIndustry(typeof value === "string" ? value.split(",") : value);
  };

  const onSubmit = async (formData) => {
    console.log("onSubmit update", formData);

    const dataToUpdate = {
      firstName: candidateProfile?.firstName,
      lastName: candidateProfile?.lastName,
      countryCode: candidateProfile?.countryCode,
      phoneNo: candidateProfile?.phoneNo,
      emailId: formData.email,
      location: formData.current_location,
      ctc: {
        lakhs: parseInt(formData.current_ctc_lacs, 10),
        thousands: parseInt(formData.current_ctc_thousand, 10),
      },
      totalExperience: {
        years: Number(formData.years),
        months: Number(formData.months),
      },
      industry: formData?.current_industry,
      function: formData?.current_func,
      organization: formData?.company,
      designation: formData?.latestDesignation,
      startDate: {
        year: formData.start_year,
        month: formData.start_month + " ",
      },
    };

    const updatecandidate = await dispatch(
      candidateActions.candidateUpdateProfile(dataToUpdate)
    );

    if (updatecandidate?.data?.status === 1) {
      const preferenceData = {
        industry: selectedIndustry,
        function: selectedFun,
        readyToRelocate: Boolean(formData.relocate),
        location: [formData.prefferedLocation],
      };
      const preferencesExist = userPreference?.length > 0;
      let preferenceResponse;
      if (!preferencesExist) {
        preferenceResponse = await dispatch(
          candidateActions.addPreferencesCandidate(preferenceData)
        );
      } else {
        const prefId = userPreference[0]?.id;

        preferenceResponse = await dispatch(
          candidateActions.updatePreference(preferenceData, prefId)
        );
      }

      if (preferenceResponse?.data?.status === 1) {
        const fileData = new FormData();
        const userId = candidateProfile?.id;
        console.log("user id", candidateProfile?.id);
        const key = "posts/" + userId;
        fileData.append("key", key);
        fileData.append("files", selectedFile, selectedFile.name);
        console.log("UPLOADED FILE", selectedFile);

        const uploadFile = await dispatch(
          candidateActions.uploadFile(fileData)
        );

        console.log("File Upload Response:", uploadFile);

        if (uploadFile.data?.status === 1) {
          await dispatch(candidateActions.getPreferences());
          await dispatch(candidateActions.getUserEducation());
          await dispatch(candidateActions.getUserExperience());
          await dispatch(candidateActions.getCandidateProfile());
          navigate("/candidate/profile");
        }

        // navigate("/candidate/profile");
      } else {
        console.error(
          "Error managing Preferences:",
          preferenceResponse?.data?.message
        );
      }
    } else {
      console.error(
        "Error updating candidate profile:",
        updatecandidate?.data?.message
      );
    }
  };
  const [selectedImg, setSelectedImg] = useState(null);

  const handleUploadImg = (event) => {
    const file = event.target.files[0];
    setSelectedImg(file);
  };
  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.container}>
        <Box className={classes.topbar}>
          <Stack direction='row'>
            <Box
              className={classes.imgFrame}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              {imagepath ? (
                <Avatar
                  className={classes.img}
                  variant='square'
                  src={imagepath}
                  sx={{ width: 120, height: 120, position: "absolute" }}
                />
              ) : (
                <Avatar
                  className={classes.img}
                  variant='square'
                  src={
                    candidateProfile?.profile_image
                      ? candidateProfile?.profile_image
                      : PersonIcon
                  }
                  sx={{ width: 120, height: 120, position: "absolute" }}
                />
              )}
              <Box sx={{ position: "relative", top: "48px", right: "-48px" }}>
                <label htmlFor='icon-button-file'>
                  <Box
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      height: "30px",
                      maxWidth: "min-content",
                      minWidth: "30px",
                      cursor: "pointer",
                      backgroundColor: "#304672",
                      borderRadius: "50%",
                    }}>
                    <Box
                      component='img'
                      className={classes.img1}
                      src={Editicon}
                    />
                  </Box>
                </label>
                <input
                  accept='image/*'
                  id='icon-button-file'
                  type='file'
                  style={{ display: "none" }}
                  // onChange={handleUploadImage}
                />
              </Box>
            </Box>
            <Box
              className={classes.text}
              sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant='h4'
                sx={{
                  marginTop: "10px",
                  fontSize: "28px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "600",
                }}>
                {candidateProfile?.firstName} {candidateProfile?.lastName}
              </Typography>

              <Stack
                direction='row'
                spacing={{ xs: 2, sm: 4, md: 10 }}
                sx={{ marginTop: "15px" }}></Stack>
            </Box>
          </Stack>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Box>
                <Box pt={4}>
                  <Typography
                    variant='h5'
                    sx={{
                      fontSize: "22px",
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Your Information
                  </Typography>
                </Box>
                <Grid container pt={2}>
                  {" "}
                  <Grid item sm={6} xs={6} pr={4}>
                    <Grid container>
                      {" "}
                      <Grid item sm={6} xs={6}>
                        <Typography
                          variant='p'
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          Experience*
                        </Typography>{" "}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            width: "100%",
                            marginRight: "10px",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            fontWeight: "500",
                          }}
                          name='years'
                          defaultValue={
                            Number(candidateProfile?.totalExperience?.years) ??
                            "fresher"
                          }
                          className={classes.countrycode}
                          renderValue={(selected) => {
                            console.log(selected, typeof selected, "pppp");
                            if (selected === 0 || selected === 1) {
                              return `${selected} year`;
                            } else {
                              return `${selected} years`;
                            }
                          }}
                          {...register("years", {
                            required: "Select your experience in years",
                          })}>
                          {Experience?.map((option) => {
                            return (
                              <MenuItem
                                key={option.label}
                                value={option.label}
                                sx={{
                                  color: "#5071C4",
                                  fontWeight: "700",
                                }}>
                                {option.value ?? option.label}
                              </MenuItem>
                            );
                          })}
                        </Select>{" "}
                        {errors.years && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.years.message}
                          </Box>
                        )}
                      </Grid>
                      <Grid item sm={6} xs={6} pt={3}>
                        {" "}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            width: "100%",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            fontWeight: "500",
                            marginLeft: "10px",
                          }}
                          name='months'
                          defaultValue={
                            Number(candidateProfile?.totalExperience?.months) ??
                            0
                          }
                          className={classes.countrycode}
                          renderValue={(selected) => {
                            console.log(selected, typeof selected);
                            if (selected === 0 || selected === 1) {
                              return `${selected} month`;
                            } else {
                              return `${selected} months`;
                            }
                          }}
                          {...register("months", {
                            required: "Select your experience in months",
                          })}>
                          {Months?.map((option) => {
                            return (
                              <MenuItem
                                key={option.label}
                                value={option.label}
                                sx={{
                                  color: "#5071C4",
                                  fontWeight: "700",
                                }}>
                                {option.value ?? option.label}
                              </MenuItem>
                            );
                          })}
                        </Select>{" "}
                        {errors.months && (
                          <Box
                            sx={{
                              color: "red",
                              fontSize: "12px",
                              marginLeft: "10px",
                            }}>
                            {" "}
                            {errors.months.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>{" "}
                  </Grid>
                  <Grid item sm={6} xs={6} pl={3}>
                    <Grid container>
                      {" "}
                      <Grid item sm={6} xs={6} sx={{ paddingRight: "5px" }}>
                        <Typography
                          variant='p'
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          Current CTC{" "}
                          <span
                            style={{
                              color: "#5071C4",
                              fontSize: "12px",
                            }}>
                            (in lacs)*
                          </span>
                        </Typography>{" "}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            width: "100%",
                            marginRight: "10px",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            fontWeight: "500",
                          }}
                          name='current_ctc_lacs'
                          defaultValue={candidateProfile?.ctc?.lakhs}
                          className={classes.countrycode}
                          renderValue={(selected) => {
                            return selected;
                          }}
                          {...register("current_ctc_lacs", {
                            required: "enter your current ctc ",
                          })}>
                          {[...Array(50)]?.map((option, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={index}
                                sx={{
                                  color: "#5071C4",
                                  fontWeight: "700",
                                }}>
                                {index}
                              </MenuItem>
                            );
                          })}
                        </Select>{" "}
                        {errors.current_ctc_lacs && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.current_ctc_lacs.message}
                          </Box>
                        )}
                      </Grid>
                      <Grid item sm={6} xs={6} style={{ paddingLeft: "5px" }}>
                        <span
                          style={{
                            color: "#5071C4",
                            fontSize: "12px",
                            fontWeight: 500,
                          }}>
                          (in thousands)*
                        </span>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            width: "100%",
                            marginRight: "10px",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            fontWeight: "500",
                          }}
                          name='current_ctc_thousand'
                          defaultValue={candidateProfile?.ctc?.thousands}
                          className={classes.countrycode}
                          renderValue={(selected) => {
                            return selected * 10;
                          }}
                          {...register("current_ctc_thousand", {
                            required: "Enter your current ctc in thousand",
                          })}>
                          {[...Array(10)]?.map((option, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={index}
                                sx={{
                                  color: "#5071C4",
                                  fontWeight: "700",
                                }}>
                                {index * 10}
                              </MenuItem>
                            );
                          })}
                        </Select>{" "}
                        {errors.current_ctc_thousand && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.current_ctc_thousand.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container pt={1}>
                  <Grid item sx={6} sm={6} pr={3}>
                    <Grid container direction='column'>
                      {" "}
                      <Grid item>
                        {" "}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          Current Industry*
                        </Typography>{" "}
                      </Grid>
                      <Grid item>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            width: "100%",
                            marginRight: "10px",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            fontWeight: "500",
                          }}
                          name='current_industry'
                          defaultValue={candidateProfile?.industry}
                          className={classes.countrycode}
                          {...register("current_industry", {
                            required: "Current Industry is required ",
                          })}>
                          {industryList1?.map((option, index) => {
                            return (
                              <MenuItem
                                key={option?._id}
                                value={option?.industry}
                                sx={{
                                  color: "#5071C4",
                                  fontWeight: "700",
                                  "&.Mui-selected": {
                                    color: "#fff",
                                    backgroundColor: "#4B4FD9",
                                  },
                                }}>
                                {option?.industry}
                              </MenuItem>
                            );
                          })}
                        </Select>{" "}
                        {errors.current_industry && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.current_industry.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={6} sm={6} pl={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Current Function*
                    </Typography>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        width: "100%",
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                        fontWeight: "500",
                      }}
                      name='current_func'
                      defaultValue={candidateProfile?.function}
                      className={classes.countrycode}
                      {...register("current_func", {
                        required: "Current function is required ",
                      })}>
                      {functionList1.map((option, index) => (
                        <MenuItem
                          key={option?._id}
                          value={option?.function}
                          sx={{
                            color: "#5071C4",
                            fontWeight: "700",
                            "&.Mui-selected": {
                              color: "#fff",
                              backgroundColor: "#4B4FD9",
                            },
                          }}>
                          {option?.function}
                        </MenuItem>
                      ))}
                    </Select>{" "}
                    {errors.current_func && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.current_func.message}
                      </Box>
                    )}
                  </Grid>
                  <Grid Item sx={6} sm={6} pr={2}></Grid>{" "}
                </Grid>
                <Grid container pt={1}>
                  <Grid item sx={6} sm={6} pr={3}>
                    <Grid container direction='column'>
                      {" "}
                      <Grid item>
                        {" "}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          Current Location*
                        </Typography>{" "}
                      </Grid>
                      <Grid item>
                        <FormInput
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "5px",
                          }}
                          name='current_location'
                          fullWidth
                          {...register("current_location", {
                            required: "Location is required",
                          })}
                          defaultValue={candidateProfile?.location}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid Item sx={6} sm={6} pl={3}>
                    {" "}
                    <Grid item>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                        }}>
                        Upload CV*
                      </Typography>{" "}
                    </Grid>
                    <Grid item>
                      <div>
                        <input
                          type='file'
                          onChange={handleUploadCv}
                          style={{ display: "none" }}
                          ref={fileInputRef}
                        />
                        <div>
                          <input
                            style={{
                              width: "100%",
                              padding: "10px",
                              display: "block",
                              borderRadius: "5px",
                              border: "none",
                              backgroundColor: "#E0DFF4",
                              color: "#304672",
                              cursor: "pointer",
                            }}
                            type='text'
                            value={
                              selectedFile ? selectedFile.name : defaultFileName
                            }
                            readOnly
                          />
                          <Button
                            sx={{
                              textTransform: "none",
                              textDecoration: "none",
                              padding: "0px 10px",
                              borderRadius: "3px ",
                              color: "#FDCF57",
                              fontWeight: "500",
                              fontSize: "15px",
                              backgroundColor: "#223870",
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#223870",
                                color: "#FDCF57 ",
                              },
                            }}
                            onClick={handleButtonClick}>
                            Choose File
                          </Button>
                        </div>
                      </div>
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.browse?.type === "required" && "Upload your cv"}
                      </Box>
                    </Grid>
                    <Box>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: "#615d5d",
                          fontSize: "10px",
                        }}>
                        DOC, DOCx, PDF, RTF l Max: 2 MB
                      </Typography>
                    </Box>
                  </Grid>{" "}
                </Grid>
              </Box>
              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </div>
            <div>
              {" "}
              <Box>
                <Box>
                  <Box pt={1}>
                    <Typography
                      variant='h5'
                      sx={{
                        fontSize: "22px",
                        textAlign: "left",
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Skills
                    </Typography>
                  </Box>
                  <Grid container pr={6} pt={2}>
                    <Grid item sx={6} sm={6}>
                      <Typography
                        variant='p'
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                        }}>
                        Add Skills*
                      </Typography>{" "}
                      <Stack direction='row' spacing={0}>
                        <FormInput
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "5px",
                          }}
                          fullWidth
                          value={skills}
                          onChange={handleSkillChange}
                        />
                        <Box
                          sx={{
                            width: "100px",
                            marginLeft: "-3px",
                            height: "40px",
                            backgroundColor: "#E0DFF4",
                            padding: "3px 0px",
                            borderRadius: "0 5px 5px 0px",
                            "@media (maxWidth: 468px)": {
                              fontSize: "14px",
                            },
                          }}>
                          {" "}
                          <Button
                            onClick={addSkills}
                            style={{
                              width: "80px",
                              marginRight: "3px",
                              backgroundColor: "#C0BEEA",
                              borderRadius: "3px",
                              color: "#223870",
                              marginTop: "1px",
                              textAlign: "center",
                              padding: "2px 0px",
                              fontSize: "16px",
                              textTransform: "none",
                              "&:hover": {
                                color: "#223870",
                                backgroundColor: "#C0BEEA",
                              },
                              "@media (maxWidth: 468px)": {
                                marginTop: "-29px",
                                textAlign: "center",
                                marginRight: "-10px",
                                fontSize: "14px",
                              },
                            }}>
                            Add
                          </Button>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid
                      Item
                      sx={12}
                      sm={12}
                      style={{
                        paddingRight: "16px",
                        display: "flex",
                        direction: "row",
                      }}>
                      <Stack direction='row' mt={1}>
                        {skillsArray?.map((skill, index) => (
                          <StyledChip
                            key={index}
                            label={skill?.skill}
                            onDelete={() => handleDeleteChip(skill?.id)}
                          />
                        ))}
                      </Stack>
                    </Grid>{" "}
                  </Grid>
                </Box>
                <Box pt={2} pb={2}>
                  <Divider />
                </Box>
              </Box>
            </div>

            {/* EDUCATION */}
            <div>
              <Box pt={1}>
                <Typography
                  variant='h5'
                  sx={{
                    fontSize: "22px",
                    textAlign: "left",
                    color: "#304672",
                    fontWeight: "500",
                  }}>
                  Education
                </Typography>
              </Box>
              {Array.isArray(educationEntries) &&
                educationEntries?.map((education, index) => (
                  <Box key={index}>
                    <Grid container pt={1}>
                      <Grid item sx={6} sm={6}>
                        <Typography
                          variant='p'
                          sx={{ color: "#304672", fontWeight: "500" }}>
                          Degree*
                        </Typography>
                        <FormInput
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "5px",
                          }}
                          name={"degree-" + index}
                          fullWidth
                          value={educationEntries[index]?.degree}
                          onChange={(e) =>
                            handleChange(index, "degree", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item sx={6} sm={6} pl={3}>
                        <Typography
                          variant='p'
                          sx={{ color: "#304672", fontWeight: "500" }}>
                          Passing year*
                        </Typography>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            width: "100%",
                            marginRight: "10px",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            fontWeight: "500",
                          }}
                          className={classes.countrycode}
                          value={educationEntries[index]?.endDate?.year}
                          onChange={(e) =>
                            handleChange(index, "endDate", {
                              year: e.target.value,
                            })
                          }
                          name={"passingyear-" + index}>
                          {passing_year_list
                            ?.slice(0)
                            ?.reverse()
                            ?.map((option, index) => {
                              return (
                                <MenuItem
                                  key={option?.value}
                                  value={option?.value}
                                  sx={{
                                    color: "#5071C4",
                                    fontWeight: "700",
                                  }}>
                                  {option?.label}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </Grid>
                    </Grid>

                    <Grid container pt={1} mb={2}>
                      <Grid item sx={6} sm={6}>
                        <Typography
                          variant='p'
                          sx={{ color: "#304672", fontWeight: "500" }}>
                          University*
                        </Typography>
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name={"university-" + index}
                          value={educationEntries[index]?.institute}
                          onChange={(e) =>
                            handleChange(index, "institute", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item sx={6} sm={6}>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}>
                          {!education?.id ? (
                            <Button
                              onClick={() => updateEducation(index)}
                              sx={{
                                textTransform: "none",
                                textDecoration: "none",
                                padding: "3px 15px",
                                marginTop: "10px",
                                borderRadius: "2px ",
                                color: "#FDCF57",
                                fontWeight: "500",
                                fontSize: "14px",
                                backgroundColor: "#223870",
                                "&:hover": {
                                  backgroundColor: "#223870",
                                  color: "#FDCF57 ",
                                },
                              }}>
                              Add
                            </Button>
                          ) : (
                            <>
                              <Button
                                onClick={() => updateEducation(index)}
                                sx={{
                                  textTransform: "none",
                                  textDecoration: "none",
                                  padding: "3px 15px",
                                  marginTop: "10px",
                                  borderRadius: "2px ",
                                  color: "#FDCF57",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  backgroundColor: "#223870",
                                  "&:hover": {
                                    backgroundColor: "#223870",
                                    color: "#FDCF57 ",
                                  },
                                }}>
                                Update
                              </Button>
                            </>
                          )}
                          <IconButton>
                            <Box
                              onClick={() => {
                                handleDeleteEducation(index);
                              }}
                              component='img'
                              sx={{
                                marginTop: "4px",
                                width: "18px",
                                height: "18px",
                                "@media (maxWidth: 468px)": {
                                  marginTop: "-8px",
                                  width: "14px",
                                  height: "14px",
                                },
                              }}
                              src={DeleteIcon}
                            />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}

              <Box
                mt={2}
                onClick={handleAddEducation}
                sx={{ cursor: "pointer" }}>
                <Typography
                  variant='p'
                  sx={{
                    color: "#304672",
                    fontWeight: "500",
                  }}>
                  + Add new education
                </Typography>
              </Box>

              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </div>

            <Box pt={1}>
              <Typography
                variant='h5'
                sx={{
                  fontSize: "22px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "500",
                }}>
                Experience
              </Typography>
            </Box>
            <Box>
              <Box>
                {!areBothFieldsBlank && (
                  <Box>
                    <Grid container pt={1}>
                      <Grid item sx={6} sm={6} pr={4}>
                        <Typography
                          variant='p'
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          Company*
                        </Typography>{" "}
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          defaultValue={candidateProfile?.organization}
                          name='company'
                          {...register("company", {
                            required: "Please enter your Company name",
                          })}
                        />{" "}
                        {errors?.company && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors?.company.message}
                          </Box>
                        )}
                      </Grid>
                      <Grid item sx={1.5} sm={1.5} pl={4}>
                        <Typography
                          variant='p'
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          Start-Month*
                        </Typography>{" "}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            width: "100%",
                            marginRight: "10px",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            fontWeight: "500",
                          }}
                          defaultValue={parseInt(
                            candidateProfile?.startDate?.month.trim()
                          )}
                          name='start_month'
                          {...register("start_month", {
                            required: "Please enter your start year",
                          })}
                          className={classes.countrycode}>
                          {month?.map((option, index) => {
                            return (
                              <MenuItem
                                key={option?._id}
                                value={option?._id}
                                sx={{
                                  color: "#5071C4",
                                  fontWeight: "700",
                                }}>
                                {option?.value}
                              </MenuItem>
                            );
                          })}
                        </Select>{" "}
                        {errors.start_month && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.start_month.message}
                          </Box>
                        )}
                      </Grid>
                      <Grid
                        Item
                        sx={1.5}
                        sm={1.5}
                        style={{ paddingLeft: "8px" }}>
                        <Typography
                          variant='p'
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          Start-Year*
                        </Typography>{" "}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            width: "100%",
                            marginRight: "10px",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            fontWeight: "500",
                          }}
                          defaultValue={candidateProfile?.startDate?.year}
                          name='start_year'
                          {...register("start_year", {
                            required: "Please enter your start year",
                          })}
                          className={classes.countrycode}>
                          {passing_year_list
                            ?.slice(0)
                            .reverse()
                            .map((option, index) => {
                              return (
                                <MenuItem
                                  key={option?.value}
                                  value={option?.value}
                                  sx={{
                                    color: "#5071C4",
                                    fontWeight: "700",
                                  }}>
                                  {option?.label}
                                </MenuItem>
                              );
                            })}
                        </Select>{" "}
                        {errors.start_year && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.start_year.message}
                          </Box>
                        )}
                      </Grid>
                      <Grid
                        Item
                        sx={1.5}
                        sm={1.5}
                        style={{ paddingLeft: "8px" }}>
                        <Typography
                          variant='p'
                          sx={{
                            color: "gray",
                            fontWeight: "500",
                            marginBottom: "0",
                          }}>
                          End-Month*
                        </Typography>{" "}
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                // color: "#5071C4",
                                color: "gray",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            width: "100%",
                            marginRight: "10px",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            fontWeight: "500",
                          }}
                          disabled={true}
                          name='end_month'
                          defaultValue={
                            checkboxVal[candidateProfile?._id]
                              ? 0
                              : candidateProfile?.end_month
                          }
                          className={classes.countrycode}>
                          {month?.map((option, index) => {
                            return (
                              <MenuItem
                                key={option?._id}
                                value={option?._id}
                                sx={{
                                  color: "#5071C4",
                                  fontWeight: "700",
                                }}>
                                {option?.value}
                              </MenuItem>
                            );
                          })}
                        </Select>{" "}
                      </Grid>
                      <Grid
                        Item
                        sx={1.5}
                        sm={1.5}
                        style={{ paddingLeft: "8px" }}>
                        <Typography
                          variant='p'
                          sx={{
                            color: "gray",
                            fontWeight: "500",
                          }}>
                          End-Year*
                        </Typography>{" "}
                        <Select
                          disabled={true}
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "gray",
                                position: "absolute",
                                right: 10,
                                pointerEvents: "none",
                              }}
                            />
                          )}
                          sx={{
                            width: "100%",
                            marginRight: "10px",
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            fontWeight: "500",
                          }}
                          defaultValue={
                            checkboxVal[candidateProfile?._id]
                              ? 0
                              : candidateProfile?.end_year
                          }
                          name='end_year'
                          className={classes.countrycode}>
                          {passing_year_list
                            ?.slice(0)
                            .reverse()
                            .map((option, index) => {
                              return (
                                <MenuItem
                                  key={option?.value}
                                  value={option?.value}
                                  sx={{
                                    color: "#5071C4",
                                    fontWeight: "700",
                                  }}>
                                  {option?.label}
                                </MenuItem>
                              );
                            })}
                        </Select>{" "}
                      </Grid>
                    </Grid>
                    <Grid container pt={1}>
                      <Grid item sx={6} sm={6} pr={4}>
                        <Typography
                          variant='p'
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          Designation* {console.log("errors", errors)}
                        </Typography>{" "}
                        <FormInput
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "5px",
                          }}
                          name='latestDesignation'
                          fullWidth
                          defaultValue={candidateProfile?.designation}
                          {...register("latestDesignation", {
                            required: "latestDesignation",
                          })}
                        />
                        {errors.latestDesignation && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.latestDesignation.message}
                          </Box>
                        )}
                      </Grid>
                      <Grid item sx={6} sm={6}>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}>
                          <Stack
                            sx={{
                              width: "50%",
                              justifyContent: "space-between",
                            }}
                            direction='row'>
                            <Stack direction='row' sx={{ paddingLeft: "8px" }}>
                              <Checkbox
                                checked={true}
                                inputProps={{
                                  "aria-label": "controlled",
                                }}
                                small
                              />
                              <Box py={1}>
                                <Typography
                                  variant='p'
                                  sx={{
                                    color: "#304672",
                                    fontWeight: "500",
                                    fontSize: "12px",
                                  }}>
                                  Currently Working
                                </Typography>
                              </Box>
                            </Stack>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {/* EXPERIENCE PAST */}
                {Array.isArray(experienceEntries) &&
                  experienceEntries?.map((experience, index) => (
                    <Box key={index}>
                      <Grid container pt={3}>
                        <Grid item sx={6} sm={6} pr={3}>
                          <Typography
                            variant='p'
                            sx={{
                              color: "#304672",
                              fontWeight: "500",
                            }}>
                            Company*
                          </Typography>{" "}
                          <FormInput
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            name={"organization-" + index}
                            value={experienceEntries[index]?.organization}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "organization",
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                        <Grid item sx={1.5} sm={1.5} pl={3}>
                          <Typography
                            variant='p'
                            sx={{
                              color: "#304672",
                              fontWeight: "500",
                            }}>
                            Start-month*
                          </Typography>{" "}
                          <Select
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              marginRight: "10px",
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                              fontWeight: "500",
                            }}
                            name={"start_month-" + index}
                            value={experienceEntries[index]?.startDate?.month}
                            onChange={(e) =>
                              handleInputChange(index, "startDate", {
                                month: e.target.value,
                                year: experienceEntries[index]?.startDate?.year,
                              })
                            }
                            className={classes.countrycode}>
                            {month?.map((option, index) => {
                              return (
                                <MenuItem
                                  key={option?._id}
                                  value={option?.value}
                                  sx={{
                                    color: "#5071C4",
                                    fontWeight: "700",
                                  }}>
                                  {option?.value}
                                </MenuItem>
                              );
                            })}
                          </Select>{" "}
                        </Grid>
                        <Grid
                          Item
                          sx={1.5}
                          sm={1.5}
                          style={{
                            paddingLeft: "16px",
                            paddingRight: "8px",
                          }}>
                          <Typography
                            variant='p'
                            sx={{
                              color: "#304672",
                              fontWeight: "500",
                            }}>
                            Start-year*
                          </Typography>{" "}
                          <Select
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              marginRight: "10px",
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                              fontWeight: "500",
                            }}
                            name={"start_year-" + index}
                            value={experienceEntries[index]?.startDate?.year}
                            onChange={(e) =>
                              handleInputChange(index, "startDate", {
                                month:
                                  experienceEntries[index]?.startDate?.month,
                                year: e.target.value,
                              })
                            }
                            className={classes.countrycode}>
                            {passing_year_list
                              ?.slice(0)
                              ?.reverse()
                              ?.map((option, index) => {
                                return (
                                  <MenuItem
                                    key={option?.value}
                                    value={option?.value}
                                    sx={{
                                      color: "#5071C4",
                                      fontWeight: "700",
                                    }}>
                                    {option?.label}
                                  </MenuItem>
                                );
                              })}
                          </Select>{" "}
                        </Grid>
                        <Grid
                          Item
                          sx={1.5}
                          sm={1.5}
                          style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                          <Typography
                            variant='p'
                            sx={{
                              color: "#304672",
                              fontWeight: "500",
                              paddingBottom: "0!important",
                            }}>
                            End-month*
                          </Typography>{" "}
                          <Select
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              marginRight: "10px",
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                              fontWeight: "500",
                            }}
                            name={"end_month-" + index}
                            value={experienceEntries[index]?.endDate?.month}
                            onChange={(e) =>
                              handleInputChange(index, "endDate", {
                                month: e.target.value,
                                year: experienceEntries[index]?.endDate?.year,
                              })
                            }
                            className={classes.countrycode}>
                            {month?.map((option, index) => {
                              return (
                                <MenuItem
                                  key={option?._id}
                                  value={option?.value}
                                  sx={{
                                    color: "#5071C4",
                                    fontWeight: "700",
                                  }}>
                                  {option?.value}
                                </MenuItem>
                              );
                            })}
                          </Select>{" "}
                        </Grid>
                        <Grid
                          Item
                          sx={1.5}
                          sm={1.5}
                          style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                          <Typography
                            variant='p'
                            sx={{
                              color: "#304672",
                              fontWeight: "500",
                            }}>
                            End-year*
                          </Typography>{" "}
                          <Select
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: "#5071C4",
                                  position: "absolute",
                                  right: 10,
                                  pointerEvents: "none",
                                }}
                              />
                            )}
                            sx={{
                              width: "100%",
                              marginRight: "10px",
                              color: "#5071C4",
                              backgroundColor: "#E0DFF4",
                              fontWeight: "500",
                            }}
                            name={"end_year-" + index}
                            value={experienceEntries[index]?.endDate?.year}
                            onChange={(e) =>
                              handleInputChange(index, "endDate", {
                                month: experienceEntries[index]?.endDate?.month,
                                year: e.target.value,
                              })
                            }
                            className={classes.countrycode}>
                            {passing_year_list
                              ?.slice(0)
                              ?.reverse()
                              ?.map((option, index) => {
                                return (
                                  <MenuItem
                                    key={option?.value}
                                    value={option?.value}
                                    sx={{
                                      color: "#5071C4",
                                      fontWeight: "700",
                                    }}>
                                    {option?.label}
                                  </MenuItem>
                                );
                              })}
                          </Select>{" "}
                        </Grid>
                      </Grid>
                      <Grid container pt={1}>
                        <Grid item sx={6} sm={6} pr={3}>
                          <Typography
                            variant='p'
                            sx={{
                              color: "#304672",
                              fontWeight: "500",
                            }}>
                            Designation *
                          </Typography>{" "}
                          <FormInput
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            name={"title-" + index}
                            value={experienceEntries[index]?.title}
                            onChange={(e) =>
                              handleInputChange(index, "title", e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item sx={6} sm={6}>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}>
                            {!experience?.id ? (
                              <Button
                                onClick={() => updateExperienceCandidate(index)}
                                sx={{
                                  textTransform: "none",
                                  textDecoration: "none",
                                  padding: "3px 15px",
                                  marginTop: "10px",
                                  borderRadius: "2px ",
                                  color: "#FDCF57",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  backgroundColor: "#223870",
                                  "&:hover": {
                                    backgroundColor: "#223870",
                                    color: "#FDCF57 ",
                                  },
                                }}>
                                Add
                              </Button>
                            ) : (
                              <>
                                <Button
                                  onClick={() =>
                                    updateExperienceCandidate(index)
                                  }
                                  sx={{
                                    textTransform: "none",
                                    textDecoration: "none",
                                    padding: "3px 15px",
                                    marginTop: "10px",
                                    borderRadius: "2px ",
                                    color: "#FDCF57",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    backgroundColor: "#223870",
                                    "&:hover": {
                                      backgroundColor: "#223870",
                                      color: "#FDCF57 ",
                                    },
                                  }}>
                                  Update
                                </Button>
                              </>
                            )}{" "}
                            <IconButton>
                              {" "}
                              <Box
                                onClick={() => {
                                  handleDeleteExperience(index);
                                }}
                                component='img'
                                sx={{
                                  marginTop: "px",
                                  width: "18px",
                                  height: "18px",
                                  "@media (maxWidth: 468px)": {
                                    marginTop: "-8px",
                                    width: "14px",
                                    height: "14px",
                                  },
                                }}
                                src={DeleteIcon}
                              />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                <Box
                  py={2}
                  onClick={handleAddExperience}
                  sx={{ width: "50%", cursor: "pointer" }}>
                  <Typography
                    variant='p'
                    sx={{
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    + Add new experience
                  </Typography>
                </Box>
              </Box>
              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </Box>

            <div>
              <Box pt={1}>
                <Typography
                  variant='h5'
                  sx={{
                    fontSize: "22px",
                    textAlign: "left",
                    color: "#304672",
                    fontWeight: "500",
                  }}>
                  Preferences
                </Typography>
              </Box>
              <Box>
                <Box>
                  <Grid container pt={1}>
                    <Grid item sx={6} sm={6} pr={3}>
                      <Typography
                        variant='p'
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                        }}>
                        Industry*
                      </Typography>{" "}
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: "#5071C4",
                              position: "absolute",
                              right: 10,
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        sx={{
                          width: "100%",
                          marginRight: "10px",
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                          fontWeight: "500",
                        }}
                        multiple
                        name='industry'
                        onChange={industryChange}
                        className={classes.countrycode}
                        value={selectedIndustry}>
                        {industryList1?.map((option, index) => {
                          return (
                            <MenuItem
                              key={option?._id}
                              value={option?.industry}
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                                "&.Mui-selected": {
                                  color: "#fff",
                                  backgroundColor: "#4B4FD9",
                                },
                              }}>
                              {option?.industry}
                            </MenuItem>
                          );
                        })}
                      </Select>{" "}
                    </Grid>
                    <Grid item sx={6} sm={6} pl={3}>
                      <Typography
                        variant='p'
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                        }}>
                        Function*
                      </Typography>{" "}
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: "#5071C4",
                              position: "absolute",
                              right: 10,
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        sx={{
                          width: "100%",
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                          fontWeight: "500",
                        }}
                        multiple
                        name='func'
                        className={classes.countrycode}
                        value={selectedFun}
                        onChange={functionChange}>
                        {functionList1.map((option, index) => (
                          <MenuItem
                            key={option?._id}
                            value={option?.function}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                              "&.Mui-selected": {
                                color: "#fff",
                                backgroundColor: "#4B4FD9",
                              },
                            }}>
                            {option?.function}
                          </MenuItem>
                        ))}
                      </Select>{" "}
                    </Grid>{" "}
                  </Grid>

                  <Grid container pt={1}>
                    <Grid item sx={6} sm={6} pr={3}>
                      <Typography
                        variant='p'
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                        }}>
                        Ready to Relocate *
                      </Typography>{" "}
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: "#5071C4",
                              position: "absolute",
                              right: 10,
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        sx={{
                          width: "100%",
                          marginLeft: "0",
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                          fontWeight: "500",
                        }}
                        defaultValue={userPreference[0]?.readyToRelocate || ""}
                        name='relocate'
                        className={classes.countrycode}
                        {...register("relocate", {
                          required: "are you ready to relocate?",
                        })}>
                        <MenuItem
                          value='true'
                          sx={{
                            color: "#5071C4",
                            fontWeight: "700",
                          }}>
                          Yes
                        </MenuItem>
                        <MenuItem
                          value='false'
                          sx={{
                            color: "#5071C4",
                            fontWeight: "700",
                          }}>
                          No
                        </MenuItem>
                      </Select>{" "}
                      {errors.relocate && (
                        <Box
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "10px",
                          }}>
                          {" "}
                          {errors.relocate.message}
                        </Box>
                      )}
                    </Grid>
                    <Grid item sx={6} sm={6} pl={3}>
                      {watch("relocate") === "true" && (
                        <div>
                          {" "}
                          <Typography
                            variant='p'
                            sx={{
                              color: "#304672",
                              fontWeight: "500",
                            }}>
                            Location*
                          </Typography>{" "}
                          <FormInput
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              width: "100%",
                              borderRadius: "5px",
                            }}
                            name='prefferedLocation'
                            fullWidth
                            disabled={watch("relocate") === "false"}
                            defaultValue={
                              userPreference[0]?.location?.[0] || ""
                            }
                            {...register("prefferedLocation", {
                              required: "your preferred location ",
                            })}
                          />
                        </div>
                      )}
                    </Grid>
                    {errors.prefferedLocation && (
                      <Box
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          marginLeft: "10px",
                        }}>
                        {" "}
                        {errors.prefferedLocation.message}
                      </Box>
                    )}
                  </Grid>
                </Box>
                <Box pt={2} pb={2}>
                  <Divider />
                </Box>
              </Box>
            </div>

            <Box pt={1}>
              <Typography
                variant='h5'
                sx={{
                  fontSize: "22px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "500",
                }}>
                Account Details
              </Typography>
            </Box>
            <Box>
              <Box>
                <Grid container pt={1}>
                  <Grid item sx={6} sm={6} pr={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Mobile No.*
                    </Typography>{" "}
                    <Grid container>
                      <Grid item sm={4} sx={{ paddingRight: "10px" }}>
                        <Autocomplete
                          id='country-select-demo'
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                          value={selectedCountry}
                          options={countryListnew}
                          autoHighlight
                          disableClearable
                          variant='standard'
                          popupIcon={
                            <KeyboardArrowDownIcon
                              sx={{
                                color: "#5071C4",
                              }}
                            />
                          }
                          name='country_code'
                          defaultValue='91'
                          onChange={(_event, country) => {
                            console.log("country", country);
                            setSelectedCountry(country);
                          }}
                          size='small'
                          getOptionLabel={(option) => {
                            return option?.iso3 + " +" + option?.phone_code;
                          }}
                          renderInput={(params) => (
                            <FormInput
                              sx={{ height: 43 }}
                              size='small'
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={8}>
                        <Stack direction='row' spacing={0}>
                          {" "}
                          <FormInput
                            sx={{
                              backgroundColor: "#E0DFF4",
                              borderRadius: "5px",
                              width: "100%",
                            }}
                            size='small'
                            variant='outlined'
                            type='text'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled
                            fullWidth
                            onChange={(e) => setMobile(e.target.value)}
                            name='mobile'
                            defaultValue={candidateProfile?.phoneNo}
                          />{" "}
                          <Box
                            sx={{
                              marginLeft: "-20px",
                              height: "42px",
                              backgroundColor: "#E0DFF4",
                              padding: "3px 0px",
                              borderRadius: "0 5px 5px 0px",
                            }}>
                            <Button
                              variant='standard'
                              sx={{
                                marginRight: "5px",
                                backgroundColor: "#223870",
                                borderRadius: "3px",
                                color: "#FDCF57",
                                marginTop: "2px",
                                padding: "2px 20px",
                                fontSize: "16px",
                                textTransform: "capitalize",
                                "&:hover": {
                                  color: "#FDCF57",
                                  backgroundColor: "#223870",
                                },
                                "@media (maxWidth: 468px)": {
                                  textAlign: "center",
                                  padding: "0 10px",
                                },
                              }}>
                              {" "}
                              Verified
                            </Button>
                            <Dialog
                              onClose={handleCloseMobile}
                              sx={{
                                "& .MuiDialog-container": {
                                  "& .MuiPaper-root": {
                                    width: "100%",
                                    maxWidth: "590px",
                                    height: "430px",
                                    borderRadius: "10px",
                                    backgroundColor: (theme) =>
                                      theme.Palette.background.dialog,
                                  },
                                },
                              }}>
                              <DialogActions>
                                <Box
                                  onClick={handleCloseMobile}
                                  component='img'
                                  src={Close}
                                  sx={{
                                    top: "10px",
                                    right: "10px",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              </DialogActions>
                              <DialogTitle
                                sx={{
                                  color: "#223870",
                                  textAlign: "center",
                                  fontSize: "22px",
                                  marginTop: "-15px",
                                }}>
                                Verify Mobile No.
                              </DialogTitle>
                              <DialogContent
                                sx={{
                                  marginTop: "20px ",
                                  textAlign: "center",
                                }}>
                                {" "}
                                <Box>
                                  {" "}
                                  <Typography
                                    variant='p'
                                    gutterBottom
                                    sx={{
                                      fontSize: "18px",
                                      color: "#5071C4",
                                      fontWeight: "500",
                                    }}>
                                    OTP sent to {mobile}
                                  </Typography>{" "}
                                </Box>
                                <Box
                                  sx={{
                                    marginTop: "20px",
                                    marginBottom: "10px",
                                  }}>
                                  {" "}
                                  <Typography
                                    variant='h6'
                                    sx={{
                                      color: "#223870",
                                      fontWeight: "500",
                                    }}>
                                    Enter OTP
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}>
                                  <OtpInput
                                    value={otp}
                                    onChange={handleChangeOtp}
                                    numInputs={6}
                                    inputStyle={{
                                      height: "35px",
                                      width: "45px",
                                      color: "#5071C4",
                                      backgroundColor: "#E0DFF4",
                                      border: "1px solid #223870",
                                      margin: "4px",
                                      fontSize: "18px",
                                      borderRadius: "3px",
                                    }}
                                    focusStyle={{
                                      border: "1px solid #223870",
                                    }}
                                  />
                                </Box>
                                <Box sx={{ marginTop: "5px" }}>
                                  <OtpTimer timerzero={timerZero} />
                                </Box>
                                <Box sx={{ marginTop: "40px " }}>
                                  {" "}
                                  <Button
                                    sx={{
                                      padding: "4px 20px",
                                      textTransform: "none",
                                      textDecoration: "none",
                                      borderRadius: "3px ",
                                      color: "#FDCF57",
                                      fontWeight: "500",
                                      fontSize: "18px",
                                      backgroundColor: "#223870",
                                      "&:hover": {
                                        backgroundColor: "#223870",
                                        color: "#FDCF57 ",
                                      },
                                    }}>
                                    Verify
                                  </Button>
                                </Box>
                              </DialogContent>
                            </Dialog>
                          </Box>
                        </Stack>
                        {errors.mobile && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.mobile.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={6} sm={6} pl={3}>
                    <Typography
                      variant='p'
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Email ID*
                    </Typography>{" "}
                    <Stack direction='row' spacing={0}>
                      <FormInput
                        size='small'
                        variant='outlined'
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                          width: "100%",
                          borderRadius: "5px",
                        }}
                        fullWidth
                        name='email'
                        defaultValue={candidateProfile?.emailId}
                        {...register("email", {
                          required: "Email is required",
                        })}
                      />
                    </Stack>
                  </Grid>{" "}
                </Grid>
              </Box>
              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </Box>

            <Box
              container
              direction='Row'
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}>
              {" "}
              <Button
                type='submit'
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "3px 30px",
                  borderRadius: "3px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "20px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                }}>
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
export default EditCandidateProfile;
