import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  MenuItem,
  Select,
  Stack,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FormInput } from "../../../../components/Input/FormInput";
import { TextareaAutosize } from "@mui/base";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import itLocale from "i18n-iso-countries/langs/it.json";
import Close from "../../../../assets/Icons/Close.png";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import Navbar from "../../../../components/Header/Navbar";
import CustomizedSnackbars from "../../../../components/Snackbar/CustomizedSnackbars";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../../../../Store/candidate";
import * as companyActions from "../../../../Store/company";
import Editicon from "../../../../assets/Icons/Edit_White.png";
// import { countryListnew } from "../../../../assets/Data/data";
import { v4 as uuidv4 } from "uuid";
import AWS from "aws-sdk";
// import * as jobsActions from "../../../../Store/Jobs";
// import * as helperActions from "../../../../Store/helper";
import {
  RegistrationType,
  CompanyType,
  CompanySize,
  industryList1,
  functionList1,
} from "../../../../assets/Data/data";

const useStyles = makeStyles((theme) => ({
  mainHome: {
    padding: "90px 200px 30px",
    backgroundSize: "cover",
    backgroundColor: "#F0F1FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  topbar: {
    position: "relative",
    padding: "20px 0px",
    backgroundSize: "cover",
    backgroundColor: "#F0F1FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  writeicon: {
    width: "20px",
    height: "20px",
    backgroundColor: "#223870",
    borderRadius: "50%",
  },
  imgFrame: {
    position: "relative",
    background: "#fff",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    objectFit: "contain",
    margin: "0 auto",
    width: "120px",
    height: "120px",
    borderRadius: "5px",
    backgroundSize: "cover",
  },
  imgFrame1: {
    bottom: "5px",
    right: "5px",
    position: "absolute",
    background: "#304672",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img1: {
    objectFit: "cover",
    margin: "0 auto",
    width: "15px",
    height: "15px",
    backgroundSize: "cover",
  },
  text: { marginLeft: "30px" },
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "7px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  container: {
    padding: "20px 200px",
    margin: "20px 75px",
    textAlign: "center",
    backgroundColor: "#E8EEFB",
    height: "auto",
    borderRadius: "10px",
  },

  "@media (min-width: 1640px)": {
    mainHome: {
      paddingTop: "90px",
    },
    title: {
      paddingRight: "60px ",
    },
  },
  "@media (maxWidth: 468px)": {
    mainHome: {
      padding: "50px 15px",
      backgroundImage: "none",
      backgroundColor: "#c6c2f6",
    },
    search: {
      marginRight: "0",
      padding: "3px 10px",
    },
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "35px",
    },
    container: {
      padding: "0 0px",
    },
  },
}));

const EditCompany = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);
  const [setName] = React.useState();
  const [regType, setRegType] = React.useState();
  const handleRegType = (event) => {
    setRegType(event.target.value);
  };
  const [compSize, setCompSize] = React.useState();
  const handleCompSize = (event) => {
    setCompSize(event.target.value);
  };
  const [compType, setCompType] = React.useState();
  const handleCompType = (event) => {
    setCompType(event.target.value);
  };
  const [selectedCountryKyc, setSelectedCountryKyc] = useState("IN");
  const handleSelectkyc = (event) => {
    console.log("handleSelect", event.target.value);
    setSelectedCountryKyc(event.target.value);
  };
  const handleChange = (event) => {
    setName(event.target.value);
  };

  const profile = useSelector((state) => state?.company?.companyDetails);
  console.log("profile kya hai", profile);
  const companyId = useSelector((state) => state?.company?.companyId);

  useEffect(() => {
    const getProfile = async () => {
      try {
        const companyProfile = await dispatch(
          companyActions?.getCompanyProfile(companyId)
        );
        console.log("get candidateDetail??????????", companyProfile);
      } catch (error) {
        console.error("Error fetching candidate profile:", error);
      }
    };
    getProfile();
  }, []);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const onSubmit = async (data) => {
    console.log("data", data);
    let taglin = [];
    taglin.push(data?.tagline);
    console.log("taglin", taglin);
    let logo = profile?.logo;
    // if (filepath) {
    //   logo = filepath;
    // }
    const updatedata = {
      first_name: data?.firstName,
      last_name: data?.lastName,
      // designation: userDesignation?._id,
      company_name: data?.companyName,
      tagline: taglin,
      domain_name: data?.domain,
      about_company: data?.about_company,
      registration_type: data?.regtype,
      website: data?.website,
      industry: data?.industry,
      function: data?.func,
      company_type: data?.companytype,
      size: data?.size,
      logo: logo,
      address: data?.address,
      country: selectedCountryKyc,
      state: data?.state,
      city: data?.city,
      pin_code: data?.pincode,
      pan_no: data?.pan,
      tan_no: data?.tan,
    };
    const updateProfile = await dispatch(
      authActions.companyUpdateProfile(updatedata)
    );
    if (updateProfile?.data?.status === true) {
      navigate("/company/profile");
    }
  };

  // const [selectedCountry, setSelectedCountry] = useState({
  //   id: 240,
  //   iso2: "IN",
  //   country_name: "India",
  //   iso3: "IND",
  //   phone_code: 91,
  // });

  // const [openEmail, setOpenEmail] = React.useState(false);
  // const [openMobile, setOpenMobile] = React.useState(false);
  // const [industry, setIndustry] = React.useState();
  // const [funct, setFunct] = React.useState();
  // const [type, setType] = React.useState();
  // const [size, setSize] = React.useState();

  // const [email, setEmail] = React.useState();
  // const companyIndustry = useSelector(
  //   (state) => state?.helper?.companyIndustry
  // );
  // const companyFunction = useSelector(
  //   (state) => state?.helper?.companyFunction
  // );
  // const companySize = useSelector((state) => state?.helper?.companySize);
  // const companytype = useSelector((state) => state?.helper?.companyType);
  // const companyRegType = useSelector((state) => state?.helper?.companyRegType);
  // useSelector((state) => state?.helper?.location);
  // useSelector((state) => state?.helper?.skills);

  // const handleClickOpenEmail = async () => {
  //   console.log(email);
  //   const data = {
  //     signup_type: 1,
  //     user_type: 2,
  //     email_id: email,
  //     is_secondary: false,
  //   };
  // };

  // dispatch(getCompanyProfile(companyId));

  // const handleClickOpenMobile = () => {
  //   setOpenMobile(true);
  // };
  // const handleCloseEmail = () => {
  //   setOpenEmail(false);
  // };
  // const handleCloseMobile = () => {
  //   setOpenMobile(false);
  // };
  // const handleIndustry = (event) => {
  //   setIndustry(event.target.value);
  // };
  // const handleFunct = (event) => {
  //   setFunct(event.target.value);
  // };
  // const handleType = (event) => {
  //   setType(event.target.value);
  // };
  // const handleSize = (event) => {
  //   setSize(event.target.value);
  // };
  // const [file, setFile] = React.useState('');
  // const [img, setImg] = React.useState('');
  // const onImageChange = (e) => {
  //   const [file] = e.target.files;
  //   setImg(URL.createObjectURL(file));
  // };
  // const fileRef = useRef();
  // useEffect(() => {
  //   async function companyIndustry() {
  //     setIndustry(profile?.industry);
  //   }
  //   companyIndustry();
  // }, []);

  // useEffect(() => {
  //   async function getdesignationlist() {
  //     await dispatch(jobsActions.getDesignation());
  //   }
  //   getdesignationlist();
  // }, [dispatch]);

  // useEffect(() => {
  //   async function companyFunction() {
  //     setFunct(profile?.function);
  //   }
  //   companyFunction();
  // }, [profile?.function]);

  // useEffect(() => {
  //   async function getIndustrylist() {
  //     await dispatch(helperActions.getIndustry());
  //   }
  //   getIndustrylist();
  // }, [dispatch]);

  // useEffect(() => {
  //   async function getSkillslist() {
  //     await dispatch(helperActions.getSkills());
  //   }
  //   getSkillslist();
  // }, [dispatch]);

  // useEffect(() => {
  //   async function getFunctionlist() {
  //     await dispatch(helperActions.getFunction());
  //   }
  //   getFunctionlist();
  // }, [dispatch]);

  // useEffect(() => {
  //   async function getLocationlist() {
  //     await dispatch(helperActions.getLocation());
  //   }
  //   getLocationlist();
  // }, [dispatch]);

  // useEffect(() => {
  //   async function getCompanySizelist() {
  //     await dispatch(helperActions.getComapnySize());
  //   }
  //   getCompanySizelist();
  // }, [dispatch]);

  // useEffect(() => {
  //   async function getCompanyTypelist() {
  //     await dispatch(helperActions.getJobType());
  //   }
  //   getCompanyTypelist();
  // }, [dispatch]);

  // useEffect(() => {
  //   async function companyDesignation() {
  //     setUserDesignation(profile?.designation);
  //   }
  //   companyDesignation();
  // }, [profile?.designation]);

  // useEffect(() => {
  //   async function companySize() {
  //     setSize(profile?.size);
  //   }
  //   companySize();
  // }, [profile?.size]);

  // useEffect(() => {
  //   async function contryCode() {
  //     setSelectedCountryKyc(profile?.country);
  //   }
  //   contryCode();
  // }, [profile?.country]);

  // useEffect(() => {
  //   async function companyType() {
  //     setType(profile?.type);
  //   }
  //   companyType();
  // }, [profile?.type]);

  // useEffect(() => {
  //   async function getCompanyRegistrationTypelist() {
  //     await dispatch(helperActions.getCompanyRegistrationType());
  //   }
  //   getCompanyRegistrationTypelist();
  // }, [dispatch]);

  // const selectCountryHandler = (value) => setSelectedCountry(value);
  // const designation = useSelector((state) => state?.jobs?.designation);
  const countryObj = countries.getNames("en", { select: "official" });
  const countryArr = Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });
  countries.registerLocale(enLocale);
  countries.registerLocale(itLocale);
  // const [openGst, setOpenGst] = React.useState(false);
  // const handleCloseGst = () => {
  //   setOpenGst(false);
  // };
  // const handleClickOpenGst = () => {
  //   setOpenGst(true);
  // };
  // const [editDesignation, setEditDesignation] = useState({});
  // const [userDesignation, setUserDesignation] = React.useState({});
  const [filepath, setFilepath] = React.useState("");
  const [uploadjd, setUploadJd] = React.useState(null);
  const S3_BUCKET = "exotalent-cv";
  const REGION = "ap-south-1";

  AWS.config.update({
    accessKeyId: "AKIAWCKLV3UAPEYYOZO3",
    secretAccessKey: "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",
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });
  const handleUploadJd = async (event) => {
    await setUploadJd(event.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    return uploadJd(event.target.files[0]);
  };
  const uploadJd = async (file) => {
    let namekey = uuidv4() + "_lx_" + file.name.replace(/ /g, "_");
    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: namekey,
    };

    let objct = myBucket.putObject(params).promise();

    console.log("aws ", objct);
    objct
      .then(function (data) {
        console.log(
          "aws Successfully uploaded data to " + S3_BUCKET + "/" + namekey
        );
        console.log("aws Success", data);
        let path =
          "https://" +
          S3_BUCKET +
          ".s3." +
          REGION +
          ".amazonaws.com/" +
          namekey;
        setFilepath(path);
      })
      .catch(function (err) {
        console.log("aws err", err);
      });
  };
  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.mainHome}>
        <Box className={classes.topbar}>
          <Stack direction='row'>
            <Box className={classes.imgFrame}>
              {filepath ? (
                <Box component='img' className={classes.img} src={filepath} />
              ) : (
                <Box
                  component='img'
                  className={classes.img}
                  src={profile?.logo}
                />
              )}
              <Box>
                {" "}
                <label htmlFor='icon-button-file'>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}>
                    <Box className={classes.imgFrame1}>
                      {" "}
                      <Box
                        component='img'
                        className={classes.img1}
                        src={Editicon}
                      />{" "}
                    </Box>{" "}
                  </Box>
                </label>
                <input
                  accept='image/*'
                  id='icon-button-file'
                  type='file'
                  style={{ display: "none" }}
                  onChange={handleUploadJd}
                  name='file'
                />
                {}
              </Box>
            </Box>

            <Box className={classes.text}>
              <Typography
                variant='h4'
                sx={{
                  marginTop: "40px",
                  fontSize: "28px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "600",
                }}>
                {/* {profile?.title} */}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography
            variant='h5'
            gutterBottom
            sx={{
              textAlign: "left",
              color: "#223870",
              fontWeight: "500",
            }}>
            Company Information
          </Typography>
          <Grid container direction='row'>
            <Grid
              Item
              xs={12}
              sm={6}
              sx={{ textAlign: "left", paddingRight: "10px" }}>
              {/* <Grid
                container
                sm={12}
                sx={{
                  marginTop: "12px",
                  width: "100%",
                }}>
                {" "}
                <Grid item sm={6} md={6}>
                  <Grid container direction='column'>
                    {" "}
                    <Grid item>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                        }}>
                        First Name*
                      </Typography>{" "}
                    </Grid>
                    <Grid item sx={{ marginRight: "6px" }}>
                      <FormInput
                        // className={classes.FormInput}
                        sx={{
                          width: "100%",
                          backgroundColor: "#E0DFF4",
                          borderRadius: "5px",
                        }}
                        size='small'
                        variant='outlined'
                        type='text'
                        defaultValue={
                          profile?.firstName ? profile?.firstName : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        onChange={handleChange}
                        name='firstName'
                        {...register("firstName", {
                          required: "First name is required",
                          minLength: {
                            value: 3,
                            message: "First name must be atleast 3 characters",
                          },
                        })}
                      />
                      {errors.firstName && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.firstName.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6} md={6}>
                  <Grid container direction='column'>
                    {" "}
                    <Grid item>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                          marginLeft: "8px",
                        }}>
                        Last Name*
                      </Typography>{" "}
                    </Grid>
                    <Grid item sx={{ marginLeft: "6px" }}>
                      <FormInput
                        // className={classes.FormInput}
                        sx={{
                          width: "100%",
                          backgroundColor: "#E0DFF4",
                          borderRadius: "5px",
                        }}
                        size='small'
                        variant='outlined'
                        type='text'
                        defaultValue={
                          profile?.last_name ? profile?.last_name : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        onChange={handleChange}
                        name='lastName'
                        {...register("lastName", {
                          required: "Last name is required",
                          minLength: {
                            value: 3,
                            message: "Last name must be atleast 3 characters",
                          },
                        })}
                      />
                      {errors.lastName && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.lastName.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid container direction='column'>
                {" "}
                <Grid
                  Item
                  sx={{
                    marginTop: "12px",
                  }}>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    // component="div"
                    sx={{
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Company Name* (Max. 60 char.)
                  </Typography>{" "}
                </Grid>
                <Grid
                  Item
                  sx={{
                    "& > :not(style)": {
                      width: "100%",
                    },
                  }}>
                  <FormInput
                    sx={{
                      width: "100%",
                      backgroundColor: "#E0DFF4",
                      borderRadius: "5px",
                    }}
                    size='small'
                    variant='outlined'
                    type='text'
                    defaultValue={profile?.title}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={handleChange}
                    name='companyName'
                    {...register("companyName", {
                      required: "Company name required",
                      maxLength: {
                        value: 60,
                        message: "Maximum 60 characters allowed",
                      },
                    })}
                  />{" "}
                  <Box sx={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    {errors.companyName?.type === "required" &&
                      "Company Name is required"}
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction='column'>
                {" "}
                <Grid
                  Item
                  sx={{
                    marginTop: "10px",
                  }}>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Domain Name*
                  </Typography>{" "}
                </Grid>
                <Grid
                  Item
                  sx={{
                    "& > :not(style)": {
                      width: "100%",
                    },
                  }}>
                  <FormInput
                    size='small'
                    variant='outlined'
                    type='text'
                    defaultValue={profile?.domain ? profile?.domain : ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    sx={{
                      color: "#5071C4",
                      backgroundColor: "#E0DFF4",
                      marginRight: "20px",
                    }}
                    onChange={handleChange}
                    name='domain'
                    {...register("domain", { required: true })}
                  />{" "}
                  <Box sx={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    {errors.domain?.type === "required" &&
                      "Domain Name is required"}
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                sm={12}
                sx={{
                  marginTop: "10px",
                  width: "100%",
                  paddingRight: "8px",
                }}>
                {" "}
                <Grid item sm={6} md={6}>
                  <Grid container direction='column'>
                    {" "}
                    <Grid item>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                        }}>
                        Industry*
                      </Typography>{" "}
                    </Grid>
                    <Grid item>
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: "#5071C4",
                              position: "absolute",
                              right: 10,
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        sx={{
                          width: "100%",
                          marginRight: "8px",
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                          fontWeight: "500",
                        }}
                        name='industry'
                        defaultValue={profile?.industry}
                        className={classes.countrycode}
                        {...register("industry", {
                          required: "Select industry ",
                        })}>
                        {industryList1?.map((option) => {
                          return (
                            <MenuItem
                              key={option._id}
                              value={option._id}
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                                "&.Mui-selected": {
                                  color: "#fff",
                                  backgroundColor: "#4B4FD9",
                                },
                              }}>
                              {`${option?.industry.substring(0, 20)}`}
                            </MenuItem>
                          );
                        })}
                      </Select>{" "}
                      {errors.industry && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.industry.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6} md={6}>
                  <Grid container direction='column'>
                    {" "}
                    <Grid item>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                          marginLeft: "10px",
                        }}>
                        Function*
                      </Typography>{" "}
                    </Grid>
                    <Grid item>
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: "#5071C4",
                              position: "absolute",
                              right: 10,
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        sx={{
                          width: "100%",
                          marginLeft: "8px",
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                          fontWeight: "500",
                        }}
                        name='func'
                        defaultValue={profile?.function}
                        className={classes.countrycode}
                        {...register("func", {
                          required: "Select function ",
                        })}>
                        {functionList1?.map((option) => {
                          return (
                            <MenuItem
                              key={option._id}
                              value={option._id}
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                                "&.Mui-selected": {
                                  color: "#fff",
                                  backgroundColor: "#4B4FD9",
                                },
                              }}>
                              {`${option.function.substring(0, 20)}`}
                            </MenuItem>
                          );
                        })}
                      </Select>{" "}
                      {errors.func && (
                        <Box
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "10px",
                          }}>
                          {" "}
                          {errors.func.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction='column'>
                {" "}
                <Grid
                  Item
                  sx={{
                    marginTop: "10px",
                  }}>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Company Type*
                  </Typography>{" "}
                  <Grid
                    Item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                        fontWeight: "500",
                      }}
                      name='companytype'
                      defaultValue={profile?.companyType}
                      value={compType}
                      onChange={handleCompType}
                      className={classes.countrycode}
                      {...register("companytype", {
                        required: "Select type of your company",
                      })}>
                      {CompanyType?.map((option) => {
                        return (
                          <MenuItem
                            key={option?._id}
                            value={option?.value}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {option?.value}
                          </MenuItem>
                        );
                      })}
                    </Select>{" "}
                    <Box sx={{ color: "red", fontSize: "12px" }}>
                      {" "}
                      {errors.type?.type === "required" && "Type is required"}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>{" "}
            </Grid>
            <Grid
              Item
              xs={12}
              sm={6}
              sx={{
                textAlign: "left",
                paddingLeft: "10px",
                marginTop: "12px",
              }}>
              {/* <Grid container direction='column'>
                {" "}
                <Grid
                  Item
                  sx={{
                    marginTop: "0",
                  }}>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    // component="div"
                    sx={{
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Designation*
                  </Typography>{" "}
                  <Grid
                    Item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}>
                    <Autocomplete
                      id='country-select-demo'
                      sx={{
                        width: "100%",
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                      }}
                      value={userDesignation}
                      options={designation}
                      autoHighlight
                      disableClearable
                      variant='standard'
                      popupIcon={
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                          }}
                        />
                      }
                      name='designation'
                      defaultValue={
                        profile?.designation ? profile?.designation : ""
                      }
                      onChange={(_event, designatin) => {
                        setUserDesignation(designatin);
                      }}
                      size='small'
                      getOptionLabel={(option) => {
                        if (option) {
                          return option?.designation;
                        } else {
                          return "";
                        }
                      }}
                      renderInput={(params) => (
                        <FormInput
                          size='small'
                          // className={classes.FormInput}
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "",
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>{" "} */}
              <Grid container direction='column'>
                {" "}
                <Grid Item>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Tagline* (Max. 60 char.)
                  </Typography>{" "}
                  <Grid
                    Item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}>
                    <FormInput
                      sx={{
                        width: "100%",
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                      }}
                      size='small'
                      variant='outlined'
                      type='text'
                      defaultValue={profile?.tagline ? profile?.tagline : ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name='tagline'
                      {...register("tagline", {
                        required: "tagline is required",
                        maxLength: {
                          value: 60,
                          message: "Maximum 60 characters allowed",
                        },
                      })}
                    />{" "}
                    <Box sx={{ color: "red", fontSize: "12px" }}>
                      {" "}
                      {errors.tagline?.type === "required" &&
                        "Tagline is required"}
                      {errors.tagline?.type === "maxLength" &&
                        errors.tagline?.message}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>{" "}
              {/* <Grid container direction='column'>
                {" "}
                <Grid
                  Item
                  sx={{
                    marginTop: "10px",
                  }}>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Website*
                  </Typography>{" "}
                  <Grid
                    Item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}>
                    <FormInput
                      sx={{
                        width: "100%",
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                      }}
                      size='small'
                      variant='outlined'
                      type='text'
                      defaultValue={profile?.website ? profile?.website : ""}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name='website'
                      {...register("website", { required: true })}
                    />{" "}
                    <Box sx={{ color: "red", fontSize: "12px" }}>
                      {" "}
                      {errors.website?.type === "required" &&
                        "Website is required"}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>{" "} */}
              <Grid container direction='column'>
                {" "}
                <Grid
                  Item
                  sx={{
                    marginTop: "10px",
                  }}>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Registration Type*
                  </Typography>{" "}
                  <Grid
                    Item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}>
                    <Select
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                            position: "absolute",
                            right: 10,
                            pointerEvents: "none",
                          }}
                        />
                      )}
                      sx={{
                        color: "#5071C4",
                        backgroundColor: "#E0DFF4",
                        fontWeight: "500",
                      }}
                      name='regtype'
                      defaultValue={profile?.registrationType}
                      value={regType}
                      onChange={handleRegType}
                      className={classes.countrycode}
                      {...register("regtype", {
                        required: "Select registration type of your company ",
                      })}>
                      {RegistrationType?.map((option) => {
                        return (
                          <MenuItem
                            key={option?._id}
                            value={option.value}
                            sx={{
                              color: "#5071C4",
                              fontWeight: "700",
                            }}>
                            {option?.value}
                          </MenuItem>
                        );
                      })}
                    </Select>{" "}
                  </Grid>
                </Grid>
              </Grid>{" "}
              <Grid container direction='Column'>
                {" "}
                <Grid
                  Item
                  sx={{
                    marginTop: "10px",
                  }}>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Size*
                  </Typography>{" "}
                </Grid>
                <Grid
                  Item
                  sx={{
                    "& > :not(style)": {
                      width: "100%",
                    },
                  }}>
                  <Select
                    IconComponent={() => (
                      <KeyboardArrowDownIcon
                        sx={{
                          color: "#5071C4",
                          position: "absolute",
                          right: 10,
                          pointerEvents: "none",
                        }}
                      />
                    )}
                    sx={{
                      color: "#5071C4",
                      backgroundColor: "#E0DFF4",
                      fontWeight: "500",
                    }}
                    name='size'
                    defaultValue={profile?.size}
                    value={compSize}
                    onChange={handleCompSize}
                    className={classes.countrycode}
                    {...register("size", {
                      required: "Select size of your company ",
                    })}>
                    {CompanySize?.map((option) => {
                      return (
                        <MenuItem
                          key={option._id}
                          value={option.value}
                          sx={{
                            color: "#5071C4",
                            fontWeight: "700",
                          }}>
                          {option?.value}
                        </MenuItem>
                      );
                    })}
                  </Select>{" "}
                  {errors.size && (
                    <Box sx={{ color: "red", fontSize: "12px" }}>
                      {" "}
                      {errors.size.message}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Stack spacing={0} sx={{ marginTop: "10px" }}>
            {" "}
            <Box>
              {" "}
              <Typography
                variant='p'
                gutterBottom
                sx={{
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "500",
                }}>
                About The Company*
              </Typography>{" "}
              <Box>
                <TextareaAutosize
                  aria-label='minimum height'
                  minRows={8}
                  defaultValue={
                    profile?.description ? profile?.description : ""
                  }
                  style={{
                    border: "none",
                    borderRadius: "5px",
                    paddingTop: "8px",
                    paddingRight: "8px",
                    paddingLeft: "8px",
                    width: "100%",
                    backgroundColor: "#E0DFF4",
                    color: "#5071C4",
                  }}
                  name='about_company'
                  {...register("about_company", {
                    required: "About Company is required",
                  })}
                />

                {errors.about_company && (
                  <Box sx={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    {errors.about_company.message}
                  </Box>
                )}
              </Box>
            </Box>
          </Stack>
          {/* <Grid container>
            <Grid item sx={6} sm={6} style={{ paddingRight: "10px" }}>
              {" "}
              <Grid container direction='Column'>
                {" "}
                <Grid
                  Item
                  sx={{
                    marginTop: "10px",
                  }}>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Official Email ID*
                  </Typography>{" "}
                </Grid>
                <Grid item>
                  <Box
                    sm={12}
                    sx={{
                      width: "100%",
                    }}>
                    {" "}
                    <Stack direction='row' spacing={0}>
                      <FormInput
                        size='small'
                        variant='outlined'
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                          width: "100%",
                          borderRadius: "5px 0px 0px 5px",
                        }}
                        name='email'
                        disabled={profile?.is_email_verified}
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                        defaultValue={profile?.email_id}
                      />
                      <Box
                        sx={{
                          backgroundColor: "#E0DFF4",
                          padding: "3px 0px",
                          borderRadius: "0 5px 5px 0px",
                        }}>
                        {" "}
                        <Button
                          onClick={handleClickOpenEmail}
                          variant='standard'
                          sx={{
                            marginTop: "1px",
                            marginRight: "5px",
                            backgroundColor: "#223870",
                            borderRadius: "3px",
                            color: "#FDCF57",
                            padding: "2px 20px",
                            fontSize: "16px",
                            textTransform: "capitalize",
                            "&:hover": {
                              color: "#FDCF57",
                              backgroundColor: "#223870",
                            },
                            "@media (maxWidth: 468px)": {
                              textAlign: "center",
                              padding: "0 10px",
                            },
                          }}>
                          {profile?.is_email_verified ? "Verified" : "Verify"}
                        </Button>
                        <Dialog
                          open={openEmail}
                          onClose={handleCloseEmail}
                          sx={{
                            "& .MuiDialog-container": {
                              "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "590px",
                                height: "430px",
                                borderRadius: "8px",
                                backgroundColor: (theme) =>
                                  theme.Palette.background.dialog,
                              },
                            },
                          }}>
                          <DialogActions>
                            <Box
                              onClick={handleCloseEmail}
                              component='img'
                              src={Close}
                              sx={{
                                top: "10px",
                                right: "10px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </DialogActions>
                          <DialogTitle
                            sx={{
                              color: "#223870",
                              textAlign: "center",
                              fontSize: "22px",
                              marginTop: "-15px",
                            }}>
                            Verify Email ID
                          </DialogTitle>
                          <DialogContent
                            sx={{
                              marginTop: "20px ",
                              textAlign: "center",
                            }}>
                            {" "}
                            <Box>
                              {" "}
                              <Typography
                                variant='p'
                                gutterBottom
                                sx={{
                                  fontSize: "18px",
                                  color: "#5071C4",
                                  fontWeight: "500",
                                }}>
                                OTP sent to
                              </Typography>{" "}
                            </Box>
                            <Box
                              sx={{ marginTop: "40px", marginBottom: "10px" }}>
                              {" "}
                              <Typography
                                variant='h6'
                                sx={{
                                  color: "#223870",
                                  fontWeight: "500",
                                }}>
                                Enter OTP
                              </Typography>
                            </Box>
                            <Box>
                              <Grid
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                  padding: "0 100px",
                                }}
                                container
                                direction='row'
                                columns={{ xs: 3, sm: 8, md: 18, lg: 18 }}>
                                <Grid item xs={2} md={2}>
                                  {" "}
                                  <FormInput
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='input1'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <FormInput
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='input2'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <FormInput
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='input3'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <FormInput
                                    //// className={classes.FormInput}
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='input4'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <FormInput
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='input5'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <FormInput
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='input6'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <Box sx={{ marginTop: "10px" }}>
                              <Typography
                                variant='h6'
                                sx={{
                                  color: "#223870",
                                  fontWeight: "500",
                                }}>
                                00.59
                              </Typography>
                            </Box>
                            <Box sx={{ marginTop: "40px " }}>
                              {" "}
                              <Button
                                type='submit'
                                sx={{
                                  padding: "5px 30px",
                                  textTransform: "none",
                                  textDecoration: "none",
                                  borderRadius: "3px ",
                                  color: "#FDCF57",
                                  fontWeight: "500",
                                  fontSize: "18px",
                                  backgroundColor: "#223870",
                                  "&:hover": {
                                    backgroundColor: "#223870",
                                    color: "#FDCF57 ",
                                  },
                                }}>
                                Verify
                              </Button>
                            </Box>
                          </DialogContent>
                        </Dialog>
                      </Box>
                    </Stack>
                    {errors.email && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.email.message}
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={6} sm={6} style={{ paddingLeft: "10px" }}>
              {" "}
              <Grid container direction='Column'>
                {" "}
                <Grid
                  Item
                  sx={{
                    marginTop: "10px",
                  }}>
                  {" "}
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    Mobile No.*
                  </Typography>{" "}
                </Grid>
                <Grid container>
                  <Grid item sm={4} sx={{ paddingRight: "10px" }}>
                    <Autocomplete
                      id='country-select-demo'
                      sx={{
                        width: "100%",
                        backgroundColor: "#E0DFF4",
                        borderRadius: "5px",
                      }}
                      value={selectedCountry}
                      options={countryListnew}
                      disabled={profile?.is_mobile_verified}
                      autoHighlight
                      disableClearable
                      variant='standard'
                      popupIcon={
                        <KeyboardArrowDownIcon
                          sx={{
                            color: "#5071C4",
                          }}
                        />
                      }
                      name='country_code'
                      onChange={(_event, country) => {
                        setSelectedCountry(country);
                      }}
                      size='small'
                      getOptionLabel={(option) => {
                        return option.iso3 + " +" + option.phone_code;
                      }}
                      renderInput={(params) => (
                        <FormInput
                          sx={{ height: 43 }}
                          size='small'
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={8}>
                    <Stack direction='row' spacing={0}>
                      {" "}
                      <FormInput
                        size='small'
                        variant='outlined'
                        type='text'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          width: "100%",
                          backgroundColor: "#E0DFF4",
                          borderRadius: "5px",
                        }}
                        fullWidth
                        onChange={handleChange}
                        disabled={profile?.is_mobile_verified}
                        defaultValue={profile?.mobile_number}
                        name='mobile'
                      />{" "}
                      <Box
                        sx={{
                          marginLeft: "-20px",
                          backgroundColor: "#E0DFF4",
                          padding: "3px 0px",
                          borderRadius: "0 5px 5px 0px",
                        }}>
                        {" "}
                        <Button
                          onClick={handleClickOpenMobile}
                          variant='standard'
                          disabled={profile?.is_mobile_verified}
                          sx={{
                            marginRight: "4px",
                            backgroundColor: "#223870",
                            borderRadius: "3px",
                            color: profile?.is_mobile_verified
                              ? "#FDCF57!important"
                              : "#FDCF57",
                            marginTop: "1px",
                            padding: "2px 20px",
                            fontSize: "16px",
                            textTransform: "capitalize",
                            "&:hover": {
                              color: "#FDCF57",
                              backgroundColor: "#223870",
                            },
                            "@media (maxWidth: 468px)": {
                              textAlign: "center",
                              padding: "0 10px",
                            },
                          }}>
                          {profile?.is_mobile_verified ? "Verified" : "Verify"}
                        </Button>
                        <Dialog
                          open={openMobile}
                          onClose={handleCloseMobile}
                          sx={{
                            "& .MuiDialog-container": {
                              "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "590px",
                                height: "430px",
                                borderRadius: "10px",
                                backgroundColor: (theme) =>
                                  theme.Palette.background.dialog,
                              },
                            },
                          }}>
                          <DialogActions>
                            <Box
                              onClick={handleCloseMobile}
                              component='img'
                              src={Close}
                              sx={{
                                top: "10px",
                                right: "10px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </DialogActions>
                          <DialogTitle
                            sx={{
                              color: "#223870",
                              textAlign: "center",
                              fontSize: "22px",
                              marginTop: "-15px",
                            }}>
                            Verify Mobile No.
                          </DialogTitle>
                          <DialogContent
                            sx={{
                              marginTop: "20px ",
                              textAlign: "center",
                            }}>
                            {" "}
                            <Box>
                              {" "}
                              <Typography
                                variant='p'
                                gutterBottom
                                sx={{
                                  fontSize: "18px",
                                  color: "#5071C4",
                                  fontWeight: "500",
                                }}>
                                OTP sent to
                              </Typography>{" "}
                            </Box>
                            <Box
                              sx={{ marginTop: "40px", marginBottom: "10px" }}>
                              {" "}
                              <Typography
                                variant='h6'
                                sx={{
                                  color: "#223870",
                                  fontWeight: "500",
                                }}>
                                Enter OTP
                              </Typography>
                            </Box>
                            <Box>
                              <Grid
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                  padding: "0 100px",
                                }}
                                container
                                direction='row'
                                columns={{ xs: 3, sm: 8, md: 16, lg: 16 }}>
                                <Grid item xs={2} md={2}>
                                  {" "}
                                  <FormInput
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='inp1'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <FormInput
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='inp2'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <FormInput
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='inp3'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <FormInput
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='inp4'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <FormInput
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='inp5'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                                <Grid item xs={2} md={2}>
                                  <FormInput
                                    size='small'
                                    variant='outlined'
                                    type='text'
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name='inp6'
                                    fullWidth
                                    className={classes.input}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <Box sx={{ marginTop: "10px" }}>
                              <Typography
                                variant='h6'
                                sx={{
                                  color: "#223870",
                                  fontWeight: "500",
                                }}></Typography>
                            </Box>
                            <Box sx={{ marginTop: "40px " }}>
                              {" "}
                              <Button
                                type='submit'
                                sx={{
                                  padding: "5px 30px",
                                  textTransform: "none",
                                  textDecoration: "none",
                                  borderRadius: "3px ",
                                  color: "#FDCF57",
                                  fontWeight: "500",
                                  fontSize: "18px",
                                  backgroundColor: "#223870",
                                  "&:hover": {
                                    backgroundColor: "#223870",
                                    color: "#FDCF57 ",
                                  },
                                }}>
                                Verify
                              </Button>
                            </Box>
                          </DialogContent>
                        </Dialog>
                      </Box>
                    </Stack>
                    {errors.mobile && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.mobile.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}

          <div
            style={{
              margin: "40px 0px",
              width: "100%",
              height: "1px",
              backgroundColor: "#223870",
            }}></div>
          {/* <Typography
            variant='h5'
            gutterBottom
            sx={{
              textAlign: "left",
              color: "#223870",
              fontWeight: "500",
            }}>
            {console.log("errors", errors)}
            KYC Compliance
          </Typography>
          <Grid container direction='column'>
            {" "}
            <Grid
              Item
              sx={{
                marginTop: "10px",
              }}>
              {" "}
              <Typography
                variant='p'
                gutterBottom
                sx={{
                  color: "#304672",
                  fontWeight: "500",
                }}>
                Address*
              </Typography>{" "}
            </Grid>
            <Grid
              Item
              sx={{
                "& > :not(style)": {
                  width: "100%",
                },
              }}>
              <FormInput
                size='small'
                variant='outlined'
                type='text'
                defaultValue={profile?.address ? profile?.address : ""}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                sx={{
                  color: "#5071C4",
                  backgroundColor: "#E0DFF4",
                  marginRight: "20px",
                  borderRadius: "5px",
                }}
                onChange={handleChange}
                name='address'
                {...register("address", {
                  required: "Address is required",
                })}
              />
              <Box sx={{ color: "red", fontSize: "12px" }}>
                {" "}
                {errors.address?.type === "required" && "Address is required"}
              </Box>
            </Grid>
          </Grid>
          <Grid container direction='row'>
            {" "}
            <Grid
              Item
              xs={12}
              sm={6}
              sx={{ textAlign: "left", width: "100%", paddingRight: "10px" }}>
              {" "}
              <Grid
                container
                sm={12}
                sx={{
                  marginTop: "10px",
                  width: "100%",
                }}>
                {" "}
                <Grid item sm={6} md={6}>
                  <Grid container direction='column'>
                    {" "}
                    <Grid item>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                        }}>
                        Country*
                      </Typography>{" "}
                    </Grid>
                    <Grid
                      Item
                      sx={{
                        paddingRight: "8px",
                      }}>
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: "#5071C4",
                              position: "absolute",
                              right: 10,
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        sx={{
                          width: "100%",
                          marginRight: "8px",
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                          fontWeight: "500",
                        }}
                        name='country'
                        value={selectedCountryKyc}
                        onChange={handleSelectkyc}
                        className={classes.countrycode}>
                        {countryArr?.map((option) => {
                          return (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}>
                              {option.label ?? option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>{" "}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6} md={6}>
                  <Grid container direction='column'>
                    {" "}
                    <Grid item>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                        }}>
                        State*
                      </Typography>{" "}
                    </Grid>
                    <Grid
                      Item
                      sx={{
                        paddingRight: "8px",
                      }}>
                      <FormInput
                        // className={classes.FormInput}
                        size='small'
                        variant='outlined'
                        type='text'
                        defaultValue={profile?.state ? profile?.state : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        sx={{
                          borderRadius: "5px",
                          width: "100%",
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                        }}
                        onChange={handleChange}
                        name='state'
                        {...register("state", {
                          required: "State Name is required",
                        })}
                      />
                      {errors.state && (
                        <Box
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "10px",
                          }}>
                          {" "}
                          {errors.state.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                sm={12}
                sx={{
                  marginTop: "10px",
                  width: "100%",
                  paddingRight: "8px",
                }}>
                {" "}
                <Grid item sm={6} md={6}>
                  <Grid container direction='column'>
                    {" "}
                    <Grid item>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                        }}>
                        PAN No.*
                      </Typography>{" "}
                    </Grid>
                    <Grid
                      Item
                      sx={{
                        paddingRight: "4px",
                      }}>
                      <FormInput
                        // className={classes.FormInput}
                        size='small'
                        variant='outlined'
                        type='text'
                        defaultValue={profile?.pan_no}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        sx={{
                          borderRadius: "5px",
                          width: "100%",
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                        }}
                        onChange={handleChange}
                        name='pan'
                        {...register("pan", {
                          required: "PAN number is required",
                          minLength: {
                            value: 10,
                            message: "Pan must be 10 characters",
                          },

                          maxLength: {
                            value: 10,
                            message: "Pan must be 10 characters",
                          },
                        })}
                      />
                      {errors.pan && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.pan.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6} md={6}>
                  <Grid container direction='column'>
                    {" "}
                    <Grid item>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                          marginLeft: "10px",
                        }}>
                        TAN No.*
                      </Typography>{" "}
                    </Grid>
                    <Grid
                      Item
                      sx={{
                        paddingLeft: "4px",
                      }}>
                      <FormInput
                        // className={classes.FormInput}
                        size='small'
                        variant='outlined'
                        type='text'
                        defaultValue={profile?.tan_no ? profile?.tan_no : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        sx={{
                          borderRadius: "5px",
                          width: "100%",
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                        }}
                        onChange={handleChange}
                        name='tan'
                        {...register("tan", {
                          required: "TAN number is required",
                          minLength: {
                            value: 10,
                            message: "Tan must be 10 characters",
                          },

                          maxLength: {
                            value: 10,
                            message: "Tan must be 10 characters",
                          },
                        })}
                      />
                      {errors.tan && (
                        <Box
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "10px",
                          }}>
                          {" "}
                          {errors.tan.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              Item
              xs={12}
              sm={6}
              sx={{ textAlign: "left", width: "100%", paddingLeft: "10px" }}>
              <Grid
                container
                sm={12}
                sx={{
                  marginTop: "10px",
                  width: "100%",
                }}>
                {" "}
                <Grid item sm={6} md={6}>
                  <Grid container direction='column'>
                    {" "}
                    <Grid item>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                          marginLeft: "10px",
                        }}>
                        City*
                      </Typography>{" "}
                    </Grid>
                    <Grid
                      Item
                      sx={{
                        paddingLeft: "8px",
                      }}>
                      <FormInput
                        // className={classes.FormInput}
                        size='small'
                        variant='outlined'
                        type='text'
                        defaultValue={profile?.city ? profile?.city : ""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        sx={{
                          borderRadius: "5px",
                          width: "100%",
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                        }}
                        onChange={handleChange}
                        name='city'
                        {...register("city", {
                          required: "City Name is required",
                        })}
                      />
                      {errors.city && (
                        <Box
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "10px",
                          }}>
                          {" "}
                          {errors.city.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6} md={6}>
                  <Grid container direction='column'>
                    {" "}
                    <Grid item>
                      {" "}
                      <Typography
                        variant='p'
                        gutterBottom
                        sx={{
                          color: "#304672",
                          fontWeight: "500",
                          marginLeft: "10px",
                        }}>
                        Pincode*
                      </Typography>{" "}
                    </Grid>
                    <Grid
                      Item
                      sx={{
                        paddingLeft: "8px",
                      }}>
                      <FormInput
                        // className={classes.FormInput}
                        size='small'
                        variant='outlined'
                        type='text'
                        defaultValue={
                          profile?.pin_code ? profile?.pin_code : ""
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        sx={{
                          borderRadius: "5px",
                          width: "100%",
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                        }}
                        onChange={handleChange}
                        name='pincode'
                        {...register("pincode", {
                          required: "Pincode is required",
                        })}
                      />
                      {errors.pincode && (
                        <Box
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            marginLeft: "10px",
                          }}>
                          {" "}
                          {errors.pincode.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Stack direction='row' spacing={0}>
                {" "}
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    Item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      GST No.*
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    <Box>
                      {" "}
                      <Stack direction='row' spacing={0}>
                        <FormInput
                          // className={classes.FormInput}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          sx={{
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            width: "100%",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                          onChange={handleChange}
                          name='gst'
                          {...register("gst", {
                            required: "GST number is required",
                            pattern: {
                              value:
                                /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
                              message:
                                "Invalid GST number. Please provide the correct GST number",
                            },
                          })}
                        />

                        <Box
                          sx={{
                            height: "42px",
                            backgroundColor: "#E0DFF4",
                            padding: "3px 0px",
                            borderRadius: "0 5px 5px 0px",
                          }}>
                          {" "}
                          <Button
                            onClick={handleClickOpenGst}
                            variant='standard'
                            sx={{
                              marginRight: "5px",
                              backgroundColor: "#223870",
                              borderRadius: "3px",
                              color: "#FDCF57",
                              marginTop: "2px",
                              padding: "2px 20px",
                              fontSize: "16px",
                              textTransform: "capitalize",
                              "&:hover": {
                                color: "#FDCF57",
                                backgroundColor: "#223870",
                              },
                              "@media (maxWidth: 468px)": {
                                marginTop: "-29px",
                                textAlign: "center",
                                marginRight: "-10px",
                                padding: "0 10px",
                              },
                            }}>
                            Verify
                          </Button>
                          <Dialog
                            open={openGst}
                            onClose={handleCloseGst}
                            sx={{
                              "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                  width: "100%",
                                  maxWidth: "590px",
                                  height: "300px",
                                  borderRadius: "5px",
                                  backgroundColor: "#F7FAFF",
                                },
                              },
                            }}>
                            <DialogActions>
                              <Box
                                onClick={handleCloseGst}
                                component='img'
                                src={Close}
                                sx={{
                                  top: "10px",
                                  right: "10px",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </DialogActions>
                            <DialogTitle
                              sx={{
                                color: "#5071C4",
                                textAlign: "center",
                                fontSize: "26px",
                              }}>
                              Agreement & NDA
                            </DialogTitle>
                            <DialogContent
                              sx={{
                                marginTop: "10px ",
                                textAlign: "center",
                              }}>
                              {" "}
                              <Box>
                                {" "}
                                <Typography
                                  variant='p'
                                  gutterBottom
                                  sx={{
                                    fontSize: "18px",
                                    color: "#223870",
                                    fontWeight: "500",
                                  }}>
                                  Agreement & NDA has been sent to your email.
                                  Please add the digital signature and send it
                                  for further validation.
                                </Typography>{" "}
                              </Box>
                              <Box sx={{ marginTop: "40px " }}>
                                {" "}
                                <Button
                                  onClick={handleCloseGst}
                                  type='submit'
                                  sx={{
                                    padding: "4px 30px",
                                    textTransform: "none",
                                    textDecoration: "none",
                                    borderRadius: "5px ",
                                    color: "#FDCF57",
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    backgroundColor: "#223870",
                                    "&:hover": {
                                      backgroundColor: "#223870",
                                      color: "#FDCF57 ",
                                    },
                                  }}>
                                  OK
                                </Button>
                              </Box>
                            </DialogContent>
                          </Dialog>
                        </Box>
                      </Stack>
                      {errors.gst && (
                        <Box
                          sx={{
                            color: "#fff",
                            fontSize: "12px",
                            backgroundColor: "red",
                            marginTop: "5px",
                          }}>
                          {" "}
                          {errors.gst.message}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid> */}
          <Box
            sx={{
              marginTop: "40px",
              textAlign: "center",
            }}>
            {" "}
            <input
              value='Save'
              type='submit'
              style={{
                textTransform: "none",
                textDecoration: "none",
                padding: "7px 30px",
                border: "none",
                borderRadius: "3px ",
                color: "#FDCF57",
                fontWeight: "500",
                fontSize: "20px",
                backgroundColor: "#223870",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#223870",
                  color: "#FDCF57 ",
                },
              }}
            />
          </Box>
        </form>
      </Box>
    </>
  );
};
export default EditCompany;
