import React from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Stack,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";
import { FormInput } from "../../components/Input/FormInput";
import Close from "../../assets/Icons/Close.png";
import formBg from "../../assets/Icons/mobileFormBG.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { countryCodeList } from "../../assets/Data/data";
import HomeBg from "../../assets/images/loginbg.png";
import { makeStyles } from "@mui/styles";
import Navbar from "../../components/Header/Navbar";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../../Store/candidate";
const useStyles = makeStyles((theme) => ({
  option: {
    padding: "10px 0px",
    margin: "1px !important",
  },
  html: {},
  containerbg: {
    marginTop: "-15px",
    backgroundImage: `url(${HomeBg})`,
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
    display: "block",
    backgroundPosition: "center center",
    position: "static",
    overflow: "auto",
    justifyContent: "center",
    alignItems: "center",
  },
  grid: {
    position: "absolute",
  },
  form: {
    paddingTop: "80px",
    paddingRight: "100px",
    position: "relative",
    width: "100%",
    height: "100vh",
    overflowY: "scroll",
  },
  input: {
    borderRadius: "5px",
    border: "1px solid #223870",
    width: "50px",
    height: "40px",
    color: "#5071C4",
    backgroundColor: "#E0DFF4",
  },
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "8.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  "@media (maxWidth: 468px)": {
    containerbg: {
      backgroundImage: `url(${formBg})`,
      marginTop: "80px",
    },
    form: {
      margin: "0",
      padding: "0 10px",
      width: "100%",
    },
  },
}));
const CssTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInput-underline:after": {
    border: "1px solid #223870",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid #223870",
    },
    "&:hover fieldset": {
      border: "1px solid #223870",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #223870",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #223870",
  },
  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #223870",
    },
  },
}));
const CompanyRecruiterSignup = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = React.useState("+91");
  const handleSelect = (event) => {
    setCountryCode(event.target.value);
  };
  const navigate = useNavigate();
  const redirectProfile = () => {
    navigate("/company/profile");
  };
  const [setName] = React.useState();

  const handleChange = (event) => {
    setName(event.target.value);
  };
  const [openEmail, setOpenEmail] = React.useState(false);
  const [openMobile, setOpenMobile] = React.useState(false);
  const [email, setEmail] = React.useState();
  const handleClickOpenEmail = async () => {
    console.log(email);
    const data = {
      signup_type: 1,
      user_type: 3,
      email_id: email,
      is_secondary: false,
    };
  };
  const handleClickOpenMobile = () => {
    setOpenMobile(true);
  };
  const handleCloseEmail = () => {
    setOpenEmail(false);
  };
  const handleCloseMobile = () => {
    setOpenMobile(false);
  };
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => console.log(data);
  return (
    <>
      <Navbar />
      <Box className={classes.containerbg}>
        <Grid container className={classes.grid}>
          <Grid item xs={12} sm={7}></Grid>
          <Grid item xs={12} sm={5} className={classes.form}>
            <Typography
              variant='h4'
              sx={{
                fontSize: "30px",
                marginTop: "40px",
                marginBottom: "10px",
                textAlign: "center",
                color: "#223870",
                fontWeight: "600",
              }}>
              Company's Recruiter Signup
            </Typography>
            <Box
              sx={{
                paddingBottom: "40px",
              }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  sm={12}
                  sx={{
                    marginTop: "25px",
                    width: "100%",
                  }}>
                  {" "}
                  <Grid item sm={6} md={6}>
                    <Grid container direction='column'>
                      {" "}
                      <Grid item>
                        {" "}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          First Name*
                        </Typography>{" "}
                      </Grid>
                      <Grid item sx={{ marginRight: "6px" }}>
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          onChange={handleChange}
                          name='firstName'
                          {...register("firstName", {
                            required: "First name is required",
                            minLength: {
                              value: 3,
                              message:
                                "First name must be atleast 3 characters",
                            },
                          })}
                        />
                        {errors.firstName && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.firstName.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} md={6}>
                    <Grid container direction='column'>
                      {" "}
                      <Grid item>
                        {" "}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                            marginLeft: "8px",
                          }}>
                          Last Name*
                        </Typography>{" "}
                      </Grid>
                      <Grid item sx={{ marginLeft: "6px" }}>
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          onChange={handleChange}
                          name='lastName'
                          {...register("lastName", {
                            required: "Last name is required",
                            minLength: {
                              value: 1,
                              message: "Last name must be atleast 1 characters",
                            },
                          })}
                        />
                        {errors.lastName && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.lastName.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Email ID*
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    <Box
                      sm={12}
                      sx={{
                        width: "100%",
                      }}>
                      {" "}
                      <Stack direction='row' spacing={0}>
                        <FormInput
                          // sx={{
                          //   backgroundColor: "#E0DFF4",
                          //   borderRadius: "5px",
                          // }}

                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            color: "#5071C4",
                            backgroundColor: "#E0DFF4",
                            width: "100%",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                          name='email'
                          fullWidth
                          onChange={(e) => setEmail(e.target.value)}
                          {...register("email", {
                            required: "Email id is required",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              message: "Please enter a valid email address",
                            },
                          })}
                        />
                        <Box
                          sx={{
                            height: "42px",
                            backgroundColor: "#E0DFF4",
                            padding: "3px 0px",
                            borderRadius: "0 5px 5px 0px",
                          }}>
                          {" "}
                          <Button
                            onClick={handleClickOpenEmail}
                            variant='standard'
                            sx={{
                              marginRight: "5px",
                              backgroundColor: "#223870",
                              borderRadius: "3px",
                              color: "#FDCF57",
                              marginTop: "2px",
                              padding: "2px 20px",
                              fontSize: "16px",
                              textTransform: "capitalize",
                              "&:hover": {
                                color: "#FDCF57",
                                backgroundColor: "#223870",
                              },
                              "@media (maxWidth: 468px)": {
                                marginTop: "-29px",
                                textAlign: "center",
                                marginRight: "-10px",
                                padding: "0 10px",
                              },
                            }}>
                            Verify
                          </Button>
                          <Dialog
                            open={openEmail}
                            onClose={handleCloseEmail}
                            sx={{
                              "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                  width: "100%",
                                  maxWidth: "590px",
                                  height: "430px",
                                  borderRadius: "8px",
                                  backgroundColor: (theme) =>
                                    theme.Palette.background.dialog,
                                },
                              },
                            }}>
                            <DialogActions>
                              <Box
                                onClick={handleCloseEmail}
                                component='img'
                                src={Close}
                                sx={{
                                  top: "10px",
                                  right: "10px",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </DialogActions>
                            <DialogTitle
                              sx={{
                                color: "#223870",
                                textAlign: "center",
                                fontSize: "22px",
                                marginTop: "-15px",
                              }}>
                              Verify Email ID
                            </DialogTitle>
                            <DialogContent
                              sx={{
                                marginTop: "20px ",
                                textAlign: "center",
                              }}>
                              {" "}
                              <Box>
                                {" "}
                                <Typography
                                  variant='p'
                                  gutterBottom
                                  sx={{
                                    fontSize: "18px",
                                    color: "#5071C4",
                                    fontWeight: "500",
                                  }}>
                                  OTP sent to aparajita@gmail.com
                                </Typography>{" "}
                              </Box>
                              <Box
                                sx={{
                                  marginTop: "40px",
                                  marginBottom: "10px",
                                }}>
                                {" "}
                                <Typography
                                  variant='h6'
                                  sx={{
                                    color: "#223870",
                                    fontWeight: "500",
                                  }}>
                                  Enter OTP
                                </Typography>
                              </Box>
                              <Box>
                                <Grid
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    padding: "0 100px",
                                  }}
                                  container
                                  direction='row'
                                  columns={{ xs: 3, sm: 8, md: 18, lg: 18 }}>
                                  <Grid item xs={2} md={2}>
                                    {" "}
                                    <CssTextField
                                      //
                                      size='small'
                                      variant='outlined'
                                      type='text'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      name='input1'
                                      fullWidth
                                      className={classes.input}
                                    />
                                  </Grid>
                                  <Grid item xs={2} md={2}>
                                    <CssTextField
                                      //
                                      size='small'
                                      variant='outlined'
                                      type='text'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      name='input2'
                                      fullWidth
                                      className={classes.input}
                                    />
                                  </Grid>
                                  <Grid item xs={2} md={2}>
                                    <CssTextField
                                      size='small'
                                      variant='outlined'
                                      type='text'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      name='input3'
                                      fullWidth
                                      className={classes.input}
                                    />
                                  </Grid>
                                  <Grid item xs={2} md={2}>
                                    <CssTextField
                                      //
                                      size='small'
                                      variant='outlined'
                                      type='text'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      name='input4'
                                      fullWidth
                                      className={classes.input}
                                    />
                                  </Grid>
                                  <Grid item xs={2} md={2}>
                                    <CssTextField
                                      size='small'
                                      variant='outlined'
                                      type='text'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      name='input5'
                                      fullWidth
                                      className={classes.input}
                                    />
                                  </Grid>
                                  <Grid item xs={2} md={2}>
                                    <CssTextField
                                      size='small'
                                      variant='outlined'
                                      type='text'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      name='input6'
                                      fullWidth
                                      className={classes.input}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box sx={{ marginTop: "10px" }}>
                                <Typography
                                  variant='h6'
                                  sx={{
                                    color: "#223870",
                                    fontWeight: "500",
                                  }}>
                                  00.59
                                </Typography>
                              </Box>
                              <Box sx={{ marginTop: "40px " }}>
                                {" "}
                                <Button
                                  type='submit'
                                  sx={{
                                    padding: "5px 30px",
                                    textTransform: "none",
                                    textDecoration: "none",
                                    borderRadius: "3px ",
                                    color: "#FDCF57",
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    backgroundColor: "#223870",
                                    "&:hover": {
                                      backgroundColor: "#223870",
                                      color: "#FDCF57 ",
                                    },
                                  }}>
                                  Verify
                                </Button>
                              </Box>
                            </DialogContent>
                          </Dialog>
                        </Box>
                      </Stack>
                      {errors.email && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.email.message}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    Item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Mobile No.*
                    </Typography>{" "}
                  </Grid>
                  <Grid container>
                    <Grid item sm={3} sx={{ paddingRight: "10px" }}>
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: "#5071C4",
                              position: "absolute",
                              right: 10,
                              pointerEvents: "none",
                            }}
                          />
                        )}
                        sx={{
                          color: "#5071C4",
                          backgroundColor: "#E0DFF4",
                          fontWeight: "500",
                          width: "100%",
                        }}
                        value={countryCode}
                        onChange={handleSelect}
                        className={classes.countrycode}>
                        {countryCodeList?.map((option) => {
                          return (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              sx={{
                                color: "#5071C4",
                                fontWeight: "700",
                              }}>
                              {option.value ?? option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>{" "}
                    </Grid>
                    <Grid item sm={9}>
                      <Stack direction='row' spacing={0}>
                        {" "}
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          onChange={handleChange}
                          name='mobile'
                          {...register("mobile", {
                            required: "Mobile number is required",
                            pattern: {
                              value: /^((\+91?)|\+)?[7-9][0-9]{9}$/,
                              message:
                                "Please enter a valid 10-digit mobile number",
                            },
                          })}
                        />{" "}
                        <Box
                          sx={{
                            marginLeft: "-20px",
                            height: "42px",
                            backgroundColor: "#E0DFF4",
                            padding: "3px 0px",
                            borderRadius: "0 5px 5px 0px",
                          }}>
                          {" "}
                          <Button
                            onClick={handleClickOpenMobile}
                            variant='standard'
                            sx={{
                              marginRight: "3px",
                              backgroundColor: "#223870",
                              borderRadius: "3px",
                              color: "#FDCF57",
                              marginTop: "2px",
                              padding: "2px 20px",
                              fontSize: "16px",
                              textTransform: "capitalize",
                              "&:hover": {
                                color: "#FDCF57",
                                backgroundColor: "#223870",
                              },
                              "@media (maxWidth: 468px)": {
                                marginTop: "-29px",
                                textAlign: "center",
                                marginRight: "-10px",
                                padding: "0 10px",
                              },
                            }}>
                            Verify
                          </Button>
                          <Dialog
                            open={openMobile}
                            onClose={handleCloseMobile}
                            sx={{
                              "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                  width: "100%",
                                  maxWidth: "590px",
                                  height: "430px",
                                  borderRadius: "10px",
                                  backgroundColor: (theme) =>
                                    theme.Palette.background.dialog,
                                },
                              },
                            }}>
                            <DialogActions>
                              <Box
                                onClick={handleCloseMobile}
                                component='img'
                                src={Close}
                                sx={{
                                  top: "10px",
                                  right: "10px",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </DialogActions>
                            <DialogTitle
                              sx={{
                                color: "#223870",
                                textAlign: "center",
                                fontSize: "22px",
                                marginTop: "-15px",
                              }}>
                              Verify Mobile No.
                            </DialogTitle>
                            <DialogContent
                              sx={{
                                marginTop: "20px ",
                                textAlign: "center",
                              }}>
                              {" "}
                              <Box>
                                {" "}
                                <Typography
                                  variant='p'
                                  gutterBottom
                                  sx={{
                                    fontSize: "18px",
                                    color: "#5071C4",
                                    fontWeight: "500",
                                  }}>
                                  OTP sent to +91 9630325138
                                </Typography>{" "}
                              </Box>
                              <Box
                                sx={{
                                  marginTop: "40px",
                                  marginBottom: "10px",
                                }}>
                                {" "}
                                <Typography
                                  variant='h6'
                                  sx={{
                                    color: "#223870",
                                    fontWeight: "500",
                                  }}>
                                  Enter OTP
                                </Typography>
                              </Box>
                              <Box>
                                <Grid
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                    padding: "0 100px",
                                  }}
                                  container
                                  direction='row'
                                  columns={{ xs: 3, sm: 8, md: 16, lg: 16 }}>
                                  <Grid item xs={2} md={2}>
                                    {" "}
                                    <CssTextField
                                      size='small'
                                      variant='outlined'
                                      type='text'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      name='inp1'
                                      fullWidth
                                      className={classes.input}
                                    />
                                  </Grid>
                                  <Grid item xs={2} md={2}>
                                    <CssTextField
                                      size='small'
                                      variant='outlined'
                                      type='text'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      name='inp2'
                                      fullWidth
                                      className={classes.input}
                                    />
                                  </Grid>
                                  <Grid item xs={2} md={2}>
                                    <CssTextField
                                      size='small'
                                      variant='outlined'
                                      type='text'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      name='inp3'
                                      fullWidth
                                      className={classes.input}
                                    />
                                  </Grid>
                                  <Grid item xs={2} md={2}>
                                    <CssTextField
                                      size='small'
                                      variant='outlined'
                                      type='text'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      name='inp4'
                                      fullWidth
                                      className={classes.input}
                                    />
                                  </Grid>
                                  <Grid item xs={2} md={2}>
                                    <CssTextField
                                      size='small'
                                      variant='outlined'
                                      type='text'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      name='inp5'
                                      fullWidth
                                      className={classes.input}
                                    />
                                  </Grid>
                                  <Grid item xs={2} md={2}>
                                    <CssTextField
                                      size='small'
                                      variant='outlined'
                                      type='text'
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      name='inp6'
                                      fullWidth
                                      className={classes.input}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box sx={{ marginTop: "10px" }}>
                                <Typography
                                  variant='h6'
                                  sx={{
                                    color: "#223870",
                                    fontWeight: "500",
                                  }}>
                                  00.59
                                </Typography>
                              </Box>
                              <Box sx={{ marginTop: "40px " }}>
                                {" "}
                                <Button
                                  type='submit'
                                  sx={{
                                    padding: "4px 20px",
                                    textTransform: "none",
                                    textDecoration: "none",
                                    borderRadius: "3px ",
                                    color: "#FDCF57",
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    backgroundColor: "#223870",
                                    "&:hover": {
                                      backgroundColor: "#223870",
                                      color: "#FDCF57 ",
                                    },
                                  }}>
                                  Verify
                                </Button>
                              </Box>
                            </DialogContent>
                          </Dialog>
                        </Box>
                      </Stack>
                      {errors.mobile && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.mobile.message}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    Item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      // component="div"
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Company*
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    <FormInput
                      sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name='companyName'
                      {...register("companyName", { required: true })}
                    />{" "}
                    <Box sx={{ color: "red", fontSize: "12px" }}>
                      {errors.companyName?.type === "required" &&
                        "Company name is required"}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    Item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      // component="div"
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Designation*
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    <FormInput
                      sx={{ backgroundColor: "#E0DFF4", borderRadius: "5px" }}
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      onChange={handleChange}
                      name='designation'
                      {...register("pan", {
                        required: "Designation is required",
                      })}
                    />
                    {errors.designation && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.designation.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>

                <Box>
                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name='acceptTerms'
                        defaultValue='false'
                        inputRef={register()}
                        render={({ field: { onChange } }) => (
                          <Checkbox
                            // checked={checked}
                            sx={{
                              color: "#223870",
                              "&.Mui-checked": {
                                color: "#223870",
                              },
                              "&$checked": {
                                color: "yellow",
                              },
                            }}
                            required
                            onChange={(e) => onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                    label={
                      <Typography
                        varient='p'
                        color={errors.acceptTerms ? "error" : "inherit"}
                        sx={{
                          fontSize: "12px",
                        }}>
                        I agree to the{" "}
                        <a href='/' style={{ color: "#223870" }}>
                          T&C and Privacy Policy
                        </a>{" "}
                        of the ExoTalent*
                      </Typography>
                    }
                  />
                  <br />
                  <Typography variant='inherit' color='textSecondary'>
                    {errors.acceptTerms
                      ? "(" + errors.acceptTerms.message + ")"
                      : ""}
                  </Typography>
                </Box>

                <Grid
                  container
                  direction='row'
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}>
                  {" "}
                  <Grid
                    item
                    // sx={{
                    //   justifyContent: 'center',
                    // }}
                  >
                    <Button
                      onClick={redirectProfile}
                      // type='submit'
                      sx={{
                        textTransform: "none",
                        textDecoration: "none",
                        padding: "4px 20px",
                        borderRadius: "3px ",
                        color: "#FDCF57",
                        fontWeight: "500",
                        fontSize: "20px",
                        backgroundColor: "#223870",
                        "&:hover": {
                          backgroundColor: "#223870",
                          color: "#FDCF57 ",
                        },
                      }}>
                      Signup
                    </Button>
                  </Grid>
                  <Grid
                    item
                    // sx={{
                    //   justifyContent: 'flex-end',
                    // }}
                  >
                    <Box mt={2}>
                      <a
                        href='/company/recruiter/login'
                        style={{
                          fontSize: "16px",
                          color: "#223870",
                        }}>
                        Login
                      </a>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CompanyRecruiterSignup;
