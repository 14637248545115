import React from "react";
import {
  Grid,
  Box,
  Button,
  MenuItem,
  Typography,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Select,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import jobStatus from "../../../../assets/Icons/Status.png";
import Close from "../../../../assets/Icons/Close.png";
import Logo from "../../../../assets/images/female.jpeg";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../../components/Header/Navbar";
import Avatar from "@mui/material/Avatar";
import TabComponent from "./tab";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  // container: { width: "auto", padding: "0", margin: "0" },

  topbar: {
    position: "relative",
    marginTop: "80px",
    padding: "20px 70px",
    backgroundSize: "cover",
    backgroundColor: "#F0F1FD",
    height: "auto",
    width: "100%",
    backgroundPosition: "center",
  },
  imgFrame: {
    background: "#fff",
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  img: {
    objectFit: "cover",
    margin: "0 auto",
    width: "120px",
    height: "120px",
    borderRadius: "50%",
    backgroundSize: "cover",
  },
  text: { marginLeft: "30px" },
  button: {
    position: "absolute",
    top: "55px",
    right: "120px",
  },
  body: {
    padding: "20px 100px",
    borderRadius: "10px",
    height: "auto",
    color: "#223870",
    backgroundColor: "#F6F8FD",
    textAlign: "center",
  },
  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "8.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  "@media (min-width: 1600px)": {
    mainHome: {
      marginTop: "80px",
      paddingTop: "150px",
    },
    title: {
      paddingRight: "60px ",
    },
  },
  "@media (maxWidth: 468px)": {
    mainHome: {
      padding: "50px 15px",
      backgroundImage: "none",
      backgroundColor: "#c6c2f6",
    },
    search: {
      marginRight: "0",
      padding: "3px 10px",
    },
    title: {
      fontSize: "22px",
      textAlign: "center",
      lineHeight: "35px",
    },
    container: {
      padding: "0 0px",
    },
  },
}));

const CandidateFeedback = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const [openstatus, setOpenstatus] = React.useState(false);
  const handleClickOpenstatus = () => {
    setOpenstatus(true);
  };
  const handleClosestatus = () => {
    setOpenstatus(false);
  };

  const [jobstatus, setJobstatus] = React.useState("Open");
  const handleChange = (event) => {
    setJobstatus(event.target.value);
  };
  return (
    <>
      <Navbar />
      <Box className={classes.container}>
        {" "}
        <Box className={classes.topbar}>
          <Stack direction='row'>
            <Box
              className={classes.imgFrame}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              {" "}
              <Avatar
                variant='square'
                src={
                  Logo
                  // candidateProfile?.profile_image
                  //   ? candidateProfile?.profile_image
                  //   : PersonIcon
                }
                sx={{ width: 120, height: 120 }}
              />
            </Box>
            <Box className={classes.text}>
              <Typography
                variant='h5'
                sx={{
                  marginTop: "10px",
                  fontSize: "24px",
                  textAlign: "left",
                  color: "#304672",
                  fontWeight: "600",
                }}>
                Neha Singh
              </Typography>
              <Typography
                variant='p'
                sx={{
                  textAlign: "left",
                  fontSize: "18px",
                  color: "#304672",
                  fontWeight: "500",
                }}>
                Director Engineering
              </Typography>
            </Box>
            <Box className={classes.button}>
              <Button
                // onClick={redirect}
                sx={{
                  marginRight: "40px",
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "8px 20px",
                  borderRadius: "5px ",
                  color: "#223870",
                  fontWeight: "500",
                  fontSize: "18px",
                  backgroundColor: "#F0F1FD",
                  "&:hover": {
                    backgroundColor: "#F0F1FD",
                    color: "#223870 ",
                  },
                }}>
                Applied
              </Button>
              <Button
                onClick={handleClickOpenstatus}
                sx={{
                  textTransform: "none",
                  textDecoration: "none",
                  padding: "8px 20px",
                  borderRadius: "5px ",
                  color: "#FDCF57",
                  fontWeight: "500",
                  fontSize: "18px",
                  backgroundColor: "#223870",
                  "&:hover": {
                    backgroundColor: "#223870",
                    color: "#FDCF57 ",
                  },
                }}>
                Change Status
              </Button>
              <Dialog
                open={openstatus}
                onClose={handleClosestatus}
                sx={{
                  // position: "relative",
                  "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                      width: "100%",
                      maxWidth: "500px",
                      height: "330px",
                      borderRadius: "8px",
                      backgroundColor: (theme) =>
                        theme.Palette.background.dialog,
                    },
                  },
                }}>
                <DialogActions>
                  <Box
                    onClick={handleClosestatus}
                    component='img'
                    src={Close}
                    sx={{
                      top: "10px",
                      right: "10px",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </DialogActions>
                <DialogTitle
                  sx={{
                    color: "#5677B9 ",
                    textAlign: "center",
                    fontSize: "24px",
                  }}>
                  Change Candidate Status
                </DialogTitle>
                <DialogContent>
                  <Stack
                    direction='row'
                    spacing={0}
                    sx={{ marginLeft: "30px" }}>
                    {" "}
                    <Box sm={2}>
                      {" "}
                      <Box
                        component='img'
                        src={jobStatus}
                        sx={{
                          width: "30px",
                          height: "30px",
                          marginTop: "40px",
                        }}
                      />
                    </Box>
                    <Box sm={10}>
                      <Grid container direction='Column'>
                        {" "}
                        <Grid item sx={{ margin: "20px 0px 5px 15px" }}>
                          {" "}
                          <Typography
                            variant='p'
                            gutterBottom
                            sx={{
                              fontSize: "18px",
                              color: "#304672",
                              fontWeight: "500",
                            }}>
                            Job Status
                          </Typography>{" "}
                        </Grid>
                        <Grid
                          Item
                          sx={{
                            "& > :not(style)": {
                              width: "36ch",
                            },
                          }}>
                          <FormControl
                            variant='standard'
                            sx={{ m: 0, mb: 2, mr: 2, maxWidth: 360 }}>
                            <Select
                              IconComponent={KeyboardArrowDownIcon}
                              className={classes.countrycode}
                              sx={{
                                color: "#5071C4",
                                fontWeight: "500",
                                backgroundColor: "#E8EEFB",
                                marginLeft: "15px",
                              }}
                              value={jobstatus}
                              labelId='demo-simple-select-standard-label'
                              id='demo-simple-select-standard'
                              onChange={handleChange}
                              renderValue={(selected) => {
                                console.log(selected);
                                if (!selected) return "Open";
                                else return selected;
                              }}>
                              <MenuItem
                                sx={{
                                  backgroundColor: "#E8EEFB",
                                  color: "#5071C4",
                                  fontWeight: "500",
                                }}
                                value='Applied'>
                                Applied
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  backgroundColor: "#E8EEFB",
                                  color: "#5071C4",
                                  fontWeight: "500",
                                }}
                                value='Shortlisted'>
                                Shortlisted
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  backgroundColor: "#E8EEFB",
                                  color: "#5071C4",
                                  fontWeight: "500",
                                }}
                                value='onHold'>
                                On Hold
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  backgroundColor: "#E8EEFB",
                                  color: "#5071C4",
                                  fontWeight: "500",
                                }}
                                value='Rejected'>
                                Rejected
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          Item
                          sx={{
                            textAlign: "center",
                            marginTop: "20px",
                          }}>
                          <Button
                            type='submit'
                            sx={{
                              padding: "4px 20px",
                              textTransform: "none",
                              textDecoration: "none",
                              borderRadius: "5px ",
                              color: "#FDCF57",
                              fontWeight: "500",
                              fontSize: "18px",
                              backgroundColor: "#223870",
                              "&:hover": {
                                backgroundColor: "#223870",
                                color: "#FDCF57 ",
                              },
                            }}>
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                </DialogContent>
              </Dialog>
            </Box>
          </Stack>
        </Box>
        <Box className={classes.body}>
          <Box sx={{ textAlign: "left" }}>
            <TabComponent />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default CandidateFeedback;
