import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Autocomplete,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormInput } from "../../components/Input/FormInput";
import CustomizedSnackbars from "../../components/Snackbar/CustomizedSnackbars";
import Close from "../../assets/Icons/Close.png";
import formBg from "../../assets/Icons/mobileFormBG.png";
import { useNavigate } from "react-router-dom";
import { countryListnew } from "../../assets/Data/data";
import { Experience } from "../../assets/Data/data";
import { Months } from "../../assets/Data/data";
import HomeBg from "../../assets/images/loginbg.png";
import { makeStyles } from "@mui/styles";
import Navbar from "../../components/Header/Navbar";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import * as candidateActions from "../../Store/candidate";
import * as helperActions from "../../Store/helper";
import OtpInput from "react-otp-input";
import OtpTimer from "../../components/OTP_Timer/OtpTimer";
import { v4 as uuidv4 } from "uuid";
const useStyles = makeStyles((theme) => ({
  containerbg: {
    marginTop: "-15px",
    backgroundImage: `url(${HomeBg})`,
    backgroundRepeat: "no-repeat",
    height: "100vh",
    width: "100vw",
    backgroundSize: "cover",
    display: "block",
    backgroundPosition: "center center",
    position: "static",
    overflow: "auto",
    justifyContent: "center",
    alignItems: "center",
  },
  grid: {
    position: "absolute",
  },
  form: {
    paddingTop: "80px",
    paddingRight: "100px",
    position: "relative",
    width: "100%",
    height: "100vh",
    overflowY: "scroll",
  },

  countrycode: {
    "& .MuiSelect-outlined": {
      padding: "8.5px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  "@media (maxWidth: 468px)": {
    containerbg: {
      backgroundImage: `url(${formBg})`,
      marginTop: "80px",
    },
    form: {
      margin: "0",
      padding: "0 10px",
      width: "100%",
    },
  },
}));

const CandidateRegister = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState({
    id: 240,
    iso2: "IN",
    country_name: "India",
    iso3: "IND",
    phone_code: 91,
  });
  const [isVerifyDialogOpen, setIsVerifyDialogOpen] = useState(false);
  const [message, setMessage] = React.useState("");
  const [months, setMonths] = React.useState();
  const handleMonths = (event) => {
    setMonths(event.target.value);
  };
  const [otp, setOtp] = useState("");
  const handleChangeOtp = (otp) => {
    setOtp(otp);
  };
  const [timer, setTimer] = useState(true);
  const timerZero = () => {
    setTimer(false);
  };
  const [open, setOpen] = React.useState(false);
  const [maxArrayValue, setMaxArrayValue] = useState([
    1, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
    24, 25,
  ]);
  const [minyear, setMinyear] = useState();
  const minYearChange = (event) => {
    setMinyear(event.target.value);
    let temp = [];
    for (let i = event.target.value + 1; i < 25; i++) {
      temp.push(i);
    }
    setMaxArrayValue(temp);
  };
  const [xauth, setXauth] = useState("");
  const handlexAuth = (data) => {
    setXauth(data);
  };

  const [isFresher, setIsFresher] = useState(false);

  const handleFresherChange = (e) => {
    setIsFresher(e.target.checked);
  };
  const [msg, setMsg] = React.useState("");
  useSelector((state) => state?.candidate?.candidateSignUp);
  const openSnackbar = useSelector((state) => state?.message?.openSnackbar);

  useEffect(() => {
    window.onbeforeunload = async function () {
      await dispatch(candidateActions.resetCandidateDetails());
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const candidateMobileVerification = useSelector(
    (state) => state?.candidate?.candidateMobileVerification
  );
  const candidateProfile = useSelector(
    (state) => state?.candidate?.candidateProfile
  );
  console.log("candidateProfile", candidateProfile);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({ mode: "onTouched" });
  const [requestId, setRequestId] = React.useState(false);
  const mobile = watch("mobile");

  const handleClickOpenMobile = async () => {
    if (!errors.mobile) {
      setMessage("");
      const data = {
        countryCode: 91,
        phoneNo: Number(mobile),
      };
      const sendOtp = await dispatch(
        candidateActions.checkCandidateMobile(data)
      );
      if (sendOtp && sendOtp.data && sendOtp.data?.data?.requestId) {
        setRequestId(sendOtp.data?.data?.requestId);

        setIsVerifyDialogOpen(true);
      }
    }
  };

  const verifyCandidateMobileOtp = async () => {
    console.log("otp", otp);
    setMessage("");
    const data = {
      requestId: requestId,
      otp: Number(otp),
    };
    const verifyotpMobile = await dispatch(
      candidateActions.verifyCandidateMobileOtp(data)
    );
    setMessage(verifyotpMobile?.data?.msg);
    setOtp("");

    console.log("verifyotpMobile", verifyotpMobile);
    handlexAuth(verifyotpMobile?.headers["x-auth"]);
  };
  const handleCloseMobile = async () => {
    await dispatch(candidateActions.closeCandidateMobileDialog());
    setIsVerifyDialogOpen(false);
  };

  useEffect(() => {
    if (candidateMobileVerification) {
      setIsVerifyDialogOpen(false);
    }
  }, [candidateMobileVerification]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const handleUploadJd = (event) => {
    // debugger;
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileError("");
  };

  const onSubmit = async (details) => {
    console.log("detail", details);
    if (!selectedFile) {
      setFileError("Please upload your updated CV");
      return;
    }
    const commonData = {
      requestId: requestId,
      role: "candidate",
      firstName: details?.firstName,
      lastName: details?.lastName,
      countryCode: 91,
      phoneNo: Number(details?.mobile),
      emailId: details?.email,
      location: details?.current_location,
    };
    let data;
    if (isFresher) {
      data = {
        ...commonData,
      };
    } else {
      data = {
        ...commonData,
        totalExperience: {
          years: Number(details?.years),
          months: Number(details?.months),
        },
        ctc: {
          lakhs: details?.current_ctc_lacs,
          thousands: details?.current_ctc_thousand,
        },
        location: details?.current_location,
        organization: details?.companyName,
        designation: details?.designation,
      };
    }
    const signupcandidate = await dispatch(
      candidateActions.signupCandidate(data)
    );
    console.log("signupcandidate??", signupcandidate);

    if (signupcandidate.data?.status === 1) {
      const fileData = new FormData();
      const userId = signupcandidate?.data?.data?.session?.userId;

      const key = "posts/" + userId;
      fileData.append("key", key);
      fileData.append("files", selectedFile, selectedFile.name);
      console.log("UPLOADED FILE", selectedFile);

      const uploadFile = await dispatch(candidateActions.uploadFile(fileData));

      console.log("File Upload Response:", uploadFile);

      if (uploadFile.data?.status === 1) {
        setOpen(true);
        await dispatch(candidateActions.getUserEducation());
        await dispatch(candidateActions.getUserExperience());
        await dispatch(candidateActions.getCandidateProfile());
        navigate("/candidate/editprofile");
      }
    }
  };
  return (
    <>
      <Navbar />
      {openSnackbar && <CustomizedSnackbars />}
      <Box className={classes.containerbg}>
        <Grid container className={classes.grid}>
          <Grid item xs={12} sm={7}></Grid>{" "}
          <Grid item xs={12} sm={5} className={classes.form}>
            <Typography
              variant='h4'
              sx={{
                marginTop: "50px",
                marginBottom: "30px",
                textAlign: "center",
                fontWeight: 500,
              }}>
              Candidate Signup
            </Typography>
            <Box
              sx={{
                paddingBottom: "40px",
              }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                  container
                  sm={12}
                  sx={{
                    width: "100%",
                    overflowY: "auto",
                  }}>
                  {" "}
                  <Grid
                    item
                    sm={6}
                    md={6}
                    sx={{
                      width: "50%",
                    }}>
                    <Grid container direction='column'>
                      {" "}
                      <Grid item>
                        {" "}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          First Name*
                        </Typography>{" "}
                      </Grid>
                      <Grid item sx={{ marginRight: "6px" }}>
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='firstName'
                          {...register("firstName", {
                            required: "First name is required",
                            minLength: {
                              value: 1,
                              message: "First name must be atleast 1 character",
                            },
                          })}
                        />
                        {errors.firstName && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.firstName.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={6}
                    sx={{
                      width: "50%",
                    }}>
                    <Grid container direction='column'>
                      {" "}
                      <Grid item>
                        {" "}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                            marginLeft: "8px",
                          }}>
                          Last Name*
                        </Typography>{" "}
                      </Grid>
                      <Grid item sx={{ marginLeft: "6px" }}>
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='lastName'
                          {...register("lastName", {
                            required: "Last name is required",
                            minLength: {
                              value: 1,
                              message: "Last name must be atleast 1 character",
                            },
                          })}
                        />
                        {errors.lastName && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.lastName.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* other inputs */}
                <Grid container direction='Column'>
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Email ID*
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    <Box
                      sm={12}
                      sx={{
                        width: "100%",
                      }}>
                      {" "}
                      <Stack direction='row' spacing={0}>
                        <FormInput
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                          name='email'
                          fullWidth
                          {...register("email", {
                            required: "Email Id is required",
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "Invalid email address",
                            },
                          })}
                        />
                      </Stack>
                      {errors.email && (
                        <Box sx={{ color: "red", fontSize: "12px" }}>
                          {" "}
                          {errors.email.message}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Mobile No.*
                    </Typography>{" "}
                  </Grid>
                  <Grid container>
                    <Grid item sm={4} sx={{ paddingRight: "10px" }}>
                      <Autocomplete
                        id='mobile-s-demo'
                        sx={{ width: "100% " }}
                        value={selectedCountry}
                        options={countryListnew}
                        autoHighlight
                        disableClearable
                        variant='standard'
                        popupIcon={
                          <KeyboardArrowDownIcon
                            sx={{
                              color: "#5071C4",
                            }}
                          />
                        }
                        name='country_code'
                        onChange={(_event, country) => {
                          console.log("country", country);
                          setSelectedCountry(country);
                        }}
                        size='small'
                        getOptionLabel={(option) => {
                          return option.iso3 + " +" + option.phone_code;
                        }}
                        renderInput={(params) => (
                          <FormInput
                            sx={{
                              backgroundColor: "#E0DFF4",
                              borderRadius: "5px",
                              height: 38.5,
                            }}
                            size='small'
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item sm={8}>
                      <Stack direction='row' spacing={0}>
                        <FormInput
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "5px 0px 0px 5px",
                          }}
                          name='mobile'
                          fullWidth
                          {...register("mobile", {
                            required: "Mobile Number is required",
                            pattern: {
                              value: /^\d{10}$/,
                              message: "Enter a valid 10 digit mobile no.",
                            },
                          })}
                        />
                        <Box
                          sx={{
                            marginLeft: "-20px",
                            backgroundColor: "#E0DFF4",
                            padding: "3px 0px",
                            borderRadius: "0 5px 5px 0px",
                            "@media (maxWidth: 468px)": {
                              fontSize: "14px",
                            },
                          }}>
                          <Button
                            onClick={handleClickOpenMobile}
                            variant='standard'
                            disabled={
                              candidateMobileVerification || errors.mobile
                            }
                            sx={{
                              marginRight: "4px",
                              backgroundColor: "#223870",
                              borderRadius: "3px",
                              color: candidateMobileVerification
                                ? "#FDCF57!important"
                                : "#FDCF57",
                              marginTop: "1px",
                              padding: "2px 20px",
                              fontSize: "16px",
                              textTransform: "capitalize",

                              "&:hover": {
                                color: "#FDCF57",
                                backgroundColor: "#223870",
                              },
                              "@media (maxWidth: 468px)": {
                                textAlign: "center",
                                padding: "0 10px",
                              },
                            }}>
                            {candidateMobileVerification
                              ? "Verified"
                              : "Verify"}
                          </Button>

                          <Dialog
                            open={isVerifyDialogOpen}
                            onClose={handleCloseMobile}
                            sx={{
                              "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                  width: "100%",
                                  maxWidth: "590px",
                                  height: "430px",
                                  borderRadius: "10px",
                                  backgroundColor: (theme) =>
                                    theme.Palette.background.dialog,
                                },
                              },
                            }}>
                            <DialogActions>
                              <Box
                                onClick={handleCloseMobile}
                                component='img'
                                src={Close}
                                sx={{
                                  top: "10px",
                                  right: "10px",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            </DialogActions>
                            <DialogTitle
                              sx={{
                                color: "#223870",
                                textAlign: "center",
                                fontSize: "22px",
                                marginTop: "-15px",
                              }}>
                              Verify Mobile No.
                            </DialogTitle>
                            <DialogContent
                              sx={{
                                marginTop: "20px ",
                                textAlign: "center",
                              }}>
                              {" "}
                              <Box>
                                {" "}
                                <Typography
                                  variant='p'
                                  gutterBottom
                                  sx={{
                                    fontSize: "18px",
                                    color: "#5071C4",
                                    fontWeight: "500",
                                  }}>
                                  OTP sent to {mobile}
                                </Typography>{" "}
                              </Box>
                              <Box
                                sx={{
                                  marginTop: "20px",
                                  marginBottom: "10px",
                                }}>
                                {" "}
                                <Typography
                                  variant='h6'
                                  sx={{
                                    color: "#223870",
                                    fontWeight: "500",
                                  }}>
                                  Enter OTP
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}>
                                <OtpInput
                                  value={otp}
                                  onChange={handleChangeOtp}
                                  numInputs={6}
                                  inputStyle={{
                                    height: "35px",
                                    width: "45px",
                                    color: "#5071C4",
                                    backgroundColor: "#E0DFF4",
                                    border: "1px solid #223870",
                                    margin: "4px",
                                    fontSize: "18px",
                                    borderRadius: "3px",
                                  }}
                                  focusStyle={{
                                    border: "1px solid #223870",
                                  }}
                                />
                              </Box>
                              <Box>
                                {message === "Wrong OTP" && (
                                  <Typography
                                    variant='h5'
                                    sx={{
                                      color: "ff0000",
                                      fontWeight: "500",
                                      fontSize: "12px",
                                    }}>
                                    {message}
                                  </Typography>
                                )}
                              </Box>
                              <Box sx={{ marginTop: "5px" }}>
                                <OtpTimer
                                  resendOtp={handleClickOpenMobile}
                                  timerzero={timerZero}
                                />
                              </Box>
                              <Box sx={{ marginTop: "40px " }}>
                                {" "}
                                <Button
                                  onClick={verifyCandidateMobileOtp}
                                  sx={{
                                    padding: "4px 20px",
                                    textTransform: "none",
                                    textDecoration: "none",
                                    borderRadius: "3px ",
                                    color: "#FDCF57",
                                    fontWeight: "500",
                                    fontSize: "18px",
                                    backgroundColor: "#223870",
                                    "&:hover": {
                                      backgroundColor: "#223870",
                                      color: "#FDCF57 ",
                                    },
                                  }}>
                                  Verify
                                </Button>
                              </Box>
                            </DialogContent>
                          </Dialog>
                        </Box>
                      </Stack>

                      {!candidateMobileVerification && !errors.mobile && (
                        <Typography sx={{ color: "red", fontSize: "12px" }}>
                          Please verify your mobile number.
                        </Typography>
                      )}
                      {!candidateMobileVerification && errors.mobile && (
                        <Typography sx={{ color: "red", fontSize: "12px" }}>
                          {errors.mobile.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container direction='column'>
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Current Location*
                    </Typography>{" "}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      "& > :not(style)": {
                        width: "100%",
                      },
                    }}>
                    {" "}
                    <FormInput
                      size='small'
                      variant='outlined'
                      type='text'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      name='current_location'
                      {...register("current_location", {
                        required: "Current Location is required",
                        minLength: {
                          value: 1,
                        },
                      })}
                    />
                    {errors.current_location && (
                      <Box sx={{ color: "red", fontSize: "12px" }}>
                        {" "}
                        {errors.current_location.message}
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Box mt={1}>
                  {" "}
                  <Checkbox
                    checked={isFresher}
                    onChange={handleFresherChange}
                  />
                  <Typography
                    variant='p'
                    gutterBottom
                    sx={{
                      color: "#304672",
                      fontWeight: "500",
                    }}>
                    I'm a Fresher
                  </Typography>{" "}
                </Box>
                {!isFresher && (
                  <>
                    <Grid container direction='column'>
                      {" "}
                      <Grid
                        item
                        sx={{
                          marginTop: "10px",
                        }}>
                        {" "}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          Company Name*
                        </Typography>{" "}
                      </Grid>
                      <Grid
                        item
                        sx={{
                          "& > :not(style)": {
                            width: "100%",
                          },
                        }}>
                        {" "}
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='companyName'
                          {...register("companyName", {
                            required: "Company Name is required",
                            minLength: {
                              value: 3,
                              message:
                                "Company Name must be atleast 3 characters",
                            },
                            maxLength: {
                              value: 60,
                              message: "Maximum 60 characters allowed",
                            },
                          })}
                        />
                        {errors.companyName && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.companyName.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container direction='column'>
                      {" "}
                      <Grid
                        item
                        sx={{
                          marginTop: "10px",
                        }}>
                        {" "}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          Designation*
                        </Typography>{" "}
                      </Grid>
                      <Grid
                        item
                        sx={{
                          "& > :not(style)": {
                            width: "100%",
                          },
                        }}>
                        {" "}
                        <FormInput
                          sx={{
                            backgroundColor: "#E0DFF4",
                            borderRadius: "5px",
                          }}
                          size='small'
                          variant='outlined'
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name='designation'
                          {...register("designation", {
                            required: "Designation is required",
                            minLength: {
                              value: 1,
                            },
                            maxLength: {
                              value: 60,
                              message: "Maximum 60 characters allowed",
                            },
                          })}
                        />
                        {errors.designation && (
                          <Box sx={{ color: "red", fontSize: "12px" }}>
                            {" "}
                            {errors.designation.message}
                          </Box>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container direction='Column'>
                      {" "}
                      <Grid
                        item
                        sx={{
                          marginTop: "10px",
                        }}>
                        {" "}
                        <Typography
                          variant='p'
                          gutterBottom
                          sx={{
                            color: "#304672",
                            fontWeight: "500",
                          }}>
                          Experience*
                        </Typography>{" "}
                      </Grid>
                      <Grid item>
                        {" "}
                        <Grid
                          container
                          sx={{
                            width: "100%",
                            paddingRight: "10px",
                          }}>
                          <Grid item sm={6} md={6} sx={{ width: "50%" }}>
                            <Select
                              IconComponent={() => (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    color: "#5071C4",
                                    position: "absolute",
                                    right: 10,
                                    pointerEvents: "none",
                                  }}
                                />
                              )}
                              sx={{
                                width: "100%",
                                marginRight: "10px",
                                color: "#5071C4",
                                backgroundColor: "#E0DFF4",
                                fontWeight: "500",
                              }}
                              value={minyear}
                              name='years'
                              onChange={minYearChange}
                              className={classes.countrycode}
                              {...register("years", {
                                required: "Select your experience in years ",
                              })}>
                              {Experience?.map((option) => {
                                return (
                                  <MenuItem
                                    key={option.label}
                                    value={option.label}
                                    sx={{
                                      color: "#5071C4",
                                      fontWeight: "700",
                                    }}>
                                    {option.value ?? option.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>{" "}
                            {errors.years && (
                              <Box sx={{ color: "red", fontSize: "12px" }}>
                                {" "}
                                {errors.years.message}
                              </Box>
                            )}
                          </Grid>
                          <Grid item sm={6} md={6} sx={{ width: "50%" }}>
                            <Select
                              IconComponent={() => (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    color: "#5071C4",
                                    position: "absolute",
                                    right: 10,
                                    pointerEvents: "none",
                                  }}
                                />
                              )}
                              sx={{
                                width: "100%",
                                color: "#5071C4",
                                backgroundColor: "#E0DFF4",
                                fontWeight: "500",
                                marginLeft: "10px",
                              }}
                              name='months'
                              value={months}
                              onChange={handleMonths}
                              className={classes.countrycode}
                              {...register("months", {
                                required: "Select your experience in months",
                              })}>
                              {Months?.map((option) => {
                                return (
                                  <MenuItem
                                    key={option.label}
                                    value={option.label}
                                    sx={{
                                      color: "#5071C4",
                                      fontWeight: "700",
                                    }}>
                                    {option.value ?? option.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>{" "}
                            {errors.months && (
                              <Box
                                sx={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginLeft: "10px",
                                }}>
                                {" "}
                                {errors.months.message}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sm={12}
                      sx={{
                        marginTop: "8px",
                        width: "100%",
                      }}>
                      {" "}
                      <Grid item sm={6} md={6} sx={{ width: "50%" }}>
                        <Grid container direction='column'>
                          {" "}
                          <Grid item>
                            {" "}
                            <Typography
                              variant='p'
                              gutterBottom
                              sx={{
                                color: "#304672",
                                fontWeight: "500",
                              }}>
                              Current CTC{" "}
                              <span
                                style={{
                                  color: "#5071C4",
                                  fontSize: "12px",
                                }}>
                                (in lacs)*
                              </span>
                            </Typography>{" "}
                          </Grid>
                          <Grid item sx={{ marginRight: "6px" }}>
                            <Select
                              IconComponent={() => (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    color: "#5071C4",
                                    position: "absolute",
                                    right: 10,
                                    pointerEvents: "none",
                                  }}
                                />
                              )}
                              sx={{
                                width: "100%",
                                marginRight: "10px",
                                color: "#5071C4",
                                backgroundColor: "#E0DFF4",
                                fontWeight: "500",
                              }}
                              name='current_ctc_lacs'
                              className={classes.countrycode}
                              {...register("current_ctc_lacs", {
                                required: "Enter your current CTC in lacs",
                              })}>
                              {[...Array(50)]?.map((option, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={index}
                                    sx={{
                                      color: "#5071C4",
                                      fontWeight: "700",
                                    }}>
                                    {index}
                                  </MenuItem>
                                );
                              })}
                            </Select>{" "}
                            {errors.current_ctc_lacs && (
                              <Box sx={{ color: "red", fontSize: "12px" }}>
                                {" "}
                                {errors.current_ctc_lacs.message}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={6} md={6} sx={{ width: "50%" }}>
                        <Grid container direction='column'>
                          {" "}
                          <Grid item>
                            <span
                              style={{
                                marginLeft: "12px",
                                color: "#5071C4",
                                fontSize: "12px",
                                fontWeight: 500,
                              }}>
                              (in thousands)*
                            </span>
                          </Grid>
                          <Grid item sx={{ marginLeft: "6px" }}>
                            <Select
                              IconComponent={() => (
                                <KeyboardArrowDownIcon
                                  sx={{
                                    color: "#5071C4",
                                    position: "absolute",
                                    right: 10,
                                    pointerEvents: "none",
                                  }}
                                />
                              )}
                              sx={{
                                width: "100%",
                                marginRight: "10px",
                                color: "#5071C4",
                                backgroundColor: "#E0DFF4",
                                fontWeight: "500",
                              }}
                              name='current_ctc_thousand'
                              className={classes.countrycode}
                              {...register("current_ctc_thousand", {
                                required: "Enter your current ctc in thousand",
                              })}>
                              {[...Array(10)]?.map((option, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={index}
                                    sx={{
                                      color: "#5071C4",
                                      fontWeight: "700",
                                    }}>
                                    {index * 10}
                                  </MenuItem>
                                );
                              })}
                            </Select>{" "}
                            {errors.current_ctc_thousand && (
                              <Box sx={{ color: "red", fontSize: "12px" }}>
                                {" "}
                                {errors.current_ctc_thousand.message}
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container direction='Column'>
                  {" "}
                  <Grid
                    item
                    sx={{
                      marginTop: "10px",
                    }}>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#304672",
                        fontWeight: "500",
                      }}>
                      Upload CV*
                    </Typography>{" "}
                  </Grid>
                  <Grid item>
                    <Box>
                      <label htmlFor='icon-button-image'></label>
                      <input
                        id='icon-button-image'
                        type='file'
                        accept='.pdf, .doc, .docx, .rtf'
                        style={{
                          width: "100%",
                          padding: "10px",
                          display: "block",
                          borderRadius: "5px",
                          backgroundColor: "#E0DFF4",
                          color: "#304672",
                          cursor: "pointer",
                        }}
                        onChange={handleUploadJd}
                        name='image'
                      />
                    </Box>
                    <Box style={{ color: "red", fontSize: "12px" }}>
                      {fileError}
                    </Box>
                  </Grid>
                  <Box>
                    {" "}
                    <Typography
                      variant='p'
                      gutterBottom
                      sx={{
                        color: "#615d5d",
                        fontSize: "10px",
                      }}>
                      DOC, DOCx, PDF, RTF l Max: 2 MB
                    </Typography>
                  </Box>
                </Grid>

                <Box>
                  <FormControlLabel
                    control={
                      <Controller
                        control={control}
                        name='acceptTerms'
                        defaultValue='false'
                        inputRef={register()}
                        render={({ field: { onChange } }) => (
                          <Checkbox
                            required
                            onChange={(e) => onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                    label={
                      <Typography
                        varient='p'
                        color={errors.acceptTerms ? "error" : "inherit"}
                        sx={{
                          fontSize: "12px",
                        }}>
                        I agree to the{" "}
                        <a href='/' style={{ color: "#223870" }}>
                          T&C and Privacy Policy
                        </a>{" "}
                        of the ExoTalent*
                      </Typography>
                    }
                  />
                  <br />
                  <Typography variant='inherit' color='textSecondary'>
                    {errors.acceptTerms
                      ? "(" + errors.acceptTerms.message + ")"
                      : ""}
                  </Typography>
                </Box>
                <Grid
                  container
                  direction='Row'
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                  }}>
                  {" "}
                  <Grid
                    item
                    sx={{
                      justifyContent: "center",
                    }}>
                    {" "}
                    <Button
                      type='submit'
                      sx={{
                        textTransform: "none",
                        textDecoration: "none",
                        padding: "3px 25px",
                        borderRadius: "3px ",
                        color: "#FDCF57",
                        fontWeight: "500",
                        fontSize: "20px",
                        backgroundColor: "#223870",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#223870",
                          color: "#FDCF57 ",
                        },
                      }}>
                      Signup
                    </Button>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      justifyContent: "flex-end",
                    }}>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: "10px",
                        right: "5px",
                      }}>
                      <a
                        href='/candidate/login'
                        style={{
                          fontSize: "16px",
                          color: "#223870",
                        }}>
                        Login
                      </a>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default CandidateRegister;
